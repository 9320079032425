export const dashboardCount = `
  query {
    allBusinesses {
      totalCount
    }
    allWallets {
      totalCount
    }
    allPaymentRequests {
      totalCount
    }
    id1: allUserdata(where: { role: "PARTICIPANT", active: 1 }) {
      totalCount
    }
    id2: allUserdata(where: { role: "GUARDIAN", active: 1 }) {
      totalCount
    }
    id3: allUserdata(where: { role: "SUPPORTER", active: 1 }) {
      totalCount
    }
  }
`;

export const guardianDashboardCount = (id) => `
  query {
    allBusinesses(where: {active: 1 }) {
      totalCount
    }
    id1: allUserdata(where: {guardianId:${id},role: "PARTICIPANT", active: 1 }) {
      totalCount
    }
    id3: allUserdata(where: {role: "SUPPORTER", active: 1 }) {
      totalCount
    }
  }
`;

export const paymentGuardianRequestAllQuery = (guardianId) => `
query{
  allPaymentRequests{
    totalCount
			PaymentRequests{
      id
      fkPaymentRequestParticipantIdrel(where:{ guardianId : ${guardianId}  }){
        Userdata{
          id
        }
      }
    }
  }
}`;

export const getGuardianParticipant = `
query($where:JSON){
  allUserdata(where:$where){
    totalCount
    Userdata{
      id
      firstname
      lastname
    }
  }
}
`;

export const superLedgerQuery = `query allSuperLedgers($where:JSON){
  allSuperLedgers (where:$where) {
    totalCount
    SuperLedgers {
      id
      amountAdded
      businessId
      userId
      txnType
      createdAt
      cardlimit
      txnId
      fkuseridsuperledgermaprel {
        Userdata {
          id
          role
          firstname
          lastname
        }
      }
      fkbusinessidsuperledgermaprel {
        Businesses {
          id
          storeName
          txnLocationCity
          txnLocationCountry
        }
      }
      fkcarddetailiimaprel {
        CardDetails {
          id
          cardLimit
          cardNumber
        }
      }
      fkcreatebysuperledgermaprel{
        Userdata{
          firstname
          lastname
        }
      }
      fkpaymentmaprel{
        PaymentRequests{
          id
          extraNotes
          itemCategoryId
        }
      }
    }
  }
}`;

export const remainingBudgetQueryData = `
query($where:JSON){
  allBudgets(where:$where){
        Budgets{
        budgetAvailable
      }
  }  
}
`;

export const guardianPartiQuery = `
 query($where:JSON){
  allUserdata(where:$where){
    Userdata{
      id
      firstname
      role
    }
  }
}`;

export const guardianBusinessQuery = `query($where:JSON){
  allParticipantConnectedBusinesses(where:$where){
    totalCount
    ParticipantConnectedBusinesses{
      storeId
      fkParticipantConnectedBusinessStoreIdrel{
        Businesses{
          id
          storeName
          email
          abnNumber
          mobileNo
          contactLocationCity
          contactLocationAdress
        }
      }
    }
  }
}`;

export const getGuardianParticipantConnectedSupporter = `
query($where:JSON){
  allParticipantConnectedSupporters(where:$where){
    totalCount
    ParticipantConnectedSupporters{
      supporterId
      isSupporterFlagged
      fkParticipantConnectedSupporterIdrel{
        Userdata{
          firstname
          lastname
          phonenumber
          address
          city
          dob
          email
          active
        }
      }
    }
  }
}
`;

export const paymentGuardianRequestDataQuery = `
query($where:JSON, $first: Int, $last: Int){
  allPaymentRequests(where:$where,first: $first, last:$last){
    totalCount
    PaymentRequests {
      id
      isrepeatpay
      participantId
      supporterId
      businessMemberId
      extraNotes
      paymentStatus
      isverified
      requestedAmount
      createdAt
      isApproxAmount
      requestedAmount
      productImg
      createdBy
      storeId
      itemDetail
      externaltransid
      isrelated
      cardId
      createdbycardid
      itemCategoryId
      authledgerPaymentRequestMap{
        Authledgers{
                status
          spentamount 
          requestedamount
         merchantname
          changeamount
              }
        }
      fk_dispute_payment_request_id {
        Disputes{
         id
          paymentRequestId
         disputeStatus
         createdAt
         createdBy
       }
       }
      fk_request_item_image_payment_request_id{
        RequestItemImages{
          itemImageUrl
        }
      }
      fkPaymentRequestParticipantIdrel {
        Userdata {
          id
          role
          firstname
          lastname
        }
      }
      fkPaymentRequestBusinessMemberIdrel {
        Userdata {
          id
          firstname
          lastname
        }
      }
      fkPaymentRequestStoreIdrel {
        Businesses {
          id
          storeName
        }
      }
      fkPaymentRequestSupporterIdrel {
        Userdata {
          id
          role
          firstname
          lastname
        }
      }
      fkPaymentRequestItemCategoryIdrel {
        MasterItemCategories {
          categoryName
          id
        }
      }
      fkpaymentrequestcreatebymaprel {
        Userdata {
          id
          role
          firstname
          lastname
          profileimage
        }
      }
      fkPaymentCardIdRelrel{
        CardDetails{
          cardLimit
        }
      }
    }
  }
}`;

export const getParticipantBarGraphDataQuery = `
mutation BudgetExpenses($participantId:Int,$duration:String,$durationValue:Int){
    BudgetExpenses(participantId:$participantId,duration:$duration,durationValue:$durationValue)
}
`;

export const getPieGraphDataQuery = `
mutation BudgetSpendingBySupportWorker($guardianId:Int,$duration:String,$durationValue:Int){
  BudgetSpendingBySupportWorker(guardianId:$guardianId,duration:$duration,durationValue:$durationValue)
}
`;

export const getPieGraphDataQueryNew = `
mutation BudgetSpendingBySupportWorkerNew($participantId:Int,$duration:String,$durationValue:Int){
  BudgetSpendingBySupportWorkerNew(participantId:$participantId,duration:$duration,durationValue:$durationValue)
}
`;
