import React, { Component } from "react";
import FundAllocation from "./ActionDropDown/FundAllocation";
import "./participants.scss";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { fetchMethod } from "../../FetchMethod";

import { getParticipantWallets } from "src/Components/Budget/BudgetQuery";

export default class EditFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletData: [],
      selectedWallet: null,
      userId: undefined
    };
  }

  componentDidMount() {
    const role = localStorage.getItem("role");
    if (role !== "ADMIN") this.props.history.push("/participants");
    const userId = parseInt(this.props.match.params?.participantId);
    if (isNaN(userId)) this.props.history.push("/participants");
    this.setState({ userId });
    this.getParticipantWallets(userId);
  }
  getParticipantWallets = async (participantId) => {
    await fetchMethod(getParticipantWallets, {
      where: { participantId, status: 1 }
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          walletData: res.data.allWallets?.Wallets?.map((item) => {
            return {
              id: item.id,
              name: item.description,
              balance: parseFloat(item.balance)
            };
          })
        });
      });
  };

  render() {
    const { selectedWallet } = this.state;
    return (
      <div style={{ padding: "20px" }}>
        <div className="walletDropDown" style={{ marginBottom: "4rem" }}>
          <h2>Wallets</h2>
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            options={this.state.walletData}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Wallets" />}
            onChange={(event, value) => {
              if (value) {
                this.setState({ selectedWallet: value });
              } else {
                this.setState({ selectedWallet: null });
              }
            }}
          />
        </div>

        {selectedWallet && (
          <div className="editFundsWrapper">
            <h2>Add/Remove Funds</h2>
            <FundAllocation
              walletId={selectedWallet?.id}
              balance={selectedWallet?.balance}
            />
          </div>
        )}
      </div>
    );
  }
}
