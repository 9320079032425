import React, { Component } from "react";
import moment from "moment";
import FormComponent from "../../Form/FormComponent";
import { addGuardianParticipantConfig } from "./ParticipantConfig";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import { saveUserdata, editParticipantData } from "./ParticipantQuery";
import "./addParticipant.css";
import "./UserCard/user_card.scss";
import BackdropLoader from "../../services/loader";
import Delete from "../../assets/svg/delete.svg";
import Edit from "../../assets/images/uploadIcon.png";

import S3Image from "../S3Image";
import { uploadImage } from "src/network/service/imageService";
export default class AddGuardianParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guardianName: undefined,
      cardtypeOptions: undefined,
      loading: false,
      file: null,
      filePreview: null
    };
  }

  async componentWillMount() {
    if (this.props.participantId) {
      this.editParticipantData(this.props.participantId);
    }
  }

  editParticipantData = async (id) => {
    this.setState({ ...this.state, loading: true });
    await fetchMethod(editParticipantData(id))
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          editableData: res.data.allUserdata.Userdata[0],
          file: res?.data?.allUserdata?.Userdata[0]?.profileimage
            ? res?.data?.allUserdata?.Userdata[0]?.profileimage
            : null,
          filePreview: res?.data?.allUserdata?.Userdata[0]?.profileimage
            ? res?.data?.allUserdata?.Userdata[0]?.profileimage
            : null
        });
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.props.onClose();
        // this.props.history.push("/participants");
      });
    this.setState({ ...this.state, loading: false });
  };

  closeForm = () => {
    this.props.onClose();
    // this.props.history.push("/participants");
  };

  preSubmitChanges = async (e) => {
    this.setState({ ...this.state, loading: true });

    let userObj = {
      firstname: e.firstname,
      lastname: e.lastname,
      dob: e.dob ? moment(e.dob).format("YYYY-MM-DD") : null,
      email: e.email,
      phonenumber: e.phonenumber,
      address: e.address,
      city: e.city,
      state: e.state,
      country: e.country,
      zipcode: e.zipcode,
      active: 1,
      role: "PARTICIPANT",
      createdby: JSON.parse(localStorage.getItem("userInfo")).id,
      updatedby: JSON.parse(localStorage.getItem("userInfo")).id
    };
    if (this.state.file && typeof this.state.file != "string") {
      let fileUploadRes = await this.uploadFile(this.state.file);
      if (fileUploadRes?.data) {
        userObj["profileimage"] = fileUploadRes.data;
      }
    } else if (typeof this.state.file == "string") {
      console.log("first--------------------->");
    } else {
      userObj["profileimage"] = null;
    }

    if (this.state.editableData) {
      userObj["id"] = this.state.editableData.id;
    }
    if (localStorage.getItem("role") === "GUARDIAN") {
      e["guardianId"] = JSON.parse(localStorage.getItem("userInfo")).id;
    }

    e["guardianId"] =
      e.guardianId === null
        ? null
        : typeof e["guardianId"] == "object"
        ? e.guardianId.id
        : (e["guardianId"] = JSON.parse(localStorage.getItem("userInfo")).id);
    userObj.guardianId = e.guardianId;
    await fetchMethod(saveUserdata, { obj: userObj })
      .then((res) => res.json())
      .then((response) => {
        const id = response.data.saveUserdata;
        if (id && id !== null) {
          swal({
            text: this.state.editableData
              ? "Participant updated successfully"
              : "Participant added successfully",
            icon: "success"
          });
          this.props.onClose("refresh");
          this.setState({ ...this.state, loading: false });
          // this.props.history.push("/participants");
        } else if (id === null) {
          swal("Email already exists", { icon: "error" });
          this.setState({ ...this.state, loading: false });
        } else {
          swal("Please try again", { icon: "error" });
          this.props.onClose();
          this.setState({ ...this.state, loading: false });
          // this.props.history.push("/participants");
        }
      });
  };

  onImageChange = (event) => {
    this.setState({
      file: event.target.files[0],
      filePreview: URL.createObjectURL(event.target.files[0])
    });
  };

  resetImage = (event) => {
    event.preventDefault();
    this.setState({
      file: null,
      filePreview: "empty"
    });
  };

  uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return uploadImage.catch((err) => {
      swal("Please try again", { icon: "error" });
    });
  };

  render() {
    return (
      <div>
        <BackdropLoader open={this.state.loading} />
        {!this.state.loading ? (
          <div className="addGuardianParticipantSection">
            <h3>
              {this.state.editableData ? "Edit Participant" : "Add Participant"}
            </h3>
            {this.state.editableData ? (
              <div style={{ bacgroundColor: "#D3DAE6" }} className="user_card ">
                {this.state.filePreview !== "empty" &&
                (this.state.file || this.state.editableData.profileimage) ? (
                  <div style={{ maxWidth: "70px" }} className="userPic">
                    {this.state.filePreview && this.state.file ? (
                      <S3Image
                        src={this.state.filePreview}
                        alt={this.state.editableData.firstname}
                      />
                    ) : (
                      <S3Image
                        src={this.state.editableData.profileimage}
                        alt={this.state.editableData.firstname}
                      />
                    )}
                  </div>
                ) : (
                  <div className="initial">
                    {this.state.editableData.firstname.charAt(0)}
                  </div>
                )}
                <button>
                  <label
                  // onClick={(e) => this.editParticipantImage()}
                  >
                    <input
                      style={{ display: "none" }}
                      onChange={this.onImageChange}
                      type="file"
                      size="60"
                    />
                    <img src={Edit} alt="Edit icon" />
                  </label>
                </button>
                <button className="delete" onClick={this.resetImage}>
                  <img src={Delete} alt="Delete icon" />
                </button>
              </div>
            ) : (
              ""
            )}
            <FormComponent
              editableData={this.state.editableData}
              formConfig={addGuardianParticipantConfig}
              preSubmitChanges={this.preSubmitChanges}
              buttonTitleCSS="adminParticipant"
              saveButtonTitle={
                this.state.editableData
                  ? "Update Participant"
                  : "Add Participant"
              }
              modalCloseCallback={() => {}}
              closeButton={this.closeForm}
              params={{
                guardiansOptions: this.state.guardianName,
                cardtypeOptions: this.state.cardtypeOptions
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
