import React, { useEffect, useState } from "react";
import { getImage } from "src/network/service/imageService";

function S3Image({ src, className, alt }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    getImage(src)
      .then((resp) => setImage(resp?.data))
      .catch(() => {
        setImage(null);
      });
  }, [src]);

  return <img className={className} src={image} alt={alt} />;
}

export default S3Image;
