import { categoryQueryData, categoryQueryDataForAdmin } from "./BudgetQuery";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import { WALLET_LIST_ROUTE } from "src/utils/constants";
import { Link } from "react-router-dom";

export const budgetList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Participant",
      accessor: "userName",
      sortable: false,
      width: 150
    },
    {
      Header: "Wal ID",
      accessor: "walletId",
      sortable: false,
      width: 70
    },
    {
      Header: "Type",
      accessor: "tier",
      sortable: false
    },
    {
      Header: "Category",
      accessor: "itemCategory",
      sortable: false
    },
    {
      Header: "Store",
      accessor: "storeName",
      sortable: false
    },
    {
      Header: "Supporter",
      accessor: "supporterName",
      sortable: false
    },
    // {
    //   Header: "Status",
    //   accessor: "active",
    //   sortable: false
    // },
    {
      Header: "Budget Allocated",
      accessor: "budgetAvailable",
      sortable: false
    },
    {
      Header: "Added On",
      accessor: "createdAt",
      sortable: false
    }
  ]
};

export const participantBudgetList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Wallet",
      accessor: "wallet",
      sortable: false,
      Cell: (row) => (
        <Link to={`/${WALLET_LIST_ROUTE}/${row?.original?.wallet?.id}`}>
          {row?.original?.wallet?.id} - {row?.original?.wallet?.description}
        </Link>
      )
    },
    {
      Header: "Category Name",
      accessor: "itemCategory",
      sortable: false
    },
    {
      Header: "Category Type",
      accessor: "tier",
      sortable: false
    },
    // {
    //   Header: "Status",
    //   accessor: "active",
    //   sortable: false
    // },
    {
      Header: "Budget Allocated",
      accessor: "budgetAvailable",
      sortable: false
    }
  ]
};

export const addBudgetConfig = {
  className: "addAdminParticipantForm",
  fields: [
    {
      label: "Wallet",
      type: "searchSelect",
      name: "walletId",
      data: "walletOptions",
      value: ""
    },
    {
      label: "Tier",
      type: "searchSelect",
      name: "tierId",
      data: "tierOptions",
      value: ""
    },
    {
      label: "Category Name",
      type: "searchSelect",
      name: "itemCategoryId",
      data: "categoryOptions",
      value: ""
    },
    {
      label: "Budget Allocated",
      type: "input",
      name: "budgetAvailable",
      value: ""
    }
  ],
  dependency: [
    {
      parent: "tierId",
      child: "itemCategoryId",
      childOptions: "categoryOptions",
      listingFunction: (value) => {
        if (value === null) {
          return [];
        }
        return localStorage.getItem("role") === "GUARDIAN"
          ? fetchMethod(categoryQueryData, {
              createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
              isActive: 1,
              tierId: value.id
            })
              .then((res) => res.json())
              .then((res) => {
                return res.data.MasterItemCategoryShowItemCategories
                  .itemCategoryData !== null
                  ? res.data.MasterItemCategoryShowItemCategories.itemCategoryData.map(
                      (item) => {
                        return {
                          id: item.id,
                          name:
                            item.category_name.charAt(0).toUpperCase() +
                            item.category_name.slice(1)
                        };
                      }
                    )
                  : "";
              })
              .catch((error) => {
                swal({ title: error.message, icon: "warning" });
              })
          : fetchMethod(categoryQueryDataForAdmin, {
              where: { tierId: value.id, isActive: 1 }
            })
              .then((res) => res.json())
              .then((res) => {
                return res.data.allMasterItemCategories.MasterItemCategories !==
                  null
                  ? res.data.allMasterItemCategories.MasterItemCategories.map(
                      (item) => {
                        return {
                          id: item.id,
                          name:
                            item.categoryName.charAt(0).toUpperCase() +
                            item.categoryName.slice(1)
                        };
                      }
                    )
                  : "";
              })
              .catch((error) => {
                swal({ title: error.message, icon: "warning" });
              });
      }
    }
  ],

  validations: {
    walletId: (e) => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter wallet"
        };
      }
      return {
        valid: true
      };
    },
    tierId: (e) => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter tier"
        };
      }
      return {
        valid: true
      };
    },
    itemCategoryId: (e) => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter category"
        };
      }
      return {
        valid: true
      };
    },
    budgetAvailable: (e) => {
      let digit = new RegExp("^[0-9]{10}$");
      if (e == "" || e <= 0) {
        return {
          valid: false,
          errMsg: "Please enter valid Budget amount."
        };
      }
      if (isNaN(e) !== false) {
        return {
          valid: false,
          errMsg: "Budget Allocated should be contain only numeric value."
        };
      }
      if (e.includes(".")) {
        var value = e;
        var length =
          value.toString().length - (value.toString().indexOf(".") + 1);
        if (parseFloat(length) > 2) {
          return {
            valid: false,
            errMsg: "Only 2 digit allowed after decimal point"
          };
        }
      }
      return {
        valid: true
      };
    }
  }
};

export const editBudgetConfig = {
  className: "addAdminParticipantForm",
  fields: [
    {
      label: "Budget Allocated",
      type: "input",
      name: "budgetAvailable",
      value: ""
    }
  ],
  validations: {
    budgetAvailable: (e) => {
      let digit = new RegExp("^[0-9]{10}$");
      if (e == "" || e <= 0) {
        return {
          valid: false,
          errMsg: "Please enter valid Budget amount."
        };
      }
      if (isNaN(e) !== false) {
        return {
          valid: false,
          errMsg: "Budget Allocated should be contain only numeric value."
        };
      }
      console.log("eeeeeeeeeeee", e);
      if (e.toString().includes(".")) {
        var value = e;
        var length =
          value.toString().length - (value.toString().indexOf(".") + 1);
        if (parseFloat(length) > 2) {
          return {
            valid: false,
            errMsg: "Only 2 digit allowed after decimal point"
          };
        }
      }
      return {
        valid: true
      };
    }
  }
};
