import React, { Component } from "react";
import {
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  Typography,
  Input,
  TextField
} from "@material-ui/core";
import SearchIcon from "../../assets/search.svg";
import ReactTableComponent from "../../ReactTable/ReactTable";
import { adminBuisnessList } from "./BuisnessConfig";
import Edit from "../../assets/edit.svg";
import Delete from "../../assets/svg/delete.svg";
import Activate from "../../assets/Activate_User.svg";
import Deactivate from "../../assets/Deactivate_User.svg";
import { withRouter } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { fetchMethod } from "../../FetchMethod";
import {
  businessQuery,
  saveBusiness,
  businessParticipantQuery,
  saveParticipantBusinessQuery,
  dropdownQuery,
  guardiandropdownQuery,
  businessTypeQuery,
  allBusiness
} from "./BuisnessQuery";
// import {businessQuery} from "../Buisness/BuisnessQuery"
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import swal from "sweetalert";
import "./buisness.css";
import { CollectionsBookmarkOutlined } from "@material-ui/icons";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class BusinessAddedByGuardian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      pagenewNo: parseInt(localStorage.getItem("rows")),
      rowsnewNo: parseInt(localStorage.getItem("rows")),
      listData: undefined,
      listData_copy: undefined,
      name: "",
      business: "",
      participantOptions: [],
      BusinessesOptions: [],
      temp: [],
      filter: {
        order: "id desc",
        createdBy: JSON.parse(localStorage.getItem("userInfo")).id
      },
      search: "",
      count: 0,
      openModal: false,
      participantId: undefined,
      storeId: undefined,
      participantxyz: null,
      openModalCard: false,
      cardData: []
    };
  }

  componentWillMount() {
    this.getBuisnessData();
  }

  resetFilters = () => {
    delete this.state.filter.participantId;

    // delete this.state.filter.txnType;
    this.setState(
      {
        participantxyz: null,
        ["participantId"]: undefined
      },
      () => {
        this.getBuisnessData();
      }
    );
    document.querySelector("#searchSelect").value = "";
  };

  addBuisness = () => {
    this.props.history.push("/addBuisness");
    localStorage.setItem("path", "addByGuardian");
  };

  getBuisnessData = async () => {
    try {
      let resp = await fetchMethod(allBusiness, {
        where: this.state.filter
      }).then((res) => res.json());
      let usersBusinessdata = resp?.data?.allBusinesses?.Businesses || [];

      for (let i = 0; i < usersBusinessdata.length; i++) {
        usersBusinessdata[i]["active"] =
          usersBusinessdata[i].active === 1 ? "Activated" : "Deactivated";
        let types = await fetchMethod(businessTypeQuery, {
          where: { storeId: usersBusinessdata[i].id }
        }).then((res) => res.json());
        const connectedStores =
          types?.data?.allStoreConnectedStoreTypes?.StoreConnectedStoreTypes ||
          [];
        if (connectedStores.length) {
          usersBusinessdata[i]["storeType"] = connectedStores
            .map((store) => {
              let storeDetail =
                store?.fkStoreConnectedStoreTypeStoreTypeIdrel
                  ?.MasterItemCategories?.[0]?.categoryName;
              return (
                storeDetail?.charAt(0).toUpperCase() + storeDetail?.slice(1)
              );
            })
            .filter((store) => store)
            .join(", ");
        }
      }
      console.log(usersBusinessdata);
      this.setState({
        count: usersBusinessdata.length,
        listData: usersBusinessdata,
        listData_copy: usersBusinessdata
      });
    } catch (error) {
      swal({ title: error.message, icon: "warning" });
      this.setState({ listData: [] });
    }
  };

  handleEditModal = (data) => {
    this.props.history.push({
      pathname: "/editBuisness",
      state: { details: data.id }
    });
    localStorage.setItem("path", "addByGuardian");
  };

  adminBuisnessInfo = (data) => {
    let id =
      data.fkParticipantConnectedBusinessStoreIdrel &&
      data.fkParticipantConnectedBusinessStoreIdrel.Businesses[0]
        ? data.fkParticipantConnectedBusinessStoreIdrel.Businesses[0].id
        : data.id;
    this.props.history.push({
      pathname: "/buisnessInfo",
      state: { details: id }
      // state: { details: data.id }
    });
  };

  handleClose = () => {
    this.setState({ storeId: undefined, openModal: false });
  };

  openModalBox = () => {
    this.setState({ openModal: true });
  };

  searchStoreName = (event) => {
    if (event.target.value) {
      this.setState({
        listData: this.state.listData_copy.filter(
          (store) =>
            store.storeName
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            store.contactLocationCity
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
        )
      });
    } else {
      this.setState({
        listData: this.state.listData_copy
      });
    }
  };

  handleDelete = (row) => {
    if (row.active === 1 || row.active === "1") {
      swal({
        title: "Are you sure you really want to deactivate this record",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          const test = {
            id: row.id,
            active: 0
          };

          fetchMethod(saveBusiness, { obj: test })
            .then((res) => res.json())
            .then((res) => {
              const id = res.data.saveBusiness.id;
              swal({
                title: id ? "Deactivated successfully" : "Error deactivating",
                icon: "success"
              });
              if (id) {
                let pageNo = this.state.pageNo;
                let previousData = this.state.listData.length;
                this.setState({ listData: undefined });

                const { filter } = this.state;

                let search = this.state.search;

                if (this.state.search != "" && previousData === 1) {
                  pageNo = this.state.rows;
                  delete filter["and"];
                  search = "";
                } else if (
                  this.state.search == "" &&
                  previousData === 1 &&
                  pageNo != this.state.rows
                ) {
                  pageNo = this.state.pageNo - this.state.rows;
                } else {
                  pageNo = this.state.pageNo;
                }

                this.setState({ pageNo, filter, search }, () =>
                  this.getBuisnessData()
                );
              }
            })
            .catch((e) => {
              swal({ title: e.message, icon: "warning" });
              this.setState({ listData: [] });
            });
        }
      });
    }
  };

  handleBusiness = (e, v) => {
    if (v !== null && v !== undefined) {
      this.setState({
        storeId: v.id
      });
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.getBuisnessData();
      }
    );
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45
      },
      {
        Header: "Store Name",
        Cell: (row) => {
          return (
            <div
              onClick={() => {
                this.adminBuisnessInfo(row.original);
              }}
            >
              {row.original.storeName}
            </div>
          );
        }
      }
    ];

    const actionButton = [
      {
        Header: "",
        sortable: false,
        Cell: (row) => (
          <div className="action">
            <IconButton
              aria-label="edit"
              onClick={() => this.handleEditModal(row.original)}
            >
              <img src={Edit} alt="Edit" />
            </IconButton>
            {row.original.active === 1 ? (
              <IconButton
                className="statusLink"
                aria-label="delete"
                onClick={() => this.handleDelete(row.original)}
              >
                <img src={Delete} alt="DELETE" />
              </IconButton>
            ) : null}
          </div>
        )
      }
    ];

    const columns = nameColumn
      .concat(adminBuisnessList.columns)
      .concat(actionButton);
    return (
      <div className="buisnessSection">
        <h3>Added Businesses</h3>
        <div className="formFlex">
          <div>
            <TextField
              type="text"
              id="searchSelect"
              placeholder="Search by store name, city"
              style={{ width: "200px" }}
              onChange={(evt) => this.searchStoreName(evt)}
              InputProps={{
                endAdornment: (
                  <img
                    src={SearchIcon}
                    alt=""
                    style={{ width: "15px", cursor: "pointer" }}
                  />
                )
              }}
            />
          </div>
          <div>
            <Button className="resetBtn" onClick={this.resetFilters}>
              Reset
            </Button>
            <Button onClick={this.addBuisness}>Add Business</Button>
          </div>
        </div>

        {this.state.listData ? (
          <div className="bussinessDataTable">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={adminBuisnessList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              // this.state.pageNo / this.state.rows}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows
              }}
              // forSerialNo={{
              //   pageNo: this.state.pageNo,
              //   pageSize: this.state.rows
              // }}
            />
          </div>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(BusinessAddedByGuardian);
