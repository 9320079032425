import React, { Component } from "react";
import { allParticipantList } from "./NotificationConfig";
import ReactTableComponent from "../../ReactTable/ReactTable";
import Edit from "../../assets/edit.svg";
import Delete from "../../assets/svg/delete.svg";
import swal from "sweetalert";
import { Button, IconButton } from "@material-ui/core";
import { participantQuery } from "../Participant/ParticipantQuery";
import { fetchMethod } from "../../FetchMethod";
import { DotLoader } from "react-spinners";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./Notification.css";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { sendNotificationQuery } from "./NotificationQuery";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      allData: undefined,
      allIds: [],
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      loader: false,
      notificationDataIdArray: [],
      allSelected: false,
      openModal: false,
      title: null,
      message: null,
      userTypeValue: "null"
    };
  }
  // 0 for get all Data and 1 for 1 page data
  componentWillMount() {
    this.getParticipantData("1", "null");
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  getParticipantData = async (allDataStatus, roleType) => {
    this.setState({ loader: true });
    let filter =
      roleType == "null"
        ? {
            or: [{ role: "PARTICIPANT" }, { role: "SUPPORTER" }],
            active: 1,
            order: "id desc"
          }
        : {
            role: roleType == 1 ? "PARTICIPANT" : "SUPPORTER",
            active: 1,
            order: "id desc"
          };
    let variable =
      allDataStatus == "0"
        ? {
            where: filter
          }
        : {
            where: filter,
            last: parseInt(this.state.rows),
            first: parseInt(this.state.pageNo)
          };

    await fetchMethod(participantQuery, variable)
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          let allIdArray = [];
          res.data.allUserdata.Userdata.map((item) => {
            allIdArray.push(item.id);
            return (
              (item["fullAddress"] =
                item.address && item.address !== null && item.address !== ""
                  ? item.address.concat(
                      item.city && item.city !== null ? " " + item.city : ""
                    )
                  : ""),
              (item.dob = this.formatDate(item.dob))
            );
          });
          if (allDataStatus == "0") {
            this.setState({
              allIds: allIdArray,
              loader: false
            });
          } else {
            this.setState({
              count:
                res.data &&
                res.data.allUserdata &&
                res.data.allUserdata !== null
                  ? res.data.allUserdata.totalCount
                  : "",
              listData:
                res.data &&
                res.data.allUserdata &&
                res.data.allUserdata !== null
                  ? res.data.allUserdata.Userdata
                  : "",
              loader: false
            });
          }
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [], loader: false });
      });
  };

  updatePagination = (pageNumber, size) => {
    // console.log('calll')
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.getParticipantData("1", this.state.userTypeValue);
      }
    );
  };
  storeAllId = async (checkedValue) => {
    // console.log('==========', this.state.allIds)
    if (checkedValue == true) {
      await this.getParticipantData("0", this.state.userTypeValue);
      // console.log('kkkkkkkk', this.state.allIds)
      await this.setState({
        notificationDataIdArray: this.state.allIds,
        allSelected: true
      });
      // console.log("----------->", this.state.notificationDataIdArray)
    } else {
      await this.setState({
        notificationDataIdArray: [],
        allSelected: false
      });
    }
  };
  storeParticularId = async (userid, checkedStatus) => {
    // console.log("checkedStatuscheckedStatus", checkedStatus, userid)
    let latestArray = this.state.notificationDataIdArray;
    if (checkedStatus == true) {
      latestArray.push(userid);
      await this.setState({
        notificationDataIdArray: latestArray
      });
    } else {
      // console.log("latestArray", latestArray)
      let updatedArry = latestArray.filter(function (item) {
        // console.log("itemitemitemitem", item)
        return item !== userid;
      });
      // console.log("updatedArry", updatedArry)
      await this.setState({
        notificationDataIdArray: updatedArry,
        allSelected: false
      });
    }
    // console.log("jjjjjjjjj", this.state.notificationDataIdArray)
  };
  sentNotificationModal = () => {
    // console.log("dddddddddddddd")
    this.setState({
      openModal: !this.state.openModal
    });
  };

  sendNotificationToUser = () => {
    // console.log("[[[[[[[[[[[[", this.state.message, this.state.title, this.state.notificationDataIdArray, this.state)
    fetchMethod(sendNotificationQuery, {
      userIds: this.state.notificationDataIdArray,
      title: this.state.title,
      message: this.state.message
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log("res======>", res.data.NotificationSend)
        if (res.data.NotificationSend.status == 200) {
          this.setState({
            openModal: false,
            notificationDataIdArray: [],
            allSelected: false
          });
          swal({ title: "Notification send successfully", icon: "success" });
          this.getParticipantData("1", this.state.userTypeValue);
        } else {
          swal({ title: "Went somthing wrong", icon: "error" });
        }
      });
  };

  render() {
    const nameColumn = [
      {
        Header: (obj) => {
          return (
            <input
              type="checkbox"
              checked={this.state.allSelected}
              onClick={(e) => this.storeAllId(e.target.checked)}
            ></input>
          );
        },
        Cell: (row) => {
          return (
            <input
              type="checkbox"
              checked={
                this.state.notificationDataIdArray.includes(row.original.id) ==
                true
                  ? true
                  : false
              }
              onClick={(e) =>
                this.storeParticularId(row.original.id, e.target.checked)
              }
            ></input>
          );
        },
        width: 45
      }
    ];

    const actionButton = [];
    const columns = nameColumn
      .concat(allParticipantList.columns)
      .concat(actionButton);
    return (
      <React.Fragment>
        <div className="rulesSection">
          <h2>Notification</h2>
          <div className="product-header-box">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.userTypeValue}
                onChange={(e) => {
                  this.getParticipantData("1", e.target.value);
                  this.setState({
                    userTypeValue: e.target.value,
                    notificationDataIdArray: [],
                    allSelected: false
                  });
                }}
              >
                <MenuItem value={1}>PARTICIPANT</MenuItem>
                <MenuItem value={2}>SUPPORTER</MenuItem>
              </Select>
            </FormControl>
            <div
              className="addbdgetBtn add"
              style={{
                cursor:
                  this.state.notificationDataIdArray.length == 0
                    ? "not-allowed"
                    : "pointer"
              }}
            >
              <Button
                onClick={() => {
                  this.sentNotificationModal();
                }}
                disabled={
                  this.state.notificationDataIdArray.length == 0 ? true : false
                }
              >
                Send Notification
              </Button>
            </div>
          </div>

          {this.state.listData ? (
            <div className="rulesTable">
              <ReactTableComponent
                listData={this.state.listData}
                listConfig={allParticipantList}
                columns={columns}
                dataCount={this.state.count}
                updatePagination={this.updatePagination}
                initialPage={this.state.pageNo / this.state.rows}
                onRowClick={() => {}}
                forSerialNo={{
                  pageNo: this.state.pageNo,
                  pageSize: this.state.rows
                }}
              />
            </div>
          ) : (
            <div className="spinner">
              {this.state.loader === true ? (
                <DotLoader size={70} color={"#020f1f"} />
              ) : (
                <h4>
                  <center>No Records Found.</center>
                </h4>
              )}
            </div>
          )}
        </div>
        {this.state.openModal == true ? (
          <div>
            <BootstrapDialog
              aria-labelledby="customized-dialog-title"
              open={this.state.openModal}
            >
              <DialogContent id="customized-dialog-title">
                <b>Send Push Notification</b>
              </DialogContent>
              <DialogContent dividers>
                <Typography>
                  <label>Title:</label>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    onChange={(e) => {
                      this.setState({ title: e.target.value });
                    }}
                  />
                </Typography>
                <Typography>
                  <label>Message:</label>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    onChange={(e) => {
                      this.setState({ message: e.target.value });
                    }}
                  />
                </Typography>
              </DialogContent>
              <DialogActions>
                <input
                  value="Close"
                  style={{
                    paddingRight: "50px",
                    marginBottom: "10px",
                    marginRight: "10px"
                  }}
                  type="button"
                  onClick={() => {
                    this.setState({ openModal: false });
                  }}
                />
                <input
                  value="send Notification"
                  style={{
                    paddingRight: "100Px",
                    marginBottom: "10px",
                    marginRight: "10px"
                  }}
                  type="button"
                  onClick={() => {
                    this.sendNotificationToUser();
                  }}
                />
              </DialogActions>
            </BootstrapDialog>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
