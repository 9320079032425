export const getAllBudgetCategoryForSupporterQuery = (
  supporterId,
  participantId
) => {
  return `query{
    allBudgetSupporterMappings(where:{supporterid:${supporterId} active: 1}){
      BudgetSupporterMappings{
        id
        supporterid
        budgetid
        active
        budgetSupporterMappingBudgetmaprel(where:{participantId:${participantId} active:1}){
          Budgets{
            id
            itemCategoryId
            participantId
            budgetAvailable
            itemCategoryId
            fkBudgetItemCategoryIdrel(where:{isActive:1}){
              MasterItemCategories{
                categoryName
                id
                tierId
                isActive
                fktiertypemaprel(where:{active:1}){
                  Tiertypes {
                    id
                    tierType
                    active
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
};
