import React, { Component } from "react";
import FormComponent from "../../Form/FormComponent";
import {
  addGuardianParticipantConfig,
  addAdminParticipantConfig,
  editGuardianParticipantConfig,
  editAdminParticipantConfig
} from "./ParticipantConfig";
import { fetchMethod, fetchMethodForNovati } from "../../FetchMethod";
import moment from "moment";
import swal from "sweetalert";
import {
  editParticipantData,
  saveUserdata,
  guardianDropdownData,
  getCardDetailsQuery,
  updateBankDetail,
  allcardtypes,
  createCard,
  UserdataSetPin,
  updateCardStatus
} from "./ParticipantQuery";
import "./addParticipant.css";
import {
  Button,
  Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
import SettingsTab from "./SettingsTab";
import ShowPwd from "../../assets/svg/show_pwd.svg";
import HidePwd from "../../assets/svg/hide_pwd.svg";

export default class EditPaticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardid: undefined,
      editableData: undefined,
      guardianName: undefined,
      cardtypeOptions: undefined,
      cardnumber: undefined,
      cardstatus: undefined,
      showPassword: false,
      password: undefined,
      exceptThisSymbols : ["e", "E", "+", "-", "."]
    };
  }

  componentWillMount() {
    if (this.props.location.state !== undefined) {
      this.editParticipantData(this.props.location.state.details);
      const filter = {
        where: {
          role: "GUARDIAN",
          active: 1,
          order: "firstname asc"
        }
      };
      fetchMethod(guardianDropdownData, filter)
        .then((res) => res.json())
        .then((res) => {
          return res !== undefined && res.data.allUserdata !== undefined
            ? this.setState({
              guardianName: res.data.allUserdata.Userdata.map((item) => {
                return {
                  id: item.id,
                  name:
                    item.firstname != null
                        ? item.firstname.concat(
                            item.lastname !== null ? " " + item.lastname : ""
                        )
                    : ""
                };
              })
            })
            : "";
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
        });

      fetchMethod(allcardtypes)
        .then((res) => res.json())
        .then((res) => {
          return res !== undefined && res.data.allCardtypes !== undefined
            ? this.setState({
                cardtypeOptions: res.data.allCardtypes.Cardtypes.map((item) => {
                return {
                  id: item.id,
                  name: item.cardtype
                };
              })
            })
            : "";
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
        });
    }
  }

  editParticipantData = (id) => {
    fetchMethod(editParticipantData(id))
      .then((res) => res.json())
      .then((res) => {
        let data = res.data.allUserdata.Userdata[0];
        res.data.allUserdata.Userdata[0].kycstatus =
          res.data.allUserdata.Userdata[0].kycstatus == "pass"
            ? 1
            : res.data.allUserdata.Userdata[0].kycstatus == null
            ? 0
            : 2;
        data.guardianId =
          data.userguardianidmaprel.Userdata.length > 0
            ? {
              id: data.userguardianidmaprel.Userdata[0].id,
                name:
                  data.userguardianidmaprel.Userdata[0].firstname != null
                    ? data.userguardianidmaprel.Userdata[0].firstname.concat(
                data.userguardianidmaprel.Userdata[0].lastname !== null
                  ? " " + data.userguardianidmaprel.Userdata[0].lastname
                  : ""
                      )
                    : ""
            }
            : null;

        if (localStorage.getItem("role") === "ADMIN") {
          fetchMethod(getCardDetailsQuery, {
            where: { userId: id }
          })
            .then(resp => resp.json())
            .then(resp => {
              // res.data.allUserdata.Userdata[0].BSB = resp.data.allCardDetails
              //   .CardDetails[0]
              //   ? resp.data.allCardDetails.CardDetails[0].bsb
              //   : "";
              res.data.allUserdata.Userdata[0].cardNumber = resp.data
                .allCardDetails.CardDetails[0]
                ? resp.data.allCardDetails.CardDetails[0].cardNumber
                : "";
              // res.data.allUserdata.Userdata[0].accNo = resp.data.allCardDetails
              //   .CardDetails[0]
              //   ? resp.data.allCardDetails.CardDetails[0].accountnumber
              //   : "";

              // res.data.allUserdata.Userdata[0].cardtypeid =
              //   resp.data.allCardDetails.CardDetails[0] &&
              //     resp.data.allCardDetails.CardDetails[0].fkcardtypeidrel
              //       .Cardtypes[0]
              //     ? {
              //       id:
              //         resp.data.allCardDetails.CardDetails[0].fkcardtypeidrel
              //           .Cardtypes[0].id,
              //       name:
              //         resp.data.allCardDetails.CardDetails[0].fkcardtypeidrel
              //           .Cardtypes[0].cardtype
              //     }
              //     : null;

              // res.data.allUserdata.Userdata[0].holdername = resp.data
              //   .allCardDetails.CardDetails[0]
              //   ? resp.data.allCardDetails.CardDetails[0].accountname
              //   : "";
              res.data.allUserdata.Userdata[0].cardstatus = resp.data.allCardDetails
                .CardDetails[0]
                ? resp.data.allCardDetails.CardDetails[0].cardstatus
                : null;
              this.setState({
                cardid: resp.data.allCardDetails.CardDetails[0]
                  ? resp.data.allCardDetails.CardDetails[0].id
                  : undefined,
                editableData: res.data.allUserdata.Userdata[0],
                cardnumber: resp.data.allCardDetails.CardDetails[0]
                  ? resp.data.allCardDetails.CardDetails[0].cardNumber
                  : "",
                cardstatus: resp.data.allCardDetails.CardDetails[0]
                  ? resp.data.allCardDetails.CardDetails[0].cardstatus
                  : undefined,
              });
            });
        } else {
          this.setState({
            editableData: res.data.allUserdata.Userdata[0]
            // cardnumber: resp.data.allCardDetails.CardDetails[0]
            //   ? resp.data.allCardDetails.CardDetails[0].cardNumber
            //   : ""
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.props.history.push("/participants");
      });
  };

  closeForm = () => {
    this.props.history.push("/participants");
  };

  preSubmitChanges = (e) => {
    // if (e.expirydate) {
    //   if (e.expirydate.length > 7) {
    //     swal({ title: "Enter valid year", icon: "warning" });
    //     return;
    //   }

    //   let expDateM = null;
    //   if (e.expirydate) {
    //     expDateM = `01/${e.expirydate}`;
    //   }
    //   console.log("expDateM", expDateM);

    //   let GettedMonth = 0;
    //   let CurrMonth = moment(new Date()).month() + 1;
    //   if (expDateM) {
    //     GettedMonth = moment(expDateM, "DD/MM/YYYY").month() + 1;
    //   }
    //   console.log("GettedMonth", GettedMonth, " CurrMonth", CurrMonth);

    //   let Gettedyear = 0;
    //   let Curryear = moment(new Date()).year();
    //   if (expDateM) {
    //     Gettedyear = moment(expDateM, "DD/MM/YYYY").year();
    //   }
    //   console.log("Gettedyear", Gettedyear, " Curryear", Curryear);

    //   let yearOkay = true;
    //   let monthOkay = true;

    //   if (Gettedyear && Gettedyear < Curryear) {
    //     yearOkay = false;
    //   }
    //   if (Gettedyear == Curryear && GettedMonth <= CurrMonth) {
    //     monthOkay = false;
    //   } else if (
    //     e.expirydate &&
    //     (String(e.expirydate).length < 7 || !String(e.expirydate).includes("/"))
    //   ) {
    //     console.log("inside alert  of e;lse if  string 7");
    //     swal({ title: "please fill valid exp date.", icon: "warning" });
    //     // alert("please fill valid exp date.");
    //     return;
    //   } else if (!yearOkay || (yearOkay && !monthOkay)) {
    //     if (!yearOkay) {
    //       console.log("inside alert  year okayh");
    //       swal({ title: "Enter valid year", icon: "warning" });
    //       // alert("Enter valid year");
    //       return;
    //     }

    //     if (yearOkay && !monthOkay) {
    //       console.log("inside alert  valid mionoth");
    //       swal({ title: "Enter valid month", icon: "warning" });
    //       // alert("Enter valid month");
    //       return;
    //     }
    //   } else if (isNaN(GettedMonth) || GettedMonth == 0) {
    //     console.log("inside alert  *********************** mionoth");
    //     swal({ title: "Enter valid month", icon: "warning" });
    //     // alert("Enter valid month");
    //     return;
    //   } else if (isNaN(Gettedyear) || Gettedyear == 0) {
    //     console.log(
    //       "inside alert  *******gettted yearl;;;;;;;;;;;;;**************** mionoth"
    //     );
    //     swal({ title: "Enter valid year", icon: "warning" });
    //     return;
    //     // alert("Enter valid year");
    //     // return;
    //   } else if (e.expirydate.length > 7) {
    //     console.log("*********yyyyyyyyyyyyyyy");
    //     swal({ title: "Enter valid year", icon: "warning" });
    //     return;
    //   } else if (!e.expirydate.includes("/")) {
    //     console.log("ejejjejee");
    //     swal({ title: "Enter valid year", icon: "warning" });
    //     return;
    //   }
    // }

    if (e.cardNumber) {
      fetchMethod(getCardDetailsQuery, { where: { cardNumber: e.cardNumber } })
        .then((res) => res.json())
        .then((response) => {
          if (
            response.data.allCardDetails.CardDetails.length > 0 &&
            this.state.cardnumber !== e.cardNumber
          ) {
            swal({ title: "card already exist.", icon: "warning" });
            return false;
          } else {
            let userObj = {
              id: this.props.location.state.details,
              firstname: e.firstname,
              lastname: e.lastname,
              dob: e.dob ? moment(e.dob).format("YYYY-MM-DD") : "",
              email: e.email,
              phonenumber: e.phonenumber,
              address: e.address,
              city: e.city,
              state: e.state,
              country: e.country,
              zipcode: e.zipcode,
              updatedby: JSON.parse(localStorage.getItem("userInfo")).id,
              kycstatus:
                e.kycstatus == 1 ? "pass" : e.kycstatus == 0 ? null : "fail"
            };

            if (localStorage.getItem("role") === "GUARDIAN") {
              e.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
            }
            e.guardianId = e.guardianId === null ? null : e.guardianId.id;
            delete e.userguardianidmaprel;
            userObj.guardianId = e.guardianId;
            // e["updatedby"] = JSON.parse(localStorage.getItem("userInfo")).id;
            fetchMethod(saveUserdata, { obj: userObj })
              .then((res) => res.json())
              .then((response) => {
                const id = response.data.saveUserdata;
                if (id && id !== null) {
                  // if (localStorage.getItem("role") === "ADMIN") {
                  //   let Obj = {
                  //     obj: {
                  //       userId: id.id,
                  //       // cvc: e.cvv,
                  //       // expiryDate: e.expirydate,
                  //       // cardNumber: e.cardNumber,
                  //       // createdAt: Date.now(),
                  //       // accountname: e.holdername,
                  //       // accountnumber: e.accNo,
                  //       // bsb: e.BSB,
                  //       // cardtypeid: e.cardtypeid ? e.cardtypeid.id : 1,
                  //       cardstatus: e.cardstatus == 1 ? "1" : "0"
                  //     }
                  //   };
                  //   if (this.state.cardid) {
                  //     Obj.obj.id = this.state.cardid;
                  //   }
                  //   fetchMethod(updateBankDetail, Obj)
                  //     .then(response => response.json())
                  //     .then(res => {
                  //       // setLoading(false);
                  //       // console.log("res _updateBankDetail => ", res);
                  //       // navigation.navigate("Profile");
                  //     })
                  //     .catch(e => {
                  //       // setLoading(false);
                  //       // alert(e);
                  //       // console.log("_updateBankDetail error ", e);
                  //     });
                  // }
                  swal({
                    text: "Participant updated successfully",
                    icon: "success"
                  });
                  //this.props.history.push("/participants");
                } else if (id === null) {
                  swal("Email already exists", { icon: "error" });
                } else {
                  swal("Please try again", { icon: "error" });
                  this.props.history.push("/participants");
                }
              });
            return false;
          }
        });
    } else {
      let userObj = {
        id: this.props.location.state.details,
        firstname: e.firstname,
        lastname: e.lastname,
        dob: e.dob ? moment(e.dob).format("YYYY-MM-DD") : "",
        email: e.email,
        phonenumber: e.phonenumber,
        address: e.address,
        city: e.city,
        state: e.state,
        country: e.country,
        zipcode: e.zipcode,
        updatedby: JSON.parse(localStorage.getItem("userInfo")).id,
        kycstatus: e.kycstatus == 1 ? "pass" : e.kycstatus == 0 ? null : "fail"
      };

      if (localStorage.getItem("role") === "GUARDIAN") {
        e.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
      }
      e.guardianId = e.guardianId === null ? null : e.guardianId.id;
      delete e.userguardianidmaprel;
      userObj.guardianId = e.guardianId;
      // e["updatedby"] = JSON.parse(localStorage.getItem("userInfo")).id;
      fetchMethod(saveUserdata, { obj: userObj })
        .then((res) => res.json())
        .then((response) => {
          const id = response.data.saveUserdata;
          if (id && id !== null) {
            // if (localStorage.getItem("role") === "ADMIN") {
            //   let Obj = {
            //     obj: {
            //       userId: id.id,
            //       // cvc: e.cvv,
            //       // expiryDate: e.expirydate,
            //       // cardNumber: e.cardNumber,
            //       // createdAt: Date.now(),
            //       // accountname: e.holdername,
            //       // accountnumber: e.accNo,
            //       // bsb: e.BSB,
            //       // cardtypeid: e.cardtypeid ? e.cardtypeid.id : 1,
            //       //cardstatus: e.cardstatus == 1 ? "1" : "0"
            //     }
            //   };
            //   if (this.state.cardid) {
            //     Obj.obj.id = this.state.cardid;
            //   }
            //   fetchMethod(updateBankDetail, Obj)
            //     .then(response => response.json())
            //     .then(res => {
            //       // setLoading(false);
            //       // console.log("res _updateBankDetail => ", res);
            //       // navigation.navigate("Profile");
            //     })
            //     .catch(e => {
            //       // setLoading(false);
            //       // alert(e);
            //       // console.log("_updateBankDetail error ", e);
            //     });
            //}
            swal({ text: "Participant updated successfully", icon: "success" });
            //this.props.history.push("/participants");
          } else if (id === null) {
            swal("Email already exists", { icon: "error" });
          } else {
            swal("Please try again", { icon: "error" });
            this.props.history.push("/participants");
          }
        });
      return false;
    }
  };

  orderCard = () => {
    let userInfo = this.state;
    let random =
      "EXT" +
      Math.floor(
        Math.random() * 900000 + Math.random() * 900000
      ).toString();


    if (this.state.editableData.kycstatus == 1) {
      let obj = {
        externalTransNo: random,
        cardType: "SPENDABLE_PLASTIC_CARD",
        customer: {
          id: userInfo.editableData.id,
          firstName: userInfo.editableData.firstname,
          lastName: userInfo.editableData.lastname,
          maidenName: "",
          street: userInfo.editableData.address,
          city: userInfo.editableData.city,
          countryCode: userInfo.editableData.country,
          postCode: userInfo.editableData.zipcode,
          mobileNumber: userInfo.editableData.phonenumber,
          email: userInfo.editableData.email,
          currentAddress: {
            country: userInfo.editableData.country,
            city: userInfo.editableData.city,
            street: userInfo.editableData.address,
            postCode: userInfo.editableData.zipcode
          }
        }
      }
      let cardTypeId = 8;
      if(this.state.editableData.cardstatus=="1"){
        swal({ text: "Card already exists", icon: "warning" });
      }
      else{
        //setLoading(true);
        fetchMethod(createCard, {
          obj,
          cardTypeId
        })
          .then((res) => res.json())
          .then((res) => {
            //setLoading(false);
            if(res.data?.UserdataNewCard?.resultCode=="0"){
              swal({ text: "Card created successfully", icon: "success" });
              this.props.history.push("/participants");
            }
            else if (res.data?.UserdataNewCard?.resultCode == "419") {
              swal({ text: res.data?.UserdataNewCard?.displayMessage, icon: "warning" });
            }
            else {
              swal({ text: res.data?.UserdataNewCard?.resultDescription, icon: "error" });   
            }
          }).catch(err=>{
           // setLoading(false);
            swal({ text: "Something went wrong", icon: "error" });
          })
      }
    }
    else {
      swal({ text: "Your KYC is not completed yet", icon: "warning" });
    }
  };

  setPin = () => {
    if (this.state.password) {
      let email = this.state.editableData.email, password = this.state.password;
      fetchMethod(UserdataSetPin(email, password))
        .then((res) => res.json())
        .then((res) => {
          if (res.data.UserdataSetPin.status == 200) {
            swal({ text: "Pin has been set successfully", icon: "success" });
          }
          else {
            swal({ text: "Something went wrong", icon: "error" });
          }
        })
    }
  };

  // handlePassword = e => {
  //   this.setState({ password: e.target.value });
  // };
  
  handlePassword = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value && regex.test(e.target.value)) {
      if (e.target.value.length <= 4) {
        this.setState({ password: e.target.value });
      }
    }
    else if(this.state.password && this.state.password.length==1){  
      this.setState({ password: undefined});
    }
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChange = (e) => {
    this.setState({
      editableData: {
        ...this.state.editableData,
        cardstatus: e.target.value
      }
    });
  }; 

  updateCardStatus = () => {
    if (this.state.cardid) {
      fetchMethod(updateCardStatus, {
        userId: this.state.editableData.id,
        cardState: this.state.editableData.cardstatus == "1" ? "UNBLOCK" : "BLOCK"
      })
        .then((res) => res.json())
        .then((res) => {
          //setLoading(false);
          if (res.data?.UserdataUpdateCardState?.resultCode == "0") {
            swal({ text: "Card status updated successfully", icon: "success" });
          }
          else {
            swal({ text: res.data?.UserdataUpdateCardState?.resultDescription, icon: "error" });
          }
        })
        .catch(e => {
          swal({ text: "Something went wrong", icon: "error" });
        });
    }
  };

 
  render() {
    return (
      <div>
        {this.props.location.state !== undefined ? (
          this.state.editableData !== undefined &&
            this.state.guardianName !== undefined &&
            this.state.cardtypeOptions != undefined ? (
            <div className="addParticipantSection">
              <h3>Edit Participant</h3>
              <FormComponent
                editableData={this.state.editableData}
                formConfig={
                  localStorage.getItem("role") === "ADMIN"
                    ? editAdminParticipantConfig
                    : editGuardianParticipantConfig
                }
                preSubmitChanges={this.preSubmitChanges}
                buttonTitleCSS="salesbottomAction"
                modalCloseCallback={() => {}}
                closeButton={this.closeForm}
                params={{
                  guardiansOptions: this.state.guardianName,
                  cardtypeOptions: this.state.cardtypeOptions,
                  kycStatus: [
                    { id: 0, name: "--Select--" },
                    { id: 1, name: "Pass" },
                    { id: 2, name: "Fail" }
                  ]
                }}
              />
              <div className="payment-sec-wrap">
              {
                localStorage.getItem("role") === "ADMIN" ?
                  (
                    <div className="payment-card-item">
                      <label>Set PIN</label>
                      <div className="payment-card-flex">
                      <div className="bh-input-group">
                        {console.log("pass",this.state.password)}
                        <input
                          value={this.state.password}
                          className="bh-form-control"
                          id="Password"
                          name="password"
                          placeholder="Pin"
                          type={this.state.showPassword ? "number" : "password"}
                          onChange={this.handlePassword}
                          maxLength="4"
                          pattern="[0-9]*"
                          onKeyDown={e => this.state.exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        />
                        <div
                          className="bh-input-group-text"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <img src={ShowPwd} alt="password" />
                          ) : (
                            <img src={HidePwd} alt="password" />
                          )}
                        </div>
                      </div>
                      <Button
                        onClick={this.setPin}
                      >
                        {"Set PIN"}
                      </Button>
                      </div>
                    </div>
                  ) : ""}
              {
                localStorage.getItem("role") === "ADMIN" ?
                  (
                    <div className="payment-card-item">
                      <div className="payment-card-flex">
                      <div className="item">
                        <label>Card Number</label>
                        <div className="bh-input-group">
                          <input
                            value={this.state.editableData.cardNumber}
                            className="bh-form-control"
                            id="Cardnumber"
                            name="cardNumber"
                            placeholder="Card Number"
                            type="text"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="item">
                        <label>Status</label>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            onChange={(e) => this.handleChange(e)}
                            labelId="cardstatus"
                            id="cardstatus"
                            name="cardstatus"
                            defaultValue={this.state.editableData.cardstatus}
                            value={this.state.editableData.cardstatus}
                            placeholder="Select a status"
                            label="Select"
                            displayEmpty
                            disabled={this.state.editableData.cardNumber ? false : true}
                          >
                            <MenuItem value={"1"}>ACTIVE</MenuItem>
                            <MenuItem value={"0"}>BLOCK</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="item">
                        <Button
                          onClick={this.orderCard}
                        >
                          Order Card
                        </Button>
                        <Button
                          onClick={this.updateCardStatus}
                        >
                          Update
                        </Button>
                      </div>
                      </div>
                    </div>
                  ) : ""}
              {
                localStorage.getItem("role") === "ADMIN"
                  ? <SettingsTab id={this.state.editableData.id} userdata={this.state.editableData} usedFor="ADMIN" />
                  : ""
              }
            </div>
            </div>
          ) : (
            ""
          )
        ) : (
          this.props.history.push("/participants")
        )}
      </div>
    );
  }
}
