import React, { Component } from "react";
import FormComponent from "../../Form/FormComponent";
import { addBuisnessConfig } from "./BuisnessConfig";
import { fetchMethod } from "../../FetchMethod";
import {
  saveBusiness,
  updateBankDetail,
  allcardtypes,
  getCardDetailsQuery,
  businessTypesListing,
  addBusiness,
  categoryQueryData,
  businessTypeQuery,
  businessType
} from "./BuisnessQuery";
import swal from "sweetalert";
import "./addBuisness.css";
export default class AddBuisness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardtypeOptions: undefined,
      storeTypeOptions: undefined
    };
  }

  componentWillMount() {
    this.prepareAddBusinessConfig();
    this.getBusinessTypes();
  }

  prepareAddBusinessConfig = () => {
    addBuisnessConfig.fields = addBuisnessConfig.fields.filter(field => {
      if ((!field.name.includes("storeType") && !field.name.includes("deleteButton") && !field.name.includes('Button')) || field.name.includes('addStoreType1')) {
        return true;
      } else {
        delete addBuisnessConfig.validations[field.name];
        return false;
      }
    });
  }

  getBusinessTypes = () => {
    if (localStorage.getItem('role') === 'GUARDIAN') {
      fetchMethod(businessType, {
        where: { isActive: 1 }
      })
        .then(res => res.json())
        .then(res => {
          // console.log(res);
          if (res?.data?.allMasterItemCategories?.MasterItemCategories.length) {
            this.setState({
              storeTypeOptions: res.data.allMasterItemCategories.MasterItemCategories.map(business => {
                return {
                  id: business.id,
                  name: business.categoryName.charAt(0).toUpperCase() + business.categoryName.slice(1)
                }
              })
            })
          }
        })
        .catch(() => {
          swal("Something went wrong", {
            icon: 'warning'
          })
        })
    } else {
      fetchMethod(businessType, {
        where: { isActive: 1 }
      })
        .then(res => res.json())
        .then(res => {
          if (res?.data?.allMasterItemCategories?.MasterItemCategories.length) {
            this.setState({
              storeTypeOptions: res.data.allMasterItemCategories.MasterItemCategories.map(business => {
                return {
                  id: business.id,
                  name: business.categoryName.charAt(0).toUpperCase() + business.categoryName.slice(1)
                }
              })
            })
          }
        })
        .catch(() => {
          swal("Something went wrong", {
            icon: 'warning'
          })
        })
    }
  }

  closeForm = () => {
    this.props.history.push("/buisness");
  };

  preSubmitChanges = e => {
    // if (e.expirydate) {
    //   console.log("EEEEEEEEEEEEEe aaya ya ni aaya", e.expirydate.length);
    //   let expDateM = null;
    //   if (e.expirydate) {
    //     expDateM = `01/${e.expirydate}`;
    //   }
    //   console.log("expDateM", expDateM);

    //   let GettedMonth = 0;
    //   let CurrMonth = moment(new Date()).month() + 1;
    //   if (expDateM) {
    //     GettedMonth = moment(expDateM, "DD/MM/YYYY").month() + 1;
    //   }
    //   console.log("GettedMonth", GettedMonth, " CurrMonth", CurrMonth);

    //   let Gettedyear = 0;
    //   let Curryear = moment(new Date()).year();
    //   if (expDateM) {
    //     Gettedyear = moment(expDateM, "DD/MM/YYYY").year();
    //   }
    //   console.log("Gettedyear", Gettedyear, " Curryear", Curryear);

    //   let yearOkay = true;
    //   let monthOkay = true;

    //   if (Gettedyear && Gettedyear < Curryear) {
    //     yearOkay = false;
    //   }
    //   if (Gettedyear == Curryear && GettedMonth <= CurrMonth) {
    //     monthOkay = false;
    //   } else if (
    //     e.expirydate &&
    //     (String(e.expirydate).length < 7 || !String(e.expirydate).includes("/"))
    //   ) {
    //     console.log("inside alert  of e;lse if  string 7");
    //     swal({ title: "please fill valid exp date.", icon: "warning" });
    //     // alert("please fill valid exp date.");
    //     return;
    //   } else if (!yearOkay || (yearOkay && !monthOkay)) {
    //     if (!yearOkay) {
    //       console.log("inside alert  year okayh");
    //       swal({ title: "Enter valid year", icon: "warning" });
    //       // alert("Enter valid year");
    //       return;
    //     }

    //     if (yearOkay && !monthOkay) {
    //       console.log("inside alert  valid mionoth");
    //       swal({ title: "Enter valid month", icon: "warning" });
    //       // alert("Enter valid month");
    //       return;
    //     }
    //   } else if (isNaN(GettedMonth) || GettedMonth == 0) {
    //     console.log("inside alert  *********************** mionoth");
    //     swal({ title: "Enter valid month", icon: "warning" });
    //     // alert("Enter valid month");
    //     return;
    //   } else if (isNaN(Gettedyear) || Gettedyear == 0) {
    //     console.log(
    //       "inside alert  *******gettted yearl;;;;;;;;;;;;;**************** mionoth"
    //     );
    //     swal({ title: "Enter valid year", icon: "warning" });
    //     return;
    //     // alert("Enter valid year");
    //     // return;
    //   } else if (e.expirydate.length > 7) {
    //     console.log("*********yyyyyyyyyyyyyyy");
    //     swal({ title: "Enter valid year", icon: "warning" });
    //     return;
    //   } else if (!e.expirydate.includes("/")) {
    //     console.log("ejejjejee");
    //     swal({ title: "Enter valid year", icon: "warning" });
    //     return;
    //   }
    // }
    let businessObj = {
      active: 1,
      storeName: e.storeName,
      storeTypeId: e.storeTypeId,
      abnNumber: e.abnNumber,
      websiteUrl: e.websiteUrl,
      email: e.email,
      txnLocationAddress: e.txnLocationAddress,
      txnLocationCity: e.txnLocationCity,
      txnLocationState: e.txnLocationState,
      txnLocationCountry: e.txnLocationCountry,
      contactLocationAdress: e.contactLocationAdress,
      contactLocationCity: e.contactLocationCity,
      contactLocationState: e.contactLocationState,
      contactLocationCountry: e.contactLocationCountry,
      storeManagerName: e.storeManagerName,
      adminId: JSON.parse(localStorage.getItem("userInfo")).id,
      createdBy: `${JSON.parse(localStorage.getItem("userInfo")).id}`,
      // updatedBy: `${JSON.parse(localStorage.getItem("userInfo")).id}`,
      mobileNo: parseFloat(e.mobileNo),
      storeTelephoneNo: parseFloat(e.storeTelephoneNo),
      contactLocationZipCode: parseFloat(e.contactLocationZipCode),
      txnLocationZipCode: parseFloat(e.txnLocationZipCode)
    };
    // e["active"] = 1;
    // e["adminId"] = JSON.parse(localStorage.getItem("userInfo")).id;
    // e["createdBy"] = `${JSON.parse(localStorage.getItem("userInfo")).id}`;
    // e["updatedBy"] = `${JSON.parse(localStorage.getItem("userInfo")).id}`;
    // e.mobileNo = parseFloat(e.mobileNo);
    // e.storeTelephoneNo = parseFloat(e.storeTelephoneNo);
    // e.contactLocationZipCode = parseFloat(e.contactLocationZipCode);
    // e.txnLocationZipCode = parseFloat(e.txnLocationZipCode);
    // console.log("obj", businessObj);
    fetchMethod(addBusiness, { data: businessObj })
      .then(res => res.json())
      .then(response => {
        // console.log(response)
        if (response?.data?.BusinessAddNewBusiness?.status === 200) {
          swal({ text: "Business added successfully", icon: "success" });
        }
        else {
          swal("Please try again", { icon: "error" });
          // this.props.history.push("/buisness");
        }
        localStorage.getItem('path') === 'addByGuardian' ? this.props.history.push("/addedBusiness") : this.props.history.push("/buisness");
        localStorage.removeItem('path');
      })
      .catch(() => {
        swal("Something went wrong", {
          icon: 'warning'
        })
      });
    return false;
  };

  render() {
    return this.state.storeTypeOptions ? (
      <div className="addBuisnessSection">
        <h3>Add Business</h3>
        <FormComponent
          formConfig={addBuisnessConfig}
          preSubmitChanges={this.preSubmitChanges}
          buttonTitleCSS="buisnessSaveButton"
          modalCloseCallback={() => { }}
          closeButton={this.closeForm}
          params={{
            storeTypeOptions: this.state.storeTypeOptions
          }}
        />
      </div>
    ) : (
      ""
    );
  }
}
