import React from "react";
import { ADMIN } from "src/utils/constants";
import Budget from "./Budget";
import GuardianBudget from "./GuardianBudget";

function MainBudget() {
  return localStorage.getItem("role") === ADMIN ? (
    <Budget />
  ) : (
    <GuardianBudget />
  );
}

export default MainBudget;
