import React, { Component } from "react";
import {
  Button,
  IconButton,
  Dialog,
  Typography,
  TextField,
  Checkbox,
  Switch,
  FormControlLabel,
  CircularProgress
} from "@material-ui/core";
import EditIcon from "../../assets/edit.svg";
import AdminParticipantTab from "./AdminParticipantTab";
import profile from "../../assets/photo.svg";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Edit from "../../assets/svg/edit.svg";
import Delete from "../../assets/svg/delete.svg";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  getParticipantData,
  getCardDetailsQuery,
  getWalletsQuery,
  getTransactions,
  saveUpdateWallet,
  getRepeatPayDetails
} from "./ParticipantQuery";
import "./participant.css";
import { formatDate } from "../FormatDate";
import S3Image from "../S3Image";
import { deleteWallet } from "src/network/service/walletService";
import ConfirmationPrompt from "src/Components/UI/ConfirmationPrompt";

//importing router for description wallet no to route us to AdminWalletDetail
import { Link } from "react-router-dom";
import { KYC_STATUS_PASS, WALLET_LIST_ROUTE } from "src/utils/constants";
import { withRouter } from "react-router";
import { getCurrentUser } from "src/utils/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
export default class AdminParticipanInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveWalletLoading: false,
      detailsData: undefined,
      openWalletModal: false,
      BusinessesOptions: [],
      cardDetails: [],
      lastFiveTransactions: [],
      walletDetails: [],
      selectedWallet: {
        default: false,
        canwithdraw: false
      }
    };
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  componentWillMount() {
    if (this.props.match.params !== undefined) {
      this.participantInfo(this.props.match.params.participantId);
      this.getCardDetails();
      this.getWalletDetails();
      this.getLastFiveTransactions();
    }
  }

  getLastFiveTransactions = () => {
    fetchMethod(getTransactions, {
      where: {
        userId: this.props.match.params.participantId,
        order: "createdAt desc"
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.allSuperLedgers?.SuperLedgers) {
          let onlyLastFiveTxn = res?.data?.allSuperLedgers?.SuperLedgers.slice(
            0,
            5
          );
          this.setState({
            lastFiveTransactions: onlyLastFiveTxn || []
          });
        }
      });
  };

  getCardDetails = () => {
    fetchMethod(getCardDetailsQuery, {
      where: { userId: this.props.match.params.participantId }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allCardDetails &&
          resp.data.allCardDetails.CardDetails
        ) {
          this.setState({
            cardDetails: resp.data.allCardDetails.CardDetails
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  getWalletDetails = () => {
    fetchMethod(getWalletsQuery, {
      where: {
        participantId: this.props.match.params.participantId,
        status: 1
      }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allWallets &&
          resp.data.allWallets.Wallets
        ) {
          this.setState({
            walletDetails: resp.data.allWallets.Wallets.map((item) => {
              return {
                id: item.id,
                description: item.description,
                participantId: item.participantId,
                participantName:
                  item.participantUserdatarel.Userdata[0]?.firstname +
                  " " +
                  item.participantUserdatarel.Userdata[0]?.lastname,
                ownerId: item.ownerId,
                ownerName:
                  item.ownerUsedatarel.Userdata[0]?.firstname +
                  " " +
                  item.ownerUsedatarel.Userdata[0]?.lastname,
                balance: item.balance,
                default: item.default,
                canwithdraw: item.canwithdraw
              };
            })
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  participantInfo = (id) => {
    fetchMethod(getParticipantData(id))
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          detailsData: res.data.allUserdata.Userdata[0]
        });
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      });
  };

  editButton = (data) => {
    this.props.history.push({
      pathname: "/editParticipants",
      state: { details: data.id }
    });
  };

  addWalletDialog = () => {
    const participantData = this.state.detailsData;
    if (participantData?.kycstatus === KYC_STATUS_PASS) {
      this.setState({ openWalletModal: true });
      this.setState({
        selectedWallet: {
          id: 0,
          description: "",
          balance: 0.0,
          ownerId: this.state.detailsData.userguardianidmaprel.Userdata[0].id,
          ownerName: `${this.state.detailsData.userguardianidmaprel.Userdata[0]?.firstname} ${this.state.detailsData.userguardianidmaprel.Userdata[0]?.lastname}`,
          participantId: this.state.detailsData.id, // Default Selected Participant
          participantName: `${this.state.detailsData?.firstname} ${this.state.detailsData.lastname}`,
          status: "",
          createdBy: 1,
          createOn: new Date(),
          updatedBy: 1,
          updatedOn: new Date(),
          default: false,
          canwithdraw: false
        }
      });
    } else {
      swal({
        icon: "warning",
        title: `We need to verify ${participantData?.firstname}'s identity. Please email support@spendable.com.au to get started`
      });
    }
  };

  editWalletDialog = (data) => {
    this.setState({ openWalletModal: true });
    this.setState({
      selectedWallet: {
        id: data.id,
        description: data.description,
        balance: data.balance,
        ownerId: data.ownerId, // Default Logged in Person
        ownerName: data.ownerName,
        participantId: data.participantId, // Default Selected Participant
        participantName: data.participantName,
        status: data.status,
        createdBy: data.createdBy,
        createOn: data.createdOn,
        updatedBy: 1,
        updatedOn: new Date(),
        default: data.default === 1,
        canwithdraw: data.canwithdraw === 1
      }
    });
  };

  handleDeleteWallet = (data) => {
    ConfirmationPrompt({
      visible: true,
      title: "Are you sure! \nDo you want to delete this wallet?",
      swalOptions: {
        buttons: true,
        dangerMode: true
      },
      onConfirm: () => {
        fetchMethod(getRepeatPayDetails, {
          where: {
            active: 1,
            isrepeatpay: 1,
            isrepeatpaysetforparticipant: 1,
            walletid: data.id,
            status: "PENDING"
          }
        })
          .then((res) => res.json())
          .then((resp) => {
            if (resp.data?.allAuthledgers?.Authledgers?.length > 0) {
              swal({
                title:
                  "Can't Delete this Wallet, first Unload the existing Repeat Pay",
                icon: "error"
              });
              return;
            } else {
              deleteWallet(data.id).then(() => {
                this.wallet();
              });
            }
          });
      }
    });
  };
  handleWalletDialogClose = () => {
    this.setState({ openWalletModal: false });
  };

  handleSaveWalletChanges = () => {
    let wallet = this.state.selectedWallet;
    let obj = {
      id: wallet.id !== 0 ? wallet.id : undefined,
      ownerId: wallet.ownerId,
      participantId: wallet.participantId,
      description: wallet.description,
      defaultWallet: wallet.default ? 1 : 0,
      requestedBy: getCurrentUser().id,
      status: 1
    };

    this.setState({ saveWalletLoading: true });
    fetchMethod(saveUpdateWallet, {
      ...obj
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.WalletCreateOrUpdateWallet?.length > 0) {
          swal({ title: "Wallet Saved", icon: "success" });
          this.handleWalletDialogClose();
          this.getWalletDetails();
        } else {
          swal({
            title: "There was an error saving the wallet",
            icon: "warning"
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      })
      .finally(() => this.setState({ saveWalletLoading: false }));
  };

  toggleDefault = () => {
    let wallet = this.state.selectedWallet;
    wallet.default = !wallet.default;
    this.setState({ selectedWallet: wallet });
  };

  toggleWithdraw = () => {
    let wallet = this.state.selectedWallet;
    wallet.canwithdraw = !wallet.canwithdraw;
    this.setState({ selectedWallet: wallet });
  };

  render() {
    return (
      <div>
        {this.props.match.params.participantId !== undefined ? (
          this.state.detailsData !== undefined ? (
            <div className="companyTabHeader">
              <div className="companyTabHeaderProfileInfo">
                <div className="companyTabHeaderProfileDetails">
                  <div className="participantName">
                    <div className="Image">
                      {this.state.detailsData.profileimage !== null &&
                      this.state.detailsData.profileimage !== undefined ? (
                        <S3Image
                          src={this.state.detailsData.profileimage}
                          alt="participant profile"
                        />
                      ) : (
                        <img src={profile} alt="participant profile" />
                      )}
                    </div>
                    <h5>
                      {this.state.detailsData?.firstname != null
                        ? this.state.detailsData?.firstname
                        : " " + this.state.detailsData.lastname != null
                        ? this.state.detailsData.lastname
                        : ""}
                    </h5>
                    <div className="editLink">
                      <Button
                        startIcon={<img src={EditIcon} alt="" />}
                        onClick={() => this.editButton(this.state.detailsData)}
                      >
                        Edit
                      </Button>
                    </div>
                    {/* <Button
                      className="cardDetailBtn"
                      onClick={() => {
                        this.openModalBox();
                      }}
                    > 
                      Card Details
                    </Button> */}
                  </div>
                  <span className="address" style={{ display: "block" }}>
                    {this.state.detailsData.address !== null
                      ? this.state.detailsData.address
                          .concat(
                            this.state.detailsData.city !== null
                              ? ", " + this.state.detailsData.city
                              : ""
                          )
                          .concat(
                            this.state.detailsData.state !== null
                              ? " " + this.state.detailsData.state
                              : ""
                          )
                          .concat(
                            this.state.detailsData.country !== null
                              ? " " + this.state.detailsData.country
                              : ""
                          )
                          .concat(
                            this.state.detailsData.zipCode !== null
                              ? " " + this.state.detailsData.zipcode
                              : ""
                          )
                      : ""}
                  </span>
                  <ul className="participantInformationDetails">
                    <li className="participantMobile">
                      <span>Date Of Birth</span>
                      <span>
                        {this.state.detailsData.dob !== null
                          ? this.formatDate(this.state.detailsData.dob)
                          : ""}
                      </span>
                    </li>
                    <li className="participantMobile">
                      <span>Mobile</span>
                      <span>
                        {this.state.detailsData.phonenumber !== null
                          ? this.state.detailsData.phonenumber
                          : ""}
                      </span>
                    </li>
                    <li className="participantEmail">
                      <span>Email Address</span>
                      <span>
                        {this.state.detailsData.email !== null
                          ? this.state.detailsData.email
                          : ""}
                      </span>
                    </li>
                    {/* <li className='participantDetail'>
                      <span>NDIS Number</span>
                      <span>
                        {this.state.detailsData.ndisNumber !== null
                          ? this.state.detailsData.ndisNumber
                          : ''}
                      </span>
                    </li> */}
                  </ul>
                </div>
              </div>

              {localStorage.getItem("role") === "GUARDIAN" ? (
                <div className="participantDocumentVerifivation">
                  <h6>Last 5 Transactions</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th>Transaction Type</th>
                        <th>Amount</th>
                        <th>Transaction Date</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.lastFiveTransactions.length ? (
                        this.state.lastFiveTransactions.map((txn, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{txn.txnType}</td>
                            <td>${txn.amountAdded}</td>
                            <td>{formatDate(txn.createdAt)}</td>
                            <td>${txn.cardlimit}</td>
                          </tr>
                        ))
                      ) : (
                        <h5
                          style={{
                            textAlign: "center",
                            justifyContent: "center"
                          }}
                        >
                          No records found.
                        </h5>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <div className="participantDocumentVerifivation">
                    <h6> Bank/Card Details</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>S No.</th>
                          <th>BSB</th>
                          <th>PPAN</th>
                          <th>Card Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cardDetails
                          ? this.state.cardDetails.map((item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.bsb}</td>

                                <td>{item.cardNumber}</td>
                                <td>
                                  {item.fkcardtypeidrel.Cardtypes[0]
                                    ? item.fkcardtypeidrel.Cardtypes[0].cardtype
                                    : ""}
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                  <div className="participantDocumentVerifivation">
                    <div className="flex justify-between align-center mb-10">
                      <h6> Wallet Details</h6>

                      <Button
                        className="addsupportBtn"
                        onClick={() => {
                          this.addWalletDialog();
                        }}
                        color="primary"
                        variant="contained"
                      >
                        CREATE WALLET
                      </Button>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>W No.</th>
                          <th>Description</th>
                          <th>Participant</th>
                          <th>Balance</th>
                          <th>Default</th>
                          <th style={{ width: "250px" }}></th>
                          <th style={{ width: "250px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.walletDetails?.length > 0 ? (
                          this.state.walletDetails.map((item, index) => (
                            <tr key={item.id}>
                              <td>
                                <Link
                                  to={`/${WALLET_LIST_ROUTE}/${item.id}`}
                                  style={{
                                    color: "#000",
                                    textDecoration: "underline",
                                    fontSize: "14px",
                                    fontWeight: 600
                                  }}
                                >
                                  {item.id}
                                </Link>
                              </td>
                              <td>{item.description}</td>
                              <td>{item.participantName}</td>
                              <td>
                                {item.balance != null ? "$" + item.balance : ""}
                              </td>
                              <td>{item.default === 1 ? "Yes" : "No"}</td>
                              <td style={{ width: "300px" }}>
                                {
                                  <Button
                                    className="edit"
                                    onClick={(e) => this.editWalletDialog(item)}
                                  >
                                    <img src={Edit} alt="Edit icon" />
                                  </Button>
                                }
                              </td>
                              <td style={{ width: "100px" }}>
                                <Button
                                  className="delete"
                                  onClick={(e) => this.handleDeleteWallet(item)}
                                  disabled={item?.default === 1}
                                >
                                  {item.default !== 1 && (
                                    <img
                                      src={Delete}
                                      height="18px"
                                      alt="Delete icon"
                                    />
                                  )}
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="no-data-found">
                            <h4>No Data Found</h4>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <AdminParticipantTab
                id={this.state.detailsData.id}
                // participantName={`${this.state.detailsData.firstname} ${this.state.detailsData.lastname}`}
              />
            </div>
          ) : (
            ""
          )
        ) : (
          /* this.props.history.push(
            `/participantInfo/${this.props.match.params.participantId}` */
          this.props.history.push("participants")
        )}

        {
          <Dialog
            open={this.state.openWalletModal}
            onClose={this.closeWalletModal}
            aria-labelledby="simple-modal-title"
            ariadescribedby="simple-modal-description"
            className="chooseBuisness selectSupportModal"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleWalletClose}
            >
              Create Wallet
            </DialogTitle>
            <div className="modal-body-box">
              {this.state.saveWalletLoading ? (
                <div className="flex justify-center width-100">
                  <CircularProgress />
                </div>
              ) : this.state.selectedWallet ? (
                <>
                  <TextField
                    value={this.state.selectedWallet.description ?? ""}
                    onChange={(e) => {
                      let wallet = this.state.selectedWallet;
                      wallet.description = e?.target?.value;
                      this.setState({ selectedWallet: wallet });
                    }}
                    label="Description"
                    variant="outlined"
                    type="text"
                  />
                  <TextField
                    disabled
                    value={this.state.selectedWallet.ownerName}
                    label="Owner"
                    variant="outlined"
                    type="text"
                  />
                  <TextField
                    disabled
                    value={this.state.selectedWallet.participantName}
                    label="Participant"
                    variant="outlined"
                    type="text"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.selectedWallet.default}
                        onChange={() => this.toggleDefault()}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Default Wallet"
                  />
                  {/* <FormControlLabel
                      control={
                        <Switch
                          name="canwithdraw-wallet"
                          checked={this.state.selectedWallet.canwithdraw}
                          onChange={() => this.toggleWithdraw()}
                          color="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="Cash Withdrawal Allowed"
                    /> */}
                </>
              ) : (
                <p> Wallet does not exist! </p>
              )}

              <DialogButton>
                <Button onClick={() => this.handleWalletDialogClose()}>
                  Cancel
                </Button>
                <Button onClick={() => this.handleSaveWalletChanges()}>
                  Save
                </Button>
              </DialogButton>
            </div>
          </Dialog>
        }
      </div>
    );
  }
}
