import React, { useState } from "react";
import CustomAlert from "../CustomAlert/CustomAlert";
import Warning from "src/assets/svg/warning.svg";
import Search from "src/assets/svg/search.svg";
import CardPagination from "src/ReactTable/CardPagination";
import { useDispatch, useSelector } from "react-redux";
import WalletCard from "./WalletCard";
import usePagination from "src/hooks/usePagination";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { WALLET_LIST_ROUTE } from "src/utils/constants";
import { useEffect } from "react";
import { fetchWalletsAction } from "./walletSlice";
import "src/Components/Participant/participants.scss";

function GuardianWallet() {
  const [showError, setShowError] = useState(false);
  const { walletData, total } = useSelector((state) => state.wallet);
  const { page, pageSize } = usePagination(0, 5);
  const history = useHistory();
  const dispatch = useDispatch();

  const updatePagination = (totalEntries, size) => {
    const pageNo = totalEntries / size - 1;
    history.push(`/${WALLET_LIST_ROUTE}?page=${pageNo}&pageSize=${size}`);
  };

  useEffect(() => {
    dispatch(
      fetchWalletsAction({
        page,
        limit: pageSize,
        status: 1
      })
    );
  }, [page, pageSize, dispatch]);

  return (
    <div className="container">
      <CustomAlert
        show={showError}
        icon={Warning}
        message="Failed to fetch the data, please check your internet connection!"
      ></CustomAlert>
      <div className="participant">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <h1>Wallets</h1>
            <p>
              View wallet balance and details or dive into wallet information to
              perform a quick audit. Connect the wallet to supporters and
              allocate budgets.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <hr />
          </div>
          <div className="col-sm-4 col-xs-12">
            <div className="filters">
              <div className="bh-form-group">
                <div className="bh-input-group">
                  <div
                    className="bh-input-group-text no-bg no-rt-border"
                    role="img"
                  >
                    <img src={Search} alt="search icon" />
                  </div>
                  <input
                    type="search"
                    placeholder="Search Wallets"
                    // value={this.state.search}
                    // onChange={this.handleSearchChange}
                    className="bh-form-control sm no-lt-border"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="listing">
              <div className="row">
                {walletData.map((item, index) => (
                  <div
                    className="col-lg-2"
                    style={{ cursor: "pointer" }}
                    key={index}
                  >
                    <WalletCard
                      key={item.id}
                      pic={item.profileimage}
                      description={item.description}
                      firstName={item?.participant}
                      id={item.id}
                      balance={item.balance}
                      repeatpayamount={item.repeatpayamount}
                      // onFirstNameClick={() => this.onFirstNameClick(item)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <hr />
            {walletData ? (
              <CardPagination
                dataPerPage={pageSize}
                totalData={total ? total : ""}
                paginate={updatePagination}
                initialPage={page + 1}
                dataRange={{
                  first: (page + 1) * pageSize,
                  last: pageSize
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuardianWallet;
