import React, { Component } from "react";
import {
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  Typography,
  Input
} from "@material-ui/core";
import Editiconss from "../../assets/svg/edit.svg";
import EditIcon from "../../assets/edit.svg";
import ParticipantTab from "./ParticipantTab";
import profile from "../../assets/photo.svg";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  getParticipantData,
  getCardDetailsQuery,
  getTransactions
} from "./ParticipantQuery";
import { API_URL } from "../../Config";
import "./participant.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Drawer from "@mui/material/Drawer";
import AddGuardianParticipant from "./AddGuardianParticipant";
import PaymentRequest from "./ParticipantTabSetup/PaymentRequest";
import CakeIcon from "@mui/icons-material/Cake";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import S3Image from "../S3Image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
export default class ParticipantInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsData: undefined,
      // openModal: false,
      BusinessesOptions: [],
      cardDetails: [],
      lastFiveTransactions: [],
      tabValue: "1",
      open: false
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  componentWillMount() {
    if (
      localStorage.getItem("participantInfoData") &&
      localStorage.getItem("participantInfoData") != "undefined"
    ) {
      this.participantInfo(localStorage.getItem("participantInfoData"));
      this.getCardDetails();
      this.getLastFiveTransactions();
    }
    // this.getCardDetails();
  }

  getLastFiveTransactions = () => {
    fetchMethod(getTransactions, {
      where: {
        userId: localStorage.getItem("participantInfoData"),
        order: "createdAt desc"
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.allSuperLedgers?.SuperLedgers) {
          let onlyLastFiveTxn = res?.data?.allSuperLedgers?.SuperLedgers.slice(
            0,
            5
          );
          this.setState({
            lastFiveTransactions: onlyLastFiveTxn || []
          });
        }
      });
  };

  getCardDetails = () => {
    fetchMethod(getCardDetailsQuery, {
      where: { userId: localStorage.getItem("participantInfoData") }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allCardDetails &&
          resp.data.allCardDetails.CardDetails
        ) {
          this.setState({
            cardDetails: resp.data.allCardDetails.CardDetails
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  participantInfo = (id) => {
    fetchMethod(getParticipantData(id))
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          detailsData: res.data.allUserdata.Userdata[0]
        });
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      });
  };

  editButton = (data) => {
    this.props.history.push({
      pathname: "/editParticipants",
      state: { details: data.id }
    });
  };
  // handleClose = () => {
  //   this.setState({ openModal: false });
  // };
  // openModalBox = () => {
  //   this.setState({ openModal: true });
  // };

  handleClose = () => {
    this.setState({ open: false });
    if (
      localStorage.getItem("participantInfoData") &&
      localStorage.getItem("participantInfoData") != "undefined"
    ) {
      this.participantInfo(localStorage.getItem("participantInfoData"));
      this.getCardDetails();
      this.getLastFiveTransactions();
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <div className="container">
        {localStorage.getItem("participantInfoData") &&
        localStorage.getItem("participantInfoData") != "undefined" ? (
          this.state.detailsData !== undefined ? (
            <div className="companyTabHeader">
              <div className="companyTabHeaderProfileInfo">
                <div className="companyTabHeaderProfileDetails">
                  <div className="participantName">
                    <div className="Image">
                      {this.state.detailsData.profileimage ? (
                        <div className="userPic">
                          <S3Image
                            src={this.state.detailsData.profileimage}
                            alt={this.props.fname}
                          />
                        </div>
                      ) : (
                        <div className="initial">
                          {this.state.detailsData.firstname != null
                            ? this.state.detailsData.firstname?.charAt(0)
                            : "U"}
                        </div>
                      )}
                    </div>
                    <div className="participant-info-detail">
                      <h3>
                        {`${
                          this.state.detailsData.firstname != null
                            ? this.state.detailsData.firstname
                            : ""
                        }  ${
                          this.state.detailsData.lastname != null
                            ? this.state.detailsData.lastname
                            : ""
                        }`}
                      </h3>
                      <div className="address" style={{ display: "block" }}>
                        {this.state.detailsData.address !== null
                          ? this.state.detailsData.address
                              .concat(
                                this.state.detailsData.city !== null
                                  ? ", " + this.state.detailsData.city
                                  : ""
                              )
                              .concat(
                                this.state.detailsData.state !== null
                                  ? " " + this.state.detailsData.state
                                  : ""
                              )
                              .concat(
                                this.state.detailsData.country !== null
                                  ? " " + this.state.detailsData.country
                                  : ""
                              )
                              .concat(
                                this.state.detailsData.zipCode !== null
                                  ? " " + this.state.detailsData.zipcode
                                  : ""
                              )
                          : ""}
                      </div>
                      <ul className="participantInformationDetails">
                        <li>
                          <span className="icon-box">
                            <MoveToInboxIcon />
                          </span>

                          {this.state.detailsData.email !== null
                            ? this.state.detailsData.email
                            : ""}
                        </li>
                        <li>
                          <span className="icon-box">
                            <PhoneIphoneIcon />
                          </span>
                          {this.state.detailsData.phonenumber !== null
                            ? this.state.detailsData.phonenumber
                            : ""}
                        </li>
                        <li>
                          <span className="icon-box">
                            <CakeIcon />
                          </span>
                          {this.state.detailsData.dob !== null
                            ? this.formatDate(this.state.detailsData.dob)
                            : ""}
                        </li>
                      </ul>
                      {localStorage.getItem("role") === "GUARDIAN" ? (
                        ""
                      ) : (
                        <div className="editLink">
                          <button
                            className="edit"
                            onClick={(e) => this.handleOpen()}
                          >
                            <img src={Editiconss} alt="Edit icon" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ParticipantTab
                id={this.state.detailsData.id}
                userData={this.state.detailsData}
              />
              <Drawer
                anchor={"right"}
                open={this.state.open}
                onClose={() => this.handleClose()}
              >
                <Box sx={{ width: 600 }} role="presentation">
                  <AddGuardianParticipant
                    onClose={() => this.handleClose()}
                    participantId={localStorage.getItem("participantInfoData")}
                  />
                </Box>
              </Drawer>
            </div>
          ) : (
            ""
          )
        ) : (
          this.props.history.push("/participants")
        )}
      </div>
    );
  }
}
