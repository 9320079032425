export const getTopThreeYear = () => {
  const date = new Date();
  // console.log(date);
  // console.log("Year : ");
  const currentYear = date.getFullYear();
  return {
    list: [currentYear, currentYear - 1, currentYear - 2],
    value: currentYear,
  };
};

export const getMonthsData = () => {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  return {
    list: [
      {
        key: 1,
        name: "January",
      },
      {
        key: 2,
        name: "February",
      },
      {
        key: 3,
        name: "March",
      },
      {
        key: 4,
        name: "April",
      },
      {
        key: 5,
        name: "May",
      },
      {
        key: 6,
        name: "June",
      },
      {
        key: 7,
        name: "July",
      },
      {
        key: 8,
        name: "August",
      },
      {
        key: 9,
        name: "September",
      },
      {
        key: 10,
        name: "October",
      },
      {
        key: 11,
        name: "November",
      },
      {
        key: 12,
        name: "December",
      },
    ],
    value: currentMonth,
  };
};
