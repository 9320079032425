import React, { Component } from "react";
import ReactTableComponent from "../../ReactTable/ReactTable";
import {
  adminPaymentRequestList,
  transactionListConfig,
} from "./PaymentRequestsConfig";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DotLoader } from "react-spinners";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { fetchMethod, fetchMethodForNovati } from "../../FetchMethod";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import noImgFound from "../../assets/images/noImgFound.jpg";
import { TextField } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { WALLET_LIST_ROUTE } from "src/utils/constants";
import { Link } from "react-router-dom";
import {
  paymentQuery,
  paymentImagesList,
  paymentShoppingList,
  userdataQuery,
  paymentDisputes,
  paymentReceipt,
  paymentGuardianRequestQuery,
  paymentGuardianRequestAllQuery,
  UserdataCardtransfer,
  UserdataCheckBalance,
  UserdataCardBlock,
  updateCardStatus,
  updateSuperledger,
  getCardDetailsQuery,
  updateCardLimit,
  GetUserdataNotification,
  getUserBudget,
  updateUserBudget,
  transactionList,
  transactionDetails,
  getSingleTransaction,
  getCardBalanace,
  getItemCategory,
  saveItemCategory,
  saveAuthledgerCategory,
} from "./PaymentRquestQuery";
import "./paymentRequests.css";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";
//import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  Typography,
  Input,
} from "@material-ui/core";
import { CSVLink, CSVDownload } from "react-csv";
import TransactionDetailsModal from "./transactionDetailsModal";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../../assets/Infinity-1s-200px.svg";
import InputLabel from "@mui/material/InputLabel";
import S3Image from "../S3Image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class GuardianPaymentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCSVData: [],
      checked: {},
      userIdofRecent: undefined,
      allChecked: false,
      listData: undefined,
      listData__copy: undefined,
      txnData: undefined,
      listData_copy: undefined,
      search: "",
      openModal: false,
      openModalReceipt: false,
      openShoppingList: false,
      openDispute: false,
      openTransaction: false,
      paymentImages: [],
      paymentShopping: undefined,
      paymentDispute: undefined,
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      images: [],
      loading: false,
      searchValue: "",
      searchStatus: "Complete",
      filter: {
        // paymentRequestId:
        order: "id desc",
      },
      role1: "",
      userData: undefined,
      paymentId: undefined,
      count: 0,
      txnCount: 0,
      createdBy: "",
      paymentxyz: null,
      receiptImages: [],
      isLoading: false, //this state is for loader when click on download from transaction drawer
      paymentStatus: "Complete",
      itemCategory: [],
    };
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy + " " + hours + ":" + minutes);
  };

  componentWillMount() {
    if (
      localStorage.getItem("userInfo") &&
      JSON.parse(localStorage.getItem("userInfo")).id
    ) {
      if (localStorage.getItem("paymentId") == null) {
        this.setRecentUserId();
        this.paymentRequestData();
        this.getCategories();
        //this.photoData()
      } else {
        let paymentId = localStorage.getItem("paymentId");
      }
    } else {
      this.props.history.push("/");
    }
  }
  // componentDidUpdate = async (prevProp, prevState) => {
  //   if (prevState.image !== this.state.image) {
  //   }

  //   if (prevState.listData !== this.state.listData) {
  //     if (this.state.listData && this.state.listData.length > 0) {
  //       this.state.listData.map((val) => {
  //         fetchMethod(paymentImagesList, {
  //           where: { paymentRequestId: val.id },
  //         })
  //           .then((res) => res.json())
  //           .then((res) => {
  //             if (
  //               res.data.allRequestItemImages &&
  //               res.data.allRequestItemImages.RequestItemImages.length > 0
  //             ) {
  //               const result =
  //                 res.data.allRequestItemImages.RequestItemImages.map(
  //                   (item) => {
  //                     return {
  //                       id: item.id,
  //                       url: item.itemImageUrl,
  //                       paymentRequestId: val.id,
  //                     };
  //                   }
  //                 );
  //               this.setState({
  //                 image: [...this.state.image, ...result],
  //               });
  //             }
  //           })
  //           .catch((e) => console.log(e));
  //       });
  //     }
  //   }
  //   if (prevState.listData !== this.state.listData) {
  //     if (this.state.listData && this.state.listData.length > 0) {
  //       this.state.listData.map((val) => {
  //         fetchMethod(paymentReceipt, {
  //           where: { paymentRequestId: val.id },
  //         })
  //           .then((res) => res.json())
  //           .then((res) => {
  //             if (
  //               res.data.allRequestItemReceipts &&
  //               res.data.allRequestItemReceipts.RequestItemReceipts.length > 0
  //             ) {
  //               const result =
  //                 res.data.allRequestItemReceipts.RequestItemReceipts.map(
  //                   (item) => {
  //                     return {
  //                       id: item.id,
  //                       url: item.receiptUrl,
  //                       paymentRequestId: val.id,
  //                     };
  //                   }
  //                 );
  //               this.setState({
  //                 receiptImage: [...this.state.receiptImage, ...result],
  //               });
  //             }
  //           })
  //           .catch((e) => console.log(e));
  //       });
  //     }
  //   }
  // };
  handleOccuranceChange = (e, p) => {};

  getPaymentImages = (id) => {
    fetchMethod(paymentImagesList, {
      where: { paymentRequestId: id },
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allRequestItemImages.RequestItemImages.length > 0
          ? this.setState({
              paymentImages:
                res.data.allRequestItemImages.RequestItemImages.map((item) => {
                  return {
                    id: item.id,
                    url: item.itemImageUrl,
                  };
                }),
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  getReceipt = (id) => {
    fetchMethod(paymentReceipt, {
      where: { paymentRequestId: id },
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allRequestItemReceipts.RequestItemReceipts.length > 0
          ? this.setState({
              receiptImages:
                res.data.allRequestItemReceipts.RequestItemReceipts.map(
                  (item) => {
                    return {
                      id: item.id,
                      url: item.receiptUrl,
                    };
                  }
                ),
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  setRecentUserId = () => {
    this.setState({
      userIdofRecent: new URLSearchParams(this.props.location.search).get("id"),
    });
  };

  paymentRequestData = async () => {
    try {
      const response = await fetchMethod(userdataQuery, {
        where: { guardianId: JSON.parse(localStorage.getItem("userInfo")).id },
      }).then((res) => res.json());
      const participants = response?.data?.allUserdata?.Userdata || [];
      var ids = [];
      participants.map((i) => {
        ids.push(i.id);
      });
      let payment;
      let usersData = [];
      var createdAt = {};
      var paymentGuardianRequestQueryWhere = {
        participantId: { inq: [ids] },
        paymentStatus: this.state.paymentStatus,
        order: "createdAt desc",
      };
      if (this.state.fromDate && !this.state.toDate)
        createdAt.gt = new Date(this.state.fromDate);
      if (this.state.toDate && !this.state.fromDate)
        createdAt.lt = new Date(this.state.toDate);
      if (this.state.toDate && this.state.fromDate) {
        let lastDay = new Date(this.state.toDate);
        lastDay.setHours(23, 59, 59, 999);
        let previousDay = new Date(this.state.fromDate);
        previousDay.setHours(0, 0, 0, 0);
        paymentGuardianRequestQueryWhere["and"] = [
          { createdAt: { lte: lastDay } },
          { createdAt: { gte: previousDay } },
        ];
      }

      if (Object.keys(createdAt).length !== 0)
        paymentGuardianRequestQueryWhere.createdAt = createdAt;

      payment = await fetchMethod(paymentGuardianRequestQuery, {
        where: paymentGuardianRequestQueryWhere,
      }).then((res) => res.json());
      const connectedSupporterPayments =
        payment.data?.allPaymentRequests?.PaymentRequests || [];
      if (connectedSupporterPayments.length) {
        this.setState({
          count: payment.data.allPaymentRequests.totalCount,
        });
        connectedSupporterPayments.map(async (item) => {
          let id = item?.id;
          let isrepeatpay = item?.isrepeatpay;
          let cardNumber =
            item?.fkPaymentRequestCreatedbyCardIdrel?.CardDetails[0]
              ?.cardNumber || "";
          let cardStatus =
            item?.fkPaymentRequestCreatedbyCardIdrel?.CardDetails.length > 0
              ? item?.fkPaymentRequestCreatedbyCardIdrel?.CardDetails[0]
                  .cardstatus === "0" ||
                item?.fkPaymentRequestCreatedbyCardIdrel?.CardDetails[0]
                  .cardstatus === 0 ||
                item?.fkPaymentRequestCreatedbyCardIdrel?.CardDetails[0]
                  .cardstatus === null
                ? "BLOCK"
                : "UNBLOCK"
              : "";
          let category = item?.fkPaymentRequestItemCategoryIdrel
            ?.MasterItemCategories[0]
            ? item?.fkPaymentRequestItemCategoryIdrel?.MasterItemCategories[0][
                "categoryName"
              ]
            : "";
          let categoryName =
            category.charAt(0).toUpperCase() + category.slice(1);
          let paymentMadeby =
            item?.fkpaymentrequestcreatebymaprel?.Userdata[0] || [];
          let paymentMadeFor =
            item?.fkPaymentRequestParticipantIdrel?.Userdata[0] || [];
          let madeby =
            paymentMadeby.firstname && paymentMadeby.lastname
              ? paymentMadeby.firstname + " " + paymentMadeby.lastname
              : " ";
          let madefor =
            item.createdBy === item.participantId
              ? "SELF"
              : paymentMadeFor.firstname + " " + paymentMadeFor.lastname;
          let supporter =
            item?.fkPaymentRequestSupporterIdrel?.Userdata[0] || [];
          let supporterName =
            supporter.firstname && supporter.lastname
              ? supporter.firstname + " " + supporter.lastname
              : " ";
          let participant =
            item?.fkPaymentRequestParticipantIdrel?.Userdata[0] || [];
          let participantName =
            participant.firstname && participant.lastname
              ? participant.firstname + " " + participant.lastname
              : "";
          let madeforId = item.participantId;
          let itemDetail = item?.itemDetail;
          let paymentStatus = item?.paymentStatus;
          let extraNotes = item?.extraNotes;
          let transactiondateValue =
            item.transactiondate !== undefined && item.transactiondate !== null
              ? this.formatDate(item.transactiondate)
              : "";
          let requestedAmount =
            item?.requestedAmount !== null ? "$" + item?.requestedAmount : "";
          let createdAt =
            item?.createdAt !== undefined && item?.createdAt !== null
              ? this.formatDate(item.createdAt)
              : "";
          let storeName =
            item?.fkPaymentRequestStoreIdrel?.Businesses.length > 0
              ? item?.fkPaymentRequestStoreIdrel?.Businesses[0].storeName
              : "";
          let DisputeStatus =
            item?.fk_dispute_payment_request_id?.Disputes.length > 0
              ? "Yes"
              : "No";
          let changeamount =
            item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
            item?.authledgerPaymentRequestMap?.Authledgers[0].changeamount !==
              null
              ? "$" +
                item?.authledgerPaymentRequestMap.Authledgers[0].changeamount
              : item?.paymentStatus === "Complete"
              ? "$0"
              : "";
          let spentamount =
            item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
            item?.authledgerPaymentRequestMap?.Authledgers[0].spentamount !==
              null
              ? "$" +
                item?.authledgerPaymentRequestMap.Authledgers[0].spentamount
              : "";
          let cardlimit =
            item?.fkPaymentMap?.SuperLedgers.length > 0
              ? "$" +
                (item?.fkPaymentMap.SuperLedgers?.length
                  ? item?.fkPaymentMap.SuperLedgers[
                      item?.fkPaymentMap.SuperLedgers?.length - 1
                    ].cardlimit
                  : 0)
              : "";
          let isverified = item?.isverified == 0 ? "No" : "Yes";
          let merchantname = item
            ? item.authledgerPaymentRequestMap
              ? item.authledgerPaymentRequestMap.Authledgers.length > 0
                ? item.authledgerPaymentRequestMap.Authledgers[0][
                    "merchantname"
                  ]
                : ""
              : ""
            : "";
          let authid = item
            ? item.authledgerPaymentRequestMap
              ? item.authledgerPaymentRequestMap.Authledgers.length > 0
                ? item.authledgerPaymentRequestMap.Authledgers[0]["id"]
                : ""
              : ""
            : "";
          let categoryList = this.state.itemCategory;

          usersData.push({
            authid,
            id,
            isrepeatpay,
            cardNumber,
            cardStatus,
            categoryName,
            madeby,
            madefor,
            supporterName,
            participantName,
            itemDetail,
            paymentStatus,
            extraNotes,
            requestedAmount,
            createdAt,
            storeName,
            DisputeStatus,
            madeforId,
            changeamount,
            spentamount,
            transactiondateValue,
            cardlimit,
            isverified,
            merchantname,
            categoryList,
          });
        });
      }
      let page = this.state.pageNo / this.state.rows;
      let finalData = [];
      let final =
        page * this.state.rows < usersData.length
          ? page * this.state.rows
          : usersData.length;
      for (let i = (page - 1) * this.state.rows; i < final; i++) {
        finalData.push(usersData[i]);
      }
      this.setState({
        listData:
          finalData !== null
            ? finalData.sort((a, b) => {
                return b.id - a.id;
              })
            : [],
        listData_copy:
          finalData !== null
            ? finalData.sort((a, b) => {
                return b.id - a.id;
              })
            : [],
        // listData__copy: usersData !== null ? usersData : [],
      });
    } catch (err) {
      swal({ title: err.message, icon: "warning" });
      this.setState({ listData: [] });
    }
  };

  resetFilters = async () => {
    if (localStorage.getItem("role") === "GUARDIAN") {
      this.setState({
        searchValue: "",
        fromDate: undefined,
        toDate: undefined,
        searchStatus: "Complete",
        listData: this.state.listData_copy,
        paymentStatus: "Complete",
      });
      document.querySelector("#date").value = "";
      if (localStorage.getItem("paymentId") != null) {
        localStorage.removeItem("paymentId");
        await this.setState(
          {
            // filter,
            listData: undefined,
            txnData: undefined,
            listData_copy: undefined,
          },
          () => {
            this.paymentRequestData();
          }
        );
      }
    } else {
      delete this.state.filter.createdBy;
      this.setState(
        {
          // filter,
          paymentxyz: null,
          searchValue: "",
          searchStatus: "Complete",
          paymentStatus: "Complete",
        },
        () => {
          this.paymentRequestData();
        }
      );
      document.querySelector("#date").value = "";
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size,
        listData: undefined,
      },
      () => {
        this.paymentRequestData();
      }
    );
  };

  getShoppingList = (id) => {
    fetchMethod(paymentShoppingList, {
      where: { paymentRequestId: id },
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allRequestItemShoppingLists.RequestItemShoppingLists.length > 0
          ? this.setState({
              loading: false,
              paymentShopping:
                res.data.allRequestItemShoppingLists.RequestItemShoppingLists.map(
                  (item) => {
                    return {
                      id: item.id,
                      name: item.itemName,
                      price: item.itemPrice ? item.itemPrice : 0,
                    };
                  }
                ),
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  handleFilterAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      const { filter } = this.state;
      filter.createdBy = v.id;

      this.setState({ createdBy: v.id, filter, paymentxyz: v });

      this.paymentRequestData();
    }
  };

  handleCheckChange = (event, ele) => {
    this.setState({
      checked: {
        ...this.state.checked,
        [ele.id]: event.target.checked,
      },
    });
    if (event.target.checked) {
      this.setState({
        allCSVData: [...this.state.allCSVData, ele],
      });
    }
    if (!event.target.checked) {
      var va = this.state.allCSVData;
      va.splice(
        va.findIndex((a) => a.id === ele.id),
        1
      );
      this.setState({
        allChecked: false,
        allCSVData: va,
      });
    }
  };

  handleAllCheckChange = (event) => {
    if (event.target.checked) {
      var value = {};
      var arr = [];
      this.state.listData.map((ele) => {
        value[ele.id] = true;
        arr.push(ele);
      });
      this.setState({
        allCSVData: arr,
        allChecked: true,
        checked: value,
      });
    }
    if (!event.target.checked) {
      this.setState({
        allCSVData: [],
        allChecked: false,
        checked: {},
      });
    }
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleSearch = (event) => {
    if (event.target.value) {
      this.setState({
        listData: this.state.listData_copy.filter(
          (record) =>
            record.madeby
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            record.madefor
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            record.storeName
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            record.requestedAmount.includes(event.target.value)
        ),
      });
    } else {
      this.setState({
        listData: this.state.listData_copy,
      });
    }
  };

  handleStatusSearch = (event) => {
    this.setState({ listData: undefined });
    this.setState({ searchStatus: event.target.value });
    this.setState({ paymentStatus: event.target.value });
    this.paymentRequestData();

    // if (event.target.value) {
    //   this.setState({
    //     listData: this.state.listData_copy.filter(
    //       (record) =>record.paymentStatus.toLowerCase().includes(event.target.value.toLowerCase())
    //     ),
    //   });
    // } else {
    //   this.setState({
    //     listData: this.state.listData_copy,
    //   });
    // }
  };

  handleDateSearch = (date, which) => {
    this.setState({ listData: undefined });
    if (which == "from") {
      this.setState({ fromDate: date });
    }
    if (which == "to") {
      this.setState({ toDate: date });
    }
    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.paymentRequestData();
      },
      200
    );
  };

  setDataWithHeader = (fileData) => {
    let filedata = [];
    fileData.map((item) => {
      return filedata.push({
        "Added On Merchant Name": item.merchantname,
        "Spent Amount": item.spentamount,
        "Card Limit": item.cardlimit,
        Category: item.categoryName,
        "Made By": item.madeby,
        "Made For": item.madefor,
        "Payment Status": item.paymentStatus,
        Verified: item.isverified,
        "Allocated Amount": item.requestedAmount,
        "Change Amount": item.changeamount,
        AuthID: item.authid,
        // "Added On": item.createdAt,
        // "Transaction Date": item.transactiondate,
        // Supporter: item.supporterName,
        // Participant: item.participantName,
        // "Store Name": item.storeName,
      });
    });
    return filedata;
  };

  downloadExcelFile = (fileData, fileName) => {
    let filedata = this.setDataWithHeader(fileData);
    try {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(filedata);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      swal("Error", { icon: "error" });
    }
  };

  isChecked = (id, isverified) => {
    const verifiedValue = this.state.listData.map((item) => {
      if (item.id == id) {
        return { ...item, isverified: isverified === 1 ? "Yes" : "No" };
      }
      return item;
    });
    this.setState({ listData: verifiedValue });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };
  handleCloseReceipt = () => {
    this.setState({ openModalReceipt: false });
  };

  openModalBox = (data) => {
    this.setState({ openModal: true, paymentImages: [] });
    this.getPaymentImages(data.id);
  };

  openReceiptBox = (data) => {
    this.setState({ openModalReceipt: true, receiptImages: [] });
    this.getReceipt(data.id);
  };

  onClickDownPic = async () => {
    await this.setState({ isLoading: !this.state.isLoading });
  };
  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  getCategories = async () => {
    let obj = {
      isActive: 1,
      forrepeatpay: 1,
    };
    this.setState({ isLoading: true });
    await fetchMethod(getItemCategory, { where: obj })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ isLoading: false });
        let result =
          res.data?.allMasterItemCategories?.MasterItemCategories?.map((i) => {
            return {
              id: i.id !== null ? i.id : "",
              name:
                i.categoryName !== null
                  ? this.capitalizeFirstLetter(i.categoryName)
                  : "",
            };
          });
        this.state.itemCategory = result;
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        this.state.itemCategory = [];
        swal({ title: error.message, icon: "warning" });
      });
  };
  saveCategory = (id, selectedCategory) => {
    if (selectedCategory > 0) {
      const Obj = {
        obj: {
          id: id,
          itemCategoryId: selectedCategory,
        },
      };
      this.setState({ isLoading: true });
      fetchMethod(saveItemCategory, Obj)
        .then((response) => response.json())
        .then((res) => {
          this.setState({ isLoading: false });
          if (res && res.data && res.data.savePaymentRequest) {
            swal({ text: "Item category saved successfully", icon: "success" });
          } else {
            if (res.error) {
              swal({ title: res.error.message, icon: "warning" });
            } else {
              swal({ title: "Update error", icon: "warning" });
            }
          }
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          swal({ title: e.message, icon: "warning" });
        });
    }
  };

  saveCategoryInAuthledger = (id, selectedCategory) => {
    if (selectedCategory > 0) {
      const Obj = {
        obj: {
          id: id,
          itemcatid: selectedCategory,
        },
      };
      this.setState({ isLoading: true });
      fetchMethod(saveAuthledgerCategory, Obj)
        .then((response) => response.json())
        .then((res) => {
          this.setState({ isLoading: false });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          swal({ title: e.message, icon: "warning" });
        });
    }
  };

  render() {
    const nameColumn = [
      {
        Header: (
          <Checkbox
            checked={this.state.allChecked}
            onChange={(event) => this.handleAllCheckChange(event)}
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
        Cell: (row) => {
          return (
            <Checkbox
              checked={this.state.checked[row.original.id]}
              onChange={(event) => this.handleCheckChange(event, row.original)}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
        width: 50,
      },
    ];

    const fieldColumns = [
      {
        Header: "",
        sortable: false,
        width: 60,
        Cell: (row) => (
          <div>
            <TransactionDetailsModal
              data={row.original}
              isChecked={(id, isverified) => {
                this.isChecked(id, isverified);
              }}
              onClickDownPic={() => {
                this.onClickDownPic();
              }}
            />
          </div>
        ),
      },
      {
        Header: "Date/time of transaction",
        accessor: "transactionDate",
        sortable: true,
        Cell: (row) => <p>{row.original.transactiondateValue}</p>,
      },
      {
        Header: "Merchant Name",
        accessor: "merchantname",
        sortable: true,
        Cell: (row) => {
          return (
            <p
              className={
                this.state.userIdofRecent
                  ? row.original.id == this.state.userIdofRecent
                    ? `recentHighlightbyid`
                    : ""
                  : ""
              }
            >
              {row.original.merchantname}
            </p>
          );
        },
      },
      {
        Header: "Starting Balance",
        accessor: "requestedAmount",
        sortable: true,
        Cell: (row) => {
          return (
            <p
              className={
                this.state.userIdofRecent
                  ? row.original.id == this.state.userIdofRecent
                    ? `recentHighlightbyid`
                    : ""
                  : ""
              }
            >
              {row.original.requestedAmount}
            </p>
          );
        },
      },
      {
        Header: "Purchase Amount",
        accessor: "spentamount",
        sortable: true,
        Cell: (row) => {
          return (
            <p
              className={
                this.state.userIdofRecent
                  ? row.original.id == this.state.userIdofRecent
                    ? `recentHighlightbyid`
                    : ""
                  : ""
              }
            >
              {row.original.paymentStatus == "Pending" ||
              row.original.paymentStatus == "Unloaded"
                ? " "
                : row.original.spentamount}
            </p>
          );
        },
      },
      {
        Header: "Change Amount",
        accessor: "changeamount",
        sortable: true,
        Cell: (row) => {
          return (
            <p
              className={
                this.state.userIdofRecent
                  ? row.original.id == this.state.userIdofRecent
                    ? `recentHighlightbyid`
                    : ""
                  : ""
              }
            >
              {row.original.changeamount}
            </p>
          );
        },
      },
      {
        Header: "Wallet name",
        accessor: "description",
        sortable: true,
        Cell: (row) => (
          <Link to={`/${WALLET_LIST_ROUTE}/${row.original.id}`}>
            {/* <p>{row.original.wallet}</p> */}
            <p>..WallName..</p>
          </Link>
        ),
      },
      {
        Header: "Participant Name",
        accessor: "participantName",
        sortable: true,
        Cell: (row) => <p>{row.original.participantName}</p>,
      },
      {
        Header: "Purchase made by",
        accessor: "madeBy",
        sortable: true,
        Cell: (row) => <p>{row.original.supporterName}</p>,
      },
      {
        Header: "Category",
        accessor: "categoryName",
        sortable: true,
        Cell: (row) => {
          if (
            row.original.isrepeatpay == 1 &&
            row.original.paymentStatus == "Complete"
          ) {
            return (
              <div className="selectCategory">
                <Autocomplete
                  id="combo-box-demo"
                  freeSolo={false}
                  options={this.state.itemCategory}
                  getOptionLabel={(option) =>
                    option.name ? option.name : option
                  }
                  sx={{ width: 100 }}
                  onChange={(data, e) => {
                    row.original.categoryName = e ? e.name : null;
                    this.saveCategory(row.original.id, e ? e.id : null);
                    this.saveCategoryInAuthledger(
                      row.original.authid,
                      e ? e.id : null
                    );
                  }}
                  value={row.original.categoryName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select category"
                    />
                  )}
                />
              </div>
            );
          } else {
            return (
              <p
                className={
                  this.state.userIdofRecent
                    ? row.original.id == this.state.userIdofRecent
                      ? `recentHighlightbyid`
                      : ""
                    : ""
                }
              >
                {row.original.categoryName}
              </p>
            );
          }
        },
      },
      {
        Header: "Verified",
        accessor: "isverified",
        sortable: true,
        Cell: (row) => {
          return (
            <p
              className={
                this.state.userIdofRecent
                  ? row.original.id == this.state.userIdofRecent
                    ? `recentHighlightbyid`
                    : ""
                  : ""
              }
            >
              {row.original.isverified}
            </p>
          );
        },
      },
      //////////////////
      // {
      //   Header: "Added On",
      //   accessor: "createdAt",
      //   sortable: true,
      //   Cell: (row) => {
      //     return (
      //       <p
      //         className={
      //           this.state.userIdofRecent
      //             ? row.original.id == this.state.userIdofRecent
      //               ? `recentHighlightbyid`
      //               : ""
      //             : ""
      //         }
      //       >
      //         {row.original.createdAt}
      //       </p>
      //     );
      //   },
      // },

      // {
      //   Header: "Card Limit",
      //   accessor: "cardlimit",
      //   sortable: true,
      //   Cell: (row) => {
      //     return (
      //       <p
      //         className={
      //           this.state.userIdofRecent
      //             ? row.original.id == this.state.userIdofRecent
      //               ? `recentHighlightbyid`
      //               : ""
      //             : ""
      //         }
      //       >
      //         {row.original.cardlimit}
      //       </p>
      //     );
      //   },
      // },

      // {
      //   Header: "Made By",
      //   accessor: "madeby",
      //   sortable: true,
      //   Cell: (row) => {
      //     return (
      //       <p
      //         className={
      //           this.state.userIdofRecent
      //             ? row.original.id == this.state.userIdofRecent
      //               ? `recentHighlightbyid`
      //               : ""
      //             : ""
      //         }
      //       >
      //         {row.original.madeby}
      //       </p>
      //     );
      //   },
      // },
      // {
      //   Header: "Made For",
      //   accessor: "madefor",
      //   sortable: true,
      //   Cell: (row) => {
      //     return (
      //       <p
      //         className={
      //           this.state.userIdofRecent
      //             ? row.original.id == this.state.userIdofRecent
      //               ? `recentHighlightbyid`
      //               : ""
      //             : ""
      //         }
      //       >
      //         {row.original.madefor}
      //       </p>
      //     );
      //   },
      // },
      // {
      //   Header: "Payment Status",
      //   accessor: "paymentStatus",
      //   sortable: true,
      //   Cell: (row) => {
      //     return (
      //       <p
      //         className={
      //           this.state.userIdofRecent
      //             ? row.original.id == this.state.userIdofRecent
      //               ? `recentHighlightbyid`
      //               : ""
      //             : ""
      //         }
      //       >
      //         {row.original.paymentStatus}
      //       </p>
      //     );
      //   },
      // },
    ];

    const actionButton = [
      {
        Header: "Photo",
        sortable: false,
        Cell: (row) => (
          <div
            onClick={() => {
              this.openModalBox(row.original);
            }}
          >
            <span className="viewimageLink">
              <u>View</u>
            </span>
          </div>
        ),
      },
      {
        Header: "Receipt",
        sortable: false,
        Cell: (row) => (
          <div
            onClick={() => {
              this.openReceiptBox(row.original);
            }}
          >
            <span className="viewimageLink">
              <u>View</u>
            </span>
          </div>
        ),
      },
      {
        Header: "AuthId",
        accessor: "authid",
        sortable: true,
        Cell: (row) => {
          return (
            <p
              className={
                this.state.userIdofRecent
                  ? row.original.id == this.state.userIdofRecent
                    ? `recentHighlightbyid`
                    : ""
                  : ""
              }
            >
              {row.original.authid}
            </p>
          );
        },
      },
    ];

    const columns = nameColumn.concat(fieldColumns).concat(actionButton);
    console.log(this.state.listData);
    return (
      <div className="container adminPaymentSection">
        <h2>Transactions</h2>
        <div className="col-xs-5">
          <hr />
        </div>
        <div>
          <div className="paymentFex transactions-flex">
            {localStorage.getItem("role") === "GUARDIAN" ? (
              <div className="transactions-flexbox">
                <FormControl>
                  <TextField
                    className="textFilters"
                    variant="outlined"
                    onKeyUp={(event) => this.handleSearch(event)}
                    onChange={(evt) =>
                      this.setState({ searchValue: evt.target.value })
                    }
                    value={this.state.searchValue}
                    label="Payment Made By, Payment Made For, Store Name, Budget"
                  />
                </FormControl>
                <FormControl variant="inline">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        label="From Transaction Date"
                        format="dd/MM/yyyy"
                        margin="normal"
                        className="textFilters"
                        InputLabelProps={{
                          shrink: true,
                          min: this.state.fromDate,
                        }}
                        placeholder={"dd/mm/yyyy"}
                        id="date-picker-inline"
                        value={this.state.fromDate ? this.state.fromDate : null}
                        onChange={(evt) => this.handleDateSearch(evt, "from")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        keyboardIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                          >
                            <path
                              fill="#000000"
                              fill-rule="evenodd"
                              d="M61,154.006845 C61,153.45078 61.4499488,153 62.0068455,153 L73.9931545,153 C74.5492199,153 75,153.449949 75,154.006845 L75,165.993155 C75,166.54922 74.5500512,167 73.9931545,167 L62.0068455,167 C61.4507801,167 61,166.550051 61,165.993155 L61,154.006845 Z M62,157 L74,157 L74,166 L62,166 L62,157 Z M64,152.5 C64,152.223858 64.214035,152 64.5046844,152 L65.4953156,152 C65.7740451,152 66,152.231934 66,152.5 L66,153 L64,153 L64,152.5 Z M70,152.5 C70,152.223858 70.214035,152 70.5046844,152 L71.4953156,152 C71.7740451,152 72,152.231934 72,152.5 L72,153 L70,153 L70,152.5 Z"
                              transform="translate(-61 -152)"
                            />
                          </svg>
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  {/* <TextField
                      id="date"
                      className="textFilters"
                      variant="outlined"
                      label="From Transaction Date"
                      type="date"
                      format="dd/MM/yyyy"
                      onChange={evt => this.handleDateSearch(evt, "from")}
                      InputLabelProps={{
                        shrink: true,
                        min: this.state.fromDate
                      }}
                    /> */}
                </FormControl>
                <FormControl variant="inline">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        label="To Transaction Date"
                        className="textFilters"
                        format="dd/MM/yyyy"
                        margin="normal"
                        minDate={this.state.fromDate}
                        InputLabelProps={{
                          shrink: true,
                          min: this.state.fromDate,
                        }}
                        placeholder={"dd/mm/yyyy"}
                        id="date-picker-inline"
                        value={this.state.toDate ? this.state.toDate : null}
                        onChange={(evt) => this.handleDateSearch(evt, "to")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        keyboardIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                          >
                            <path
                              fill="#000000"
                              fill-rule="evenodd"
                              d="M61,154.006845 C61,153.45078 61.4499488,153 62.0068455,153 L73.9931545,153 C74.5492199,153 75,153.449949 75,154.006845 L75,165.993155 C75,166.54922 74.5500512,167 73.9931545,167 L62.0068455,167 C61.4507801,167 61,166.550051 61,165.993155 L61,154.006845 Z M62,157 L74,157 L74,166 L62,166 L62,157 Z M64,152.5 C64,152.223858 64.214035,152 64.5046844,152 L65.4953156,152 C65.7740451,152 66,152.231934 66,152.5 L66,153 L64,153 L64,152.5 Z M70,152.5 C70,152.223858 70.214035,152 70.5046844,152 L71.4953156,152 C71.7740451,152 72,152.231934 72,152.5 L72,153 L70,153 L70,152.5 Z"
                              transform="translate(-61 -152)"
                            />
                          </svg>
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  {/* <TextField
                      id="date"
                      className="textFilters"
                      variant="outlined"
                      label="To Transaction Date"
                      type="date"
                      format="dd/MM/yyyy"
                      placeholder="dd/mm/yyyy"
                      onChange={evt => this.handleDateSearch(evt, "to")}
                      InputLabelProps={{
                        shrink: true,
                        min: this.state.fromDate
                      }}
                    /> */}
                </FormControl>
                <FormControl variant="outlined">
                  <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined">
                    Status
                  </label>
                  <Select
                    label="Status"
                    value={this.state.searchStatus}
                    onChange={(e) => this.handleStatusSearch(e)}
                  >
                    <MenuItem value={"Complete"}>Complete</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Unloaded"}>Unloaded</MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div>
                <FormControl>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    value={this.state.paymentxyz}
                    options={this.state.userData ? this.state.userData : ""}
                    onChange={(e, v) => this.handleFilterAutocomplete(e, v)}
                    getOptionLabel={(option) =>
                      option &&
                      option.firstname +
                        " " +
                        (option.lastname !== null ? option.lastname : "")
                    }
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Made By"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}
            <div className="display_inline">
              {this.state.allCSVData ? (
                this.state.allCSVData.length > 0 ? (
                  <Button
                    className="resetBtn"
                    onClick={() => {
                      this.downloadExcelFile(
                        this.state.allCSVData,
                        "all-payment-requests"
                      );
                    }}
                  >
                    Download Excel
                  </Button>
                ) : (
                  <Button disabled className="resetBtn">
                    Download Excel
                  </Button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {this.state.listData ? (
          <div
            style={{ colour: "red" }}
            className="adminPaymentTable guardianPaymentTable"
          >
            <Dialog
              open={this.state.openModal}
              onClose={this.handleClose}
              aria-labelledby="simple-modal-title"
              ariadescribedby="simple-modal-description"
              className="viewImageScrollModal"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                Photos
              </DialogTitle>
              <DialogContent>
                <Carousel>
                  {this.state.paymentImages.length > 0 ? (
                    this.state.paymentImages.map((item, index) => (
                      <Carousel.Item>
                        <S3Image
                          className="d-block w-100"
                          src={item.url}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    ))
                  ) : (
                    <div>
                      <img
                        src={noImgFound}
                        alt="noImgFound"
                        className="noImageFound"
                      />
                    </div>
                  )}
                </Carousel>
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.openModalReceipt}
              onClose={this.handleClose}
              aria-labelledby="simple-modal-title"
              ariadescribedby="simple-modal-description"
              className="viewImageScrollModal"
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleCloseReceipt}
              >
                Receipt
              </DialogTitle>
              <DialogContent>
                <Carousel>
                  {this.state.receiptImages.length > 0 ? (
                    this.state.receiptImages.map((item, index) => (
                      <Carousel.Item>
                        <S3Image
                          className="d-block w-100"
                          src={item.url}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    ))
                  ) : (
                    <div>
                      <img
                        src={noImgFound}
                        alt="noImgFound"
                        className="noImageFound"
                      />
                    </div>
                  )}
                </Carousel>
              </DialogContent>
            </Dialog>
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={adminPaymentRequestList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows,
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            {/* <DotLoader size={50} color={"#fff"} /> */}
            <img src={Loader}></img>
          </div>
        )}
        {this.state?.isLoading && (
          <div className="spinner">
            <img src={Loader}></img>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(GuardianPaymentRequest);
