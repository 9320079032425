import swal from "sweetalert";
import api from "../api";
import { LINK, UNLINK } from "src/utils/constants";

export const fetchWallets = (data) => {
  return api.post(`wallets/search`, data);
};

export const fetchWalletDetails = (id) => {
  return api.get(`wallets/${id}/detail`);
};

export const walletSearch = (data) => {
  return api.post(`wallets/search`, {
    page: 0,
    limit: 10000,
    searchText: data,
    active: 1
  });
};

export const connectSupporter = (walletId, supporterId) => {
  return api.post(`wallets/${walletId}/supporters/${supporterId}`);
};

export const deleteWallet = async (walletId) => {
  try {
    await api.delete(`wallets/${walletId}`);
    swal({ title: "wallet has been removed successfully", icon: "success" });
  } catch (error) {
    swal({
      title: error?.message,
      icon: "error"
    });
  }
  return Promise.resolve();
};

export const deleteSupporter = async (walletId, supporterId) => {
  try {
    await api.delete(`wallets/${walletId}/supporters/${supporterId}`);
    swal({
      title: "supporter  has been removed successfully",
      icon: "success"
    });
  } catch (error) {
    swal({
      title: error?.message,
      icon: "error"
    });
  }
  return Promise.resolve();
};

export const deleteBudget = async (budgetId) => {
  try {
    await api.delete(`budgets/${budgetId}`);
    swal({ title: "budget  has been removed successfully", icon: "success" });
  } catch (error) {
    swal({
      title: error?.message,
      icon: "error"
    });
  }
  return Promise.resolve();
};

export const getParticipantConnectedSupporters = (participantId) => {
  return api.get(`/users/${participantId}/supporters`);
};

export const getSupporterConnectedWalletIds = (supporterId, participantId) => {
  return api.post(`/wallets/search`, {
    supporterIds: [supporterId],
    participantIds: [participantId],
    page: 0,
    limit: 1000
  });
};

export const getParticipantConnectedWallets = (participantId) => {
  return api.post(`/wallets/search`, {
    participantIds: [participantId],
    status: 1,
    page: 0,
    limit: 10000
  });
};

export function linkSupporterToWallets(participantId, supporterId, walletIds) {
  return api.put(`/users/${participantId}/wallets`, {
    supporterId,
    action: LINK,
    walletIds
  });
}
export function unlinkSupporterToWallets(
  participantId,
  supporterId,
  walletIds
) {
  return api.put(`/users/${participantId}/wallets`, {
    supporterId,
    action: UNLINK,
    walletIds
  });
}
export function linkSupporterToAllWallets(participantId, supporterId) {
  return api.put(`/users/${participantId}/wallets`, {
    supporterId,
    action: LINK
  });
}
