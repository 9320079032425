import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function usePagination(defaultPage = 0, defaultPageSize = 10) {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    let page = parseInt(params.get("page"));
    let pageSize = parseInt(params.get("pageSize"));

    if (isNaN(page)) page = defaultPage;
    if (isNaN(pageSize)) pageSize = defaultPageSize;
    return {
      page,
      pageSize
    };
  }, [search, defaultPage, defaultPageSize]);
}

export default usePagination;
