import React, { Component } from "react";
import FormComponent from "../../Form/FormComponent";
import { SidePopUpProfileConfig, changePasswordConfig } from "./ProfileConfig";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import {
  editProfileData,
  saveUserdata,
  UserdataChangePassword,
  getCardDetailsQuery,
  updateBankDetail
} from "./ProfileQuery";
import "./profile.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import "./profile.css";
import Delete from "../../assets/svg/delete.svg";
import Edit from "../../assets/images/uploadIcon.png";
import S3Image from "../S3Image";
import { uploadImage } from "src/network/service/imageService";
class ProfileSidePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editableData: undefined,
      cardno: undefined,
      cardId: undefined,
      popupShow: false,
      modal: false,
      renderState: false
    };
  }

  componentWillMount() {
    this.profileData();
  }

  profileData = () => {
    if (localStorage.getItem("userInfo")) {
      fetchMethod(
        editProfileData(JSON.parse(localStorage.getItem("userInfo")).id)
      )
        .then((res) => res.json())
        .then((res) => {
          fetchMethod(getCardDetailsQuery, {
            where: { userId: JSON.parse(localStorage.getItem("userInfo")).id }
          })
            .then((resp) => resp.json())
            .then((resp) => {
              if (
                resp &&
                res.data &&
                res.data.allUserdata &&
                res.data.allUserdata.Userdata
              ) {
                res.data.allUserdata.Userdata[0].cardNumber = resp.data
                  .allCardDetails.CardDetails[0]
                  ? resp.data.allCardDetails.CardDetails[0].cardNumber
                  : "";
                this.setState({
                  editableData: res.data.allUserdata.Userdata[0],
                  cardno: resp.data.allCardDetails.CardDetails[0]
                    ? resp.data.allCardDetails.CardDetails[0].cardNumber
                    : "",
                  cardId: resp.data.allCardDetails.CardDetails[0]
                    ? resp.data.allCardDetails.CardDetails[0].id
                    : ""
                });
              }
            });
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
        });
    }
  };

  uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return uploadImage(formData).catch((err) => {
      swal("Please try again", { icon: "error" });
    });
  };

  preSubmitChanges = async (e) => {
    if (e.cardNumber) {
      fetchMethod(getCardDetailsQuery, { where: { cardNumber: e.cardNumber } })
        .then((res) => res.json())
        .then(async (response) => {
          if (
            response.data.allCardDetails.CardDetails.length > 0 &&
            this.state.cardno !== e.cardNumber
          ) {
            swal({ title: "card already exist.", icon: "warning" });
            return false;
          } else {
            let cardnumber = e.cardNumber;
            if (this.props.file) {
              let fileUploadRes = await this.uploadFile(this.props.file);
              if (fileUploadRes?.data) {
                e["profileimage"] = fileUploadRes.data;
              }
            } else {
              e["profileimage"] = null;
            }

            e["updatedby"] = JSON.parse(localStorage.getItem("userInfo")).id;
            delete e.email;
            delete e.cardNumber;
            fetchMethod(saveUserdata, { obj: e })
              .then((res) => res.json())
              .then((response) => {
                const id = response.data.saveUserdata;
                if (id && id !== null) {
                  let Obj = {
                    obj: {
                      userId: id.id,
                      cardNumber: cardnumber,
                      createdAt: Date.now(),
                      cardtypeid: e.cardtypeid ? e.cardtypeid.id : 1
                    }
                  };

                  if (this.state.cardId) {
                    Obj.obj.id = this.state.cardId;
                  }
                  fetchMethod(updateBankDetail, Obj)
                    .then((response) => response.json())
                    .then((res) => {
                      // setLoading(false);
                      // console.log("res _updateBankDetail => ", res);
                      // navigation.navigate("Profile");
                    })
                    .catch((e) => {
                      // setLoading(false);
                      // alert(e);
                      // console.log("_updateBankDetail error ", e);
                    });

                  swal({
                    text: "Profile updated successfully",
                    icon: "success"
                  });
                  this.setState({ popupShow: false });
                  this.profileData();
                } else if (id === null) {
                  swal("Email already exists", { icon: "error" });
                } else {
                  swal("Please try again", { icon: "error" });
                }
              });
            return false;
          }
        });
    } else {
      e["updatedby"] = JSON.parse(localStorage.getItem("userInfo")).id;
      delete e.email;
      delete e.cardNumber;
      delete e.storeTypeId;
      if (this.props.file) {
        let fileUploadRes = await this.uploadFile(this.props.file);
        if (fileUploadRes?.data) {
          e["profileimage"] = fileUploadRes.data;
        }
      } else {
        e["profileimage"] = null;
      }
      fetchMethod(saveUserdata, { obj: e })
        .then((res) => res.json())
        .then((response) => {
          const id = response.data.saveUserdata;
          if (id && id !== null) {
            this.setState({ popupShow: false });
            swal({ text: "Profile updated successfully", icon: "success" });
          } else if (id === null) {
            swal("Email already exists", { icon: "error" });
          } else {
            swal("Please try again", { icon: "error" });
          }
        });
      return false;
    }
  };

  preSubmitChangesPassword = (e) => {
    delete e.confirmPassword;

    fetchMethod(UserdataChangePassword, {
      id: JSON.parse(localStorage.getItem("userInfo")).id,
      oldPassword: e.oldPassword,
      newPassword: e.newPassword
    })
      .then((res) => res.json())
      .then((response) => {
        const id = response.data.UserdataChangePassword;
        if (response?.errors?.length) {
          swal({
            title: response.errors[0].message,
            icon: "warning"
          });
        } else {
          this.setState({ modal: false });
          swal({
            title: "Password updated successfully",
            icon: "success"
          }).then((data) => {
            if (data || data === null) {
              localStorage.clear();
              window.location = "/";
            }
          });
        }
      });
    return false;
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ [anchor]: open });
  };

  toggleDraweroff = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if ("reSetFile" in this.props) this.props.reSetFile(null);
    this.setState({ [anchor]: open });
  };

  reSetFiles = () => {
    this.props.reSetFile();
    this.setState({ renderState: !this.state.renderState });
  };

  render() {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 4
    };

    return (
      <div>
        {this.props.buttonName && this.props.buttonName == "Edit Profile" ? (
          <p onClick={this.toggleDrawer("popupShow", true)}>
            {this.props.buttonName}
          </p>
        ) : (
          <p onClick={this.toggleDrawer("modal", true)}>
            {this.props.buttonName}
          </p>
        )}
        <Drawer
          anchor={"right"}
          open={this.state.popupShow}
          onClose={this.toggleDraweroff("popupShow", false)}
        >
          <Box className="editprofile-modal" role="presentation">
            {this.state.editableData !== undefined ? (
              <div className="profilemodifySection">
                <h3 className="headingEditProfile">
                  Edit Profile{" "}
                  <IconButton className="col-xs-1 ">
                    <CloseIcon
                      onClick={this.toggleDraweroff("popupShow", false)}
                    />
                  </IconButton>
                </h3>
                <div className="FormComponent">
                  <div className="profileImage">
                    <div className="image" onClick={this.props.onBtnClick}>
                      {this.props.filePreview ||
                      this.state.editableData.profileimage ? (
                        this.props.filePreview ? (
                          this.props.filePreview === "empty" ? (
                            <S3Image src="upload-icon-20609.png" />
                          ) : (
                            <img src={this.props.filePreview} alt="preview" />
                          )
                        ) : (
                          <S3Image src={this.state.editableData.profileimage} />
                        )
                      ) : (
                        <S3Image src="upload-icon-20609.png" />
                      )}
                    </div>
                    {this.props.filePreview ||
                    this.state.editableData.profileimage ? (
                      <div className="button-wrap">
                        <button onClick={this.props.onBtnClick}>
                          <img src={Edit} alt="Edit icon" />
                        </button>
                        <button className="delete" onClick={this.reSetFiles}>
                          <img src={Delete} alt="Delete icon" />
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <FormComponent
                    formConfig={SidePopUpProfileConfig}
                    editableData={this.state.editableData}
                    preSubmitChanges={this.preSubmitChanges}
                    buttonTitleCSS="salesbottomAction"
                    modalCloseCallback={() => {}}
                    continueButton="Update Profile"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </Box>
        </Drawer>
        <Modal
          open={this.state.modal}
          onClose={this.toggleDraweroff("modal", false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h3>Change Password</h3>
            <FormComponent
              formConfig={changePasswordConfig}
              buttonTitleCSS="salesbottomAction"
              modalCloseCallback={() => {}}
              preSubmitChanges={this.preSubmitChangesPassword}
              continueButton="Update Password"
            />
          </Box>
        </Modal>
      </div>
    );
  }
}

export default ProfileSidePopup;
