import React, { Component } from "react";
import ReactTableComponent from "../../../ReactTable/ReactTable";
import { fetchMethod } from "../../../FetchMethod";
import swal from "sweetalert";

import {
  getParticipantData,
  getWalletsQuery,
  saveUpdateWallet,
  transferFunds
} from "./ParticipantTabQuery";
//import { getParticipantData } from "./../ParticipantQuery";
import { DotLoader } from "react-spinners";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import "./participantTabSetup.css";
import {
  Button,
  IconButton,
  Switch,
  Dialog,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Delete from "../../../assets/svg/delete.svg";
import Edit from "../../../assets/svg/edit.svg";
import { Link } from "react-router-dom";
import { KYC_STATUS_PASS, WALLET_LIST_ROUTE } from "src/utils/constants";
import ConfirmationPrompt from "src/Components/UI/ConfirmationPrompt";
import { deleteWallet } from "src/network/service/walletService";

import {
  // Other Material-UI imports...
  DialogActions
} from "@material-ui/core";
import { getCurrentUser } from "src/utils/utils";
import { getRepeatPayDetails } from "../ParticipantQuery";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantId: this.props.id,
      participantData: {},
      listData: undefined,
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      filter: {
        participantId: this.props.id,
        order: "id desc",
        status: 1
      },
      openModal: false,
      totalData: undefined,
      cardData: [],
      flagged: undefined,
      supporterId: undefined,
      openWalletDialog: false,
      openTransferDialog: false,
      selectedWallet: undefined,
      walletDetailLoading: false,
      selectedWalletData: null,
      loading: false, // Initially set loading to false
      loadingSaveWallet: false
    };
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  componentWillMount() {
    this.wallet();
  }

  wallet = (flag) => {
    fetchMethod(getWalletsQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          this.setState(
            {
              count:
                res.data && res.data.allWallets && res.data.allWallets !== null
                  ? res.data.allWallets.totalCount
                  : "",
              listData:
                res.data &&
                res.data.allWallets !== undefined &&
                res.data.allWallets !== null
                  ? res.data.allWallets.Wallets
                  : ""
            },
            () => {
              this.props.disablerefetchConnect();
              //this.props.listModalData(this.state.totalData, flag);
            }
          );
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });

    fetchMethod(getParticipantData(this.state.participantId))
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          participantData: res.data.allUserdata.Userdata[0]
        });

        fetchMethod(getParticipantData(this.state.participantData.guardianId))
          .then((res) => res.json())
          .then((res) => {
            this.setState({
              guardianData: res.data.allUserdata.Userdata[0]
            });
          })
          .catch((e) => {
            swal({ title: e.message, icon: "warning" });
          });
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      });
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.wallet();
      }
    );
  };

  openModalBox = (data) => {
    this.setState({
      openModal: true,
      flagged: data.flagged,
      supporterId: data.supporterId
    });
    this.getCardDetails(data.supporterId);
  };

  handleClose = () => {
    this.setState({
      openModal: false,
      cardData: [],
      flagged: undefined,
      supporterId: undefined
    });
  };

  openWalletDialog = (data) => {
    if (this.state.participantData?.kycstatus === KYC_STATUS_PASS) {
      let walletId = data.id ? data.id : -1;
      this.setState({
        openWalletDialog: true,
        flagged: data ? data.flagged : true,
        walletId: walletId
      });
      if (walletId === -1) {
        this.createWalletDetail();
      } else {
        this.getWalletDetail(walletId);
      }
    } else {
      swal({
        icon: "warning",
        title: `We need to verify ${this.state.participantData?.firstname}'s identity. Please email support@spendable.com.au to get started`
      });
    }
  };

  handleDeleteWallet = (data) => {
    ConfirmationPrompt({
      visible: true,
      title: "Are you sure! \nDo you want to delete this wallet?",
      swalOptions: {
        buttons: true,
        dangerMode: true
      },
      icon: "warning",
      onConfirm: () => {
        fetchMethod(getRepeatPayDetails, {
          where: {
            active: 1,
            isrepeatpay: 1,
            isrepeatpaysetforparticipant: 1,
            walletid: data.id,
            status: "PENDING"
          }
        })
          .then((res) => res.json())
          .then((resp) => {
            if (resp.data?.allAuthledgers?.Authledgers?.length > 0) {
              swal({
                title:
                  "Can't Delete this Wallet, first Unload the existing Repeat Pay",
                icon: "error"
              });
              return;
            } else {
              deleteWallet(data.id).then(() => {
                this.wallet();
              });
            }
          });
      }
    });
  };

  handleWalletDialogClose = () => {
    this.setState({
      openWalletDialog: false,
      selectedWallet: undefined,
      flagged: undefined,
      walletId: undefined
    });
  };

  handleSaveWalletChanges = () => {
    let wallet = this.state.selectedWallet;
    let obj = {
      id: wallet.id !== 0 ? wallet.id : undefined,
      ownerId: wallet.ownerId,
      participantId: wallet.participantId,
      description: wallet.description,
      defaultWallet: wallet.default ? 1 : 0,
      requestedBy: getCurrentUser().id,
      status: 1
    };
    this.setState({ loadingSaveWallet: true });
    fetchMethod(saveUpdateWallet, {
      ...obj
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.WalletCreateOrUpdateWallet?.length > 0) {
          swal({ title: "Wallet Saved", icon: "success" });
          this.handleWalletDialogClose();
          this.wallet();
        } else {
          swal({
            title: "There was an error saving the wallet",
            icon: "warning"
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      })
      .finally(() => this.setState({ loadingSaveWallet: false }));
  };

  createWalletDetail = () => {
    let part = this.state.participantData;
    let guard = this.state.guardianData;

    this.setState({
      selectedWallet: {
        id: 0,
        description: "",
        balance: 0.0,
        // ownerId: guard.id, // Default Logged in Person
        // ownerName: `${guard.firstname} ${guard.lastname}`,
        participantId: part?.id, // Default Selected Participant
        participantName: part?.id ? `${part?.firstname} ${part?.lastname}` : "",
        status: "",
        createdBy: guard.id,
        createOn: new Date(),
        updatedBy: guard.id,
        updatedOn: new Date(),
        default: false,
        canwithdraw: false
      }
    });
  };

  getWalletDetail = (walletId) => {
    this.setState({
      walletDetailLoading: true
    });
    fetchMethod(getWalletsQuery, {
      where: { id: walletId }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.data.allWallets.Wallets.length > 0) {
          let item = resp.data.allWallets.Wallets[0];
          this.setState({
            selectedWallet: {
              id: item.id,
              description: item.description,
              balance: item.balance,
              ownerId: item.ownerId,
              ownerName:
                item.ownerUsedatarel.Userdata?.length > 0
                  ? item.ownerUsedatarel?.Userdata[0]?.firstname +
                    " " +
                    item.ownerUsedatarel?.Userdata[0]?.lastname
                  : "",
              participantId: item.participantId,
              participantName:
                item.participantUserdatarel.Userdata[0]?.firstname +
                " " +
                item.participantUserdatarel.Userdata[0]?.lastname,
              status: item.status,
              createdBy: item.createdBy,
              createOn: item.createOn,
              updatedBy: item.updatedBy,
              updatedOn: item.updatedOn,
              default: item.default === 1,
              canwithdraw: item.canwithdraw === 1
            }
          });
        } else {
          swal({ title: "Wallet does not exist!", icon: "warning" });
          this.setState({
            openWalletDialog: false
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      })
      .finally(() => {
        this.setState({
          walletDetailLoading: false
        });
      });
  };

  openTransferDialog = () => {
    this.setState({
      openTransferDialog: true,
      transferfrom: 0,
      transferTo: 0,
      transferAmount: 0.0
    });
  };

  handleTransferDialogClose = () => {
    this.setState({
      openTransferDialog: false,
      transferFrom: undefined,
      transferFromName: undefined,
      transferTo: undefined,
      transferToName: undefined,
      transferAmount: undefined
    });
  };

  handleTransferFunds = () => {
    if (this.state.transferFrom === 0 || this.state.transferTo === 0) {
      swal({ title: "Select a wallet.", icon: "warning" });
      return;
    }

    if (this.state.transferFrom === this.state.transferTo) {
      swal({ title: "Selected Wallets are the same.", icon: "warning" });
      return;
    }

    if (this.state.transferAmount <= 0.0) {
      swal({ title: "Enter an amount greater than $0", icon: "warning" });
      return;
    }

    fetchMethod(
      transferFunds(
        this.state.transferFrom,
        this.state.transferTo,
        this.state.transferAmount
      )
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.data.WalletTransferFunds) {
          swal({ title: "Transfer Successful", icon: "success" });
          this.handleTransferDialogClose();
          this.wallet();
        } else {
          swal({ title: "Error Transferring funds", icon: "warning" });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  handleSourceSelect = (e, v, r) => {
    if (v) {
      this.setState({
        transferFrom: v.id,
        transferFromName: v.description
      });
    }
  };

  handleDestinationSelect = (e, v, r) => {
    if (v) {
      this.setState({
        transferTo: v.id,
        transferToName: v.description
      });
    }
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };

  debounceTimer = null;

  handleWalletSearch = (event, values) => {
    const { filter } = this.state;
    if (values) {
      filter["supporterId"] = values.supporterId;
      this.setState({ listData: undefined });
      this.setState({
        search: values?.firstname,
        filter,
        pageNo: this.state.rows
      });
    } else {
      delete filter["supporterId"];
      this.setState({ listData: undefined });
      this.setState({
        search: "",
        filter,
        pageNo: this.state.rows
      });
    }
    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.connectedSupporter(1);
      },
      200
    );
  };

  walletDialog() {
    return (
      <Dialog
        open={this.state.openWalletDialog}
        onClose={this.handleWalletDialogClose}
        aria-labelledby="simple-modal-title"
        ariadescribedby="simple-modal-description"
        className="chooseBuisness selectSupportModal"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.handleWalletDialogClose}
        >
          Edit Wallet
        </DialogTitle>
        <div className="modal-body-box">
          {this.state.loadingSaveWallet ? (
            <div className="flex justify-center width-100">
              <CircularProgress />
            </div>
          ) : this.state.selectedWallet ? (
            <>
              <TextField
                value={this.state.selectedWallet?.description ?? ""}
                onChange={(e) => {
                  let wallet = this.state.selectedWallet;
                  wallet.description = e?.target?.value;
                  this.setState({ selectedWallet: wallet });
                }}
                label="Description"
                variant="outlined"
                type="text"
              />
              <TextField
                disabled
                value={this.state.selectedWallet?.ownerName}
                label="Owner"
                variant="outlined"
                type="text"
              />
              <TextField
                disabled
                value={this.state.selectedWallet?.participantName}
                label="Participant"
                variant="outlined"
                type="text"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.selectedWallet?.default}
                    onChange={(e, value) => {
                      let wallet = this.state.selectedWallet;
                      wallet.default = value ? 1 : 0;
                      this.setState({ selectedWallet: wallet });
                    }}
                  />
                }
                label="Default Wallet"
              />
              {/* <FormControlLabel
                  control={
                    <Switch
                      name="canwithdraw-wallet"
                      checked={this.state.selectedWallet?.canwithdraw}
                      onChange={(e, value) => {
                        let wallet = this.state.selectedWallet;
                        wallet.canwithdraw = value ? 1 : 0;
                        this.setState({ selectedWallet: wallet });
                      }}
                      color="primary"
                    />
                  }
                  label={"Cash Withdrawal Allowed"}
                /> */}
              <DialogButton>
                <Button onClick={() => this.handleWalletDialogClose()}>
                  Cancel
                </Button>
                <Button onClick={() => this.handleSaveWalletChanges()}>
                  Save
                </Button>
              </DialogButton>
            </>
          ) : this.state.walletDetailLoading ? (
            <div style={{ width: "100%" }} className="flex justifyCenter">
              <CircularProgress />
            </div>
          ) : (
            <p> Wallet does not exist! </p>
          )}
        </div>
      </Dialog>
    );
  }

  transferDialog() {
    return (
      <Dialog
        open={this.state.openTransferDialog}
        onClose={this.handleTransferDialogClose}
        aria-labelledby="simple-modal-title"
        ariadescribedby="simple-modal-description"
        className="chooseBuisness selectSupportModal"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.handleTransferDialogClose}
        >
          Transfer funds
        </DialogTitle>
        <div className="modal-body-box">
          <Autocomplete
            size="small"
            label="Wallet From"
            onChange={this.handleSourceSelect}
            value={this.state.walletFromName}
            options={this.state.listData}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Source Wallet"
                variant="outlined"
                placeholder="Type to search"
              />
            )}
          />
          <Autocomplete
            size="small"
            label="Wallet To"
            onChange={this.handleDestinationSelect}
            value={this.state.walletToName}
            options={this.state.listData}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination Wallet"
                variant="outlined"
                placeholder="Type to search"
              />
            )}
          />
          <TextField
            value={this.state.transferAmount}
            onChange={(e) =>
              this.setState({ transferAmount: e?.target?.value })
            }
            label="Transfer Amount"
            variant="outlined"
          />
          <DialogButton>
            <Button onClick={() => this.handleTransferDialogClose()}>
              Cancel
            </Button>
            <Button onClick={() => this.handleTransferFunds()}>Transfer</Button>
          </DialogButton>
        </div>
      </Dialog>
    );
  }

  render() {
    const nameColumn = [
      {
        Header: "Wallet ID",
        accessor: "id",
        sortable: true,
        Cell: (row) => {
          return <div className="dot">{row.original.id}</div>;
        }
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: true,
        Cell: (row) => {
          return (
            <Link
              to={`/${WALLET_LIST_ROUTE}/${row.original.id}`}
              className="link"
            >
              {row.original.description}
            </Link>
          );
        }
      },
      {
        Header: "Owner",
        accessor: "ownerid",
        sortable: true,
        Cell: (row) => {
          return (
            <div className="dot">
              {row.original.ownerUsedatarel.Userdata[0]?.id &&
                row.original.ownerUsedatarel.Userdata[0]?.firstname +
                  " " +
                  row.original.ownerUsedatarel.Userdata[0]?.lastname}
            </div>
          );
        }
      },
      {
        Header: "Balance",
        accessor: "balance",
        sortable: true,
        Cell: (row) => {
          return <div className="dot">{"$" + row.original.balance}</div>;
        }
      }
    ];
    const actionButton = [
      {
        Header: "Edit",
        sortable: false,
        Cell: (row) => (
          <div>
            <IconButton
              className="edit"
              onClick={(e) => this.openWalletDialog(row.original)}
            >
              <img src={Edit} alt="Edit icon" />
            </IconButton>
          </div>
        )
      },
      {
        Header: "Delete",
        sortable: false,
        Cell: (row) =>
          row.original.default ? null : (
            <div>
              <IconButton
                className="edit"
                onClick={(e) => this.handleDeleteWallet(row.original)}
              >
                <img src={Delete} alt="Delete icon" />
              </IconButton>
            </div>
          )
      }
    ];

    const columns = nameColumn.concat(actionButton);
    return (
      <div>
        {this.walletDialog()}
        {this.transferDialog()}
        {this.props.hasRefetch ? this.wallet() : ""}
        {this.state.listData ? (
          <>
            <div className="tab-data-box">
              <div className="tab-filter-box">
                <div className="row">
                  <div className="col-md-7">
                    <p></p>
                  </div>
                  <div className="col-md-3">
                    <Button
                      style={{ textDecoration: "underline" }}
                      onClick={this.openWalletDialog}
                    >
                      Create New Wallet
                    </Button>
                  </div>
                  <div className="col-md-2">
                    <Button
                      style={{ textDecoration: "underline" }}
                      onClick={this.openTransferDialog}
                    >
                      Transfer Funds
                    </Button>
                  </div>
                </div>
              </div>
              <div className="connectedSupporterTable">
                <ReactTableComponent
                  listData={this.state.listData}
                  listConfig={{
                    showPagination: true
                  }}
                  columns={columns}
                  dataCount={this.state.count}
                  updatePagination={this.updatePagination}
                  initialPage={this.state.pageNo / this.state.rows}
                  onRowClick={() => {}}
                  forSerialNo={{
                    pageNo: this.state.pageNo,
                    pageSize: this.state.rows
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}
      </div>
    );
  }
}

export default Wallet;
