import React, { Component } from "react";
import "./user_card.scss";
import Edit from "../../../assets/svg/edit.svg";
import S3Image from "src/Components/S3Image";
import { fetchMethod } from "src/FetchMethod";
import { authLedgerQuery } from "../ParticipantQuery";
import { Skeleton } from "@mui/material";

export class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardBal: 0,
      loading: false
    };
  }
  componentDidMount() {
    this.getParticipantCardBal();
  }
  async getParticipantCardBal() {
    this.setState({ loading: true });
    try {
      const resp = await fetchMethod(authLedgerQuery, {
        where: {
          userId: this.props.id,
          active: 1,
          supporterId: null,
          status: "PENDING"
        }
      });
      const data = await resp.json();
      const cardBal =
        data?.data?.allAuthledgers?.Authledgers[0]?.requestedamount;
      if (cardBal) {
        this.setState({
          cardBal,
          loading: false
        });
      }
    } finally {
      this.setState({
        loading: false
      });
    }
  }
  render() {
    return this.state.loading ? (
      <Skeleton variant="rectangle" className="user_card" height={187} />
    ) : (
      <div className="user_card">
        {localStorage.getItem("role") === "GUARDIAN" ? (
          ""
        ) : (
          <button
            className="edit"
            onClick={(e) => this.props.editParticipant()}
          >
            <img src={Edit} alt="Edit icon" />
          </button>
        )}
        <button onClick={(e) => this.props.onFirstNameClick()}>
          <div className="userDetails">
            {this.props.pic ? (
              <div className="userPic">
                <S3Image src={this.props.pic} alt={this.props.fname} />
              </div>
            ) : (
              <div className="initial">{this.props.fname.charAt(0)}</div>
            )}
            {this.props.id ? (
              <div className="userName">{"Id: " + this.props.id}</div>
            ) : (
              ""
            )}
            <div className="userName">{this.props.fname}</div>
            <div className="balance">
              <span>Card Bal: ${this.state.cardBal}</span>
            </div>
          </div>
        </button>
      </div>
    );
  }
}
export default UserCard;
