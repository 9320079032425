export const adminPaymentRequestList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Added On",
      accessor: "createdAt",
      sortable: false
    },
    {
      Header: "Transaction Date",
      accessor: "transactiondate",
      sortable: false
    },
    {
      Header: "Made By",
      accessor: "madeby",
      sortable: false
    },
    {
      Header: "Made For",
      accessor: "madefor",
      sortable: false
    },
    {
      Header: "Wal. id",
      accessor: "walletid",
      sortable: false
    },
    {
      Header: "Category",
      accessor: "categoryName",
      sortable: false
    },
    {
      Header: "Allocated Amount",
      accessor: "requestedAmount",
      sortable: false
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
      sortable: false
    },
    {
      Header: "Store Name",
      accessor: "storeName",
      sortable: false
    }
  ]
};

export const transactionListConfig = {
  showPagination: false,
  showSerialNo: true,
  columns: [
    {
      Header: "User Name",
      accessor: "userName",
      sortable: false
    },
    {
      Header: "User Type",
      accessor: "userType",
      sortable: false
    },
    {
      Header: "Store Name",
      accessor: "storeName",
      sortable: false
    },
    {
      Header: "Store City",
      accessor: "storeCity",
      sortable: false
    },
    {
      Header: "Item Category",
      accessor: "itemCategory",
      sortable: false
    },
    {
      Header: "Amount Spent",
      accessor: "requestedAmount",
      sortable: false
    },
    {
      Header: "Date",
      accessor: "date",
      sortable: false
    },
    {
      Header: "PPPN",
      accessor: "ppan",
      sortable: false
    }
  ]
};
