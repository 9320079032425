import swal from "sweetalert";
import { fetchMethod } from "../../FetchMethod";

export const cardLimitList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Participant",
      accessor: "userName",
      sortable: false
    },
    {
      Header: "Funds Allocated",
      accessor: "budgetAvailable",
      sortable: false
    },
    {
      Header: "Schedule",
      accessor: "scheduler",
      sortable: false
    }
  ]
}

export const addAutomateFundsConfig = {
  className: "addAdminParticipantForm",
  fields: [
    {
      label: "Participant",
      type: "searchSelect",
      name: "participantId",
      data: "participantOptions",
      value: ""
    },
    {
      label: "Schedule",
      type: "searchSelect",
      name: "timePeriod",
      data: "timePeriodOptions",
      value: ""
    },
    {
      label: "Funds Allocated",
      type: "input",
      name: "budgetAvailable",
      value: ""
    }
  ],

  validations: {
    participantId: e => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter participant"
        };
      }
      return {
        valid: true
      };
    },
    timePeriod: e => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter schedule."
        };
      }
      return {
        valid: true
      };
    },
    budgetAvailable: e => {
      let digit = new RegExp("^[0-9]{10}$");
      if (e == "" || e <= 0) {
        return {
          valid: false,
          errMsg: "Please enter valid Fund amount."
        };
      }
      if (isNaN(e) !== false) {
        return {
          valid: false,
          errMsg: "Fund Allocated should be contain only numeric value."
        };
      }
      if (e.includes(".")) {
        var value = e;
        var length = value.toString().length - (value.toString().indexOf('.') + 1)
        if (parseFloat(length) > 2) {
          return {
            valid: false,
            errMsg: "Only 2 digit allowed after decimal point"
          };
        }
      }
      return {
        valid: true
      };
    }
  }
}

export const editAutomatedFundsConfig = {
  className: "addAdminParticipantForm",
  fields: [
    {
      label: "Participant",
      type: "input",
      name: "participantId",
      disabled: true
    },

    {
      label: "Schedule",
      type: "searchSelect",
      name: "timePeriod",
      data: "timePeriodOptions",
      value: ""
    },
    {
      label: "Fund Allocated",
      type: "input",
      name: "budgetAvailable",
      value: ""
    },
  ],
  validations: {
    budgetAvailable: e => {
      let digit = new RegExp("^[0-9]{10}$");
      if (e == "" || e <= 0) {
        return {
          valid: false,
          errMsg: "Please enter valid Fund amount."
        };
      }
      if (isNaN(e) !== false) {
        return {
          valid: false,
          errMsg: "Fund Allocated should be contain only numeric value."
        };
      }
      if (e.toString().includes(".")) {
        var value = e;
        var length = value.toString().length - (value.toString().indexOf('.') + 1)
        if (parseFloat(length) > 2) {
          return {
            valid: false,
            errMsg: "Only 2 digit allowed after decimal point"
          };
        }
      }
      return {
        valid: true
      };
    },
    timePeriod: e => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter schedule."
        };
      }
      return {
        valid: true
      };
    },
  }
}
