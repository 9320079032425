import { fetchMethod } from "src/FetchMethod";
import { getAllBudgetCategoryForSupporterQuery } from "../queries/budgetConnectedSupporter";
import { budgetQuery } from "src/Components/Budget/BudgetQuery";
import api from "../api";
import { LINK, UNLINK } from "src/utils/constants";

export const getSupporterConnectedBudgets = async (
  supporterId,
  participantIds
) => {
  const res = await fetchMethod(
    getAllBudgetCategoryForSupporterQuery(supporterId, participantIds)
  );
  const data = await res.json();
  return data;
};

export const getParticipantConnectedBudgets = async (participantId) => {
  const res = await fetchMethod(budgetQuery, {
    where: {
      participantId,
      active: 1
    }
  });
  const data = await res.json();
  return data;
};

export function unlinkSupporterToBudgets(
  participantId,
  supporterId,
  budgetIds
) {
  return api.put(`/users/${participantId}/budgets`, {
    supporterId,
    action: UNLINK,
    budgetIds
  });
}
export function linkSupporterToBudgets(participantId, supporterId, budgetIds) {
  return api.put(`/users/${participantId}/budgets`, {
    supporterId,
    action: LINK,
    budgetIds
  });
}
