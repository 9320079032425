import { useCallback, useState } from "react";
import ReactTableComponent from "src/ReactTable/ReactTable";
import { settlementsConfig } from "./SettlementsConfig";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  getAllSettlements,
  settleTransaction
} from "src/network/service/settlementsService";
import { Button } from "@material-ui/core";
import { ADMIN } from "src/utils/constants";
import ConfirmationPrompt from "src/Components/UI/ConfirmationPrompt";
import usePagination from "src/hooks/usePagination";

function Settlements(props) {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [id, setId] = useState(null);
  const [settlementData, setSettlementData] = useState([]);
  const history = useHistory();

  const { page, pageSize } = usePagination();

  const onConfirmSettlement = () => {
    setLoading(true);
    settleTransaction(id)
      .then((_response) => {
        setLoading(false);
        fetchSettlements();
      })
      .catch((_error) => {
        setLoading(false);
      });
  };

  const fetchSettlements = useCallback(() => {
    setLoading(true);
    getAllSettlements(page, pageSize)
      .then((response) => {
        setCount(response?.total);
        setLoading(false);
        const { txnSettlements } = response;
        setSettlementData(txnSettlements);
      })
      .catch((_error) => {
        setLoading(false);
      });
  }, [page, pageSize]);

  useEffect(() => {
    if (localStorage.getItem("role") !== ADMIN) {
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    fetchSettlements();
  }, [page, pageSize, fetchSettlements]);

  const updatePagination = (totalEntries, size) => {
    const pageNo = totalEntries / size - 1;
    history.push(`/settlements?page=${pageNo}&pageSize=${size}`);
  };

  const actionButton = [
    {
      Header: "",
      sortable: false,
      Cell: (row) => (
        <Button
          variant="outlined"
          color="default"
          onClick={() => {
            setConfirmationVisible(true);
            setId(row.original.id);
          }}
        >
          settle
        </Button>
      ),
      width: 90
    }
  ];

  const columns = nameColumn
    .concat(settlementsConfig.columns)
    .concat(actionButton);

  return (
    <div className="adminPaymentSection">
      <h2>Transaction Settlements</h2>
      <ConfirmationPrompt
        title="Do you want to settle this transaction"
        visible={confirmationVisible}
        onConfirm={onConfirmSettlement}
      />
      <div className="adminPaymentTable">
        <ReactTableComponent
          listData={settlementData}
          listConfig={settlementsConfig}
          columns={columns}
          dataCount={count}
          updatePagination={updatePagination}
          initialPage={page + 1}
          forSerialNo={{
            pageNo: (page + 1) * pageSize,
            pageSize: pageSize
          }}
          loading={loading}
        />
      </div>
    </div>
  );
}

const nameColumn = [
  {
    Header: "S No.",
    Cell: (row) => {
      return <div>{row.original.sNo}</div>;
    },
    width: 50
  }
];

export default Settlements;
