import { Button, IconButton } from "@material-ui/core";
import React, { Component } from "react";
import { DotLoader } from "react-spinners";
import { fetchMethod } from "../../FetchMethod";
import ReactTableComponent from "../../ReactTable/ReactTable";
import "./Rules.css";
import { rulesList } from "./RulesConfig";
import { deleteCron, getCron, participantQueryData } from "./RulesQuery";
import Edit from "../../assets/edit.svg";
import Delete from "../../assets/svg/delete.svg";
import swal from "sweetalert";

export class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      filter: {
        order: "createdon desc"
      },
      loader: false
    };
  }

  componentWillMount() {
    this.getCronRules();
  }

  addRule = () => {
    this.props.history.push("/addrule");
  };

  getCronRules = () => {
    let obj = {};
    this.setState({ loader: true });
    if (localStorage.getItem("role") === "GUARDIAN") {
      var filterdata = this.state.filter;
      var participantObj = { role: "PARTICIPANT", active: 1 };
      participantObj.guardianId = JSON.parse(
        localStorage.getItem("userInfo")
      ).id;

      fetchMethod(participantQueryData, {
        where: participantObj
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.allUserdata &&
            resp.data.allUserdata.Userdata
          ) {
            var ids = [];
            resp.data.allUserdata.Userdata.map((i) => {
              ids.push(i.id);
            });
            filterdata.participantid = { inq: [ids] };
            this.setState({
              filter: filterdata
            });
          }
        })
        .then(() => {
          fetchMethod(getCron, {
            where: this.state.filter,
            last: parseInt(this.state.rows),
            first: parseInt(this.state.pageNo)
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.data?.allCronjobs?.Cronjobs?.length) {
                res.data.allCronjobs.Cronjobs.map((item) => {
                  return (
                    (item["userName"] =
                      item.fk_userdata_participantid &&
                      item.fk_userdata_participantid.Userdata.length
                        ? item.fk_userdata_participantid.Userdata[0].firstname +
                          " " +
                          item.fk_userdata_participantid.Userdata[0].lastname
                        : ""),
                    (item["itemCategory"] = item
                      .fk_masteritemcategory_categoryid.MasterItemCategories
                      .length
                      ? item.fk_masteritemcategory_categoryid.MasterItemCategories[0].categoryName
                          ?.charAt(0)
                          .toUpperCase() +
                        item.fk_masteritemcategory_categoryid.MasterItemCategories[0].categoryName?.slice(
                          1
                        )
                      : ""),
                    (item["budgetAvailable"] = "$" + item.amount),
                    (item["scheduler"] =
                      item.event?.charAt(0)?.toUpperCase() +
                      item.event.slice(1)),
                    (item["status"] = item.fk_masteritemcategory_categoryid
                      .MasterItemCategories.length
                      ? item.fk_masteritemcategory_categoryid
                          .MasterItemCategories[0].isActive
                      : "")
                  );
                });
                this.setState({
                  count: res.data.allCronjobs.totalCount,
                  listData: res.data.allCronjobs.Cronjobs,
                  loader: false
                });
              } else {
                this.setState({
                  loader: false
                });
              }
            });
        });
    } else {
      obj = {
        order: "createdon desc"
      };
      fetchMethod(getCron, {
        where: obj,
        last: parseInt(this.state.rows),
        first: parseInt(this.state.pageNo)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data?.allCronjobs?.Cronjobs?.length) {
            res.data.allCronjobs.Cronjobs.map((item) => {
              return (
                (item["userName"] =
                  item.fk_userdata_participantid &&
                  item.fk_userdata_participantid.Userdata.length
                    ? item.fk_userdata_participantid.Userdata[0].firstname +
                      " " +
                      item.fk_userdata_participantid.Userdata[0].lastname
                    : ""),
                (item["itemCategory"] = item.fk_masteritemcategory_categoryid
                  .MasterItemCategories.length
                  ? item.fk_masteritemcategory_categoryid.MasterItemCategories[0].categoryName
                      ?.charAt(0)
                      ?.toUpperCase() +
                    item.fk_masteritemcategory_categoryid.MasterItemCategories[0].categoryName.slice(
                      1
                    )
                  : ""),
                (item["budgetAvailable"] = "$" + item.amount),
                (item["scheduler"] =
                  item.event?.charAt(0)?.toUpperCase() + item.event?.slice(1)),
                (item["status"] = item.fk_masteritemcategory_categoryid
                  .MasterItemCategories.length
                  ? item.fk_masteritemcategory_categoryid
                      .MasterItemCategories[0].isActive
                  : "")
              );
            });
            this.setState({
              count: res.data.allCronjobs.totalCount,
              listData: res.data.allCronjobs.Cronjobs
            });
          }
        });
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.getCronRules();
      }
    );
  };

  handleEditModal = (data) => {
    this.props.history.push({
      pathname: "/editRule",
      state: { details: data.id }
    });
  };

  handleDelete = (data) => {
    swal({
      title: "Are you sure you really want to delete this record?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          fetchMethod(deleteCron, { id: data.id })
            .then((res) => res.json())
            .then((res) => {
              if (res.data.CronjobDeleteById.count === 1) {
                swal({
                  title: "Cron rule deleted successfully",
                  icon: "success"
                });
                this.getCronRules();
              }
            });
        }
      })
      .catch((err) => {
        swal({ title: err.message, icon: "warning" });
      });
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45
      }
    ];

    const actionButton = [
      {
        Header: "",
        sortable: false,
        Cell: (row) => (
          <div>
            {row.original.status === 0 ? (
              <IconButton aria-label="edit" disabled>
                <img src={Edit} alt="Edit" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="edit"
                onClick={() => this.handleEditModal(row.original)}
              >
                <img src={Edit} alt="Edit" />
              </IconButton>
            )}
            <IconButton
              aria-label="delete"
              onClick={() => this.handleDelete(row.original)}
            >
              <img src={Delete} alt="Delete" />
            </IconButton>
          </div>
        )
      }
    ];

    const columns = nameColumn.concat(rulesList.columns).concat(actionButton);
    return (
      <div className="rulesSection">
        <h2>Rules</h2>
        <div className="addbdgetBtn add">
          <Button onClick={this.addRule}>Add Rule</Button>
        </div>
        {this.state.listData ? (
          <div className="rulesTable">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={rulesList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            {this.state.loader === true ? (
              <DotLoader size={70} color={"#020f1f"} />
            ) : (
              <h4>
                <center>No Records Found.</center>
              </h4>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Rules;
