import gql from "graphql-tag";

export const connectedParticipantQuery = `
query($where:JSON,$last: Int, $first: Int){
  allParticipantConnectedSupporters(where:$where,last:$last,first:$first) {
      totalCount
    ParticipantConnectedSupporters {
      id
      fkParticipantConnectedSupporterParticipantIdrel{
        Userdata{
          id
          firstname
          lastname
          email
          phonenumber
          dob
          address
          city
          ndisNumber
        }
      }
    }
  }
}
`;

export const connectedWalletQuery = `
query($where:JSON,$last: Int, $first: Int){
  allWalletConnectedUsers(where:$where,last:$last,first:$first){
    WalletConnectedUsers{
       walletWalletrel(where: { status:1}){
         Wallets{
          id
          description
           participantUserdatarel(where: { active:1}){
             Userdata{
               id
               firstname
               lastname
               email
               phonenumber
               ndisNumber 
             }
          }
        }
      }
    }
  }
}
`;

export const getGuardianParticipant = `
query($where:JSON){
  allUserdata(where:$where){
    totalCount
    Userdata{
      id
    }
  }
}
`;

export const getSupporterConnectedParticipant = `
  query($where:JSON){
    allParticipantConnectedSupporters(where:$where){
      ParticipantConnectedSupporters{
        fkParticipantConnectedSupporterParticipantIdrel{
          Userdata{
            firstname
            lastname
            phonenumber
            address
            city
            dob
            email
          }
        }
      }
    }
  }
`;

export const superLedgerTransactions = `
query($where:JSON){
  allSuperLedgers(where:$where){
    SuperLedgers{
      txnType
      txnStatus
      createdBy
      amountAdded
      userId
      fkpaymentmaprel{
        PaymentRequests{
          itemDetail
         	fkPaymentRequestItemCategoryIdrel{
            MasterItemCategories{
              categoryName
            }
          }
        }
      }
      fkuseridsuperledgermaprel{
        Userdata{
          firstname
          lastname
          role
          id
        }
      }
      fkcreatebysuperledgermaprel{
        Userdata{
          firstname
          lastname
          id
          role
        }
      }
    }
  }
}
`;

export const connectedPaymentRequestQuery = `
query($where:JSON){
  allPaymentRequests(where:$where){
    totalCount
    PaymentRequests{
      id
        itemDetail
        requestedAmount
        paymentStatus
        fkPaymentRequestParticipantIdrel{
          Userdata{
            firstname
            lastname
          }
        }
        fkPaymentRequestItemCategoryIdrel{
          MasterItemCategories{
            categoryName
          }
        }
        fkpaymentrequestcreatebymaprel{
          Userdata{
            firstname
            lastname
          }
        }
    }
  }
}
`;
