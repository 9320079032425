import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  withStyles,
  Checkbox
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import ReactTableComponent from "src/ReactTable/ReactTable";
import { adminWalletsConfig } from "src/Components/Wallet/walletsConfig";
import "./participantTabSetup.css";
import {
  getParticipantConnectedWallets,
  getSupporterConnectedWalletIds,
  linkSupporterToWallets,
  unlinkSupporterToWallets
} from "src/network/service/walletService";
import { useState } from "react";
import { ACTIVE, INACTIVE, NO, YES } from "src/utils/constants";
import { useRef } from "react";
import { useMemo } from "react";
import Loader from "src/assets/Infinity-1s-200px.svg";
import swal from "sweetalert";

const DialogButton = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

function SupporterConnectedWallets({
  open,
  supporterId,
  handleClose,
  participantId,
  handleSubmit
}) {
  const [addedWalletIds, setAddedWalletIds] = useState([]);
  const [removedWalletIds, setRemovedWalletIds] = useState([]);
  const [walletsData, setWalletsData] = useState([]);
  const [loadingSupporterWallets, setLoadingSupporterWallets] = useState(false);
  const [loadingParticipantWallets, setLoadingParticipantWallets] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const connectedWalletIds = useRef([]);

  const onCheckBoxClick = (value, data) => {
    if (value) {
      if (!addedWalletIds.includes(data.id)) {
        setAddedWalletIds((prev) => [...prev, data.id]);
      }
      setRemovedWalletIds((prev) => prev.filter((id) => id !== data.id));
    } else {
      if (!removedWalletIds.includes(data.id)) {
        setRemovedWalletIds((prev) => [...prev, data.id]);
      }
      setAddedWalletIds((prev) => prev.filter((id) => id !== data.id));
    }
  };
  const handleSupporterSave = () => {
    setLoading(true);
    Promise.all([
      addedWalletIds.length > 0
        ? linkSupporterToWallets(participantId, supporterId, addedWalletIds)
        : Promise.resolve(),
      removedWalletIds.length > 0
        ? unlinkSupporterToWallets(participantId, supporterId, removedWalletIds)
        : Promise.resolve()
    ])
      .catch(() => {
        swal({ icon: "error", title: "Something went wrong try again" });
      })
      .finally(() => {
        setLoading(false);
        handleSubmit();
        handleClose();
      });
  };

  useEffect(() => {
    // fetch supporters's wallets
    if (supporterId !== undefined) {
      setLoadingSupporterWallets(true);
      getSupporterConnectedWalletIds(supporterId, participantId)
        .then((res) => {
          const idList = res?.list?.map((item) => item.id) ?? [];
          connectedWalletIds.current = idList;
        })
        .catch(() => {
          connectedWalletIds.current = [];
        })
        .finally(() => {
          setLoadingSupporterWallets(false);
        });
    }
  }, [supporterId]);

  useEffect(() => {
    // fetch participant's wallets
    if (participantId !== undefined) {
      setLoadingParticipantWallets(true);
      getParticipantConnectedWallets(participantId)
        .then((res) => {
          const participantWallets = res?.list?.map((item) => ({
            ...item,
            participant: item.participant?.fullName,
            owner: item.owner?.fullName,
            status: item.status ? ACTIVE : INACTIVE,
            canwithdraw: item.canwithdraw ? YES : NO
          }));
          setWalletsData(participantWallets);
        })
        .catch(() => {
          setWalletsData([]);
        })
        .finally(() => {
          setLoadingParticipantWallets(false);
        });
    }
  }, [participantId]);

  const actionButton = useMemo(
    () => [
      {
        Header: "Connect",
        Cell: (row) => {
          return (
            <TableCheckBox
              row={row}
              connectedWalletIds={connectedWalletIds.current}
              onCheckBoxClick={onCheckBoxClick}
            />
          );
        }
      }
    ],
    [connectedWalletIds.current]
  );
  const columns = nameColumn
    .concat(adminWalletsConfig.columns)
    .concat(actionButton);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="supporterConnectedWallets"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        className="chooseBuisness"
      >
        Edit Supporter's Wallets
      </DialogTitle>
      {loading ? (
        <div className="spinner">
          <img src={Loader} alt="loader" />
        </div>
      ) : (
        <DialogContent>
          <div className="modal-body-box">
            <div className="adminPaymentTable">
              <ReactTableComponent
                listData={walletsData}
                listConfig={{ ...adminWalletsConfig, showPagination: false }}
                columns={columns}
                dataCount={0}
                forSerialNo={{
                  pageNo: 0,
                  pageSize: 0
                }}
                loading={loadingParticipantWallets || loadingSupporterWallets}
              />
            </div>
            <DialogButton>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSupporterSave}>Save</Button>
            </DialogButton>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}

const nameColumn = [
  {
    Header: "S No.",
    Cell: (row) => {
      return <div>{row.original.sNo}</div>;
    },
    width: 50
  }
];

const TableCheckBox = ({ row, connectedWalletIds, onCheckBoxClick }) => {
  return (
    <Checkbox
      color="primary"
      defaultChecked={connectedWalletIds?.includes(row.original.id)}
      onChange={(_event, value) => {
        onCheckBoxClick(value, row.original);
      }}
    />
  );
};
export default SupporterConnectedWallets;
