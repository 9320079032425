import React, { Component } from "react";
import swal from "sweetalert";
import { withRouter } from "react-router-dom";
import { fetchMethod } from "../FetchMethod";
import { userdataSignup } from "./SignUpQuery";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo-budget-hub.svg";
import ShowPwd from "../assets/svg/show_pwd.svg";
import HidePwd from "../assets/svg/hide_pwd.svg";
import User from "../assets/svg/user.svg";
import Lock from "../assets/svg/lock.svg";
import AccessKey from "../assets/svg/access-key.svg";
import "./login.scss";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCode: "",
      showPassword: false,
      showcPassword: false,
      email: "",
      fname:"",
      lname:"",
      password: "",
      cPassword: "",
      errors: { email: "", password: "",fname:"",lname:"" }
    };
  }

  componentWillMount() {
    // if (this.props && this.props.location && this.props.location.email) {
    //   this.setState({ email: this.props.location.email });
    // }
  }
  // onSignIn = () => {
  //   this.props.history.push("/");
  // };
  onSubmit() {
    const { accessCode, password, cPassword, email, fname, lname } = this.state;
    if (
      email.length != 0 &&
      fname.length != 0 &&
      lname.length != 0 &&
      accessCode != 0 &&
      password.length != 0 &&
      cPassword.length != 0 &&
      password === cPassword
    ) {
      if (this.validateForm(email, password, fname, lname)) {
        fetchMethod(userdataSignup(email, accessCode, password, fname, lname))
          .then(res => res.json())
          .then(res => {
            // console.log(res);
            if (res && res.data && res.data.UserdataSignup.status === 409) {
              swal({
                icon: 'warning',
                title: 'Already signup!'
              })
            }
            if (res && res.data && res.data.UserdataSignup.status === 403) {
              swal({
                icon: 'warning',
                title: 'Invalid access code.'
              })
            }
            if (res && res.data && res.data.UserdataSignup.status === 404) {
              swal({
                icon: 'warning',
                title: 'Email does not exist.'
              })
            }
            if (res && res.data && res.data.UserdataSignup.status === 200) {
              this.setState({
                email: "",
                accessCode: "",
                password: ""
              });
              swal({
                title: "Sign up successfully.",
                icon: "success"
              });
              this.props.history.push("/");
            }
          });
      } else {
        swal({
          title:
            this.state.errors.email != ""
              ? this.state.errors.email
              : (this.state.errors.fname ? (this.state.errors.lname) : this.state.errors.password),
          icon: "error"
        });
      }
    } else if (accessCode === '') {
      swal({
        title: "Please enter valid access code",
        icon: "warning"
      });
    } else if (email === '') {
      swal({
        title: "Please enter valid email",
        icon: "warning"
      });
    } else if (password === '') {
      swal({
        title: "Please enter valid password",
        icon: "warning"
      });
    } else if (cPassword === '') {
      swal({
        title: "Please enter valid confirm password",
        icon: "warning"
      });
    } else if (password !== cPassword) {
      swal({
        title: "Password & confirm password must be same.",
        icon: "error"
      });
    }else if (fname  === '') {
      swal({
        title:  "Please enter valid first name",
        icon: "error"
      });
    }else if (lname  === '') {
      swal({
        title:  "Please enter valid last name",
        icon: "error"
      });
    }
  }
  validateForm = (email, password,fname,lname) => {
    const { errors } = this.state;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    errors.email = validEmailRegex.test(email.trim())
      ? ""
      : "Email is not valid!";
    errors.password =
      password.trim().length > 6
        ? ""
        : "Password should be greater than 6 characters";
    this.setState({ errors });
    const validNameRegx = RegExp(/^[a-zA-Z]+$/)
    errors.fname = validNameRegx.test(fname.trim())
      ? ""
      : "First Name is not valid!";
    errors.lname = validNameRegx.test(lname.trim())
      ? ""
      : "Last Name is not valid!";
    return this.state.errors.email === "Email is not valid!" ||
      this.state.errors.password ===
      "Password should be greater than 6 characters"
      ? false
      : true ||
      this.state.errors.fname === "First Name is not valid!" ||
      this.state.errors.lname === "Last Name is not valid!"
  };
  handleCode = e => {
    this.setState({ accessCode: e.target.value });
  };
  handleEmail = e => {
    this.setState({ email: e.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleClickShowcPassword = () => {
    this.setState({ showcPassword: !this.state.showcPassword });
  };
  handlePassword = e => {
    this.setState({ password: e.target.value });
  };
  handleChange = e => {
    this.setState({
      cPassword: e.target.value
    });
  };
  handleFirstName = e => {
    this.setState({ fname: e.target.value });
  };
  handleLastName = e => {
    this.setState({ lname: e.target.value });
  };

  render() {
    return (
      <div className="signInNew">
        <div className="flex">
          <div className="topRow">
            <p>
              Already have an account?{" "}
              <Link to="/" className="btn btn-primary sm">
                Sign In
              </Link>
            </p>
          </div>
          <div className="signInWrap">
            <div className="logo">
              <img src={Logo} alt="Logo | Budget hub" />
            </div>
            <div className="innerBox signUp">
              <h1>Sign Up</h1>
              {/* <form > */}
              <ul>
                <li>
                  <label>Access code</label>
                  <div className="bh-input-group">
                    <div className="bh-input-group-text">
                      <img src={AccessKey} alt="user access key" />
                    </div>
                    <input
                      value={this.state.accessCode}
                      className="bh-form-control"
                      id="Access Code"
                      name="Access Code"
                      placeholder="Access Code"
                      onChange={this.handleCode}
                    />
                  </div>
                </li>
                <li>
                  <label>Email address</label>
                  <div className="bh-input-group">
                    <div className="bh-input-group-text">
                      <img src={User} alt="user email address" />
                    </div>
                    <input
                      value={this.state.email}
                      className="bh-form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={this.handleEmail}
                    />
                  </div>
                </li>
                <li>
                  <label>First Name</label>
                  <div className="bh-input-group">
                    <div className="bh-input-group-text">
                      <img src={User} alt="user email address" />
                    </div>
                    <input
                      value={this.state.fname}
                      className="bh-form-control"
                      id="firstNmae"
                      name="firstNmae"
                      placeholder="First Name"
                      onChange={this.handleFirstName}
                    />
                  </div>
                </li>
                <li>
                  <label>Last Name</label>
                  <div className="bh-input-group">
                    <div className="bh-input-group-text">
                      <img src={User} alt="user email address" />
                    </div>
                    <input
                      value={this.state.lname}
                      className="bh-form-control"
                      id="lastNmae"
                      name="lastNmae"
                      placeholder="Last Name"
                      onChange={this.handleLastName}
                    />
                  </div>
                </li>
                <li>
                  <label>New password</label>
                  <div className="bh-input-group">
                    <div className="bh-input-group-text">
                      <img src={Lock} alt="user passowrd" />
                    </div>
                    <input
                      value={this.state.Password}
                      className="bh-form-control"
                      id="Password"
                      name="Password"
                      placeholder="Password"
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handlePassword}
                    />
                    <div
                      className="bh-input-group-text"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <img src={HidePwd} alt="user email address" />
                      ) : (
                        <img src={ShowPwd} alt="user email address" />
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <label>Confirm password</label>
                  <div className="bh-input-group">
                    <div className="bh-input-group-text">
                      <img src={Lock} alt="user passowrd" />
                    </div>
                    <input
                      value={this.state.cPassword}
                      className="bh-form-control"
                      id="Confirm password"
                      name="Confirm password"
                      placeholder="Confirm password"
                      type={this.state.showcPassword ? "text" : "password"}
                      onChange={this.handleChange}
                    />
                    <div
                      className="bh-input-group-text"
                      onClick={this.handleClickShowcPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <img src={HidePwd} alt="user email address" />
                      ) : (
                        <img src={ShowPwd} alt="user email address" />
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <button
                    className="btn btn-primary full"
                    type="submit"
                    onClick={() => this.onSubmit()}
                  >
                    Sign Up
                  </button>
                </li>
              </ul>
              <div className="forgotPass">
                <Link to="/resendAccessCode">Resend access code</Link>
              </div>

              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(SignUp);
