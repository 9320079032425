export const settlementsConfig = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Settle Amt.",
      accessor: "settlementAmount",
      sortable: false
    },
    {
      Header: "Auth Amt.",
      accessor: "spentAmount",
      sortable: false
    },
    {
      Header: "Req Amt.",
      accessor: "requestedAmount",
      sortable: false
    },
    {
      Header: "Group Pay",
      accessor: "groupPayment",
      sortable: false
    },
    {
      Header: "Txn Date",
      accessor: "transactionDate",
      sortable: false
    },
    {
      Header: "Wallet",
      accessor: "wallet",
      sortable: false
    },
    {
      Header: "Approval Code",
      accessor: "approvalNumber",
      sortable: false
    },
    {
      Header: "PPAN",
      accessor: "ppan",
      sortable: false
    },
    {
      Header: "Merchant",
      accessor: "merchant",
      sortable: false
    }
  ]
};
