import React, { useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  withStyles
} from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { fetchMethod } from "src/FetchMethod";
import { saveUpdateWallet } from "src/Components/Participant/ParticipantQuery";
import swal from "sweetalert";
import { getCurrentUser } from "src/utils/utils";

function WalletEditDialog({
  selectedWallet,
  openModal,
  handleWalletDialogClose,
  setSelectedWallet,
  onSave = () => {}
}) {
  const [loading, setLoading] = useState(false);
  const toggleDefault = () => {
    setSelectedWallet((prev) => ({
      ...prev,
      default: !prev.default
    }));
  };
  const toggleWithdraw = () => {
    setSelectedWallet((prev) => ({
      ...prev,
      canwithdraw: !prev.canwithdraw
    }));
  };

  const handleSaveWalletChanges = () => {
    let wallet = selectedWallet;
    let obj = {
      id: wallet.id !== 0 ? wallet.id : undefined,
      ownerId: wallet.ownerId,
      participantId: wallet.participantId,
      description: wallet.description,
      defaultWallet: wallet.default ? 1 : 0,
      requestedBy: getCurrentUser().id
    };

    setLoading(true);
    fetchMethod(saveUpdateWallet, {
      ...obj
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.WalletCreateOrUpdateWallet?.length > 0) {
          swal({ title: "Wallet Saved", icon: "success" });
          handleWalletDialogClose();
          onSave();
        } else {
          swal({
            title: "There was an error saving the wallet",
            icon: "warning"
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleWalletDialogClose}
      aria-labelledby="simple-modal-title"
      ariadescribedby="simple-modal-description"
      className="chooseBuisness selectSupportModal"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleWalletDialogClose}
      >
        Edit Wallet
      </DialogTitle>
      <div className="modal-body-box">
        {loading ? (
          <div className="flex justify-center width-100">
            <CircularProgress />
          </div>
        ) : selectedWallet ? (
          <>
            <TextField
              value={selectedWallet.description ?? ""}
              onChange={(e) => {
                let description = e?.target?.value;
                setSelectedWallet((prev) => ({
                  ...prev,
                  description: description
                }));
              }}
              label="Description"
              variant="outlined"
              type="text"
            />
            <TextField
              disabled
              value={selectedWallet.ownerName}
              label="Owner"
              variant="outlined"
              type="text"
            />
            <TextField
              disabled
              value={selectedWallet.participantName}
              label="Participant"
              variant="outlined"
              type="text"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedWallet.default}
                  onChange={() => toggleDefault()}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Default Wallet"
            />
            {/* <FormControlLabel
                control={
                  <Switch
                    name="canwithdraw-wallet"
                    checked={selectedWallet.canwithdraw}
                    onChange={() => toggleWithdraw()}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Cash Withdrawal Allowed"
              /> */}
          </>
        ) : (
          <p> Wallet does not exist! </p>
        )}
        <DialogButton>
          <Button onClick={() => handleWalletDialogClose()}>Cancel</Button>
          <Button onClick={() => handleSaveWalletChanges()} disabled={loading}>
            Save
          </Button>
        </DialogButton>
      </div>
    </Dialog>
  );
}

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
export default WalletEditDialog;
