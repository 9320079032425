import React, { useEffect, useRef } from "react";
import { adminRoutingList, guardianRoutingList } from "./RouteContent";
import { Link, useHistory, useLocation } from "react-router-dom";
import BackdropLoader from "../../../services/loader";
import Logo from "../../../assets/images/logo-white.svg";
import Help from "../../../assets/svg/help.svg";
import Notification from "../../../assets/svg/notification.svg";
import DropDown from "../../../assets/svg/down-arrow.svg";
import Ham from "../../../assets/svg/hamburger.svg";
import Cross from "../../../assets/svg/cross.svg";
import CrossGrey from "../../../assets/svg/cross-grey.svg";
import DashboardIcon from "../../../assets/svg/dashboard.svg";
import DashboardActiveIcon from "../../../assets/svg/dashboard_active.svg";
import ParticipantsIcon from "../../../assets/svg/participants.svg";
import ParticipantsActiveIcon from "../../../assets/svg/participants_active.svg";
import BudgetsIcon from "../../../assets/svg/budgets.svg";
import BudgetsActiveIcon from "../../../assets/svg/budgets_active.svg";
import TransactionsIcon from "../../../assets/svg/transactions.svg";
import TransactionsActiveIcon from "../../../assets/svg/transactions_active.svg";
import ItemCategoryActiveIcon from "../../../assets/svg/box.svg";
import AutomatedIcon from "../../../assets/svg/automated.svg";
import AutomatedActiveIcon from "../../../assets/svg/automated_active.svg";
import { Dialog, IconButton, Typography, TextField } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fetchMethod } from "../../../FetchMethod";
import swal from "sweetalert";
import { participantQueryData } from "../../Budget/BudgetQuery";
import {
  checkParticipantSupportExit,
  saveParticipantSupporterQuery,
  supporterDevice,
} from "../../Supporter/SupporterQuery";
import { saveParticipantBusinessQuery } from "../../Buisness/BuisnessQuery";
import "./sidebar.scss";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "react-bootstrap";
import ProfileSidePopup from "../../Profile/ProfileSidePopup";
import GuardianAddbusinessModal from "../../Buisness/guardianAddbusinessModal";
import { connectedSupporter } from ".././../Participant/ParticipantTabSetup/ParticipantTabQuery";
import { BudgetItemCategory } from "../../ItemCategory/BudgetItemCategory";
import { WALLET_LIST_ROUTE } from "src/utils/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function BudgetSidebar(props) {
  const history = useHistory();
  const location = useLocation();
  let listConfig =
    localStorage.getItem("role") === "GUARDIAN"
      ? guardianRoutingList
      : adminRoutingList;
  const [menuOpen, setMenuOpen] = React.useState(true);
  const [isActive, setIsActive] = React.useState("/dashboard");
  const [userInitials, setUserInitials] = React.useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );

  // Showmodal
  const [showModal, setShowModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allParticipantList, setAllParticipantList] = React.useState([]);
  const [participantId, setParticipantId] = React.useState(undefined);
  const [allSupporterList, setAllSupporterList] = React.useState([]);
  const [supporterId, setSupporterId] = React.useState(undefined);
  const [allBusinessList, setAllBusinessList] = React.useState([]);
  const [businessId, setBusinessId] = React.useState(undefined);
  const [showSupporterModal, setSupporterModal] = React.useState(false);
  const [showBusinessModal, setBusinessModal] = React.useState(false);

  const [showEditProfileModal, setEditProfileModal] = React.useState(false);
  const [showChangePassWordModal, setChangePassWordModal] =
    React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [file, setFile] = React.useState(null);
  const [filePreview, setFilePreview] = React.useState(null);
  const [isSupportterFil, setIsSupporter] = React.useState([]);
  const [supporterEmail, setSupporterEmail] = React.useState(null);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerShow = () => {
    setDrawerOpen(true);
    setAnchorEl(false);
  };

  const handleProfileModal = () => {
    setEditProfileModal((showEditProfileModal) => !showEditProfileModal);
  };

  const handleChangePasswordModal = () => {
    setChangePassWordModal(
      (showChangePassWordModal) => !showChangePassWordModal
    );
  };

  const handleShowModal = () => {
    setShowModal((showModal) => !showModal);
  };

  const handleSupporterModal = () => {
    setDrawerOpen(false);
    handleChangeField();
    setSupporterModal(!showSupporterModal);
    setAnchorEl(false);
    setSupporterEmail(null);
  };

  const handleBusinessModal = () => {
    handleChangeField();
    setBusinessModal(!showBusinessModal);
  };

  const [loader, setLoader] = React.useState(false);
  const handleDrawerToggle = () => {
    setMenuOpen((menuOpen) => !menuOpen);
  };

  const handleDrawerToggleActive = (value = "/dashboard") => {
    handleDrawerToggle();
    setIsActive(value);
  };

  window.addEventListener("storage", (e) => {
    if (e.key === "token" && e.oldValue && e.newValue === null) {
      LogOut();
    }
  });
  const LogOut = () => {
    localStorage.clear();
    return (window.location = "");
  };
  const allNotConnectSupporter = async (partcipantId) => {
    await fetchMethod(connectedSupporter, {
      where: {
        participantId: partcipantId.id,
        active: 1,
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        let connectedSupporter =
          res?.data?.allParticipantConnectedSupporters
            ?.ParticipantConnectedSupporters;
        console.log("connectedSupporter", connectedSupporter, allSupporterList);
        let unconnectedSupporter = [];
        await allSupporterList.map((item) => {
          let validSupporter = connectedSupporter.filter(
            (value) => value.supporterId == item.id
          );
          if (validSupporter == "") {
            unconnectedSupporter.push(item);
          }
        });
        if (localStorage?.getItem("role") == "GUARDIAN") {
          setAllSupporterList([]);
          setIsSupporter(unconnectedSupporter);
        } else {
          setAllSupporterList(unconnectedSupporter);
          setIsSupporter([]);
        }
      });
  };

  const handleChange = async (field, e) => {
    if (field === "participantId") {
      await allNotConnectSupporter(e.target.value);
      setParticipantId(e ? (e.target ? e.target.value : e) : e);
    } else if (field === "businessId") {
      setBusinessId(e ? (e.target ? e.target.value : e) : e);
    } else {
      setSupporterId(e ? (e.target ? e.target.value : e) : e);
    }
  };
  const onHandleSupporterChange = async (e) => {
    const emailValue = e?.target?.value ?? e;
    let val = { status: false, data: null };
    if (!!emailValue) {
      if (IsValidEmail(emailValue)) {
        let status = await fetchMethod(supporterDevice, {
          where: {
            role: "SUPPORTER",
            active: 1,
            and: [
              {
                or: [
                  {
                    email: {
                      like: `%${emailValue}%`,
                      options: "i",
                    },
                  },
                ],
              },
            ],
          },
        })
          .then((res) => res.json())
          .then(async (res) => {
            if (res?.data?.allUserdata?.Userdata?.length > 0) {
              setAllSupporterList(
                res.data.allUserdata.Userdata.map((item) => {
                  return {
                    id: item.id,
                    name: `${item.firstname ?? ""} ${item?.lastname ?? ""} ${
                      !!item?.email ? `- [${item?.email ?? ""}]` : ""
                    }`,
                  };
                })
              );
              return await fetchMethod(checkParticipantSupportExit, {
                where: {
                  supporterId: res?.data?.allUserdata?.Userdata?.[0]?.id ?? 0,
                  participantId: parseFloat(participantId.id),
                },
              })
                .then((resSupPar) => resSupPar.json())
                .then(async (resSupPar) => {
                  if (
                    resSupPar?.data?.allParticipantConnectedSupporters
                      ?.ParticipantConnectedSupporters?.length == 0
                  ) {
                    await setSupporterId(
                      res?.data?.allUserdata?.Userdata?.[0]?.id
                    );
                    return {
                      status: true,
                      data: res?.data?.allUserdata?.Userdata?.[0]?.id,
                    };
                  } else {
                    swal({
                      title: "Supporter Already Connected With Participant.",
                      icon: "error",
                    });
                    return { status: false, data: null };
                  }
                });
            } else {
              setAllSupporterList([]);
              swal({ title: " Supporter email not found.", icon: "error" });
              return { status: false, data: null };
            }
          });
        return { status: status?.status, data: status?.data };
      } else {
        swal({
          title: "Please enter valid supporter email id.",
          icon: "error",
        });
        return { status: false, data: null };
      }
    } else {
      return { status: false, data: null };
    }
  };
  const IsValidEmail = (value) => {
    //this below code is done becaoue the regex only take alphabet in small letter
    const newValue = !!value ? value?.toLowerCase() : "";
    const RegTest = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)$/
    ).test(newValue);
    return RegTest;
  };
  const handleChangeField = async (field, e) => {
    // if (field === "participantId") {
    // let Search = e.target.value ? e.target.value.trim().replace(/\s\s+/g, "") : "";
    // Search = Search.split(" ");
    // const like1 = "%" + `${Search[0]}` + "%";
    // const like2 = "%" + `${Search[1]}` + "%";

    // let filt = {
    //   or: [
    //     { firstname: { like: like1, options: "i" } },
    //     { lastname: { like: like2, options: "i" } },
    //     { lastname: { like: like1, options: "i" } },
    //   ],
    // };
    var obj = {
      role: "PARTICIPANT",
      active: 1,
      // and: [filt],
    };
    if (localStorage.getItem("role") === "GUARDIAN") {
      obj.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
    }
    // if (e.target.value.toString().length > 2) {
    await fetchMethod(participantQueryData, {
      where: obj,
    })
      .then((res) => res.json())
      .then((res) => {
        let data = res.data.allUserdata.Userdata.map((item) => {
          return {
            id: item.id,
            name:
              item.firstname +
              " " +
              (item.lastname != null ? item.lastname : ""),
            loginDevice: item.loginDevice,
          };
        });
        setAllParticipantList(data);
      })
      .catch((error) => {
        setAllParticipantList([]);
        swal({ title: error.message, icon: "warning" });
      });
    // }
    // }
    // else if (field === "businessId") {
    // let Search = e.target.value ? e.target.value.trim().replace(/\s\s+/g, "") : "";
    // Search = Search.split(" ");
    // const like1 = "%" + `${Search[0]}` + "%";
    // const like2 = "%" + `${Search[1]}` + "%";

    // let filt = {
    //   or: [
    //     { firstname: { like: like1, options: "i" } },
    //     { lastname: { like: like2, options: "i" } },
    //     { lastname: { like: like1, options: "i" } },
    //     { email: { like: `%${e.target.value}%` } },
    //     { phonenumber: { like: `%${e.target.value}%` } }
    //   ],
    // };
    var obj = {
      role: "BUSINESSMEMBER",
      active: 1,
      // and: [filt],
    };
    // if (e.target.value.toString().length > 2) {
    await fetchMethod(participantQueryData, {
      where: obj,
    })
      .then((res) => res.json())
      .then((res) => {
        let data = res.data.allUserdata.Userdata.map((item) => {
          return {
            id: item.id,
            name:
              item.firstname +
              " " +
              (item.lastname != null ? item.lastname : ""),
            loginDevice: item.loginDevice,
          };
        });
        setAllBusinessList(data);
      })
      .catch((error) => {
        setAllBusinessList([]);
        swal({ title: error.message, icon: "warning" });
      });
    // }
    // }
    // else {
    // let Search = e.target.value ? e.target.value.trim().replace(/\s\s+/g, "") : "";
    // Search = Search.split(" ");
    // const like1 = "%" + `${Search[0]}` + "%";
    // const like2 = "%" + `${Search[1]}` + "%";

    // let filt = {
    //   or: [
    //     { firstname: { like: like1, options: "i" } },
    //     { lastname: { like: like2, options: "i" } },
    //     { lastname: { like: like1, options: "i" } },
    //     { email: { like: `%${e.target.value}%` } },
    //     { phonenumber: { like: `%${e.target.value}%` } }
    //   ],
    // };
    var obj = {
      role: "SUPPORTER",
      active: 1,
      // and: [filt],
    };
    // if (e.target.value.toString().length > 2) {
    await fetchMethod(participantQueryData, {
      where: obj,
    })
      .then((res) => res.json())
      .then((res) => {
        let data = res.data.allUserdata.Userdata.map((item) => {
          return {
            id: item.id,
            name:
              item.firstname +
              " " +
              (item.lastname != null ? item.lastname : ""),
            loginDevice: item.loginDevice,
          };
        });
        setAllSupporterList(data);
      })
      .catch((error) => {
        setAllSupporterList([]);
        swal({ title: error.message, icon: "warning" });
      });
    // }
    // }
  };

  const connectSupportWorker = async (e) => {
    e.preventDefault();
    if (!!participantId) {
      if (!!supporterId) {
        setSupporterModal(!showSupporterModal);
        let variables = {
          obj: {
            participantId: participantId ? parseFloat(participantId.id) : null,
            supporterId: supporterId ? parseFloat(supporterId.id) : null,
            createdBy: parseFloat(
              JSON.parse(localStorage.getItem("userInfo")).id
            ),
            updatedBy: parseFloat(
              JSON.parse(localStorage.getItem("userInfo")).id
            ),
          },
        };
        await fetchMethod(saveParticipantSupporterQuery, variables)
          .then((res) => res.json())
          .then((res) => {
            const id = res?.data?.saveParticipantConnectedSupporter?.id || null;
            if (id) {
              setParticipantId(undefined);
              setSupporterId(undefined);
              swal({ title: "Supporter connected.", icon: "success" });
            }
            handleChangeField();
          })
          .catch((error) => {
            swal({ title: error.message, icon: "warning" });
          });
      } else {
        swal({
          title: "Please enter valid supporter email id",
          icon: "warning",
        });
      }
    } else {
      swal({ title: "Please Select Participant", icon: "warning" });
    }
  };

  const connectSupportWorkerEmailCheck = async (e) => {
    e.preventDefault();
    if (!!participantId) {
      if (!!supporterEmail) {
        const status = await onHandleSupporterChange(supporterEmail);
        if (status?.status) {
          setSupporterModal(!showSupporterModal);
          let variables = {
            obj: {
              participantId: participantId
                ? parseFloat(participantId.id)
                : null,
              supporterId: parseFloat(status?.data),
              createdBy: parseFloat(
                JSON.parse(localStorage.getItem("userInfo")).id
              ),
              updatedBy: parseFloat(
                JSON.parse(localStorage.getItem("userInfo")).id
              ),
            },
          };
          await fetchMethod(saveParticipantSupporterQuery, variables)
            .then((res) => res.json())
            .then((res) => {
              const id =
                res?.data?.saveParticipantConnectedSupporter?.id || null;
              if (id) {
                setParticipantId(undefined);
                setSupporterId(undefined);
                swal({ title: "Support connected.", icon: "success" });
                setSupporterEmail(null);
                // history.push('/')
              }
              handleChangeField();
            })
            .catch((error) => {
              swal({ title: error.message, icon: "warning" });
            });
        } else {
          console.log("return false");
          // swal({title:"Supporter email not found.", icon:"warning"})
        }
      } else {
        swal({ title: "Please enter supporter email id", icon: "warning" });
      }
    } else {
      swal({ title: "Please Select Participant", icon: "warning" });
    }
  };

  const addNewBusiness = async () => {
    let variables = {
      obj: {
        participantId: participantId ? parseFloat(participantId.id) : null,
        storeId: businessId ? parseFloat(businessId.id) : null,
        createdBy: parseFloat(JSON.parse(localStorage.getItem("userInfo")).id),
        updatedBy: parseFloat(JSON.parse(localStorage.getItem("userInfo")).id),
      },
    };
    await fetchMethod(saveParticipantBusinessQuery, variables)
      .then((res) => res.json())
      .then((res) => {
        const id = res?.data?.saveParticipantConnectedBusiness?.id || null;
        if (id) {
          setBusinessModal(!showBusinessModal);
          setParticipantId(undefined);
          setBusinessId(undefined);
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  useEffect(() => {
    if (location !== undefined) {
      setIsActive(location.pathname);
    }
  }, [location]);

  const onTabChage = (route) => {
    setIsActive(route);
    handleDrawerToggle();
    history.push(`${route}`);
  };

  const getActiveBreadcrumBar = () => {
    let path = location.pathname;
    if (path.includes("/addParticipantBudget")) path = "/addParticipantBudget";
    if (path.includes("/editParticipantBudget"))
      path = "/editParticipantBudget";
    if (path.includes(`/${WALLET_LIST_ROUTE}`)) path = `/${WALLET_LIST_ROUTE}`;
    switch (path) {
      case "/dashboard":
        return (
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        );
      case "/participants":
        return (
          <li>
            <Link to="/participants">Participants</Link>
          </li>
        );
      case "/addParticipantBudget":
        return (
          <li>
            <Link to="/participants">Participants</Link>
          </li>
        );
      case `/${WALLET_LIST_ROUTE}`:
        return (
          <li>
            <Link to={`/${WALLET_LIST_ROUTE}`}>Wallets</Link>
          </li>
        );
      case "/editParticipantBudget":
        return (
          <li>
            <Link to="/participants">Participants</Link>
          </li>
        );
      case "/newParticipantInfo":
        return (
          <li>
            <Link to="/newParticipantInfo">Participants</Link>
          </li>
        );
      case "/transactions":
        return (
          <li>
            <Link to="/transactions">Transactions</Link>
          </li>
        );
      case "/budget":
        return (
          <li>
            <Link to="/budget">Budgets</Link>
          </li>
        );
      case "/addBudget":
        return (
          <>
            <li>
              <Link to="/budget">Budgets</Link>
            </li>
            <li>
              <Link to="/addBudget">Add Budgets</Link>
            </li>
          </>
        );
      case "/automated-budgets":
        return (
          <li>
            <Link to="/automated-budgets">Automated Budgets</Link>
          </li>
        );
      // case "/item_category":
      //   return (
      //     <li>
      //       <Link to="/item_category">Item Category</Link>
      //     </li>
      //   );
      default:
        return location.pathname.includes("editBudget") ? (
          <>
            <li>
              <Link to="/budget">Budgets</Link>
            </li>
            <li>
              <Link to={`/editBudget/${location.pathname.split("/")[2]}`}>
                Edit Budgets
              </Link>
            </li>
          </>
        ) : location.pathname === "/newParticipantInfo" ? (
          <li>
            <Link to="/newParticipantInfo">Participants</Link>
          </li>
        ) : (
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddBudget = () => {
    handleClose();
    history.push("/addBudget");
  };

  const handleEditBudget = () => {
    handleClose();
    history.push("/budget");
  };

  const handleTransactionModal = () => {
    handleClose();
    history.push("/transactions");
  };
  // const handleItemCategoryModal = () => {
  //   handleClose();
  //   history.push('/item_category');
  // }

  const inputFileRef = useRef(null);

  const onFilechange = (event) => {
    /*Selected files data can be collected here.*/
    // file: event.target.files[0],
    //   filePreview: URL.createObjectURL(event.target.files[0])
    setFile(event.target.files[0]);
    setFilePreview(URL.createObjectURL(event.target.files[0]));
    console.log(event.target.files);
  };
  const reSetFile = (val = "empty") => {
    setFile(null);
    setFilePreview(val);
  };
  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };

  return (
    <>
      <BackdropLoader open={loader} />
      <header>
        <div className="flex">
          <div className="logo">
            <Link to="/">
              <img
                aria-label="Budget Hub Logo"
                src={Logo}
                alt="Budget Hub | Logo"
              />
            </Link>
          </div>
          <div className="user">
            <ul>
              {/* <li>
                <Link to="#help">
                  <img aria-label="Help icon" src={Help} alt="Help icon" />
                </Link>
              </li> */}
              {/* <li>
                <Link to="#notifications">
                  <img
                    aria-label="Notification icon"
                    src={Notification}
                    alt="Notification icon"
                  />
                </Link>
              </li> */}
              <li data-toggle="dropdown">
                <Link to="#" className="init">
                  {userInitials !== null && userInitials.userInitials !== null
                    ? userInitials.userInitials
                    : ""}
                </Link>
                <div className="dropDown">
                  <ul>
                    <li className="profile">
                      <span className="init">
                        {userInitials !== null &&
                        userInitials.userInitials !== null
                          ? userInitials.userInitials
                          : ""}
                      </span>
                      <span>
                        <strong>
                          {userInitials !== null ? userInitials.fullName : ""}
                        </strong>
                        <span>
                          {userInitials !== null ? userInitials.email : ""}
                        </span>
                      </span>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <Link to="#" onClick={handleProfileModal}>
                        <ProfileSidePopup
                          buttonName={"Edit Profile"}
                          onBtnClick={onBtnClick}
                          reSetFile={reSetFile}
                          filePreview={filePreview}
                          file={file}
                        ></ProfileSidePopup>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={handleChangePasswordModal}>
                        <ProfileSidePopup
                          buttonName={"Change Password"}
                        ></ProfileSidePopup>
                      </Link>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <Link to="#" onClick={LogOut}>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="breadcrumBar">
          <div className="flex">
            <div
              className={menuOpen ? "hamburger" : "hamburger active"}
              onClick={handleDrawerToggle}
            >
              <img
                src={Ham}
                alt="Hamburger icon"
                aria-label="Menu icon to toggle menu"
              />
            </div>
            <div className="breadcrumb">
              <ul>{getActiveBreadcrumBar()}</ul>
            </div>
            <ul className="quicks">
              <li>
                <button className="btn" onClick={handleShowModal}>
                  Deposit Money
                </button>
              </li>
              <li className="quickLinks" data-toggle="dropdown">
                <Link to="#" onClick={handleClick}>
                  Quick Links <img src={DropDown} alt="Dropdown icon" />
                </Link>
                <div className="dropDown">
                  <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setIsActive("/budget");
                        handleAddBudget();
                      }}
                    >
                      Add Budget
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setIsActive("/budget");
                        handleEditBudget();
                      }}
                    >
                      Edit Budget
                    </MenuItem>
                    <MenuItem onClick={() => handleDrawerShow()}>
                      Add Business
                    </MenuItem>
                    <MenuItem onClick={() => handleSupporterModal()}>
                      Add Supporter
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setIsActive("/transactions");
                        handleTransactionModal();
                      }}
                    >
                      View Transactions
                    </MenuItem>
                    {/* <MenuItem onClick={() => handleItemCategoryModal()}>Add Item Category</MenuItem> */}
                    <hr style={{ margin: "0px" }} />
                  </StyledMenu>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div
        className={menuOpen ? "sidebar" : "sidebar active"}
        role="presentation"
        aria-label="Sidebar menu"
      >
        <div className="sidebarClose" onClick={handleDrawerToggle}>
          <img
            src={Cross}
            alt="close sidebar"
            aria-label="sidebar close icon"
          />
        </div>
        <nav>
          <ul>
            <li>
              <Link
                to="#"
                className={isActive.includes("dashboard") ? "active" : ""}
                onClick={(e) => onTabChage("/dashboard")}
              >
                <span>
                  <img
                    src={
                      isActive.includes("dashboard")
                        ? DashboardActiveIcon
                        : DashboardIcon
                    }
                    alt="Dashboard icon"
                  />
                </span>
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className={
                  isActive.includes("participants") ||
                  isActive.includes("addParticipantBudget") ||
                  isActive.includes("editParticipantBudget") ||
                  isActive.includes("newParticipantInfo")
                    ? "active"
                    : ""
                }
                onClick={(e) => onTabChage("/participants")}
              >
                <span>
                  <img
                    src={
                      isActive.includes("participants") ||
                      isActive.includes("addParticipantBudget") ||
                      isActive.includes("editParticipantBudget") ||
                      isActive.includes("newParticipantInfo")
                        ? ParticipantsActiveIcon
                        : ParticipantsIcon
                    }
                    alt="Dashboard icon"
                  />
                </span>
                Participants
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className={isActive.includes(WALLET_LIST_ROUTE) ? "active" : ""}
                onClick={(e) => onTabChage(`/${WALLET_LIST_ROUTE}`)}
              >
                <span>
                  {/* <img
                    src={
                      isActive.includes(WALLET_LIST_ROUTE)
                        ? ParticipantsActiveIcon
                        : ParticipantsIcon
                    }
                    alt="Wallet icon"
                  /> */}
                  {/* <img> */}
                  <svg
                    width="30"
                    height="34"
                    viewBox="5 0 45 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path
                        d="M22.5 9.72727L5 2.45455V18.4545L22.5 25M22.5 9.72727L40 2.45455V18.4545L22.5 25M22.5 9.72727V25M7.5 1L22.5 7.54545L38.5 1"
                        stroke="black"
                        // shape-rendering="crispEdges"
                      />
                    </g>
                    <path
                      d="M12.6511 19.0909V8.18182H13.35V19.0909H12.6511ZM15.0034 11.4545C14.9522 11.0227 14.7449 10.6875 14.3812 10.4489C14.0176 10.2102 13.5716 10.0909 13.0431 10.0909C12.6568 10.0909 12.3187 10.1534 12.0289 10.2784C11.742 10.4034 11.5176 10.5753 11.3556 10.794C11.1966 11.0128 11.117 11.2614 11.117 11.5398C11.117 11.7727 11.1724 11.973 11.2832 12.1406C11.3968 12.3054 11.5417 12.4432 11.7179 12.554C11.894 12.6619 12.0787 12.7514 12.2718 12.8224C12.465 12.8906 12.6426 12.946 12.8045 12.9886L13.6909 13.2273C13.9181 13.2869 14.171 13.3693 14.4494 13.4744C14.7306 13.5795 14.9991 13.723 15.2548 13.9048C15.5133 14.0838 15.7264 14.3139 15.894 14.5952C16.0616 14.8764 16.1454 15.2216 16.1454 15.6307C16.1454 16.1023 16.0218 16.5284 15.7747 16.9091C15.5304 17.2898 15.1724 17.5923 14.7008 17.8168C14.2321 18.0412 13.6625 18.1534 12.992 18.1534C12.367 18.1534 11.8258 18.0526 11.3684 17.8509C10.9139 17.6491 10.5559 17.3679 10.2946 17.0071C10.036 16.6463 9.88974 16.2273 9.85565 15.75H10.9466C10.975 16.0795 11.0858 16.3523 11.2789 16.5682C11.475 16.7812 11.7221 16.9403 12.0204 17.0455C12.3216 17.1477 12.6454 17.1989 12.992 17.1989C13.3954 17.1989 13.7576 17.1335 14.0787 17.0028C14.3997 16.8693 14.6539 16.6847 14.8414 16.4489C15.0289 16.2102 15.1227 15.9318 15.1227 15.6136C15.1227 15.3239 15.0417 15.0881 14.8798 14.9062C14.7179 14.7244 14.5048 14.5767 14.2406 14.4631C13.9764 14.3494 13.6909 14.25 13.3841 14.1648L12.3102 13.858C11.6284 13.6619 11.0886 13.3821 10.6909 13.0185C10.2931 12.6548 10.0943 12.179 10.0943 11.5909C10.0943 11.1023 10.2264 10.6761 10.4906 10.3125C10.7576 9.94602 11.1156 9.66193 11.5645 9.46023C12.0162 9.25568 12.5204 9.15341 13.0772 9.15341C13.6397 9.15341 14.1397 9.25426 14.5772 9.45597C15.0147 9.65483 15.3613 9.92756 15.617 10.2741C15.8755 10.6207 16.0119 11.0142 16.0261 11.4545H15.0034Z"
                      fill="black"
                    />
                    <defs>
                      <filter
                        id="filter0_d_1248_2100"
                        x="0.5"
                        y="0.537231"
                        width="44"
                        height="32.9966"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1248_2100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1248_2100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                  {/* </img> */}
                </span>
                Wallets
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className={
                  isActive.includes("/budget") ||
                  isActive.includes("editBudget") ||
                  isActive.includes("addBudget")
                    ? "active"
                    : ""
                }
                onClick={(e) => onTabChage("/budget")}
              >
                <span>
                  <img
                    src={
                      isActive.includes("/budget") ||
                      isActive.includes("editBudget") ||
                      isActive.includes("addBudget")
                        ? BudgetsActiveIcon
                        : BudgetsIcon
                    }
                    alt="Dashboard icon"
                  />
                </span>
                Budgets
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className={isActive.includes("transactions") ? "active" : ""}
                onClick={(e) => onTabChage("/transactions")}
              >
                <span>
                  <img
                    src={
                      isActive.includes("transactions")
                        ? TransactionsActiveIcon
                        : TransactionsIcon
                    }
                    alt="Dashboard icon"
                  />
                </span>
                Transactions
              </Link>
            </li>
            {/* <li>
              <Link
                className={isActive.includes("itemcategory") ? "active" : ""}
                onClick={(e) => onTabChage("/item_category")}
              >
                <span>
                  <img
                    src={
                      isActive.includes("itemcategory")
                        ? ItemCategoryActiveIcon
                        : ItemCategoryActiveIcon
                    }
                    width= "23px" height="30px"
                    alt="Dashboard icon"
                  />
                </span>
                Item Category
              </Link>
            </li> */}
            <li>
              <Link
                to="#"
                className={
                  isActive.includes("automated-budgets") ? "active" : ""
                }
                onClick={(e) => onTabChage("/automated-budgets")}
              >
                <span>
                  <img
                    src={
                      isActive.includes("automated-budgets")
                        ? AutomatedActiveIcon
                        : AutomatedIcon
                    }
                    alt="Dashboard icon"
                  />
                </span>
                Automated Budgets
              </Link>
            </li>
          </ul>
        </nav>
        <div className="copy">Powered by Spendable</div>
      </div>
      <div
        className={!showModal ? "modal fade" : "modal fade show"}
        style={{ display: "block" }}
        id="deposit"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button onClick={handleShowModal}>
                <img src={CrossGrey} alt="close modal" />
              </button>
            </div>
            <div className="modal-body">
              <p>
                Load money to your Spendable wallet by making a bank transfer to
                the below account details:
              </p>
              <div className="details">
                <div className="fRow">
                  <div className="item">Account Name</div>
                  <div className="item">:</div>
                  <div className="item">
                    <strong>Gimme IT Pty Ltd</strong>
                  </div>
                </div>
                <div className="fRow">
                  <div className="item">BSB</div>
                  <div className="item">:</div>
                  <div className="item">
                    <strong>062 692</strong>
                  </div>
                </div>
                <div className="fRow">
                  <div className="item">Account #</div>
                  <div className="item">:</div>
                  <div className="item">
                    <strong>4531 7702</strong>
                  </div>
                </div>
                <div className="fRow">
                  <div className="item">Description</div>
                  <div className="item">:</div>
                  <div className="item">
                    <strong>[Wallet ID]</strong>
                  </div>
                </div>
              </div>
              <p>
                Ensure you include the ID number in the description to make sure
                we get it to the right participant.
              </p>
              <p className="note">
                *Deposits can take 1-2 business days to process
              </p>
            </div>
          </div>
        </div>
        <Dialog
          open={showSupporterModal}
          onClose={handleSupporterModal}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          className="supporterModal"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => handleSupporterModal()}
          >
            Add Supporter
          </DialogTitle>
          {/* <DialogContent> */}
          <div className="AddSupporterModal">
            <p>You can connect the existing supporter or can add a new</p>
            <p>supporter for any participant</p>
            <hr />
            <div className="participantList">
              <label>Choose a participant</label>
              <Autocomplete
                id="combo-box-demo"
                freeSolo={true}
                options={allParticipantList}
                getOptionLabel={(option) =>
                  option.name ? option.name : option
                }
                onChange={(e, data) => {
                  handleChange("participantId", {
                    target: { value: data ? data : "" },
                  });
                }}
                value={participantId || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type to search"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="supporterList">
              <label>Choose a supporter</label>
              {/*<Autocomplete*/}
              {/*  id="combo-box-demo"*/}
              {/*  freeSolo={true}*/}
              {/*  options={allSupporterList}*/}
              {/*  disabled={participantId != undefined ? false : true}*/}
              {/*  getOptionLabel={(option) =>*/}
              {/*    option.name ? option.name : option*/}
              {/*  }*/}
              {/*  onChange={(e, data) => {*/}
              {/*    handleChange("supporterId", {*/}
              {/*      target: { value: data ? data : "" },*/}
              {/*    });*/}
              {/*  }}*/}
              {/*  value={supporterId || ""}*/}
              {/*  renderInput={(params) => (*/}
              {/*    <TextField*/}
              {/*        onChange={(e)=>onHandleSupporterChange(e)}*/}
              {/*      {...params}*/}
              {/*      variant="outlined"*/}
              {/*      placeholder="Type to search"*/}
              {/*      fullWidth*/}
              {/*    />*/}
              {/*  )}*/}
              {/*/>*/}
              <TextField
                value={supporterEmail ?? ""}
                onChange={(e) => setSupporterEmail(e?.target?.value)}
                variant="outlined"
                placeholder="Type to search"
                fullWidth
              />
            </div>
          </div>
          {/* </DialogContent> */}
          <DialogButton>
            <Button
              onClick={(e) =>
                localStorage?.getItem("role") == "GUARDIAN"
                  ? connectSupportWorkerEmailCheck(e)
                  : connectSupportWorker(e)
              }
            >
              Connect Support Worker
            </Button>
          </DialogButton>
        </Dialog>

        <Dialog
          open={showBusinessModal}
          onClose={handleBusinessModal}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          className="businessModal"
        ></Dialog>
        <div
          className={menuOpen ? "sidebar" : "sidebar active"}
          role="presentation"
          aria-label="Sidebar menu"
        >
          <div className="sidebarClose" onClick={handleDrawerToggle}>
            <img
              src={Cross}
              alt="close sidebar"
              aria-label="sidebar close icon"
            />
          </div>
          <nav>
            <ul>
              <li onClick={() => handleDrawerToggleActive("/dashboard")}>
                <Link
                  to="/dashboard"
                  className={isActive === "/dashboard" ? "active" : ""}
                >
                  <span>
                    {isActive === "/dashboard" ? (
                      <img src={DashboardActiveIcon} alt="Dashboard icon" />
                    ) : (
                      <img src={DashboardIcon} alt="Dashboard icon" />
                    )}
                  </span>
                  Dashboard
                </Link>
              </li>
              <li onClick={() => handleDrawerToggleActive("/participants")}>
                <Link
                  to="/participants"
                  className={
                    isActive === "/participants" ||
                    isActive.includes("addParticipantBudget") ||
                    isActive.includes("editParticipantBudget") ||
                    isActive.includes("newParticipantInfo")
                      ? "active"
                      : ""
                  }
                >
                  <span>
                    {isActive === "/participants" ||
                    isActive.includes("addParticipantBudget") ||
                    isActive.includes("editParticipantBudget") ||
                    isActive.includes("newParticipantInfo") ? (
                      <img
                        src={ParticipantsActiveIcon}
                        alt="Participant icon"
                      />
                    ) : (
                      <img src={ParticipantsIcon} alt="Participant icon" />
                    )}
                  </span>
                  Participants
                </Link>
              </li>
              <li
                onClick={() =>
                  handleDrawerToggleActive(`/${WALLET_LIST_ROUTE}`)
                }
              >
                <Link
                  to={`/${WALLET_LIST_ROUTE}`}
                  className={
                    isActive === `/${WALLET_LIST_ROUTE}` ||
                    isActive.includes(WALLET_LIST_ROUTE)
                      ? "active"
                      : ""
                  }
                >
                  <span>
                    {isActive === `/${WALLET_LIST_ROUTE}` ||
                    isActive.includes(WALLET_LIST_ROUTE) ? (
                      <img src={BudgetsActiveIcon} alt="Budgets icon" />
                    ) : (
                      <img src={BudgetsIcon} alt="Budgets icon" />
                    )}
                  </span>
                  Wallets
                </Link>
              </li>
              <li onClick={() => handleDrawerToggleActive("/budgets")}>
                <Link
                  to="/budgets"
                  className={
                    isActive == "/budgets" ||
                    isActive.includes("editBudget") ||
                    isActive.includes("addBudget")
                      ? "active"
                      : ""
                  }
                >
                  <span>
                    {isActive == "/budgets" ||
                    isActive.includes("editBudget") ||
                    isActive.includes("addBudget") ? (
                      <img src={BudgetsActiveIcon} alt="Budgets icon" />
                    ) : (
                      <img src={BudgetsIcon} alt="Budgets icon" />
                    )}
                  </span>
                  Budgets
                </Link>
              </li>
              <li onClick={() => handleDrawerToggleActive("/transactions")}>
                <Link
                  to="/transactions"
                  className={isActive == "/transactions" ? "active" : ""}
                >
                  <span>
                    {isActive == "/transactions" ? (
                      <img
                        src={TransactionsActiveIcon}
                        alt="Transactions icon"
                      />
                    ) : (
                      <img src={TransactionsIcon} alt="Transactions icon" />
                    )}
                  </span>
                  Transactions
                </Link>
              </li>
              <li onClick={() => handleDrawerToggleActive("/itemcategory")}>
                <Link
                  to="/item_category"
                  className={isActive == "/itemcategory" ? "active" : ""}
                >
                  <span>
                    {isActive == "/itemcategory" ? (
                      <img
                        src={ItemCategoryActiveIcon}
                        width="23px"
                        height="30px"
                        alt="Transactions icon"
                      />
                    ) : (
                      <img
                        src={TransactionsIcon}
                        width="23px"
                        height="30px"
                        alt="Transactions icon"
                      />
                    )}
                  </span>
                  Item Category
                </Link>
              </li>
              <li
                onClick={() => handleDrawerToggleActive("/automated-budgets")}
              >
                <Link
                  to="/automated-budgets"
                  className={isActive === "/automated-budgets" ? "active" : ""}
                >
                  <span>
                    {isActive === "/automated-budgets" ? (
                      <img src={AutomatedActiveIcon} alt="Automated icon" />
                    ) : (
                      <img src={AutomatedIcon} alt="Automated icon" />
                    )}
                  </span>
                  Automated Budgets
                </Link>
              </li>
            </ul>
          </nav>
          <div className="copy">Powered by Spendable</div>
        </div>
        <GuardianAddbusinessModal
          drawerOpen={drawerOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerShow={handleDrawerShow}
        />
        <div>
          <input type="file" ref={inputFileRef} onChange={onFilechange} />
        </div>
      </div>
    </>
  );
}

export default BudgetSidebar;
