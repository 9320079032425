import { Button, FormControl, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { Component } from 'react'
import { DotLoader } from 'react-spinners';
import swal from 'sweetalert';
import { fetchMethod } from '../../FetchMethod';
import FormComponent from '../../Form/FormComponent';
import { participantQueryData } from '../SuperLedger/LedgerQuery';
import "./Rules.css"
import { addRuleConfig } from './RulesConfig';
import { getAllItemCategories, saveCron } from './RulesQuery';

export default class AddRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
          budgetxyz: null,
          categoryxyz: null,
          allParticipantList: undefined,
          allCategoryList: undefined,
          selectedParticipant: "",
          selectedCategory: "",
          timePeriod: ["Daily", "Weekly", "Monthly"]
        };
    }

    componentWillMount() {
        this.getAllParticipant();
        // this.getAllCategories();
    }

    getAllParticipant = () => {
        var obj = { role: "PARTICIPANT", active: 1 };
        if (localStorage.getItem("role") === "GUARDIAN") {
          obj['guardianId'] = JSON.parse(localStorage.getItem("userInfo")).id;
        }
        fetchMethod(participantQueryData, {
          where: obj
        })
          .then(resp => resp.json())
          .then(res => {
            console.log(res);
            this.setState({
              allParticipantList: res.data.allUserdata.Userdata.map(item => {
                return {
                  id: item.id,
                  name: item.firstname + " " + (item.lastname != null ? item.lastname : "")
                };
              })
            });
          })
          .catch(error => {
            swal({ title: error.message, icon: "warning" });
          });
    };
    
    // getAllCategories = () => {
    //   fetchMethod(getAllItemCategories, {where:{isActive:1}})
    //     .then(res => res.json())
    //     .then(res => {
    //       console.log(res);
    //       this.setState({
    //         allCategoryList: res.data.allMasterItemCategories.MasterItemCategories.map(item => {
    //           return {
    //             id: item.id,
    //             name: item.categoryName.charAt(0).toUpperCase() + item.categoryName.slice(1)
    //           }
    //         })
    //       })
    //     })
    //     .catch(err => {
    //       swal({ title: err.message, icon: "warning" });
    //     })
    // }

    preSubmitChanges = (e) => {
      fetchMethod(saveCron, {
        participantid: e.participantId.id,
        categoryid: e.itemCategoryId.id,
        event: e.timePeriod.toLowerCase(),
        amount: parseInt(e.budgetAvailable),
        createdBy: JSON.parse(localStorage.getItem('userInfo')).id 
      })
      .then(res => res.json())
      .then(res => {
        if(res.data.CronjobSaveRule.status === 200){
          swal({
            text: res.data.CronjobSaveRule.msg,
            icon: "success"
          });
          this.props.history.push("/rules");
        } else {
          swal({
            text: res.data.CronjobSaveRule.msg,
            icon: "warning"
          })
        }
      })
      .catch(err => {
        swal({text:err.message, icon: "warning"})
      })
    }

    closeForm = () => {
      this.props.history.push("/rules");
    };

    render() {
        return (
        <div className="addbudgetSection">
            <h2>Add Rule</h2>
            {this.state.allParticipantList !== undefined &&
             this.state.allParticipantList && 
             this.state.allParticipantList !== "" ? (
                <FormComponent 
                  formConfig = {addRuleConfig}
                  preSubmitChanges = {this.preSubmitChanges}
                  buttonTitleCSS = "adminParticipant"
                  modalCloseCallback = {() => {}}
                  closeButton={this.closeForm}
                  params={{
                    participantOptions: this.state.allParticipantList,
                    timePeriodOptions: this.state.timePeriod
                  }}
                />
             ) : (
              <div className="spinner">
                <DotLoader size={70} color={"#020f1f"} />
              </div>
             )}
        </div>
        )
    }
}
