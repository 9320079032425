import React, { useState, useEffect, useCallback } from "react";
import "./novattisftp.css";
import ReactTableComponent from "src/ReactTable/ReactTable";
import { AllNovattiData } from "./NovattiQuery";
import { fetchMethod } from "src/FetchMethod";
import swal from "sweetalert";
import axios from "axios";
import { API_URL } from "src/Config";
import { useHistory } from "react-router-dom";
import usePagination from "src/hooks/usePagination";
import { Button } from "@material-ui/core";

const downloadUrl = API_URL + "/download";

const NovattiSftp = () => {
  const [datas, setDatas] = useState([]);
  const [datalength, setDatalength] = useState([]);
  const [startLoader, setLoader] = useState(false);
  const history = useHistory();

  const { page, pageSize } = usePagination();

  const updatePagination = (totalEntries, size) => {
    const pageNo = totalEntries / size - 1;
    history.push(`/novattisftp?page=${pageNo}&pageSize=${size}`);
  };

  const actionButtons = [
    {
      Header: "Downloads",
      Cell: (row) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleChange(row.original)}
        >
          Download File
        </Button>
      ),
      sortable: false
    }
  ];

  const handleChange = async (data) => {
    let fileUrl = await downloadFile(data.filename);
    await fetch(fileUrl?.data?.data)
      .then(async (image) => {
        setLoader(false);
        if (image.status === 200) {
          const imageBlog = await image?.blob();
          const imageURL = URL?.createObjectURL(imageBlog);
          const link = document?.createElement("a");
          link.href = imageURL;
          let filename = image.url?.split("/");
          filename = filename[4];
          filename = filename.split("?")[0];
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          swal({ title: "Unable to download", icon: "error" });
        }
      })
      .catch((e) => {
        setLoader(false);
        swal({ title: "Unable to download", icon: "error" });
      });
  };

  const downloadFile = async (file) => {
    const url = downloadUrl + "?key=novattitransfiles/" + file;
    const options = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    let data = await axios.get(url, options);
    return data;
  };

  const fetchNovattiSftps = useCallback(() => {
    setLoader(true);
    fetchMethod(AllNovattiData, {
      where: {
        active: 1,
        order: "createdon desc"
      },
      first: (page + 1) * pageSize,
      last: pageSize
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          if (
            data &&
            data.data &&
            data.data.allNovattiSftps &&
            data.data.allNovattiSftps.NovattiSftps
          ) {
            setDatalength(data.data.allNovattiSftps.totalCount);
          }

          if (
            data &&
            data.data &&
            data.data.allNovattiSftps &&
            data.data.allNovattiSftps.NovattiSftps
          ) {
            let getdata = data.data.allNovattiSftps.NovattiSftps;
            if (getdata.length > 0) {
              setDatas(getdata.slice(0, pageSize));
              setLoader(false);
            } else {
              setDatalength([]);
              setDatas([]);
              setLoader(false);
            }
          }
        }
      })
      .finally(() => {
        setLoader(false);
      });
  }, [page, pageSize]);

  useEffect(() => {
    fetchNovattiSftps();
  }, [page, pageSize, fetchNovattiSftps]);

  return (
    <div className="wrapper">
      <h2>Novatti SFTP files</h2>
      <div className="tableWrapper">
        <ReactTableComponent
          listData={datas}
          listConfig={{
            showPagination: true,
            showSerialNo: true
          }}
          columns={[...columns, ...actionButtons]}
          dataCount={datalength}
          updatePagination={updatePagination}
          initialPage={page + 1}
          forSerialNo={{
            pageNo: (page + 1) * pageSize,
            pageSize: pageSize
          }}
          loading={startLoader}
        />
      </div>
    </div>
  );
};

export default NovattiSftp;

const columns = [
  {
    Header: "Sr No.",
    sortable: false,
    Cell: (row) => {
      return <div className="dot">{row.original.sNo}</div>;
    },
    width: 70
  },
  {
    Header: "File Name",
    accessor: "filename",
    width: 500,
    sortable: false
  },
  {
    Header: "Created On",
    Cell: (row) => {
      return <div>{new Date(row.original.createdon).toDateString()}</div>;
    },
    sortable: false
  }
];
