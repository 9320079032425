import gql from "graphql-tag";

export const budgetQuery = `
query($where:JSON,$last: Int, $first: Int){
  allBudgets(where:$where,last:$last,first:$first){
    totalCount
    Budgets{
      id
      budgetAvailable
      createdAt
      updatedAt
      isshared
      canwithdraw
      alwaysavailablebudget
      active
      walletId
      fkTieridrel{
        Tiertypes{
          id
          tierType
        }
      }
      budget_supporter_mapping_budgetMap(where:{active:1}){
        BudgetSupporterMappings{
          id
          budgetSupporterMappingSupportermaprel(where:{active:1}){
            Userdata{
              id
              firstname
              lastname
              active
            }
          }
        }
      }
      budget_store_mapping_budgetMap(where:{active:1}){
        BudgetStoreMappings{
          id
         budgetStoreMappingStoremaprel{
          Businesses{
            id
            storeName
          }
        }
        }
      }
      fkBudgetParticipantIdrel{
        Userdata{
          id
          firstname
          lastname
          role
          fkUserIdCardDetailMap{
            CardDetails{
              cardLimit
              cardNumber
            }
          }
        }
      }
      fkBudgetWalletIdrel{
        Wallets{
          description
        }
      }
      fkSupporterIdBudgetMaprel{
        Userdata{
          id
          firstname
          lastname
          role
        }
      }
      fkBusinessIdBudgetMaprel{
        Businesses{
          storeName   
          id
        }
      }
      fkBudgetItemCategoryIdrel{
        MasterItemCategories{
          categoryName
          id
          isActive
          fktiertypemaprel{
            Tiertypes{
              id
              tierType
            }
          }
        }
      }
      fkBudgetWalletIdrel{
        Wallets{
          id
          description
          balance
        }
      }
    }
  }
}`;

export const saveBudget = gql`
  mutation saveBudget($obj: BudgetInput!) {
    saveBudget(obj: $obj) {
      id
      canwithdraw
      participantId
      walletId
    }
  }
`;

export const participantQuery = `
query{
  allUserdata(where:{active:1}){
    Userdata{
      id
      firstname
      lastname
    }
  }
}
`;

export const getAllItemCategory = `
query{
  allMasterItemCategories{
    MasterItemCategories{
      categoryName
      id
    }
  }
}
`;

export const getItemCategory = `
query allMasterItemCategories($where: JSON){
  allMasterItemCategories(where: $where){
    MasterItemCategories{
      categoryName
      id
    }
  }
}
`;

export const editBudgetQuery = (id) => `
query{
    allBudgets (where:{id:${id}}){
      Budgets{
        id
          itemCategoryId
          budgetAvailable
        participantId

        fkBudgetWalletIdrel{
          Wallets{
            id
            balance
          }
        }
      }
    }
  }

`;

export const participantQueryData = `
query($where:JSON){
  allUserdata(where:$where){
    Userdata{
      id
      firstname
      lastname
      loginDevice
    }
  }
}
`;

export const getSupporterConnectedParticipant = `
  query($where:JSON){
    allParticipantConnectedSupporters(where:$where){
      ParticipantConnectedSupporters{
        fkParticipantConnectedSupporterIdrel(where: { active:1}){
          Userdata{
            id
            firstname
            lastname
            phonenumber
            address
            city
            dob
            email
          }
        }
      }
    }
  }
`;

export const getWalletConnectedUser = `
  query($where:JSON){
    allWalletConnectedUsers(where:$where){
      WalletConnectedUsers{
        walletUserrel(where: { active:1}){
          Userdata{
            id
            firstname
            lastname
            phonenumber
            address
            city
            dob
            email
          }
        }
      }
    }
  }
`;

// export const connectedSupporter = `
// query($where:JSON){
//   allParticipantConnectedSupporters(where:$where){
//    ParticipantConnectedSupporters{
//      supporterId
//    }

//  }
//    }
// `;

export const categoryQueryDataForAdmin = `
query($where:JSON){
  allMasterItemCategories(where:$where){
    MasterItemCategories{
      id
      categoryName
      tierId
      isActive
    }
  }
}
`;

export const categoryQueryData = `
mutation MasterItemCategoryShowItemCategories($createdBy:Int, $isActive:Int, $tierId:Int){
  MasterItemCategoryShowItemCategories(createdBy:$createdBy, isActive:$isActive, tierId:$tierId)
}
`;

export const businessQueryData = `
query($where:JSON){
  allBusinesses(where:$where){
    Businesses{
      id   
      storeManagerName
      storeName
    }
  }
}`;

export const budgetTotalData = `
query($where:JSON){
  allBudgets(where:$where){
    totalCount
    Budgets{
      id
      budgetAvailable
      createdAt
      active
      fkBudgetParticipantIdrel{
       Userdata{
        firstname
        lastname
      }
      }
      fkBudgetItemCategoryIdrel{
        MasterItemCategories{
          categoryName
          id
        }
      }
    }
  }
}
`;

export const getAllTier = `
{
  allTiertypes(where:{active:1}){
    Tiertypes{
      id
      tierType
    }
  }
}`;

export const getTier = `
query allTiertypes($where: JSON){
  allTiertypes(where:$where){
    Tiertypes{
      id
      tierType
    }
  }
}
`;

export const GetUserdataNotification = (token, title, body, data) =>
  `
mutation{
  UserdataNotification(deviceToken:"${token}",title:"${title}",body:"${body}",data:{type:"${data}"})
}
`;

export const getCardLimitById = `
query allCardDetails($where: JSON){
  allCardDetails(where:$where){
    CardDetails{
      id
      userId
      cardLimit
      cardNumber
    }
  }
}
`;

export const connectItemWithstoreQueryData = `
mutation StoreConnectedStoreTypeAddItemCategory($storeId:JSON, $item_category_id:Int, $participantid:Int, $createdBy:Int, $updatedBy:Int) {
  StoreConnectedStoreTypeAddItemCategory(storeId:$storeId,item_category_id:$item_category_id,participantid:$participantid,createdBy:$createdBy,updatedBy:$updatedBy) 
}
`;

export const editBudgetDetailsQuery = `
mutation BudgetEditBudgetData($budgetId:Int, $isshared:Int,$alwaysavailablebudget:Int,$itemCategoryId:Int,$participantId:Int,$walletId:Int,$tierid:Int,$active:Int,$updatedBy:Int,$budgetAvailable:String,$addstoreToBudget:JSON,$removestoreFromBudget:JSON,$addsupporterToBudget:JSON,$removesupporterFromBudget:JSON, $canwithdraw:Int){
  BudgetEditBudgetData(budgetId:$budgetId, isshared:$isshared,alwaysavailablebudget:$alwaysavailablebudget,itemCategoryId:$itemCategoryId,participantId:$participantId,walletId:$walletId,tierid:$tierid,active:$active,updatedBy:$updatedBy,budgetAvailable:$budgetAvailable,addstoreToBudget:$addstoreToBudget,removestoreFromBudget:$removestoreFromBudget,addsupporterToBudget:$addsupporterToBudget,removesupporterFromBudget:$removesupporterFromBudget,canwithdraw:$canwithdraw)
}
`;

export const getAuthledgerData = (id) => `
query{
  allAuthledgers(where:{userid:${id},canwithdraw: 1,active: 1}){      
        Authledgers{
          id
          userid
          canwithdraw
          withdrawfrom
        }    
  }
}
`;

export const participantBudget = `
query($where:JSON){
  allBudgets(where:$where){
    Budgets{
      id
      active
      canwithdraw     
    }
  }
}
`;

export const saveAuthledger = `mutation saveAuthledger ($obj:  AuthledgerInput!){
  saveAuthledger (obj: $obj){
    id
  }
}`;

export const getParticipantWallets = `
  query allWallets($where: JSON){
    allWallets(where:$where){
      Wallets{
        id
        description
        balance
      }
    }
  }
`;
