import React, { Component } from 'react'
import { DotLoader } from 'react-spinners';
import swal from 'sweetalert';
import { fetchMethod } from '../../FetchMethod';
import FormComponent from '../../Form/FormComponent';
import { editAutomatedFundsConfig } from './automateFundsConfig';
import { editCardLimitQuery, saveCardLimitdata } from './AutomateFundsQuery';

export class EditAutomatedFunds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: undefined,
            search: "",
            pageNo: parseInt(localStorage.getItem("rows")),
            rows: parseInt(localStorage.getItem("rows")),
            filter: {
                active: 1,
                role: "PARTICIPANT",
                order: "id desc"
            },
            count: 0,
            participantOptions: undefined,
            itemDataOptions: undefined,
            allParticipantList: undefined,
            allCategoryList: undefined,
            timePeriod: ["Daily", "Weekly", "Monthly"],
            id:
                this.props.location.state && this.props.location.state.details
                    ? this.props.location.state.details
                    : "",
            editableData: undefined
        };
    }

    async componentWillMount() {
        await this.getCroneRuleById(this.state.id);
    }

    getCroneRuleById = (id) => {
        if (!this.state.id) {
            this.props.history.push('/rules');
            return;
        }
        fetchMethod(editCardLimitQuery(id))
            .then(res => res.json())
            .then(res => {
                if (res.data.allCardcronjobs.Cardcronjobs.length) {
                    let data = res.data.allCardcronjobs.Cardcronjobs[0];
                    let obj = {
                        id: data.id,
                        budgetAvailable: data.amount,
                        participantId: data.cardcronjobusermaprel.Userdata[0].firstname + data.cardcronjobusermaprel.Userdata[0].lastname,
                        timePeriod: data.event
                    }
                    this.setState({ editableData: obj, cardLimit: data?.cardcronjobusermaprel?.Userdata?.[0]?.fkUserIdCardDetailMap?.CardDetails?.[0]?.cardLimit })
                }
            })
    }
    closeForm = () => {
        this.props.history.push("/automateFunds");
        // swal({
        //     text: res.data.CardcronjobSaveRule.msg,
        //     icon: "success"
        // });
        // this.props.history.push("/automateFunds");
    };

    preSubmitChanges = (e) => {
        console.log("this.state.cardLimit",this.state.cardLimit)
        if (parseFloat(this.state.cardLimit) >= parseFloat(e.budgetAvailable)) {
            let variable = {
                obj: {
                    id: e.id,
                    amount: parseFloat(e.budgetAvailable),
                    event: e.timePeriod.toLowerCase(),
                    updatedby: JSON.parse(localStorage.getItem('userInfo')).id
                }
            }
            fetchMethod(saveCardLimitdata, variable).then(res => res.json())
                .then(res => {
                    if (res.data.saveCardcronjob.id) {
                        swal({ title: "Automate funds updated successfully.", icon: "success" })
                        this.props.history.push("/automateFunds");
                    }
                })
                .catch(err => {
                    swal({ title: err.message, icon: "warning" })
                })
        } else {
            swal("Fund allocated should be less than card limit", { icon: "error" });
        }
    }

    render() {
        return (
            <div className="addbudgetSection">
                <h2>Edit Automate Funds</h2>
                {this.props.location.state !== undefined && this.state.editableData ? (
                    <FormComponent
                        formConfig={editAutomatedFundsConfig}
                        editableData={this.state.editableData}
                        preSubmitChanges={this.preSubmitChanges}
                        buttonTitleCSS="adminParticipant"
                        modalCloseCallback={() => { }}
                        closeButton={this.closeForm}
                        params={{
                            timePeriodOptions: this.state.timePeriod
                        }}
                    />
                ) : (
                    <div className="spinner">
                        <DotLoader size={70} color={"#020f1f"} />
                    </div>
                )}
            </div>
        )
    }
}

export default EditAutomatedFunds
