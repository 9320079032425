import React from "react";
import S3Image from "../S3Image";
import { WALLET_LIST_ROUTE } from "src/utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "src/Components/Participant/UserCard/user_card.scss";

function WalletCard({ pic, description, firstName, balance, id }) {
  const history = useHistory();
  return (
    <div className="user_card">
      <button onClick={() => history.push(`/${WALLET_LIST_ROUTE}/${id}`)}>
        <div className="userDetails">
          {pic ? (
            <div className="userPic">
              <S3Image src={pic} alt={description} />
            </div>
          ) : (
            <div className="initial">{firstName?.charAt(0)}</div>
          )}
          {description ? <div className="userName">{"Id: " + id}</div> : ""}
          <div className="userName">{firstName}</div>
          <div className="balance">
            Bal: ${balance && balance !== "" ? balance : 0}
          </div>
        </div>
      </button>
    </div>
  );
}

export default WalletCard;
