export const adminPaymentRequestList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    // {
    //   Header: "Participant",
    //   accessor: "participant",
    //   sortable: false
    // },
    // {
    //   Header: "Supporter",
    //   accessor: "supporter",
    //   sortable: false
    // },
    // {
    //   Header: "Buisness Member",
    //   accessor: "buisnessMember",
    //   sortable: false
    // },

    {
      Header: "Payment Date",
      accessor: "paymentDate",
      sortable: false,
    },
    {
      Header: "Payment Type",
      accessor: "paymentType",
      sortable: false,
    },
    {
      Header: "Merchant Name",
      accessor: "merchantName",
      sortable: false,
    },
    {
      Header: "Purchase Amount",
      accessor: "Amount",
      sortable: false,
    },
    // {
    //   Header: "Amount AUD",
    //   accessor: "amountAud",
    //   sortable: false
    // },
    {
      Header: "Reference",
      accessor: "Reference",
      sortable: false,
    },
  ],
};

export const adminParticipantList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "First Name",
      accessor: "firstname",
      sortable: false,
    },
    {
      Header: "Last Name",
      accessor: "lastname",
      sortable: false,
    },
    {
      Header: "Email",
      accessor: "email",
      sortable: false,
    },
    {
      Header: "Mobile No.",
      accessor: "phonenumber",
      sortable: false,
    },
    {
      Header: "Date Of Birth",
      accessor: "dob",
      sortable: false,
    },
    {
      Header: "Address",
      accessor: "fullAddress",
      sortable: false,
    },
    // {
    //   Header: "NDIS Number",
    //   accessor: "ndisNumber",
    //   sortable: false
    // }
  ],
};

export const adminWalletListForSupporter = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Wallet ID",
      accessor: "id",
      sortable: false,
    },
    {
      Header: "Description",
      accessor: "description",
      sortable: false,
    },
    {
      Header: "First Name",
      accessor: "firstname",
      sortable: false,
    },
    {
      Header: "Last Name",
      accessor: "lastname",
      sortable: false,
    },
    {
      Header: "Email ID",
      accessor: "email",
      sortable: false,
    },
    {
      Header: "Mobile No.",
      accessor: "phonenumber",
      sortable: false,
    },
    {
      Header: "NDIS Number",
      accessor: "ndisNumber",
      sortable: false,
    },
  ],
};
