import React, { Component } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  Input,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Wallet from "./ParticipantTabSetup/Wallet";
import ConnectedBuisness from "./ParticipantTabSetup/ConnectedBuisness";
import ConnectedSupporter from "./ParticipantTabSetup/ConnectedSupporter";
import PaymentRequest from "./ParticipantTabSetup/PaymentRequest";
import "./participant.css";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import swal from "sweetalert";
import { fetchMethod } from "../../FetchMethod";
import moment from "moment";
import {
  connectedSupporterQuery,
  allSupporterConnectedwithParticipant,
  OnlyParticipantConnectedBusinesses,
  connectedBuisnessQuery,
  supporterdata,
  GetUserdataNotification
} from "../Participant/ParticipantTabSetup/ParticipantTabQuery";
import {
  supporterParticipantQuery,
  saveParticipantSupporterQuery,
  dropdownQuery,
  supporterDevice,
  checkParticipantSupportExit
} from "../Supporter/SupporterQuery";
import {
  businessQuery,
  saveBusiness,
  businessParticipantQuery,
  saveParticipantBusinessQuery,
  connectedBusiness
} from "../Buisness/BuisnessQuery";
import { isNullableType } from "graphql";
import { SignalCellularNullRounded } from "@material-ui/icons";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Drawer from "@mui/material/Drawer";
import GuardianAddbusinessModal from "../Buisness/guardianAddbusinessModal";
//import ParticipantBudget from "./ParticipantTabSetup/ParticipantBudget";
import GuardianBudget from "../Budget/GuardianBudget";
import ParticipantBudget from "./ParticipantTabSetup/ParticipantBudget";
import SettingsTab from "./SettingsTab";
import { GUARDIAN } from "src/utils/constants";
import { linkSupporterToAllWallets } from "src/network/service/walletService";
import DepositTab from "./ParticipantTabSetup/DepositTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
class ParticipantTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      tabValue: 0,
      filter: {
        active: 1,
        role: "SUPPORTER",
        order: "id desc"
      },
      // listData: undefined,
      count: 0,
      openModal: false,
      openModalBox: false,
      supporterOptions: [],
      supporterId: undefined,
      participantId: this.props.id,
      storeId: undefined,
      filterData: {
        participantId: this.props.id,
        active: 1,
        order: "id desc"
      },
      refetchConnect: false,
      refetchBusinessConnect: false,
      ConnectedSupporteraAllData: [],
      totalData: undefined,
      totalBusinessData: undefined,
      isVerified: false,
      drawerOpen: false,
      supporterEmail: null
    };
  }
  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };
  componentWillMount() {
    this.getAllDropDownData();
    if (
      this.props.history.location.state &&
      this.props.history.location.state.from === "addParticipantBudget"
    ) {
      this.setState({ value: 3 });
    }
  }

  getAllDropDownData = () => {
    // fetchMethod(dropdownQuery, {
    //   where: { role: "PARTICIPANT", active: 1 }
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log("mmmmmmmmm", res);
    //     if (res.data.allUserdata != undefined && res.data.id1 != undefined) {
    //       this.setState({
    //         participantOptions: res.data.id1.Userdata.map((item) => {
    //           return {
    //             id: item.id,
    //             name:
    //               item.firstname + " " + item.lastname != null
    //                 ? item.lastname
    //                 : ""
    //           };
    //         }),
    //         ...(localStorage?.getItem("role") == "GUARDIAN"
    //           ? {
    //               supporterOptions: []
    //             }
    //           : {
    //               supporterOptions: res.data.allUserdata.Userdata.map(
    //                 (item) => {
    //                   return {
    //                     id: item.id,
    //                     supporter:
    //                       item.firstname +
    //                       " " +
    //                       (item.lastname != null ? item.lastname : "")
    //                   };
    //                 }
    //               )
    //             })
    //       });
    //       this.getParticipantSupporterData();
    //     } else this.setState({ loading: true });
    //   })
    //   .catch((e) => console.log(e));
    fetchMethod(businessQuery, {
      where: { order: "id desc", active: 1 }
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.data.allBusinesses !== undefined) {
          this.setState({
            BusinessesOptions: res.data.allBusinesses.Businesses.map((item) => {
              return {
                id: item.id,
                business: item.storeName
              };
            })
          });
          this.getBuisnessParticipantData();
        } else this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  getParticipantSupporterData = () => {
    // if (localStorage.getItem("role") === "ADMIN") {
    fetchMethod(connectedSupporterQuery, {
      where: this.state.filterData,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          this.setState({
            totalData: res.data.id1.ParticipantConnectedSupporters
          });
          res.data.allParticipantConnectedSupporters.ParticipantConnectedSupporters.map(
            (item) => {
              return (
                (item.firstname =
                  item.fkParticipantConnectedSupporterIdrel &&
                  item.fkParticipantConnectedSupporterIdrel.Userdata.length > 0
                    ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .firstname
                    : ""),
                (item.lastname =
                  item.fkParticipantConnectedSupporterIdrel &&
                  item.fkParticipantConnectedSupporterIdrel.Userdata.length > 0
                    ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .lastname
                    : ""),
                (item.email =
                  item.fkParticipantConnectedSupporterIdrel &&
                  item.fkParticipantConnectedSupporterIdrel.Userdata.length > 0
                    ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .email
                    : ""),
                (item.flagged = item.isSupporterFlagged === 0 ? "NO" : "YES"),
                //SUPPORTER ACTIVATED OR DEACTIVATED
                (item.status =
                  item.fkParticipantConnectedSupporterIdrel &&
                  item.fkParticipantConnectedSupporterIdrel.Userdata.length > 0
                    ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .active === 0
                      ? "DEACTIVATED"
                      : "ACTIVATED"
                    : ""),
                (item.address =
                  item.fkParticipantConnectedSupporterIdrel &&
                  item.fkParticipantConnectedSupporterIdrel.Userdata.length > 0
                    ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .address !== null &&
                      item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .address !== ""
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0].address.concat(
                          item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                            .city &&
                            item.fkParticipantConnectedSupporterIdrel
                              .Userdata[0].city !== null
                            ? " " +
                                item.fkParticipantConnectedSupporterIdrel
                                  .Userdata[0].city
                            : ""
                        )
                      : ""
                    : ""),
                (item.phonenumber =
                  item.fkParticipantConnectedSupporterIdrel &&
                  item.fkParticipantConnectedSupporterIdrel.Userdata.length > 0
                    ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .phonenumber !== null
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .phonenumber
                      : ""
                    : ""),
                (item.dob =
                  item.fkParticipantConnectedSupporterIdrel &&
                  item.fkParticipantConnectedSupporterIdrel.Userdata.length > 0
                    ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                        .dob !== null
                      ? this.formatDate(
                          item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                            .dob
                        )
                      : ""
                    : "")
              );
            }
          );
          this.setState(
            {
              count:
                res.data &&
                res.data.allParticipantConnectedSupporters &&
                res.data.allParticipantConnectedSupporters !== null
                  ? res.data.allParticipantConnectedSupporters.totalCount
                  : "",
              listData:
                res.data &&
                res.data.allParticipantConnectedSupporters !== undefined &&
                res.data.allParticipantConnectedSupporters !== null
                  ? res.data.allParticipantConnectedSupporters
                      .ParticipantConnectedSupporters
                  : ""
            },
            () => {
              this.disablerefetchConnect();
              this.modalDropdownData(this.state.totalData);

              // this.props.disablerefetchConnect();
              // this.props.listModalData(this.state.totalData);
            }
          );
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });
    // }

    if (localStorage.getItem("role") === "GUARDIAN") {
      fetchMethod(allSupporterConnectedwithParticipant, {
        where: this.state.filter
        // last: this.state.rows,
        // first: this.state.pageNo
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res);
          if (
            res.data &&
            res.data.allParticipantConnectedSupporters &&
            res.data.allParticipantConnectedSupporters
              .ParticipantConnectedSupporters &&
            res.data.allParticipantConnectedSupporters
              .ParticipantConnectedSupporters != ""
          ) {
            let DATA =
              res.data.allParticipantConnectedSupporters
                .ParticipantConnectedSupporters;
            let myData = [];
            DATA.map((item, index) => {
              let UData =
                item.fkParticipantConnectedSupporterIdrel &&
                item.fkParticipantConnectedSupporterIdrel.Userdata != ""
                  ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                  : null;
              if (UData) {
                let mainData = {
                  ...item,
                  ...UData,
                  fkParticipantConnectedSupporterIdrel: null
                };
                myData.push(mainData);
              } else {
                // console.log(
                //   "getAllConnectedParticipant user data not availalable",
                //   res
                // );
              }
            });
            // console.log("getAllConnectedSupportertest Final data", myData);

            myData.map((item, index) => {
              // console.log(
              //   "response data",
              //   item.address,
              //   ":::::::::::::",
              //   item.city
              // );

              let dob = "";
              if (item.dob) {
                dob = item.dob;
              }
              let dobN = "";
              if (dob && !dob.includes("/")) {
                dobN = moment(dob).format("DD/MM/YYYY");
              } else {
                dobN = dob;
              }
              return (
                // (item.S No=
                (item.firstname = item.firstname),
                (item.lastname = item.lastname),
                (item.email = item.email),
                (item.flagged = item.isSupporterFlagged === 0 ? "NO" : "YES"),
                //SUPPORTER ACTIVATED OR DEACTIVATED
                (item.status = item.active === 0 ? "DEACTIVATED" : "ACTIVATED"),
                // (item.address =
                //   item.address != null
                //     ? item.address
                //     : "" + " " + (item.city != null ? item.city : "")),

                (item.address =
                  item.address != null
                    ? item.address + " " + (item.city != null ? item.city : "")
                    : ""),
                (item.phonenumber = item.phonenumber),
                (item.dob = dobN)
              );
            });

            this.setState({
              // count:
              //   res.data &&
              //   res.data.allParticipantConnectedSupporters &&
              //   res.data.allParticipantConnectedSupporters !== null
              //     ? res.data.allParticipantConnectedSupporters.totalCount
              //     : "",
              count: myData ? myData.length : 0,
              listData: myData ? myData : ""
            });
            // this.setState(
            // {
            // count:
            //   res.data &&
            //   res.data.allParticipantConnectedSupporters &&
            //   res.data.allParticipantConnectedSupporters !== null
            //     ? res.data.allParticipantConnectedSupporters.totalCount
            //     : "",
            // listData: myData
            // res.data &&
            // res.data.allParticipantConnectedSupporters !== undefined &&
            // res.data.allParticipantConnectedSupporters !== null
            //   ? res.data.allParticipantConnectedSupporters
            //       .ParticipantConnectedSupporters
            //   : ""
            // },
            // () => {
            //   this.props.disablerefetchConnect();
            //   this.props.listModalData(this.state.totalData);
            // }
            // );
            // setpeople(myData);
          } else {
            this.setState({ listData: [] });
            // setNoData(true);
          }
        })
        .catch((e) => {
          this.setState({ listData: [] });
          // console.log("error supporter", e);
        });
    }
  };
  getBuisnessParticipantData = () => {
    // if (localStorage.getItem("role") === "ADMIN") {
    fetchMethod(connectedBuisnessQuery, {
      where: this.state.filterData,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          // this.BusinessmodalDropdownData()
          // console.log('res.data.id1.ParticipantConnectedBusinesses', res.data.id1.ParticipantConnectedBusinesses)
          this.setState({
            totalBusinessData: res.data.id1.ParticipantConnectedBusinesses
          });
          res.data.allParticipantConnectedBusinesses.ParticipantConnectedBusinesses.map(
            (item) => {
              return (
                (item.storeName =
                  item.fkParticipantConnectedBusinessStoreIdrel &&
                  item.fkParticipantConnectedBusinessStoreIdrel.Businesses
                    .length > 0
                    ? item.fkParticipantConnectedBusinessStoreIdrel
                        .Businesses[0].storeName
                    : ""),
                (item.abnNumber =
                  item.fkParticipantConnectedBusinessStoreIdrel &&
                  item.fkParticipantConnectedBusinessStoreIdrel.Businesses
                    .length > 0
                    ? item.fkParticipantConnectedBusinessStoreIdrel
                        .Businesses[0].abnNumber
                    : ""),
                (item.mobileNo =
                  item.fkParticipantConnectedBusinessStoreIdrel &&
                  item.fkParticipantConnectedBusinessStoreIdrel.Businesses
                    .length > 0
                    ? item.fkParticipantConnectedBusinessStoreIdrel
                        .Businesses[0].mobileNo
                    : ""),
                (item.address =
                  item.fkParticipantConnectedBusinessStoreIdrel &&
                  item.fkParticipantConnectedBusinessStoreIdrel.Businesses
                    .length > 0
                    ? item.fkParticipantConnectedBusinessStoreIdrel
                        .Businesses[0].txnLocationAddress !== null &&
                      item.fkParticipantConnectedBusinessStoreIdrel
                        .Businesses[0].txnLocationAddress !== ""
                      ? item.fkParticipantConnectedBusinessStoreIdrel.Businesses[0].txnLocationAddress.concat(
                          item.fkParticipantConnectedBusinessStoreIdrel
                            .Businesses[0].txnLocationCity &&
                            item.fkParticipantConnectedBusinessStoreIdrel
                              .Businesses[0].txnLocationCity !== null
                            ? " " +
                                item.fkParticipantConnectedBusinessStoreIdrel
                                  .Businesses[0].txnLocationCity
                            : ""
                        )
                      : ""
                    : ""),
                (item.email =
                  item.fkParticipantConnectedBusinessStoreIdrel &&
                  item.fkParticipantConnectedBusinessStoreIdrel.Businesses
                    .length > 0
                    ? item.fkParticipantConnectedBusinessStoreIdrel
                        .Businesses[0].email
                    : ""),
                (item.flagged = item.isBusinessFlagged === 0 ? "NO" : "YES"),
                //SUPPORTER ACTIVATED OR DEACTIVATED
                (item.status =
                  item.fkParticipantConnectedBusinessStoreIdrel &&
                  item.fkParticipantConnectedBusinessStoreIdrel.Businesses
                    .length > 0
                    ? item.fkParticipantConnectedBusinessStoreIdrel
                        .Businesses[0].active === 0
                      ? "DEACTIVATED"
                      : "ACTIVATED"
                    : "")
              );
            }
          );
          this.setState(
            {
              count:
                res.data &&
                res.data.allParticipantConnectedBusinesses &&
                res.data.allParticipantConnectedBusinesses !== null
                  ? res.data.allParticipantConnectedBusinesses.totalCount
                  : "",
              listData:
                res.data &&
                res.data.allParticipantConnectedBusinesses &&
                res.data.allParticipantConnectedBusinesses !== null
                  ? res.data.allParticipantConnectedBusinesses
                      .ParticipantConnectedBusinesses
                  : 0
            },
            () => {
              this.disablerefetchBusinessConnect();
              this.BusinessmodalDropdownData(this.state.totalBusinessData);
            }
          );
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });
    // }

    if (localStorage.getItem("role") === "GUARDIAN") {
      fetchMethod(OnlyParticipantConnectedBusinesses, {
        where: this.state.filter
        // last: this.state.rows,
        // first: this.state.pageNo
      })
        // fetchMethod(allSupporterConnectedwithParticipant(id))
        .then((res) => res.json())
        .then((res) => {
          // console.log("res getAllConnectedSupporter", res);
          // setLoading(false);
          if (
            res.data &&
            res.data.allParticipantConnectedBusinesses &&
            res.data.allParticipantConnectedBusinesses
              .ParticipantConnectedBusinesses &&
            res.data.allParticipantConnectedBusinesses
              .ParticipantConnectedBusinesses != ""
          ) {
            let DATA =
              res.data.allParticipantConnectedBusinesses
                .ParticipantConnectedBusinesses;
            let myData = [];
            DATA.map((item, index) => {
              let UData =
                item.fkParticipantConnectedBusinessStoreIdrel &&
                item.fkParticipantConnectedBusinessStoreIdrel.Businesses != ""
                  ? item.fkParticipantConnectedBusinessStoreIdrel.Businesses[0]
                  : null;
              if (UData) {
                let mainData = {
                  ...item,
                  ...UData,
                  fkParticipantConnectedBusinessStoreIdrel: null
                };

                myData.push(mainData);
              } else {
                this.setState({
                  listData: []
                });
              }
            });
            // console.log(
            //   " Final data++++++++++business data+++++++++++++++++",
            //   myData
            // );

            myData.map((item) => {
              return (
                (item.storeName = item.storeName),
                (item.abnNumber = item.abnNumber),
                (item.email = item.email),
                (item.mobileNo = item.mobileNo),
                (item.flagged = item.isBusinessFlagged === 0 ? "NO" : "YES"),
                //SUPPORTER ACTIVATED OR DEACTIVATED
                (item.status = item.active === 0 ? "DEACTIVATED" : "ACTIVATED"),
                (item.address =
                  item.txnLocationAddress != null
                    ? item.txnLocationAddress +
                      " " +
                      (item.txnLocationCity != null ? item.txnLocationCity : "")
                    : "")

                // "{"),
                //  item.fkParticipantConnectedSupporterIdrel.Userdata[0].address.concat(
                //     item.fkParticipantConnectedSupporterIdrel
                //       .Userdata[0].city &&
                //       item.fkParticipantConnectedSupporterIdrel
                //         .Userdata[0].city !== null
                //       ? " " +
                //           item.fkParticipantConnectedSupporterIdrel
                //             .Userdata[0].city
                //       : ""
                //   )

                // (item.dob =
                //   item.dob != null ? this.formatDate(item.dob) : "")
              );
            });

            this.setState({
              // count:
              //   res.data &&
              //   res.data.allParticipantConnectedSupporters &&
              //   res.data.allParticipantConnectedSupporters !== null
              //     ? res.data.allParticipantConnectedSupporters.totalCount
              //     : "",
              count: myData ? myData.length : 0,
              listData: myData ? myData : []
            });
            // this.setState(
            // {
            // count:
            //   res.data &&
            //   res.data.allParticipantConnectedSupporters &&
            //   res.data.allParticipantConnectedSupporters !== null
            //     ? res.data.allParticipantConnectedSupporters.totalCount
            //     : "",
            // listData: myData
            // res.data &&
            // res.data.allParticipantConnectedSupporters !== undefined &&
            // res.data.allParticipantConnectedSupporters !== null
            //   ? res.data.allParticipantConnectedSupporters
            //       .ParticipantConnectedSupporters
            //   : ""
            // },
            // () => {
            //   this.props.disablerefetchConnect();
            //   this.props.listModalData(this.state.totalData);
            // }
            // );
            // setpeople(myData);
          } else {
            this.setState({
              listData: []
            });
            // setNoData(true);
          }
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
          this.setState({ listData: [] });
        });
    }
  };
  handleTabs = (event, newValue) => {
    this.setState({
      value: newValue
    });
  };
  openModalBox = () => {
    console.log("i am here");
    this.getAllDropDownData();
    this.setState({ openModal: true });
  };
  openBusinessModel = () => {
    console.log("i am here");
    this.getAllDropDownData();
    this.setState({ openModalBox: true });
  };
  handleCloseBusiness = () => {
    this.setState({ openModalBox: false, storeId: undefined });
  };
  handleClose = () => {
    this.setState({
      supporterId: undefined,
      openModal: false,
      isVerified: false,
      supporterEmail: null
    });
  };
  filterData = () => {
    const { filter } = this.state;
    filter["participantId"] = this.state.participantId;
    this.setState(
      {
        filter
      },
      () => this.getParticipantSupporterData()
    );
  };

  filterBusinessData = () => {
    const { filterData } = this.state;
    // filter["storeId"] = this.state.storeId;
    filterData["participantId"] = this.state.participantId;
    this.setState(
      {
        filterData
      },
      () => this.getBuisnessParticipantData()
    );
  };

  handleIsVerified = (evt) => {
    if (evt.target.checked) {
      this.setState({
        isVerified: true
      });
    } else {
      this.setState({
        isVerified: false
      });
    }
  };

  SupporterDeviceUdid = async (supporterId, participantId) => {
    var obj = { id: supporterId, active: 1 };
    fetchMethod(supporterDevice, { where: obj })
      .then((res) => res.json())
      .then((res) => {
        let deviceId = res.data.allUserdata.Userdata[0].loginDevice;
        fetchMethod(supporterDevice, {
          where: { id: participantId, active: 1 }
        })
          .then((res) => res.json())
          .then((resp) => {
            let fname = this.props.userData.firstname;
            //let userRole = localStorage.getItem("role");
            fetchMethod(
              GetUserdataNotification(
                deviceId,
                `${fname} added you!`,
                "You can now pay as one of his trusted supporters",
                "Connected"
              )
            )
              .then((response) => response.json())
              .then((nres) => {
                console.log("GetUserdataNotification on payment status", nres);
              });
          });
      });
  };

  submitButton = () => {
    if (
      this.state.supporterId !== undefined &&
      this.state.supporterId !== " " &&
      this.state.supporterId !== null
    ) {
      const test = {
        supporterId: this.state.supporterId,
        participantId: this.state.participantId,
        isverified: this.state.isVerified ? 1 : 0,
        createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
        updatedBy: JSON.parse(localStorage.getItem("userInfo")).id
      };
      this.handleClose();
      fetchMethod(saveParticipantSupporterQuery, { obj: test })
        .then((res) => res.json())
        .then(async (response) => {
          const id = response.data.saveParticipantConnectedSupporter;
          console.log("idddddd", id);
          const getSupporterDeviceId = await this.SupporterDeviceUdid(
            this.state.supporterId,
            this.state.participantId
          );
          if (id && id !== null) {
            this.filterData();
            this.setState({ refetchConnect: true, supporterId: undefined });

            swal({
              text: "Support Connected.",
              icon: "success"
            });
            this.handleClose();
          } else {
            swal("Please try again", { icon: "error" });
          }
          this.filterData();
        });
    } else {
      swal({ title: "Please enter valid supporter email id", icon: "warning" });
    }
  };
  handleSupporterFilter = (e) => {
    const {
      target: { name, value }
    } = e;

    const { filter } = this.state;
    filter[name] = value;
    this.setState({
      [name]: value,
      filter
    });
  };

  handleBuisnessFilter = (e) => {
    const {
      target: { name, value }
    } = e;

    const { filter } = this.state;
    filter[name] = value;
    this.setState({
      [name]: value,
      filter
    });
  };

  submitBusinessButton = () => {
    if (this.state.storeId !== undefined && this.state.storeId != "-1") {
      this.handleCloseBusiness();
      const test = {
        storeId: this.state.storeId,
        participantId: this.state.participantId,
        createdBy: `${JSON.parse(localStorage.getItem("userInfo")).id}`,
        updatedBy: `${JSON.parse(localStorage.getItem("userInfo")).id}`
      };
      fetchMethod(saveParticipantBusinessQuery, { obj: test })
        .then((res) => res.json())
        .then((response) => {
          const id = response.data.saveParticipantConnectedBusiness;
          if (id && id !== null) {
            this.filterBusinessData();
            this.setState({
              refetchBusinessConnect: true,
              storeId: undefined
            });
            swal({
              text: "Business connected successfully",
              icon: "success"
            });
            this.handleCloseBusiness();

            // this.modalDropdownData()
          } else {
            swal("Please try again", { icon: "error" });
          }
        });
    } else {
      swal({ title: "Please select business", icon: "warning" });
    }
  };

  submitButtonPS = async () => {
    if (!!this.state.supporterEmail) {
      const status = await this.handleSupporterText(this.state.supporterEmail);
      if (status?.status) {
        const test = {
          supporterId: status?.data ?? 0,
          participantId: this.state.participantId,
          isverified: this.state.isVerified ? 1 : 0,
          createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
          updatedBy: JSON.parse(localStorage.getItem("userInfo")).id
        };
        this.handleClose();
        linkSupporterToAllWallets(this.state.participantId, test.supporterId)
          .then((res) => res.json())
          .then(async (response) => {
            const id = response?.id;
            if (id && id !== null) {
              this.filterData();
              this.setState({ refetchConnect: true, supporterId: undefined });
              swal({
                title: "Support Connected.",
                icon: "success"
              });
              this.handleClose();
              // this.setState({ supporterEmail: null });
            } else {
              swal("Please try again", { icon: "error" });
            }
            this.filterData();
          })
          .catch((error) => console.error(error));
      } else {
        console.log("return false");
      }
    } else {
      swal({ title: "Please enter supporter email id", icon: "warning" });
    }
  };

  disablerefetchConnect = () => {
    this.setState({ refetchConnect: false });
  };

  disablerefetchBusinessConnect = () => {
    this.setState({ refetchBusinessConnect: false });
  };
  handleBusiness = (e, v) => {
    if (v !== null && v !== undefined && v.id && v.id != "-1") {
      this.setState({
        storeId: v.id
      });
    } else if (v !== null && v !== undefined && v.id && v.id == "-1") {
      this.handleCloseBusiness();
      this.setState({
        storeId: v.id
      });
      this.handleDrawerShow();
    } else {
      this.setState({
        storeId: undefined
      });
    }
  };
  handleSupporter = (e, v) => {
    if (v !== null && v !== undefined && v.id) {
      this.setState({
        supporterId: v.id
      });
    } else {
      this.setState({
        supporterId: undefined
      });
    }
  };

  modalDropdownData = (e, flag) => {
    // fetchMethod(supporterdata)
    //   .then(res => res.json())
    //   .then(res => {
    //     console.log("REs initial test", res);
    //     if (
    //       res &&
    //       res.data &&
    //       res.data.allUserdata &&
    //       res.data.allUserdata.Userdata
    //     ) {
    //       console.log("EEEEREST", res);
    //       this.setState(
    //         {
    //           supporterOptions: res.data.allUserdata.Userdata.map(item => {
    //             return {
    //               id: item.id,
    //               supporter:
    //                 item.firstname +
    //                 " " +
    //                 (item.lastname != null ? item.lastname : "")
    //             };
    //           })

    //           // allParticipantList: resp.data.allUserdata.Userdata
    //         },
    //         () => {
    //           let temp = [...this.state.supporterOptions];
    //           console.log("Temp....................if flkag.......", temp);
    //           console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e);
    //           let userId = e.map(
    //             i => i.fkParticipantConnectedSupporterIdrel.Userdata[0].id
    //           );
    //           console.log("*user id .luser id ", userId);
    //           for (let i = 0; i < userId.length; i++) {
    //             for (let x = 0; x < temp.length; x++) {
    //               if (temp[x].id === userId[i]) temp.splice(x, 1);
    //             }
    //           }
    //           console.log("+++++++++++++++++++++++++++++++++++", temp);
    //           this.setState({ supporterOptions: temp });
    //         }
    //       );
    //     }
    //   })
    //   .catch(error => {
    //     swal({ title: error.message, icon: "warning" });
    //   });
    if (flag == 2) {
      // console.log('test flag')
      fetchMethod(supporterdata)
        .then((res) => res.json())
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.allUserdata &&
            res.data.allUserdata.Userdata
          ) {
            this.setState(
              {
                supporterOptions: res.data.allUserdata.Userdata.map((item) => {
                  return {
                    id: item.id,
                    supporter:
                      item.firstname +
                      " " +
                      (item.lastname != null ? item.lastname : "")
                  };
                })

                // allParticipantList: resp.data.allUserdata.Userdata
              },
              () => {
                let temp = [...this.state.supporterOptions];

                let userId = e.map(
                  (i) => i.fkParticipantConnectedSupporterIdrel.Userdata[0].id
                );

                for (let i = 0; i < userId.length; i++) {
                  for (let x = 0; x < temp.length; x++) {
                    if (temp[x].id === userId[i]) temp.splice(x, 1);
                  }
                }
                this.setState({ supporterOptions: temp });
              }
            );
          }
        })
        .catch((error) => {
          swal({ title: error.message, icon: "warning" });
        });
    } else {
      // console.log('else test flag')
      let temp = [...this.state.supporterOptions];
      let userId = e.map(
        (i) => i?.fkParticipantConnectedSupporterIdrel?.Userdata[0]?.id
      );

      for (let i = 0; i < userId.length; i++) {
        for (let x = 0; x < temp.length; x++) {
          if (temp[x].id === userId[i]) temp.splice(x, 1);
        }
      }

      this.setState({ supporterOptions: temp });
    }
  };

  BusinessmodalDropdownData = (e, flag) => {
    localStorage.getItem("role") === "GUARDIAN"
      ? fetchMethod(connectedBusiness, { active: 1 })
          .then((res) => res.json())
          .then((res) => {
            res.data.BusinessShowBusiness.businessdata !== undefined
              ? this.setState(
                  {
                    BusinessesOptions:
                      res.data.BusinessShowBusiness.businessdata.map((item) => {
                        return {
                          id: item.id,
                          business: item.store_name
                        };
                      })
                  },
                  () => {
                    // this.BusinessmodalDropdownData(this.state.temp);
                  }
                )
              : this.setState({ loading: true });
            // console.log(this.state.BusinessesOptions);
          })
          .then(() => {
            if (
              this.state.BusinessesOptions !== null &&
              this.state.BusinessesOptions !== undefined
            ) {
              let temp = [...this.state.BusinessesOptions];
              let businessId = e.map(
                (i) =>
                  i.fkParticipantConnectedBusinessStoreIdrel.Businesses[0].id
              );
              for (let i = 0; i < businessId.length; i++) {
                for (let x = 0; x < temp.length; x++) {
                  if (temp[x].id === businessId[i]) temp.splice(x, 1);
                }
              }
              this.setState({ BusinessesOptions: temp });
            } else {
            }
          })
          .catch((e) => console.log(e))
      : fetchMethod(businessQuery, {
          where: { active: 1 }
        })
          .then((res) => res.json())
          .then((res) => {
            res.data.allBusinesses.Businesses !== undefined
              ? this.setState({
                  BusinessesOptions: res.data.allBusinesses.Businesses.map(
                    (item) => {
                      return {
                        id: item.id,
                        business: item.storeName
                      };
                    }
                  )
                })
              : this.setState({ loading: true });
          })
          .then(() => {
            if (
              this.state.BusinessesOptions !== null &&
              this.state.BusinessesOptions !== undefined
            ) {
              let temp = [...this.state.BusinessesOptions];
              let businessId = e.map(
                (i) =>
                  i.fkParticipantConnectedBusinessStoreIdrel.Businesses[0].id
              );
              for (let i = 0; i < businessId.length; i++) {
                for (let x = 0; x < temp.length; x++) {
                  if (temp[x].id === businessId[i]) temp.splice(x, 1);
                }
              }
              this.setState({ BusinessesOptions: temp });
            } else {
            }
            // console.log(this.state.BusinessesOptions);
          })
          .catch((e) => console.log(e));
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleDrawerShow = () => {
    this.setState({ drawerOpen: true });
  };
  IsValidEmail = (value) => {
    //this below code is done becaoue the regex only take alphabet in small letter
    const newValue = !!value ? value?.toLowerCase() : "";
    const RegTest = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)$/
    ).test(newValue);
    return RegTest;
  };
  handleSupporterText = async (e) => {
    let value = e?.target?.value ?? e;
    let val = { status: false, data: null };
    if (!!value) {
      if (this.IsValidEmail(value)) {
        const status = await fetchMethod(supporterDevice, {
          where: {
            role: "SUPPORTER",
            active: 1,
            and: [
              {
                or: [
                  {
                    email: {
                      like: `%${value}%`,
                      options: "i"
                    }
                  }
                ]
              }
            ]
          }
        })
          .then((res) => res.json())
          .then(async (res) => {
            if (res?.data?.allUserdata?.Userdata?.length > 0) {
              this.setState({
                supporterId: res?.data?.allUserdata?.Userdata?.[0]?.id ?? 0
              });
              return {
                status: true,
                data: res?.data?.allUserdata?.Userdata?.[0]?.id
              };
            } else {
              this.setState({
                supporterOptions: [],
                supporterId: null
              });
              swal({ title: " Supporter email not found.", icon: "error" });
              return { status: false, data: null };
            }
          });
        return { status: status?.status, data: status?.data };
      } else {
        swal({
          title: "Please enter valid supporter email id.",
          icon: "error"
        });
        return { status: false, data: null };
      }
    } else {
      return { status: false, data: null };
    }
  };
  render() {
    return (
      <div className="participantTab">
        <div className="tabs-top-box">
          <div className="tabs-links">
            <Tabs
              variant="scrollable"
              indicatorColor="primary"
              className="tab-appbar"
              style={{ color: "black" }}
              value={this.state.value}
              onChange={this.handleTabs}
              aria-label="simple tabs example"
            >
              <Tab className="tab" label="Wallets" {...a11yProps(0)} />
              <Tab
                className="tab"
                label="Connected Supporter"
                {...a11yProps(1)}
              />
              <Tab
                className="tab"
                label="Connected Business"
                {...a11yProps(2)}
              />
              <Tab className="tab" label="Transactions" {...a11yProps(3)} />
              <Tab className="tab" label="Deposit" {...a11yProps(4)} />
              <Tab className="tab" label="Budget" {...a11yProps(5)} />
              <Tab className="tab" label="Settings" {...a11yProps(6)} />
            </Tabs>
          </div>
        </div>
        <TabPanel value={this.state.value} index={0}>
          <Wallet
            id={this.props.id}
            hasRefetch={this.state.refetchConnect}
            disablerefetchConnect={this.disablerefetchConnect}
            listModalData={this.modalDropdownData}
            openModalBox={this.openModalBox}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <ConnectedSupporter
            id={this.props.id}
            hasRefetch={this.state.refetchConnect}
            disablerefetchConnect={this.disablerefetchConnect}
            listModalData={this.modalDropdownData}
            openModalBox={this.openModalBox}
            userData={this.props.userData}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <ConnectedBuisness
            id={this.props.id}
            hasRefetch={this.state.refetchBusinessConnect}
            disablerefetchConnect={this.disablerefetchBusinessConnect}
            listModalData={this.BusinessmodalDropdownData}
            openModalBox={this.openBusinessModel}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          <PaymentRequest id={this.props.id} />
        </TabPanel>
        <TabPanel value={this.state.value} index={4}>
          <DepositTab participantId={this.props.id} />
        </TabPanel>
        <TabPanel value={this.state.value} index={5}>
          <ParticipantBudget
            id={this.props.id}
            userdata={this.props.userData}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={6}>
          <SettingsTab id={this.props.id} userdata={this.props.userData} />
        </TabPanel>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          // className="chooseBuisness"
          className="chooseBuisness selectSupportModal"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Select Supporter
          </DialogTitle>
          {/* <DialogContent> */}
          <div className="modal-body-box">
            <TextField
              disabled
              value={
                this.props.userData
                  ? `${this.props.userData.firstname} ${this.props.userData.lastname}`
                  : ""
              }
              label="Participant"
              variant="outlined"
            />
            <TextField
              value={this.state.supporterEmail ?? ""}
              onChange={(e) =>
                this.setState({ supporterEmail: e?.target?.value })
              }
              variant="outlined"
              label="Supporter Email"
            />

            {/* </DialogContent> */}
            <DialogContent>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.isVerified}
                    onChange={(e) => this.handleIsVerified(e)}
                  />
                }
                label="Is verified"
              />
            </DialogContent>
            <DialogButton>
              <Button onClick={() => this.handleClose()}>Cancel</Button>
              <Button
                onClick={() =>
                  localStorage?.getItem("role") === GUARDIAN
                    ? this.submitButtonPS()
                    : this.submitButton()
                }
              >
                Save
              </Button>
            </DialogButton>
          </div>
        </Dialog>

        <Dialog
          open={this.state.openModalBox}
          onClose={this.handleCloseBusiness}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          className="chooseBuisness"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleCloseBusiness}
          >
            Select Buisness
          </DialogTitle>
          {/* <DialogContent> */}
          <div className="modal-body-box">
            {/* <FormControl>
                <InputLabel id="demo-simple" margin="dense" variant="outlined">
                  Business
                </InputLabel>
                <Select
                  labelid="demo-simple"
                  value={this.state.storeId}
                  name="storeId"
                  onChange={this.handleBuisnessFilter}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {this.state.BusinessesOptions !== undefined
                    ? this.state.BusinessesOptions.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.business}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl> */}

            {/* <FormControl> */}
            <TextField
              disabled
              value={
                this.props.userData
                  ? `${this.props.userData.firstname} ${this.props.userData.lastname}`
                  : ""
              }
              label="Participant"
              variant="outlined"
            />
            <Autocomplete
              value={this.state.storeId}
              // options={this.state.BusinessesOptions}
              options={[{ business: "Add new business", id: "-1" }].concat(
                this.state.BusinessesOptions
              )}
              onChange={(e, v) => this.handleBusiness(e, v)}
              getOptionLabel={(option) =>
                option && option.business ? option.business : ""
              }
              renderInput={(params) => (
                <TextField {...params} label="Business" variant="outlined" />
              )}
            />
            {/* <Autocomplete
                  options={this.state.supporterOptions}
                  getOptionLabel={option =>
                    option && option.supporter ? option.supporter : ""
                  }
                  style={{ margin: 10, width: 300 }}
                  onChange={(e, v) => this.handleSupporter(e, v)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Supporter"
                    // margin="normal"
                    />
                  )}
                /> */}

            {/* </FormControl> */}

            {/* </DialogContent> */}
            <DialogButton>
              <Button onClick={() => this.handleCloseBusiness()}>Cancel</Button>
              <Button
                onClick={() => {
                  this.submitBusinessButton();
                }}
              >
                Save
              </Button>
            </DialogButton>
          </div>
        </Dialog>
        {this.state.storeId === "-1" ? (
          <GuardianAddbusinessModal
            drawerOpen={this.state.drawerOpen}
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerShow={this.handleDrawerShow}
            openBusinessModel={this.openBusinessModel}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(ParticipantTab);
