import { Button, FormControlLabel, Switch } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import "./actiondropdown.scss";
import { addEditFunds, getWalletsAndAuthledger } from "../ParticipantQuery";
import { fetchMethod } from "../../../FetchMethod";
import { DotLoader } from "react-spinners";
import { useCallback } from "react";
import ConfirmationPrompt from "src/Components/UI/ConfirmationPrompt";

function UserInfo({
  onAddClick,
  showAdd,
  useWholeBalance,
  setUseWholeBalance,
  repeatPayAmount,
  setRepeatPayAmount,
  addedFund = 0,
  walletId
}) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [repeatPayInputError, setRepeatPayInputError] = useState(null);

  const handleRepeatAmountChange = (event) => {
    const targetValue = event.target.value;
    if (targetValue > addedFund + userData.cardLimit) {
      setRepeatPayInputError("value can't exceed the total balance");
      return;
    }
    if (targetValue < 0) {
      setRepeatPayInputError("value can't be negative");
      return;
    }
    setRepeatPayInputError(null);
    setRepeatPayAmount(targetValue);
  };

  const removeFunds = () => {
    ConfirmationPrompt({
      visible: true,
      title: "Do you want to revoke the funds",
      onConfirm: () => {
        setLoading(true);
        fetchMethod(addEditFunds, {
          userid: userData?.id,
          walletId,
          flag: 0,
          createdby: JSON.parse(localStorage.getItem("userInfo")).id
        })
          .then(() => fetchWalletData())
          .finally(() => setLoading(false));
      },
      swalOptions: {
        buttons: true,
        dangerMode: true
      },
      icon: "warning"
    });
  };

  const fetchWalletData = useCallback(() => {
    setLoading(true);
    fetchMethod(getWalletsAndAuthledger, {
      where: {
        id: walletId,
        active: 1
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        const userDataObj = {
          ...data?.data?.allWallets?.Wallets[0]?.participantUserdatarel
            ?.Userdata[0]
        };
        userDataObj["userName"] =
          userDataObj?.firstname + " " + userDataObj?.lastname;
        userDataObj["cardLimit"] =
          userDataObj?.fkUserIdCardDetailMap?.CardDetails[0]?.cardLimit;
        userDataObj["walletId"] = walletId;
        userDataObj["ppan"] =
          userDataObj?.fkUserIdCardDetailMap?.CardDetails[0]?.cardNumber;

        userDataObj["balance"] = data?.data?.allWallets?.Wallets[0]?.balance;
        userDataObj["repeatpayamount"] =
          data?.data?.allWallets?.Wallets[0]?.authledgerWalletMap?.Authledgers[0]?.requestedamount;

        setUserData(userDataObj);
      })
      .finally(() => setLoading(false));
  }, [walletId]);

  useEffect(() => {
    fetchWalletData();
  }, [walletId, fetchWalletData]);

  return loading ? (
    <div className="spinner">
      <DotLoader size={70} color={"#020f1f"} />
    </div>
  ) : (
    <div className="flex gap-10">
      <div className="userInfoWrapper">
        <div className="field-container">
          {editFundFields.map((field) => (
            <>
              <div className="field-title">{field.title}</div>
              {userData && userData[field.accessor] ? (
                <div className="field-value">
                  <span>{field.prefix}</span>
                  {userData[field.accessor]}
                </div>
              ) : (
                <div> - </div>
              )}
            </>
          ))}
        </div>
        <div className="button-group">
          <Button variant="contained" color="secondary" onClick={removeFunds}>
            Remove
          </Button>
          {showAdd && (
            <Button variant="contained" color="primary" onClick={onAddClick}>
              Add
            </Button>
          )}
        </div>
      </div>
      {/* {userData?.repeatpayamount !== null && !showAdd && (
        <div className="userInfoWrapper">
          <div className="field-container">
            <div className="field-title mb-10">
              Use Whole Balance as <br /> repeat pay
            </div>

            <div className="field-value">
              <FormControlLabel
                control={
                  <Switch
                    checked={useWholeBalance}
                    onChange={(e) => {
                      setUseWholeBalance((prev) => !prev);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
            </div>
            <div></div>
            <div className="orText">Or</div>
            <div className="field-title">
              How much should <br /> be available in repeat pay?
            </div>
            <div className="field-value">
              <OutlinedInput
                size="small"
                type="number"
                variant="filled"
                disabled={useWholeBalance}
                value={repeatPayAmount}
                onChange={handleRepeatAmountChange}
                error={repeatPayInputError}
              />
              <Typography
                variant="body1"
                component="p"
                color="red"
                fontSize={14}
                mt={0.1}
              >
                {repeatPayInputError}
              </Typography>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}

const editFundFields = [
  {
    title: "User ID",
    accessor: "id"
  },
  {
    title: "User Name",
    accessor: "userName"
  },
  {
    title: "PPAN",
    accessor: "ppan"
  },
  {
    title: "Wallet ID",
    accessor: "walletId"
  },
  {
    title: "Balance",
    // accessor: "cardLimit",
    accessor: "balance",
    prefix: "$"
  },
  {
    title: "Always Available Balance",
    accessor: "repeatpayamount",
    prefix: "$"
  }
];

export default UserInfo;
