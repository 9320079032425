export const getCardLimitCron = `
query allCardcronjobs($where:JSON,$last: Int, $first: Int){
    allCardcronjobs(where:$where,last:$last,first:$first) {
        totalCount
        Cardcronjobs {
            id
            amount
            event
            active
            cardcronjobusermaprel{
                Userdata{
                  firstname
                  lastname
        }
              }
        }
    }
}
`
export const saveCardLimitCron = `
mutation CardcronjobSaveRule($userid:Int,$event:String,$amount:Int,$createdBy:Int){
    CardcronjobSaveRule(userid:$userid,,event:$event,amount:$amount,createdBy:$createdBy)
}
`
export const editCardLimitQuery = id => `
query{
    allCardcronjobs (where:{id:${id}}){
        Cardcronjobs {
            id
            amount
            createdon
            event
            cardcronjobusermaprel{
                Userdata{
                  firstname
                  lastname
                  fkUserIdCardDetailMap{
                    CardDetails{
                      cardLimit
                    }
                  }
        }
              }
        }
    }
}
`

export const saveCardLimitdata = `
  mutation saveCardcronjob($obj: CardcronjobInput!) {
    saveCardcronjob(obj: $obj) {
      id
    }
  }
`;

export const deleteCardCron = `
mutation CardcronjobDeleteById($id:JSON){
  CardcronjobDeleteById(id:$id)
}`


