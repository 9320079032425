import React, { Component } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@material-ui/icons/Close";
import {
  TextField,
  Button,
  IconButton,
  Input,
  FormHelperText
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { fetchMethod } from "../../FetchMethod";
import {
  participantQueryData,
  getCardLimitCron,
  saveBudgetdata,
  getAllCategories,
  getAlltypes,
  saveCardLimitCron,
  getAllCategoriesByTypeId,
  getCardLimitById
} from "./automatedBudgetQuery";
import swal from "sweetalert";
import "./automatedBudget.css";

export default class AddAutomationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalParticipant: "",
      modalCategory: "",
      modalType: "",
      modalOcuurance: "",
      modalDate: "",
      modalBudget: "",
      modalErrors: {},
      allCategories: [],
      cardLimit: 0,
      minDate: this.minDate()
    };
  }

  GetAllCategoriesByType = () => {
    if (this.state.modalType) {
      fetchMethod(getAllCategoriesByTypeId, { tierId: this.state.modalType.id })
        .then((res) => res.json())
        .then((res) => {
          if (res.data.TiertypeCategory.data) {
            this.setState({ allCategories: res.data.TiertypeCategory.data });
          }
        })
        .catch((err) => {
          swal({ title: err.message, icon: "warning" });
        });
    } else {
      this.setState({ allCategories: [] });
    }
  };

  handleValidation = () => {
    let formIsValid = true;
    let errorsT = {};
    // if (!this.state.modalParticipant) {
    var result = this.participantValidations(this.state.modalParticipant);
    if (!result.valid) {
      formIsValid = false;
      errorsT["partcipant"] = result;
    }
    // }
    if (!this.state.modalType) {
      formIsValid = false;
      var result = this.typeValidations(this.state.modalType);
      errorsT["type"] = result;
    }
    // if (!this.state.modalCategory) {
    var result = this.categoryValidations(this.state.modalCategory);
    if (!result.valid) {
      formIsValid = false;
      errorsT["category"] = result;
    }
    // }
    // if (!this.state.modalOcuurance) {
    var result = this.occuranceValidations(this.state.modalOcuurance);
    if (!result.valid) {
      formIsValid = false;
      errorsT["occurance"] = result;
    }
    // }
    // if (!this.state.modalDate) {
    var result = this.dateValidations(this.state.modalDate);
    if (!result.valid) {
      formIsValid = false;
      errorsT["date"] = result;
    }
    // }
    // if (!this.state.modalBudget || this.state.modalBudget == 0) {
    var result = this.budgetValidation(this.state.modalBudget);
    if (!result.valid) {
      formIsValid = false;
      errorsT["budget"] = result;
    }
    // }
    this.setState({
      modalErrors: errorsT
    });
    return formIsValid;
  };

  handleaddAutomation = (e) => {
    e.preventDefault();
    let finalSave = this.handleValidation();
    if (finalSave) {
      let {
        modalBudget,
        modalDate,
        modalOcuurance,
        modalCategory,
        modalParticipant,
        modalType
      } = this.state;
      if (
        modalBudget &&
        modalDate &&
        modalOcuurance &&
        modalCategory &&
        modalParticipant &&
        modalType
      ) {
        this.props.handleaddAutomation(
          modalBudget,
          modalDate,
          modalOcuurance,
          modalCategory,
          modalParticipant,
          modalType,
          this.handleCloseData
        );
      }
    }
  };

  participantValidations = (e) => {
    if (e === "" || e === null) {
      return {
        valid: false,
        errMsg: "Please enter participant"
      };
    }
    return {
      valid: true
    };
  };

  getCardLimitQuery = (id) => {
    fetchMethod(getCardLimitById, { where: { userId: parseFloat(id) } })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.allCardDetails?.CardDetails?.length > 0) {
          this.setState({
            cardLimit: res?.data?.allCardDetails?.CardDetails?.[0]?.cardLimit
          });
        } else {
          this.setState({ cardLimit: 0 });
        }
      })
      .catch((err) => {
        this.setState({ cardLimit: 0 });
        swal({ title: err.message, icon: "warning" });
      });
  };

  handlePraticipant = (event, values) => {
    var result = this.participantValidations(values);
    this.setState({
      modalErrors: {
        ...this.state.modalErrors,
        partcipant: result
      }
    });
    if (result.valid) {
      this.getCardLimitQuery(values.id);
      this.setState({ modalParticipant: values });
    } else this.setState({ modalParticipant: "" });
  };

  categoryValidations = (e) => {
    if ((e === "" || e === null) && this.state.modalType) {
      return {
        valid: false,
        errMsg: "Please select type to show options of category"
      };
    } else if (e === "" || e === null) {
      return {
        valid: false,
        errMsg: "Please enter category"
      };
    }
    return {
      valid: true
    };
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleCategory = (event, values) => {
    var result = this.categoryValidations(values);
    this.setState({
      modalErrors: {
        ...this.state.modalErrors,
        category: result
      }
    });
    if (result.valid) this.setState({ modalCategory: values });
    else this.setState({ modalCategory: "" });
  };

  typeValidations = (e) => {
    if (e === "" || e === null) {
      return {
        valid: false,
        errMsg: "Please enter type"
      };
    }
    return {
      valid: true
    };
  };

  handleType = (event, values) => {
    var result = this.typeValidations(values);
    this.setState({
      modalErrors: {
        ...this.state.modalErrors,
        type: result
      }
    });
    if (result.valid) {
      this.setState({ modalType: values, modalCategory: "" });
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.GetAllCategoriesByType();
        },
        100
      );
    } else {
      this.setState({ modalType: values });
      this.setState({ allCategories: [] });
    }
  };

  occuranceValidations = (e) => {
    if (e === "" || e === null) {
      return {
        valid: false,
        errMsg: "Please enter occurance"
      };
    }
    return {
      valid: true
    };
  };

  handleOccurance = (event, values) => {
    var result = this.occuranceValidations(values);
    this.setState({
      modalErrors: {
        ...this.state.modalErrors,
        occurance: result
      }
    });
    if (result.valid) this.setState({ modalOcuurance: values });
  };

  dateValidations = (e) => {
    if (e === "" || e === null) {
      return {
        valid: false,
        errMsg: "Please enter date"
      };
    }
    return {
      valid: true
    };
  };

  handleDate = (event) => {
    var result = this.dateValidations(event.target.value);
    this.setState({
      modalErrors: {
        ...this.state.modalErrors,
        date: result
      }
    });
    if (result.valid) this.setState({ modalDate: event.target.value });
    else this.setState({ modalDate: null });
  };

  minDate = () => {
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = now.getFullYear() + "-" + month + "-" + day;
    return today;
  };

  budgetValidation = (e) => {
    if (!e) {
      return {
        valid: false,
        errMsg: "Please enter required field"
      };
    }
    if (isNaN(e) !== false) {
      return {
        valid: false,
        errMsg:
          "Fund allocated should be contain only numeric value and decimal"
      };
    }
    if (!isNaN(e) && e == 0) {
      return {
        valid: false,
        errMsg: "Fund allocated should be greather than 0"
      };
    }
    if (parseFloat(e) > parseFloat(this.state.cardLimit)) {
      return {
        valid: false,
        errMsg:
          this.state.cardLimit == 0
            ? "Insufficient Card Balance"
            : `Fund allocated should be less than ${this.state.cardLimit}`
      };
    }
    if (e) {
      if (e.includes(".")) {
        var value = e;
        var length =
          value.toString().length - (value.toString().indexOf(".") + 1);
        if (parseFloat(length) > 2) {
          return {
            valid: false,
            errMsg: "Only 2 digit allowed after decimal point"
          };
        }
      }
    }
    return {
      valid: true
    };
  };
  handleBudget = (event) => {
    var result = this.budgetValidation(event.target.value);
    this.setState({
      modalErrors: {
        ...this.state.modalErrors,
        budget: result
      }
    });
    this.setState({ modalBudget: event.target.value });
  };

  validate = (event) => {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(event.target.value)) {
      this.setState({ modalBudget: event.target.value });
    } else {
      event.target.value = this.state.modalBudget;
    }
  };

  handleCloseData = () => {
    this.setState({
      modalBudget: null,
      modalDate: "",
      modalOcuurance: null,
      modalCategory: null,
      modalParticipant: "",
      modalType: "",
      modalErrors: {}
    });
    this.props.handleClose();
  };
  render() {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 4
    };
    return (
      <div className="modalData">
        <Modal
          open={this.props.show || false}
          onClose={() => this.handleCloseData()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="automationModal"
        >
          <Box sx={style} className="automationModalBox">
            <h3 className="title-box">
              Add Automation
              <IconButton className="btn-wrap">
                <CloseIcon onClick={() => this.handleCloseData()} />
              </IconButton>
            </h3>
            <div className=" bh-form-group row ">
              <p>
                Choose a participant <span style={{ color: "red" }}>*</span>
              </p>
              <Autocomplete
                onChange={this.handlePraticipant}
                value={this.state.modalParticipant}
                options={this.props.allUserdata}
                getOptionLabel={(option) =>
                  option ? `${option.firstname} ${option.lastname}` : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type to search"
                    fullWidth
                  />
                )}
              />
              {"partcipant" in this.state.modalErrors ? (
                this.state.modalErrors.partcipant.valid ? (
                  ""
                ) : (
                  <FormHelperText>
                    {this.state.modalErrors.partcipant.errMsg}
                  </FormHelperText>
                )
              ) : (
                ""
              )}
            </div>
            <div className="bh-form-group row">
              <p>
                Type <span style={{ color: "red" }}>*</span>
              </p>
              <Autocomplete
                onChange={this.handleType}
                value={this.state.modalType}
                options={this.props.allTypes}
                getOptionLabel={(option) => option.tierType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type to search"
                    // margin="normal"
                    fullWidth
                  />
                )}
              />
              {"type" in this.state.modalErrors ? (
                this.state.modalErrors.type.valid ? (
                  ""
                ) : (
                  <FormHelperText>
                    {this.state.modalErrors.type.errMsg}
                  </FormHelperText>
                )
              ) : (
                ""
              )}
            </div>
            <div className="bh-form-group row">
              <p>
                Choose a category <span style={{ color: "red" }}>*</span>
              </p>
              <Autocomplete
                onChange={this.handleCategory}
                value={this.state.modalCategory}
                options={this.state.allCategories}
                getOptionLabel={(option) =>
                  option
                    ? option.category_name
                        .split(" ")
                        .map(
                          (cateogry) =>
                            cateogry.charAt(0).toUpperCase() + cateogry.slice(1)
                        )
                        .join(" ")
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type to search"
                    // margin="normal"
                    fullWidth
                  />
                )}
              />
              {"category" in this.state.modalErrors ? (
                this.state.modalErrors.category.valid ? (
                  ""
                ) : (
                  <FormHelperText>
                    {this.state.modalErrors.category.errMsg}
                  </FormHelperText>
                )
              ) : (
                ""
              )}
            </div>
            <div className="bh-form-group row">
              <p>
                Occurance <span style={{ color: "red" }}>*</span>
              </p>
              <Autocomplete
                onChange={this.handleOccurance}
                value={this.state.modalOcuurance}
                options={["daily", "weekly", "monthly", "specific date"]}
                getOptionLabel={(option) =>
                  option
                    ? option
                        .split(" ")
                        .map(
                          (opt) => opt.charAt(0).toUpperCase() + opt.slice(1)
                        )
                        .join(" ")
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type to search"
                    // margin="normal"
                    fullWidth
                  />
                )}
              />
              {"occurance" in this.state.modalErrors ? (
                this.state.modalErrors.occurance.valid ? (
                  ""
                ) : (
                  <FormHelperText>
                    {this.state.modalErrors.occurance.errMsg}
                  </FormHelperText>
                )
              ) : (
                ""
              )}
            </div>
            <div className=" bh-form-group row ">
              <p>
                Starts from <span style={{ color: "red" }}>*</span>
              </p>
              <div className="bh-input-group">
                <input
                  type="date"
                  value={this.state.modalDate ? this.state.modalDate : ""}
                  onChange={this.handleDate}
                  min={this.state.minDate}
                  className="bh-form-control sm"
                />
              </div>
              {"date" in this.state.modalErrors ? (
                this.state.modalErrors.date.valid ? (
                  ""
                ) : (
                  <FormHelperText>
                    {this.state.modalErrors.date.errMsg}
                  </FormHelperText>
                )
              ) : (
                ""
              )}
            </div>

            <div className=" bh-form-group row ">
              <p>
                Budget allocated <span style={{ color: "red" }}>*</span>
              </p>
              <div className="bh-input-group">
                <div
                  className="bh-input-group-text no-bg no-rt-border"
                  role="img"
                >
                  <p>$</p>
                </div>
                <input
                  name={"modalBudget"}
                  className="bh-form-control sm no-lt-border"
                  onChange={(e) => {
                    this.handleBudget(e);
                  }}
                  onInput={(e) => {
                    this.validate(e);
                  }}
                  disabled={this.state.modalParticipant ? false : true}
                  type="text"
                  value={this?.state?.modalBudget || ""}
                />
              </div>
              {"budget" in this.state.modalErrors ? (
                this.state.modalErrors.budget.valid ? (
                  ""
                ) : (
                  <FormHelperText>
                    {this.state.modalErrors.budget.errMsg}
                  </FormHelperText>
                )
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <button
                className="btn btn-primary sm col-md-15 "
                onClick={this.handleaddAutomation}
              >
                Add Automation
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}
