import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FormComponent from "../../Form/FormComponent";
import { addBudgetConfig } from "./BudgetConfig";
import { mutationQueries } from "../../MutationMethod";
import { gql } from "apollo-boost";
import { fetchMethod } from "../../FetchMethod";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import Loader from "../../assets/Infinity-1s-200px.svg";
import swal from "sweetalert";
import "./addBudget.css";
import {
  saveBudget,
  businessQueryData,
  getItemCategory,
  budgetTotalData,
  getTier,
  participantQueryData,
  getCardLimitById,
  getSupporterConnectedParticipant,
  GetUserdataNotification,
  connectItemWithstoreQueryData,
  getAuthledgerData,
  participantBudget,
  saveAuthledger,
  getParticipantWallets,
  getWalletConnectedUser
} from "./BudgetQuery";
import { saveMasterItemCategoryByGuardian } from "../ItemCategory/ItemQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  FormControl,
  Input,
  ListItemText,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  Button
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MultiSelect from "./multiSelectSeletion";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class AddGuardianBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allParticipantList: [],
      walletData: [],
      participantId: undefined,
      paramParticipantId: this.props.match.params.participantId,
      allCategoryList: [],
      categoryId: undefined,
      newcategory: undefined,
      typeId: { id: 1, name: "Essential" },
      allTierList: [],
      isshared: false,
      canwithdraw: false,
      alwaysavailablebudget: false,
      search: undefined,
      storeId: [],
      storeData: [],
      selectSupportData: [],
      authledgerData: null,
      selectedSupportData: [],
      budgetAllocated: null,
      filter: {
        active: 1,
        order: "firstname asc, lastname asc",
        role: "SUPPORTER"
      },
      status: 1,
      errors: {},
      cardLimit: 0,
      loader: false,
      allAdminId: [],
      cardNumber: null
    };
  }

  componentDidMount() {
    this.getStoreData();
    // this.getSupporterData();
    this.handleTypeField();

    this.handleChangeField();
    this.adminId();
  }

  getSupporterData = async (walletId) => {
    await fetchMethod(getWalletConnectedUser, {
      where: {
        walletId: walletId,
        active: 1
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          res.data.allWalletConnectedUsers &&
          res.data.allWalletConnectedUsers?.WalletConnectedUsers !== ""
        ) {
          let finalData = [];
          res.data.allWalletConnectedUsers.WalletConnectedUsers.map((item) => {
            let UData =
              item.walletUserrel && item.walletUserrel.Userdata != ""
                ? item.walletUserrel.Userdata[0]
                : null;
            if (UData) {
              finalData.push({
                id:
                  item.walletUserrel.Userdata != ""
                    ? item.walletUserrel.Userdata[0].id
                    : null,
                name:
                  item.walletUserrel.Userdata != ""
                    ? item.walletUserrel.Userdata[0].firstname +
                      " " +
                      (item.walletUserrel.Userdata[0].lastname != null
                        ? item.walletUserrel.Userdata[0].lastname
                        : "")
                    : null
              });
            }
          });
          const selectedIDs = this.state.selectedSupportData
            ? this.state.selectedSupportData.map((item) => item.id)
            : [];
          this.setState({
            selectSupportData: finalData
              ? finalData.filter((item) => selectedIDs.indexOf(item.id) < 0)
              : []
          });
        } else {
          this.setState({
            selectSupportData: []
          });
        }
      })
      .catch((error) => {
        this.setState({ selectSupportData: [] });
        swal({ title: error.message, icon: "warning" });
      });
  };

  getAuthData = async (participantId) => {
    await fetchMethod(getAuthledgerData(participantId))
      .then((res) => res.json())
      .then((res) => {
        if (res.data.allAuthledgers.Authledgers) {
          let data = res.data.allAuthledgers.Authledgers.length
            ? res.data.allAuthledgers.Authledgers[0]
            : null;
          this.setState({
            authledgerData: data
          });
        } else {
          this.setState({
            authledgerData: null
          });
        }
      })
      .catch((error) => {
        this.setState({ authledgerData: null });
        swal({ title: error.message, icon: "warning" });
      });
  };

  adminId = async () => {
    await fetchMethod(participantQueryData, {
      where: { role: "ADMIN", active: 1 }
    })
      .then((res) => res.json())
      .then(async (res) => {
        let idArray = [];
        idArray.push(JSON.parse(localStorage.getItem("userInfo")).id);
        await res.data.allUserdata.Userdata.map((item) => {
          idArray.push(item.id);
        });
        this.setState({
          allAdminId: idArray
        });
      })
      .catch((error) => {
        this.setState({ allAdminId: [] });
        swal({ title: error.message, icon: "warning" });
      });
    this.handleChangeCategory();
  };

  getStoreData = async () => {
    await this.adminId();
    //where: { active: 1, order: "storeName asc", adminId: { "inq": this.state.allAdminId }}
    await fetchMethod(businessQueryData, {
      where: { active: 1, order: "storeName asc" }
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          storeData: res.data.allBusinesses.Businesses.map((item) => {
            return {
              id: item.id,
              name: item.storeName
            };
          })
        });
      })
      .catch((error) => {
        this.setState({ storeData: [] });
        swal({ title: error.message, icon: "warning" });
      });
  };

  getParticipantWallets = async (participantId) => {
    await fetchMethod(getParticipantWallets, {
      where: { participantId: participantId, status: 1 }
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          walletData: res.data.allWallets.Wallets.map((item) => {
            return {
              id: item.id,
              name: item.description,
              balance: parseFloat(item.balance)
            };
          })
        });
      });
  };

  handleChangeField = async (field, e) => {
    // let Search = e.target.value ? e.target.value.trim().replace(/\s\s+/g, "") : "";
    // Search = Search.split(" ");
    // const like1 = "%" + `${Search[0]}` + "%";
    // const like2 = "%" + `${Search[1]}` + "%";

    // let filt = {
    //   or: [
    //     { firstname: { like: like1, options: "i" } },
    //     { lastname: { like: like2, options: "i" } },
    //     { lastname: { like: like1, options: "i" } },
    //   ],
    // };
    var obj = {
      role: "PARTICIPANT",
      active: 1
      // and: [filt],
    };
    if (localStorage.getItem("role") === "GUARDIAN") {
      obj.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
    }
    if (this.state.paramParticipantId) {
      obj.id = this.state.paramParticipantId;
    }
    // if (e.target.value.toString().length > 2) {
    await fetchMethod(participantQueryData, {
      where: obj
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          allParticipantList: res.data.allUserdata.Userdata.map((item) => {
            return {
              id: item.id,
              name:
                item.firstname +
                " " +
                (item.lastname != null ? item.lastname : ""),
              loginDevice: item.loginDevice
            };
          })
        });
      })
      .catch((error) => {
        this.setState({ allParticipantList: [] });
        swal({ title: error.message, icon: "warning" });
      });
    // }
  };

  handleTypeField = async (field, e) => {
    let obj = {
      active: 1
      // and: [{
      //   or: [
      //     { tierType: { like: `%${e.target.value}%` } },
      //   ],
      // }],
    };
    // if (e.target.value.toString().length > 2) {
    await fetchMethod(getTier, { where: obj })
      .then((resp) => resp.json())
      .then((resp) => {
        resp?.data?.allTiertypes
          ? this.setState({
              allTierList: resp.data.allTiertypes.Tiertypes.map((item) => {
                return { id: item.id, name: item.tierType };
              })
            })
          : this.setState({ loader: false });
      })
      .catch((error) => {
        this.setState({ allTierList: [] });
        swal({ title: error.message, icon: "warning" });
      });
    // }
  };

  handleChangeCategory = async (field, e) => {
    let obj = {
      isActive: 1,
      createdBy: { inq: this.state.allAdminId }
      // and: [{
      //   or: [
      //     { categoryName: { like: `%${e.target.value}%` } },
      //   ],
      // }],
    };
    if (this.state.typeId) {
      obj.tierId = parseFloat(this.state.typeId.id);
    }
    // if (e.target.value.toString().length > 2) {
    await fetchMethod(getItemCategory, { where: obj })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          allCategoryList:
            res.data?.allMasterItemCategories?.MasterItemCategories?.map(
              (i) => {
                return {
                  id: i.id !== null ? i.id : "",
                  name:
                    i.categoryName !== null
                      ? this.capitalizeFirstLetter(i.categoryName)
                      : ""
                };
              }
            )
        });
      })
      .catch((error) => {
        this.setState({ allCategoryList: [] });
        swal({ title: error.message, icon: "warning" });
      });
    // }
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  handleChangeValidation = (fieldname, e) => {
    let errorsT = {};
    let formIsValid = true;
    let fieldValue = e ? (e.target ? e.target.value : e) : e;
    if (fieldname === "participantId") {
      if (!fieldValue) {
        formIsValid = false;
        errorsT["participantId"] = "Please select participant";
      }
    }
    if (fieldname === "walletId") {
      if (!fieldValue) {
        formIsValid = false;
        errorsT["walletId"] = "Please select wallet";
      }
    }
    if (fieldname === "typeId") {
      if (!fieldValue) {
        formIsValid = false;
        errorsT["typeId"] = "Please select type";
      }
    }
    if (fieldname === "categoryId" || fieldname === "newcategory") {
      if (!fieldValue) {
        formIsValid = false;
        errorsT["categoryId"] = "Please select category";
      }
    }
    if (fieldname === "budgetAllocated") {
      if (!fieldValue) {
        formIsValid = false;
        errorsT["budgetAllocated"] = "Please enter budget allocated";
      }
      if (fieldValue) {
        if (isNaN(fieldValue) !== false) {
          formIsValid = false;
          errorsT["budgetAllocated"] =
            "Fund allocated should be contain only numeric value and decimal";
        }
        if (parseFloat(fieldValue) <= 0) {
          formIsValid = false;
          errorsT["budgetAllocated"] =
            "Budget allocated should be greater than 0.";
        }
        console.log(this.state.walletId.balance);
        if (parseFloat(fieldValue) > parseFloat(this.state.walletId.balance)) {
          formIsValid = false;
          errorsT["budgetAllocated"] =
            "Fund allocated should be less than card limit";
        }
        if (fieldValue.includes(".")) {
          var value = fieldValue;
          var length =
            value.toString().length - (value.toString().indexOf(".") + 1);
          if (parseFloat(length) > 2) {
            formIsValid = false;
            errorsT["budgetAllocated"] =
              "Only 2 digit allowed after decimal point";
          }
        }
      }
    }
    if (fieldname === "storeId") {
      if (fieldValue.length <= 0) {
        formIsValid = false;
        errorsT["storeId"] = "Please select store";
      }
    }
    this.setState({ errors: errorsT });
    return { formIsValid, fieldValue };
  };

  getCardLimitQuery = (id) => {
    fetchMethod(getCardLimitById, { where: { userId: parseFloat(id) } })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.allCardDetails?.CardDetails?.length > 0) {
          this.setState({
            cardLimit: res?.data?.allCardDetails?.CardDetails?.[0]?.cardLimit,
            cardNumber: res?.data?.allCardDetails?.CardDetails?.[0]?.cardNumber
          });
        } else {
          this.setState({ cardLimit: 0 });
        }
      })
      .catch((err) => {
        this.setState({ cardLimit: 0 });
        swal({ title: err.message, icon: "warning" });
      });
  };

  handleChange = (field, e) => {
    const { fieldValue } = this.handleChangeValidation(field, e);
    let fieldsT = this.state;
    fieldsT[field] = fieldValue;
    this.setState({ fieldsT });
    if (field === "participantId") {
      if (e.target.value) {
        //this.getCardLimitQuery(e.target.value.id);
        this.getAuthData(e.target.value.id);
        this.getParticipantWallets(e.target.value.id);
      } else {
        this.setState({
          walletId: null,
          budgetAllocated: null
        });
      }
    }
    if (field === "walletId" && e.target.value) {
      this.getSupporterData(e.target.value.id);
      this.setState({
        cardLimit: e.target.value.balance
      });
    }
    if (field === "typeId" && e.target.value != "") {
      this.setState({ categoryId: "", newcategory: "" });
      this.handleChangeCategory();
    } else if (field == "typeId" && e.target.value == "") {
      this.setState({ allCategoryList: [] });
    }
  };

  handleCheck = (name, e) => {
    this.setState({ [name]: e.target.checked });
  };

  handleCheckBox = (event, e) => {
    if (event.target.checked) {
      const storeId = [...this.state.storeId];
      storeId.push(e);
      this.setState({ storeId });
    } else {
      const temp = [...this.state.storeId];
      const storeId = temp.filter((item) => {
        return item ? item.id !== e.id : "";
      });
      this.setState({ storeId });
    }
  };

  handleValidation = () => {
    let errorsT = {};
    let formIsValid = true;
    if (!this.state.participantId) {
      formIsValid = false;
      errorsT["participantId"] = "Please select participant";
    }
    if (!this.state.walletId) {
      formIsValid = false;
      errorsT["walletId"] = "Please select wallet";
    }
    if (!this.state.typeId) {
      formIsValid = false;
      errorsT["typeId"] = "Please select type";
    }
    if (!this.state.categoryId && !this.state.newcategory) {
      formIsValid = false;
      errorsT["categoryId"] = "Please select category";
    }
    if (!this.state.budgetAllocated) {
      formIsValid = false;
      errorsT["budgetAllocated"] = "Please enter budget allocated";
    }
    if (this.state.budgetAllocated) {
      if (isNaN(this.state.budgetAllocated) !== false) {
        formIsValid = false;
        errorsT["budgetAllocated"] =
          "Fund allocated should be contain only numeric value and decimal";
      }
      if (parseFloat(this.state.budgetAllocated) <= 0) {
        formIsValid = false;
        errorsT["budgetAllocated"] =
          "Budget allocated should be greater than 0.";
      }
      if (
        parseFloat(this.state.budgetAllocated) >
        parseFloat(this.state.cardLimit)
      ) {
        formIsValid = false;
        errorsT["budgetAllocated"] =
          "Fund allocated should be less than card limit";
      }
      if (this.state.budgetAllocated.includes(".")) {
        var value = this.state.budgetAllocated;
        var length =
          value.toString().length - (value.toString().indexOf(".") + 1);
        if (parseFloat(length) > 2) {
          formIsValid = false;
          errorsT["budgetAllocated"] =
            "Only 2 digit allowed after decimal point";
        }
      }
    } else {
      formIsValid = false;
      errorsT["budgetAllocated"] = "Please enter budget allocated";
    }
    if (this.state.storeId.length <= 0) {
      formIsValid = false;
      errorsT["storeId"] = "Please select store";
    }
    this.setState({ errors: errorsT });
    return formIsValid;
  };

  saveBudgetData = async (variables) => {
    await fetchMethod(saveBudget, variables)
      .then((res) => res.json())
      .then((res) => {
        const id = res?.data?.saveBudget?.id || null;
        if (id) {
          if (this.state.storeId.length > 0) {
            let newQuery = "";
            this.state.storeId.map((item, index) => {
              return (newQuery += `id${index}: saveBudgetStoreMapping(
  obj: {
    budgetid: ${parseFloat(id)},
    storeid: ${parseFloat(item.id)},
    active:${1},
    createdby:${parseFloat(JSON.parse(localStorage.getItem("userInfo")).id)},
    updatedby:${parseFloat(
      JSON.parse(localStorage.getItem("userInfo")).id
    )}               
  }
){id}`);
            });
            fetchMethod(gql`
mutation {
${newQuery}
}
`)
              .then((res) => res.json())
              .then((res) => {
                if (res.errors) {
                  swal({
                    title: "Error saving",
                    icon: "warning"
                  });
                } else {
                  if (this.state.selectSupportData.length > 0) {
                    let query = "";
                    this.state.selectSupportData.map((item, index) => {
                      return (query += `id${index}: saveBudgetSupporterMapping(
    obj: {
      budgetid: ${parseFloat(id)}
      supporterid: ${parseFloat(item.id)}
      active:${1}
      createdby:${parseFloat(JSON.parse(localStorage.getItem("userInfo")).id)}
      updatedby:${parseFloat(
        JSON.parse(localStorage.getItem("userInfo")).id
      )}               
    }
  ){id}`);
                    });
                    fetchMethod(gql`
  mutation {
    ${query}
  }
`)
                      .then((res) => res.json())
                      .then((res) => {
                        if (res.errors) {
                          swal({
                            title: "Error saving",
                            icon: "warning"
                          });
                        }
                        // else {
                        //   swal({
                        //     title: "Budget Saved successfully",
                        //     icon: "success"
                        //   });
                        //   this.props.history.push("/budget");
                        // }
                      });
                  }
                }
              });
          }
        }
        if (
          this.state.authledgerData == null &&
          res?.data?.saveBudget?.canwithdraw == 1
        ) {
          //this.saveWithdraw(res?.data?.saveBudget?.participantId, res?.data?.saveBudget?.canwithdraw)
        }
        if (
          this.state.authledgerData &&
          res?.data?.saveBudget?.canwithdraw == 0
        ) {
          fetchMethod(participantBudget, {
            where: {
              participantId: parseFloat(this.state.participantId.id),
              active: 1
            }
          })
            .then((res) => res.json())
            .then(async (res) => {
              if (res.data.allBudgets && res.data.allBudgets.Budgets.length) {
                let offwithdraw = false;
                res.data.allBudgets.Budgets.map((item) => {
                  if (item.canwithdraw == 1) {
                    offwithdraw = true;
                    return offwithdraw;
                  }
                });
                if (!offwithdraw) {
                  //this.saveWithdraw(parseFloat(this.state.participantId.id), false)
                }
              }
            });
        }
        this.setState({ loader: false });
        swal({
          title: "Budget Saved successfully",
          icon: "success"
        });
        this.props.history.push("/budget");
      })
      .catch((error) => {
        this.setState({ loader: false });
        swal({ title: error.message, icon: "warning" });
      });
  };

  saveItemCategory = async (itemVar) => {
    return fetchMethod(saveMasterItemCategoryByGuardian, { obj: itemVar })
      .then((res) => res.json())
      .then((response) => {
        if (response?.data?.MasterItemCategoryAddByGuardian?.status === 200) {
          return {
            status: 200,
            data: response.data.MasterItemCategoryAddByGuardian.data
          };
        } else {
          swal(response?.data?.MasterItemCategoryAddByGuardian?.msg, {
            icon: "warning"
          });
        }
      });
  };

  connectToItemToStore = async (storeItemVar) => {
    return fetchMethod(connectItemWithstoreQueryData, storeItemVar)
      .then((res) => res.json())
      .then((response) => {
        if (
          response?.data?.StoreConnectedStoreTypeAddItemCategory?.status === 200
        ) {
          return { status: 200 };
        } else {
          swal(response?.data?.MasterItemCategoryAddByGuardian?.msg, {
            icon: "warning"
          });
        }
      });
  };

  submitHandler = async (e) => {
    e.preventDefault();
    let finalSave = this.handleValidation();
    if (finalSave) {
      this.setState({ loader: true });
      fetchMethod(budgetTotalData, {
        where: {
          participantId: parseFloat(this.state.participantId.id),
          itemCategoryId: parseFloat(this.state.categoryId.id),
          active: 1
        }
      })
        .then((res) => res.json())
        .then(async (res) => {
          if (res.data.allBudgets && res.data.allBudgets.Budgets.length) {
            this.setState({ loader: false });
            swal("Budget already exists for this category", { icon: "error" });
            return false;
          } else {
            if (finalSave) {
              if (this.state.categoryId == "" && this.state.newcategory != "") {
                let itemVariable = {
                  tierId: this.state.typeId
                    ? parseFloat(this.state.typeId.id)
                    : null,
                  createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
                  isActive: 1,
                  allCreatedBy: this.state.allAdminId.toString(),
                  categoryName: this.state.newcategory
                    ? this.state.newcategory.name
                    : null
                };
                const SaveItem = await this.saveItemCategory(itemVariable);
                let BudgetVariables = {
                  obj: {
                    participantId: this.state.participantId
                      ? parseInt(this.state.participantId.id)
                      : null,
                    walletId: this.state.walletId
                      ? parseInt(this.state.walletId.id)
                      : null,
                    tierid: this.state.typeId
                      ? parseFloat(this.state.typeId.id)
                      : null,
                    itemCategoryId:
                      SaveItem.status == 200
                        ? parseFloat(SaveItem.data.id)
                        : null,
                    budgetAvailable: this.state.budgetAllocated
                      ? parseFloat(this.state.budgetAllocated)
                      : 0,
                    active: this.state.status ? 1 : 0,
                    isshared: this.state.isshared ? 1 : 0,
                    canwithdraw: this.state.canwithdraw ? 1 : 0,
                    alwaysavailablebudget: this.state.alwaysavailablebudget
                      ? 1
                      : 0,
                    createdBy: parseFloat(
                      JSON.parse(localStorage.getItem("userInfo")).id
                    ),
                    updatedBy: parseFloat(
                      JSON.parse(localStorage.getItem("userInfo")).id
                    )
                  }
                };
                let addItemWithStoreAfteraddItem = {
                  storeId: this.state.storeId,
                  item_category_id:
                    SaveItem.status == 200
                      ? parseFloat(SaveItem.data.id)
                      : null,
                  participantid: this.state.participantId
                    ? parseFloat(this.state.participantId.id)
                    : null,
                  createdBy: parseFloat(
                    JSON.parse(localStorage.getItem("userInfo")).id
                  ),
                  updatedBy: parseFloat(
                    JSON.parse(localStorage.getItem("userInfo")).id
                  )
                };
                this.connectToItemToStore(addItemWithStoreAfteraddItem);
                const Savedata = await this.saveBudgetData(BudgetVariables);
              } else {
                let variables = {
                  obj: {
                    participantId: this.state.participantId
                      ? parseFloat(this.state.participantId.id)
                      : null,
                    walletId: this.state.walletId
                      ? parseInt(this.state.walletId.id)
                      : null,
                    tierid: this.state.typeId
                      ? parseInt(this.state.typeId.id)
                      : null,
                    itemCategoryId: this.state.categoryId
                      ? parseFloat(this.state.categoryId.id)
                      : null,
                    budgetAvailable: this.state.budgetAllocated
                      ? parseFloat(this.state.budgetAllocated)
                      : 0,
                    active: this.state.status ? 1 : 0,
                    isshared: this.state.isshared ? 1 : 0,
                    canwithdraw: this.state.canwithdraw ? 1 : 0,
                    alwaysavailablebudget: this.state.alwaysavailablebudget
                      ? 1
                      : 0,
                    createdBy: parseFloat(
                      JSON.parse(localStorage.getItem("userInfo")).id
                    ),
                    updatedBy: parseFloat(
                      JSON.parse(localStorage.getItem("userInfo")).id
                    )
                  }
                };
                let addItemWithStoreAfteraddItemData = {
                  storeId: this.state.storeId,
                  item_category_id: this.state.categoryId
                    ? parseFloat(this.state.categoryId.id)
                    : null,
                  participantid: this.state.participantId
                    ? parseFloat(this.state.participantId.id)
                    : null,
                  createdBy: parseFloat(
                    JSON.parse(localStorage.getItem("userInfo")).id
                  ),
                  updatedBy: parseFloat(
                    JSON.parse(localStorage.getItem("userInfo")).id
                  )
                };
                this.connectToItemToStore(addItemWithStoreAfteraddItemData);
                const Savedata = await this.saveBudgetData(variables);
              }
            }
          }
        });
    }
  };

  selectData = (item, index) => {
    if (item === "right") {
      let data = this.state.selectSupportData;
      if (data[index].selected) {
        delete data[index].selected;
      } else {
        data[index]["selected"] = true;
      }
      this.setState({ selectSupportData: data });
    } else if (item === "left") {
      let data = this.state.selectedSupportData;
      if (data[index].selected) {
        delete data[index].selected;
      } else {
        data[index]["selected"] = true;
      }
      this.setState({ selectedSupportData: data });
    }
  };

  addSupportData = () => {
    let supportData = this.state.selectSupportData;
    let checkData = false;
    let data =
      this?.state?.selectedSupportData?.length > 0
        ? this?.state?.selectedSupportData?.map((item, index) => {
            if (item.selected) {
              let selectData = supportData.filter(
                (newData) => newData.id === item.id
              );
              if (selectData?.length > 0) {
                checkData = true;
                swal({
                  title:
                    "You have already selected this supporter, Please remove it first",
                  icon: "warning"
                });
              } else {
                delete item.selected;
                supportData.push(item);
              }
            }
          })
        : "";
    let newData =
      this?.state?.selectedSupportData?.length > 0
        ? this?.state?.selectedSupportData?.filter(
            (item, index) =>
              !supportData.find((subItem) => item.id == subItem.id)
          )
        : "";
    if (!checkData) {
      const updatedArray = this.state.selectSupportData.filter(
        (value) => value.name != null
      );
      updatedArray.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      this.setState({
        selectSupportData: updatedArray,
        selectedSupportData: newData
      });
    }
  };

  removeSupportData = () => {
    let supportData =
      this.state.selectedSupportData != ""
        ? this.state.selectedSupportData
        : [];
    let checkData = false;
    let data =
      this?.state?.selectSupportData?.length > 0
        ? this?.state?.selectSupportData?.map((item, index) => {
            if (item.selected) {
              let selectData =
                supportData != "" || supportData.length != 0
                  ? supportData.filter((newData) => newData.id === item.id)
                  : [];
              if (selectData?.length > 0) {
                checkData = true;
                swal({
                  title:
                    "You have already selected this supporter, Please remove it first",
                  icon: "warning"
                });
              } else {
                delete item.selected;
                supportData.push(item);
              }
            }
          })
        : "";
    let newData =
      this?.state?.selectSupportData?.length > 0
        ? this?.state?.selectSupportData?.filter(
            (item, index) =>
              !supportData.find(
                (subItem) => item.id == subItem.id && !item.selected
              )
          )
        : [];
    if (!checkData) {
      this.setState(
        { selectedSupportData: supportData, selectSupportData: newData },
        () => {
          const updatedArray = this.state.selectSupportData.filter(
            (value) => value.name != null
          );
          updatedArray.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );

          // this.state.selectSupportData.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          this.setState({ selectSupportData: updatedArray });
        }
      );
    }
  };

  handleStoreValidation = (field, e) => {
    let errorsT = {};
    let fieldValue = e;
    if (field === "storeId") {
      if (fieldValue.length <= 0) {
        errorsT["storeId"] = "Please select store";
      }
    }
    this.setState({ errors: errorsT });
    return { fieldValue };
  };

  handleStoreSelection = (field, e) => {
    const { fieldValue } = this.handleStoreValidation(field, e);
    this.setState({ storeId: fieldValue });
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleSearchChange = (e) => {
    e.preventDefault();

    if (this.state.walletId != "" && this.state.walletId != undefined) {
      if (e.target.value.length != 0 && e.target.value != null) {
        this.setState({
          search: e.target.value
        });

        let newSelectedSupporterArray = [];
        this.state.selectSupportData.map((item) => {
          if (item.name != null) {
            if (
              item.name
                .toLowerCase()
                .includes(`${e.target.value.toLowerCase()}`)
            ) {
              newSelectedSupporterArray.push(item);
            }
          }
        });
        this.setState({
          selectSupportData: newSelectedSupporterArray
        });
      } else {
        this.getSupporterData(this.state.walletId.id);
        this.setState({
          search: ""
        });
      }
    } else {
      swal({
        title: "Please Select participent.",
        icon: "error"
      });
    }
    // const { filter } = this.state;
    // let Search = e.target.value ? e.target.value.trim().replace(/\s\s+/g, "") : "";
    // Search = Search.split(" ");
    // const like1 = "%" + `${Search[0]}` + "%";
    // const like2 = "%" + `${Search[1]}` + "%";

    // let filt = {
    //   or: [
    //     { firstname: { like: like1, options: "i" } },
    //     { lastname: { like: like2, options: "i" } },
    //     { lastname: { like: like1, options: "i" } },
    //   ],
    // };
    // filter["and"] = [filt];

    // // this.setState({ listData: undefined });
    // this.setState({
    //   search: e.target.value,
    //   filter,
    //   pageNo: this.state.rows,
    // });
    // this.debounceTimer = this.debounce(
    //   this.debounceTimer,
    //   () => {
    //     this.getSupporterData();
    //   },
    //   200
    // );
  };

  saveWithdraw = (id, toggle) => {
    const Obj = {
      obj: {
        id: this.state.authledgerData ? this.state.authledgerData?.id : 0,
        ppan: this.state.cardNumber,
        userid: id,
        canwithdraw: toggle ? 1 : 0,
        withdrawfrom: "budget"
      }
    };
    fetchMethod(saveAuthledger, Obj)
      .then((response) => response.json())
      .then((res) => {})
      .catch((e) => {});
  };

  render() {
    return (
      <div className="container addbudgetSection">
        <h2> Add Budgets</h2>
        <div className="addBudgetForm">
          <div className="participantList">
            <label>
              Participant <span style={{ color: "red" }}>*</span>
            </label>
            <Autocomplete
              id="combo-box-participant"
              freeSolo={true}
              sx={{ width: 300 }}
              options={this.state.allParticipantList}
              getOptionLabel={(option) => (option.name ? option.name : option)}
              onChange={(e, data) => {
                this.handleChange("participantId", {
                  target: { value: data ? data : "" }
                });
              }}
              value={this.state.participantId || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Type to search"
                  fullWidth
                />
              )}
            />
            <div
              style={{ color: "red", textAlign: "left" }}
              className="errorField"
            >
              {this.state.errors["participantId"]}
            </div>
          </div>
          <div className="participantList">
            <label>
              Wallet <span style={{ color: "red" }}>*</span>
            </label>
            <Autocomplete
              id="combo-box-wallet"
              freeSolo={true}
              sx={{ width: 300 }}
              options={this.state.walletData}
              getOptionLabel={(option) => (option.name ? option.name : option)}
              value={this.state.walletId || ""}
              onChange={(e, data) => {
                this.handleChange("walletId", {
                  target: { value: data ? data : "" }
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Type to search"
                  fullWidth
                />
              )}
            />
            <div
              style={{ color: "red", textAlign: "left" }}
              className="errorField"
            >
              {this.state.errors["walletId"]}
            </div>
          </div>
          <h4>Item category</h4>
          <div className="itemCategorySection">
            <div className="autocomplete-class">
              <label>
                Type <span style={{ color: "red" }}>*</span>
              </label>
              <Autocomplete
                id="combo-box-demo"
                freeSolo={false}
                options={this.state.allTierList}
                getOptionLabel={(option) =>
                  option.name ? option.name : option
                }
                sx={{ width: 300 }}
                onChange={(e, data) => {
                  this.handleChange("typeId", {
                    target: { value: data ? data : "" }
                  });
                }}
                value={this.state.typeId || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type to search"
                    fullWidth
                  />
                )}
              />
              <div
                style={{ color: "red", textAlign: "left" }}
                className="errorField"
              >
                {this.state.errors["typeId"]}
              </div>
            </div>
            <div className="autocomplete-class">
              <label>
                Category <span style={{ color: "red" }}>*</span>
              </label>

              <Autocomplete
                id="combo-box-demo"
                freeSolo={true}
                options={this.state.allCategoryList}
                getOptionLabel={(option) =>
                  option.name ? option.name : option
                }
                onChange={(e, data) => {
                  this.handleChange("categoryId", {
                    target: { value: data ? data : "" }
                  });
                }}
                sx={{ width: 300 }}
                value={this.state.categoryId || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Type to search"
                    onChange={(e, data) => {
                      this.handleChange("newcategory", {
                        target: {
                          value: data ? data : { id: 0, name: e.target.value }
                        }
                      });
                    }}
                    fullWidth
                  />
                )}
              />
              <div
                style={{ color: "red", textAlign: "left" }}
                className="errorField"
              >
                {this.state.errors["categoryId"]}
              </div>
            </div>
            <div className="inputField">
              <label>
                Budget Allocated <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                variant="outlined"
                autoComplete="new-name"
                style={{ width: "100%", marginTop: "1px" }}
                // label={"Budget Available"}
                name={"budgetAllocated"}
                onChange={(e) => {
                  this.handleChange("budgetAllocated", e);
                }}
                disabled={
                  this.state.participantId && this.state.walletId ? false : true
                }
                placeholder="Enter budget allocated"
                margin="normal"
                value={this?.state?.budgetAllocated || ""}
              />
              <div
                style={{ color: "red", textAlign: "left" }}
                className="errorField"
              >
                {this.state.errors["budgetAllocated"]}
              </div>
            </div>
            <div className="fieldBox">
              <label>Status</label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  onChange={(e) => this.handleChange("status", e)}
                  labelId="status"
                  id="status"
                  name="status"
                  value={this.state.status}
                  placeholder="Select a status"
                  label="Select"
                  displayEmpty
                  disabled
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <h4 className="storeText">
            Store selection<span style={{ color: "red" }}>*</span>
          </h4>
          <MultiSelect
            items={this.state.storeData}
            selectedStore={this.state.storeId ? this.state.storeId : []}
            getOptionLabel={(option) => option.name}
            label="Select Stores"
            placeholder="Type to search store"
            // selectAllLabel="Select all"
            onChange={(e) => this.handleStoreSelection("storeId", e)}
          />
          {this?.state?.errors ? (
            <div
              style={{ color: "red", textAlign: "left" }}
              className="errorField"
            >
              {this.state.errors["storeId"]}
            </div>
          ) : (
            ""
          )}
          <div className="storeData">
            {/* <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-mutiple-checkbox-label">
                All Store
              </InputLabel>
              <Select
                value={
                  this.state.storeId ? this.state.storeId : []
                }
                name="storeId"
                multiple
                input={<Input />}
                renderValue={selected => {
                  return selected
                    ? selected.map((item, index) => {
                      if (index + 1 === selected.length)
                        return item && item.name ? item.name : "";
                      else {
                        return item && item.name
                          ? item.name.concat(", ")
                          : "";
                      }
                    })
                    : "";
                }}
                MenuProps={MenuProps}
              >
                {this.state.storeData
                  ? this.state.storeData.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item}
                        className="storeDataItem"
                      >
                        <Checkbox
                          checked={
                            this.state.storeId
                              .map(item1 => {
                                return item1 ? item1.id : 0;
                              })
                              .indexOf(item.id) > -1
                          }
                          onChange={e => this.handleCheckBox(e, item)}
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    );
                  })
                  : ""}
              </Select>
            </FormControl> */}
          </div>
          <h4 className="supporterText">Supporters</h4>
          <div className="supporterSearch">
            <TextField
              className="bh-form-control sm no-lt-border"
              id={"search"}
              placeholder={"Search Supporters"}
              type="search"
              variant="outlined"
              fullWidth
              name={"search"}
              value={this?.state?.search || ""}
              onChange={this.handleSearchChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button edge="end">
                      <SearchIcon />
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="supporterData">
            <div className="selectData">
              <label>Select Supporters</label>
              <div className="selectSupportData">
                {this?.state?.selectedSupportData?.length > 0
                  ? this.state.selectedSupportData.map((item, index) => {
                      return (
                        <span
                          className={item.selected ? "selected" : ""}
                          onClick={() => this.selectData("left", index)}
                        >
                          {item.name}
                        </span>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="button">
              <Button onClick={() => this.addSupportData()}>Add</Button>
              <Button onClick={() => this.removeSupportData()}>Remove</Button>
            </div>
            <div className="selectData">
              <label>Selected Supporters</label>
              <div className="selectSupportData selectedData">
                {this?.state?.selectSupportData?.length > 0
                  ? this.state.selectSupportData.map((item, index) => {
                      return (
                        <span
                          className={item.selected ? "selected" : ""}
                          onClick={() => this.selectData("right", index)}
                        >
                          {item.name}
                        </span>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          <div className="issharedToggle">
            <FormControlLabel
              control={
                <Switch
                  name="isshared"
                  checked={this.state.isshared}
                  onChange={(e) => {
                    this.handleCheck("isshared", e);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={"Share the budget with Supporters"}
            />
          </div>
          <div>
            {(this.state.authledgerData &&
              this.state.authledgerData.canwithdraw === 1 &&
              this.state.authledgerData.withdrawfrom === "budget") ||
            this.state.authledgerData == null ? (
              <div className="issharedToggle">
                <FormControlLabel
                  control={
                    <Switch
                      name="canwithdraw"
                      checked={this.state.canwithdraw}
                      onChange={(e) => {
                        this.handleCheck("canwithdraw", e);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={"Withdraw cash with budget"}
                />
              </div>
            ) : (
              <div className="issharedToggle">
                <FormControlLabel
                  control={
                    <Switch
                      name="canwithdraw"
                      checked={this.state.canwithdraw}
                      onChange={(e) => {
                        this.handleCheck("canwithdraw", e);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={"Withdraw cash with budget"}
                  disabled
                />
              </div>
            )}
          </div>
          {/* <div className="issharedToggle">
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={"Withdraw cash with budget"}
                  disabled
                />
              </div>
            )}
          </div>
              }<div className="issharedToggle">
            <FormControlLabel
              control={
                <Switch
                  name="alwaysavailablebudget"
                  checked={this.state.alwaysavailablebudget || false}
                  onChange={(e) => {
                    this.handleCheck("alwaysavailablebudget", e);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Repeat Pay"
            />
          </div> */}
        </div>
        <div className="btnComponent">
          <Button className="addBdgtBtn" onClick={(e) => this.submitHandler(e)}>
            Add Budget
          </Button>
        </div>
        {this.state.loader ? (
          <div className="spinner">
            <img src={Loader} alt="loader" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default withRouter(AddGuardianBudget);
