import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  MenuItem,
  Select
} from "@mui/material";
import Button from "@material-ui/core/Button";
import MuiButton from "@mui/material/Button";
import React from "react";
import "./actiondropdown.scss";
import { adminBudgetList } from "../../Budget/AminBudgetConfig";
import ReactTableComponent from "../../../ReactTable/ReactTable";
import { fetchMethod } from "../../../FetchMethod";
import { budgetQuery } from "../../Budget/BudgetQuery";
import swal from "sweetalert";
import { formatDate } from "../../../utils/dateformat";
import FundAllocationTableInput from "./FundAllocationTableInput";
import { withRouter } from "react-router-dom";
import { addPaymentTypes } from "./FundAllocationConfig";
import UserInfo from "./UserInfo";
import updateFundService from "src/network/service/updateFundService";
import { DotLoader } from "react-spinners";
import { addEditFunds } from "../ParticipantQuery";

const FUND_INPUT_ERROR = "Amount can't be negative or zero";
class FundAllocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      listData: [],
      count: 0,
      filter: {
        active: 1,
        order: "id desc",
        participantId: this.props.match.params?.participantId
      },
      loading: false,
      fundInputValue: 0,
      fundInputError: null,
      showBudgetTable: false,
      addedFund: 0,
      initiallyAddedFund: 0,
      disableTableInput: false,
      allocationMap: new Map(),
      isAddFund: false,
      useWholeBalance: false,
      repeatPayAmount: null,
      paymentType: null,
      loadingOnSubmit: false
    };
  }

  componentDidMount() {
    // this.getBudgetData();
  }

  onFundInputChange = (event) => {
    let targetValue = parseFloat(event.target.value);
    if (isNaN(targetValue)) targetValue = 0;
    if (targetValue < 0) {
      this.setState({
        fundInputError: FUND_INPUT_ERROR
      });
    } else {
      this.setState({ fundInputValue: targetValue, fundInputError: null });
    }
  };
  handleAmountFormSubmit = async (event) => {
    event.preventDefault();
    if (this.state.fundInputError || this.state.fundInputValue === 0) {
      if (this.state.fundInputValue === 0) {
        this.setState({
          fundInputError: FUND_INPUT_ERROR
        });
      }
      return;
    }
    if (this.state.showBudgetTable) {
      this.setState({
        allocationMap: new Map()
      });
      await this.setState({
        showBudgetTable: false
      });
    }
    await this.setState({
      showBudgetTable: true,
      addedFund: this.state.fundInputValue,
      initiallyAddedFund: this.state.fundInputValue,
      disableTableInput: false
    });
    this.submitData();
  };

  handlePaymentTypeChange = (event) => {
    this.setState({
      paymentType: event.target.value
    });
  };

  submitData = async () => {
    const userId = parseInt(this.props.match.params?.participantId);
    if (isNaN(userId)) return;
    let updateRepeatPay = null;
    if (this.state.useWholeBalance) {
      updateRepeatPay = {
        isRepeatPay: true,
        userId,
        amountToSet: null,
        useWholeBalance: true
      };
    } else if (this.state.repeatPayAmount) {
      updateRepeatPay = {
        isRepeatPay: true,
        userId,
        amountToSet: this.state.repeatPayAmount
      };
    }
    const budgetAmountMapping = [];
    this.state.allocationMap.forEach((value, key) =>
      budgetAmountMapping.push({
        budgetId: key,
        amount: value
      })
    );
    const dataObj = {
      userId,
      amount: parseFloat(this.state.initiallyAddedFund),
      budgetAmountMapping,
      updateRepeatPay,
      paymentType: this.state.paymentType
    };
    this.setState({ loadingOnSubmit: true });
    const createdby = await JSON.parse(localStorage.getItem("userInfo")).id;
    fetchMethod(addEditFunds, {
      walletId: this.props.walletId,
      amount: `${dataObj.amount}`,
      description: dataObj.paymentType,
      flag: 1,
      createdby,
      userid: userId
    })
      .then((resp) => resp.json())
      .then((response) => {
        if (response?.errors) {
          throw Error("something went wrong");
        }
        swal({
          title: `fund is added`,
          icon: "success"
        });
        this.setState({
          fundInputValue: 0,
          fundInputError: null,
          showBudgetTable: false,
          addedFund: 0,
          initiallyAddedFund: 0,
          disableTableInput: false,
          allocationMap: new Map(),
          isAddFund: false,
          useWholeBalance: false,
          repeatPayAmount: null,
          paymentType: null,
          loadingOnSubmit: false
        });
      })
      .catch((error) => {
        swal({
          title: error.message,
          icon: "error"
        });
        this.setState({ loadingOnSubmit: false });
      });
  };

  getBudgetData = () => {
    this.setState({
      loading: true
    });
    fetchMethod(budgetQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => {
        this.setState({
          loading: false
        });
        return res.json();
      })
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          res.data.allBudgets.Budgets.map((item) => {
            return (
              (item["budgetAvailable"] =
                item.budgetAvailable !== null && item.budgetAvailable !== ""
                  ? "$" + item.budgetAvailable
                  : 0),
              (item["userName"] =
                item &&
                item.fkBudgetParticipantIdrel &&
                item.fkBudgetParticipantIdrel.Userdata &&
                item.fkBudgetParticipantIdrel.Userdata[0]
                  ? item.fkBudgetParticipantIdrel.Userdata[0].firstname +
                    " " +
                    (item.fkBudgetParticipantIdrel.Userdata[0].lastname !== null
                      ? item.fkBudgetParticipantIdrel.Userdata[0].lastname
                      : "")
                  : item &&
                    item.fkSupporterIdBudgetMaprel &&
                    item.fkSupporterIdBudgetMaprel.Userdata &&
                    item.fkSupporterIdBudgetMaprel.Userdata[0]
                  ? item.fkSupporterIdBudgetMaprel.Userdata[0].firstname +
                    " " +
                    (item.fkSupporterIdBudgetMaprel.Userdata[0].lastname !==
                    null
                      ? item.fkSupporterIdBudgetMaprel.Userdata[0].lastname
                      : "")
                  : item &&
                    item.fkBusinessIdBudgetMaprel &&
                    item.fkBusinessIdBudgetMaprel.Businesses &&
                    item.fkBusinessIdBudgetMaprel.Businesses[0] &&
                    item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                  ? item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                  : ""),
              (item["userType"] =
                item &&
                item.fkBudgetParticipantIdrel &&
                item.fkBudgetParticipantIdrel.Userdata &&
                item.fkBudgetParticipantIdrel.Userdata[0] &&
                item.fkBudgetParticipantIdrel.Userdata[0].role
                  ? item.fkBudgetParticipantIdrel.Userdata[0].role
                  : item &&
                    item.fkSupporterIdBudgetMaprel &&
                    item.fkSupporterIdBudgetMaprel.Userdata &&
                    item.fkSupporterIdBudgetMaprel.Userdata[0] &&
                    item.fkSupporterIdBudgetMaprel.Userdata[0].role
                  ? item.fkSupporterIdBudgetMaprel.Userdata[0].role
                  : "BUSINESS"),
              (item["itemCategory"] =
                item !== null &&
                item.fkBudgetItemCategoryIdrel != null &&
                item.fkBudgetItemCategoryIdrel &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                  .categoryName
                  ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName
                      .charAt(0)
                      .toUpperCase() +
                    item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName.slice(
                      1
                    )
                  : ""),
              (item["status"] =
                item !== null &&
                item.fkBudgetItemCategoryIdrel != null &&
                item.fkBudgetItemCategoryIdrel &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].isActive
                  ? "ACTIVE"
                  : "INACTIVE"),
              (item["createdAt"] =
                item != null && item.createdAt
                  ? formatDate(item.createdAt)
                  : ""),
              (item["tier"] =
                item !== null &&
                item.fkBudgetItemCategoryIdrel != null &&
                item.fkBudgetItemCategoryIdrel &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                  .fktiertypemaprel &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                  .fktiertypemaprel.Tiertypes &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                  .fktiertypemaprel.Tiertypes[0] &&
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                  .fktiertypemaprel.Tiertypes[0].tierType
                  ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                      .fktiertypemaprel.Tiertypes[0].tierType
                  : "")
            );
          });
          this.setState({
            count:
              res.data && res.data.allBudgets && res.data.allBudgets !== null
                ? res.data.allBudgets.totalCount
                : "",
            listData:
              res.data && res.data.allBudgets && res.data.allBudgets !== null
                ? res.data.allBudgets.Budgets
                : "",
            temp:
              res.data && res.data.allBudgets && res.data.allBudgets !== null
                ? res.data.allBudgets.Budgets
                : ""
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [], loading: false });
      });
  };

  budgetAllocation = (inputAmount, id) => {
    let amount = parseInt(inputAmount);
    if (isNaN(amount)) amount = 0;

    if (this.state.allocationMap.has(id)) {
      const prevAmount = parseInt(this.state.allocationMap.get(id));
      this.state.allocationMap.set(id, amount);
      this.setState({
        addedFund: this.state.addedFund + prevAmount - amount,
        disableTableInput: this.state.addedFund + prevAmount - amount <= 0
      });
    } else {
      this.state.allocationMap.set(id, amount);
      this.setState({
        addedFund: this.state.addedFund - amount,
        disableTableInput: this.state.addedFund - amount <= 0
      });
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.getBudgetData();
      }
    );
  };

  setUseWholeBalance = (value) => this.setState({ useWholeBalance: value });
  setRepeatPayAmount = (value) => this.setState({ repeatPayAmount: value });

  fundAllocationInput = [
    {
      Header: "Allocate Fund",
      sortable: false,
      Cell: (row) => {
        return (
          <FundAllocationTableInput
            handleInputChange={(amount) =>
              this.budgetAllocation(amount, row.original.id)
            }
            disabled={this.state.disableTableInput}
            addedFund={this.state.addedFund}
          />
        );
      },
      width: 120
    }
  ];
  nameColumn = [
    {
      Header: "S No.",
      sortable: false,
      Cell: (row) => {
        return <div className="dot">{row.original.sNo}</div>;
      },
      width: 45
    }
  ];

  render() {
    // const columns = this.nameColumn
    //   .concat(adminBudgetList.columns)
    //   .concat(this.fundAllocationInput);

    if (this.state.loadingOnSubmit) {
      return (
        <div className="spinner">
          <DotLoader size={70} color={"#020f1f"} />
        </div>
      );
    }

    return (
      <>
        <UserInfo
          onAddClick={() => this.setState({ isAddFund: true })}
          showAdd={!this.state.isAddFund}
          useWholeBalance={this.state.useWholeBalance}
          setUseWholeBalance={this.setUseWholeBalance}
          repeatPayAmount={this.state.repeatPayAmount}
          setRepeatPayAmount={this.setRepeatPayAmount}
          addedFund={this.state.initiallyAddedFund}
          walletId={this.props.walletId}
        />
        {this.state.isAddFund && (
          <div className="input-container">
            <form onSubmit={this.handleAmountFormSubmit} className="amountForm">
              <FormControl sx={{ width: "30%" }}>
                <InputLabel
                  htmlFor="outlined-adornment-amount"
                  error={this.state.fundInputError}
                >
                  Amount
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  label="Amount"
                  autoFocus
                  size="small"
                  type="number"
                  inputProps={{
                    step: "0.001"
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  onChange={this.onFundInputChange}
                  error={this.state.fundInputError}
                  required
                />
                <Typography
                  variant="body1"
                  component="p"
                  color="red"
                  className="fundInputError"
                  fontSize={14}
                >
                  {this.state.fundInputError}
                </Typography>
              </FormControl>
              <FormControl sx={{ width: 250 }}>
                <InputLabel>Payment Type</InputLabel>
                <Select
                  value={this.state.paymentType}
                  label="Payment Type"
                  onChange={this.handlePaymentTypeChange}
                  inputAdor
                >
                  <MenuItem value="">None</MenuItem>
                  {addPaymentTypes.map((fundType) => (
                    <MenuItem key={fundType.id} value={fundType.id}>
                      {fundType.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className="w100"
              >
                {/* {this.state.showBudgetTable ? "Change" : "Add"} */}
                Add
              </Button>
              {/* {this.state.showBudgetTable && (
                <MuiButton
                  variant="contained"
                  color="success"
                  className="w100"
                  onClick={this.submitData}
                >
                  Save
                </MuiButton>
              )} */}
            </form>

            {/* {this.state.showBudgetTable && (
              <div>
                <div className="flex spaceBetween">
                  <h2>Budgets</h2>
                  <h6>Available Amount: {this.state.addedFund}</h6>
                </div>
                <div className="budgetTable">
                  <ReactTableComponent
                    listData={this.state.listData}
                    listConfig={adminBudgetList}
                    columns={columns}
                    dataCount={this.state.count}
                    updatePagination={this.updatePagination}
                    initialPage={this.state.pageNo / this.state.rows}
                    onRowClick={() => {}}
                    forSerialNo={{
                      pageNo: this.state.pageNo,
                      pageSize: this.state.rows,
                    }}
                    loading={this.state.loading}
                    resizable={false}
                  />
                </div>
              </div>
            )} */}
          </div>
        )}
      </>
    );
  }
}

export default withRouter(FundAllocation);
