import React, { useEffect, useState } from "react";
import { getImage } from "src/network/service/imageService";

function S3Link({ href, className, children, target }) {
  const [link, setLink] = useState(null);

  useEffect(() => {
    getImage(href)
      .then((resp) => setLink(resp?.data))
      .catch(() => {
        setLink(null);
      });
  }, [href]);

  return (
    link && (
      <a href={link} className={className} target={target}>
        {children}
      </a>
    )
  );
}

export default S3Link;
