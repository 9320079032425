import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Edit from "src/assets/svg/edit.svg";
import { deepCopy } from "src/utils/utils";
import WalletEditDialog from "./WalletEditDialog";
import { fetchWalletDetailsAction } from "./walletSlice";
import { useDispatch } from "react-redux";
import { ACTIVE, INACTIVE } from "src/utils/constants";

function WalletTable({ wallet }) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState();
  const dispatch = useDispatch();

  const editWalletDialog = () => {
    setOpenModal(true);
    setSelectedWallet({
      ...deepCopy(wallet),
      ownerId: wallet.owner?.id,
      participantId: wallet.participant?.id,
      ownerName: wallet.owner?.fullName,
      participantName: wallet.participant?.fullName,
      updatedBy: 1,
      updatedOn: new Date(),
      default: wallet.defaultWallet,
    });
  };

  const handleWalletDialogClose = () => {
    setOpenModal(false);
    setSelectedWallet(null);
  };

  return (
    <>
      <WalletEditDialog
        openModal={openModal}
        handleWalletDialogClose={handleWalletDialogClose}
        selectedWallet={selectedWallet}
        setSelectedWallet={setSelectedWallet}
        onSave={() => dispatch(fetchWalletDetailsAction(wallet.id))}
      />
      <div>
        <div className="flex justify-between align-center mb-10">
          <h6> Wallet Details</h6>
        </div>
        <table>
          <thead>
            <tr>
              <th>W No.</th>
              <th>Description</th>
              <th>Status</th>
              <th>Owner</th>
              <th>Participant</th>
              <th>Balance</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {wallet && (
              <tr key={wallet.id}>
                <td>{wallet.id}</td>
                <td>{wallet.description}</td>
                <td>{wallet.status ? ACTIVE : INACTIVE} </td>
                <td>{wallet.owner?.fullName}</td>
                <td>{wallet.participant?.fullName}</td>
                <td>{wallet.balance != null ? "$" + wallet.balance : ""}</td>
                <td>
                  {
                    <Button
                      className="edit"
                      onClick={(e) => editWalletDialog()}
                    >
                      <img src={Edit} alt="Edit icon" />
                    </Button>
                  }
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default WalletTable;
