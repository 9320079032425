export const addPaymentTypes = [
  {
    id: "DEPOSIT",
    title: "Deposit"
  },
  {
    id: "CREDIT",
    title: "Credit - Balance Adjust"
  },
  // {
  //   id: "debit",
  //   title: "Debit - Balance Adjust"
  // },
  {
    id: "BONUS",
    title: "Bonus/Reward"
  },
  {
    id: "SETTLEMENT",
    title: "Settled transaction missed"
  }
  // {
  //   id: "withdrawal",
  //   title: "Withdrawal"
  // }
];
