import swal from "sweetalert";

function ConfirmationPrompt({
  visible,
  title,
  onConfirm = () => {},
  swalOptions,
  icon = "info"
}) {
  if (visible) {
    swal({
      title,
      icon,
      ...swalOptions
    }).then((result) => {
      if (result) {
        onConfirm();
      }
    });
  }
  return null;
}

export default ConfirmationPrompt;
