import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import {
  authLedgerQuery,
  participantQuery,
  saveUserdata
} from "./ParticipantQuery";
import { CustomAlert } from "../CustomAlert/CustomAlert";
import Warning from "../../assets/svg/warning.svg";
import Search from "../../assets/svg/search.svg";
import UserCard from "./UserCard/UserCard";
import "./participants.scss";
import AddGuardianParticipant from "./AddGuardianParticipant";
import CardPagination from "../../ReactTable/CardPagination";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Loader from "../../assets/Infinity-1s-200px.svg";
class GuardianParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      search: "",
      pageNo: 5,
      rows: 5,
      filter: {
        active: 1,
        role: "PARTICIPANT",
        order: "id asc",
        guardianId: JSON.parse(localStorage.getItem("userInfo")).id
      },
      count: 0,
      showError: false,
      open: false
    };
  }

  componentWillMount() {
    this.getParticipantData();
  }

  componentDidMount() {
    if (localStorage.getItem("addParticipantModal") === "true") {
      this.handleOpenDialog();
    }
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  getParticipantData = () => {
    fetchMethod(participantQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          res.data.allUserdata.Userdata.map(async (item) => {
            return (
              (item["fullAddress"] =
                item.address && item.address !== null && item.address !== ""
                  ? item.address.concat(
                      item.city && item.city !== null ? " " + item.city : ""
                    )
                  : ""),
              (item.dob = this.formatDate(item.dob)),
              (item["cardLimit"] =
                item?.fkUserIdCardDetailMap?.CardDetails[0]?.cardLimit),
              (item.repeatpayamount =
                res.data?.allAuthledgers?.Authledgers[0]?.repeatpayamount)
            );
          });
          this.setState({
            count:
              res.data && res.data.allUserdata && res.data.allUserdata !== null
                ? res.data.allUserdata.totalCount
                : "",
            listData:
              res.data && res.data.allUserdata && res.data.allUserdata !== null
                ? res.data.allUserdata.Userdata
                : ""
          });
        }
      })
      .catch((e) => {
        this.setState({
          showError: true
        });
        // swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });
  };

  updatePagination = (pageNumber, size) => {
    // console.log("asd", pageNumber, size)
    this.setState(
      {
        pageNo: pageNumber,
        rows: size,
        listData: undefined
      },
      () => {
        this.getParticipantData();
      }
    );
  };

  handleDelete = (row) => {
    if (row.active === 1 || row.active === "1") {
      swal({
        title: "Are you sure you really want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          const test = {
            id: row.id,
            active: 0
          };

          fetchMethod(saveUserdata, { obj: test })
            .then((res) => res.json())
            .then((res) => {
              const id = res.data.saveUserdata.id;
              swal({
                title: id ? "Deleted successfully" : "Error deleting",
                icon: "success"
              });
              if (id) {
                let pageNo = this.state.pageNo;
                let previousData = this.state.listData.length;
                this.setState({ listData: undefined });

                const { filter } = this.state;

                let search = this.state.search;

                if (this.state.search !== "" && previousData === 1) {
                  pageNo = this.state.rows;
                  delete filter["and"];
                  search = "";
                } else if (
                  this.state.search === "" &&
                  previousData === 1 &&
                  pageNo !== this.state.rows
                ) {
                  pageNo = this.state.pageNo - this.state.rows;
                } else {
                  pageNo = this.state.pageNo;
                }

                this.setState({ pageNo, filter, search }, () =>
                  this.getParticipantData()
                );
              }
            })
            .catch((e) => {
              swal({ title: e.message, icon: "warning" });
              this.setState({ listData: [] });
            });
        }
      });
    }
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleSearchChange = (event) => {
    const { filter } = this.state;
    filter["and"] = [
      {
        or: [
          { firstname: { like: `%${event.target.value}%` } },
          { lastname: { like: `%${event.target.value}%` } }
        ]
      }
    ];

    // this.setState({ listData: undefined });
    this.setState({
      search: event.target.value,
      filter,
      pageNo: this.state.rows
    });
    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.getParticipantData();
      },
      200
    );
  };

  addParticipant = () => {
    // this.props.history.push("/addParticipants");
    this.handleOpenDialog();
  };

  handleEditModal = (data) => {
    this.handleOpenDialog(data.id);
  };

  onFirstNameClick = (data) => {
    localStorage.setItem("participantInfoData", data.id);
    this.props.history.push({
      pathname: "/newParticipantInfo",
      state: { details: data.id }
    });
  };

  handleClose = (data = "") => {
    if (data) this.getParticipantData();
    this.setState({ ...this.state, open: false });
    localStorage.setItem("addParticipantModal", false);
  };

  handleOpenDialog = (participantId = 0) => {
    this.setState({ ...this.state, open: true, participantId: participantId });
  };

  render() {
    return (
      <>
        {this.state.listData ? (
          <div className="container">
            <CustomAlert
              show={this.state.showError}
              icon={Warning}
              message="Failed to fetch the data, please check your internet connection!"
            ></CustomAlert>
            <div className="participant">
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <h1>Participant</h1>
                  <p>
                    View wallet balance and details or dive into connected
                    wallets to perform a quick audit. Connect the wallet to
                    other businesses
                  </p>
                </div>
                {/* <div className="col-xs-12 col-md-4 flex align-flex-start justify-content-end">
                  <button
                    className="btn btn-primary sm"
                    onClick={() => this.handleOpenDialog()}
                  >
                    Add Participant
                  </button>
                </div> */}
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <hr />
                </div>
                <div className="col-sm-4 col-xs-12">
                  <div className="filters">
                    <div className="bh-form-group">
                      <div className="bh-input-group">
                        <div
                          className="bh-input-group-text no-bg no-rt-border"
                          role="img"
                        >
                          <img src={Search} alt="search icon" />
                        </div>
                        <input
                          type="search"
                          placeholder="Search Participants"
                          value={this.state.search}
                          onChange={this.handleSearchChange}
                          className="bh-form-control sm no-lt-border"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="listing">
                    <div className="row">
                      {this.state.listData.map((item, index) => (
                        <div
                          className="col-lg-2"
                          style={{ cursor: "pointer" }}
                          key={index}
                        >
                          <UserCard
                            key={item.id}
                            pic={item.profileimage}
                            fname={`${item.firstname} ${item.lastname}`}
                            lname={item.lasttname}
                            id={item.id}
                            cardLimit={item.cardBal}
                            repeatpayamount={item.cardBal}
                            onFirstNameClick={() => this.onFirstNameClick(item)}
                            editParticipant={() => this.handleEditModal(item)}
                            deleteParticipant={() => this.handleDelete(item)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <hr />
                  {this.state.listData ? (
                    <CardPagination
                      dataPerPage={this.state.rows}
                      totalData={this.state.count ? this.state.count : ""}
                      paginate={this.updatePagination}
                      initialPage={this.state.pageNo / this.state.rows}
                      dataRange={{
                        first: this.state.pageNo - this.state.rows + 1,
                        last:
                          this.state.pageNo -
                          this.state.rows +
                          this.state.listData.length
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="spinner">
            <img src={Loader} alt="loader" />
          </div>
        )}

        <Drawer
          anchor={"right"}
          open={this.state.open}
          onClose={(data) => this.handleClose(data)}
        >
          <Box sx={{ width: 600 }} role="presentation">
            <AddGuardianParticipant
              onClose={(data) => this.handleClose(data)}
              participantId={this.state.participantId}
            />
          </Box>
        </Drawer>
      </>
    );
  }
}

export default withRouter(GuardianParticipant);
