import React, { Component } from "react";
import "./superLedger.css";
import { superLedgerList } from "./SuperLedgerConfig";
import ReactTableComponent from "../../ReactTable/ReactTable";
import {
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  Typography,
  Input,
  TextField,
  Checkbox
} from "@material-ui/core";
import { DotLoader } from "react-spinners";
import { dropdownQuery, GetUserdataNotification } from "./LedgerQuery";
import { fetchMethod } from "../../FetchMethod";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import swal from "sweetalert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatDate } from "../FormatDate";
import {
  participantQuery,
  businessQuery,
  superLedgerQuery,
  saveLedger,
  updateCardLimit,
  participantQueryData,
  businessQueryData,
  cardData
} from "../SuperLedger/LedgerQuery";
import { CSVLink } from "react-csv";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default class SuperLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      listData_copy: [],
      listData__copy: [],
      allCSVData: [],
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      participantOptions: [],
      Options: [],
      openModal: false,
      isRemoveFund: false,
      participantId: undefined,
      userId: undefined,
      businessId: undefined,
      role: undefined,
      roleselect: undefined,
      userIdSelect: undefined,
      businessIdSelect: undefined,
      selectedDate: undefined,
      amount: 0,
      filter: {
        order: "id desc",
      },
      role1: "",
      id: "",
      userData: [],
      businessData: [],
      selecetedRole: "",
      userxyz: null,
      txnxyz: null,
      Role: [
        {
          role: "PARTICIPANT",
          id: "PARTICIPANT"
        }
        // {
        //   role: "SUPPORTER", id: "SUPPORTER"

        // },
        // {
        //   role: "BUSINESS", id: "BUSINESS"

        // }
      ],
      cardLimit: undefined,
      searchValue: "",
      cardOptions: [],
      cardId: null,
      txnType: "",
      CardType: [
        {
          txnType: "CREDIT",
          id: "CREDIT"
        },
        {
          txnType: "DEBIT",
          id: "DEBIT"
        }
      ],
      selectedRows: [],
      csvForDownload: [],
      bool: false,
      masterCheckboxSelected: false
    };
  }
  componentWillMount() {
    this.getLedgerDetails();
    this.getdropdown();
    this.getAllSuperLedgerData();
    this.getPaticipnatDropDown();
    fetchMethod(dropdownQuery, {
      where: this.state.role
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allUserdata != undefined
          ? this.setState({
              participantOptions: res.data.allUserdata.Userdata.map((item) => {
                return {
                  id: item.id,
                  phonenumber: item.phonenumber,
                  name:
                    item.firstname +
                    " " +
                    (item.lastname != null ? item.lastname : "")
                };
              })
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  }

  getPaticipnatDropDown = () => {
    var obj = { role: "PARTICIPANT", active: 1 };
    if (localStorage.getItem("role") === "GUARDIAN") {
      obj.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
    }
    fetchMethod(participantQueryData, {
      where: obj
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          userData: res.data.allUserdata.Userdata
        });
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      });
  };

  getCardDetails = () => {
    if (this.state.userId) {
      fetchMethod(cardData, {
        where: { userId: this.state.userId }
      })
        .then((res) => res.json())
        .then((res) => {
          res.data.allCardDetails != undefined
            ? this.setState({
                cardOptions: res.data.allCardDetails.CardDetails
              })
            : this.setState({ loading: true });
        })
        .catch((e) => console.log(e));
    } else {
      fetchMethod(cardData, {
        where: { businessId: this.state.businessId }
      })
        .then((res) => res.json())
        .then((res) => {
          res.data.allCardDetails != undefined
            ? this.setState({
                cardOptions: res.data.allCardDetails.CardDetails
              })
            : this.setState({ loading: true });
        })
        .catch((e) => console.log(e));
    }
  };

  getBusinessList = () => {
    fetchMethod(businessQuery, {
      where: { active: 1 }
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allBusinesses != undefined
          ? this.setState({
              Options: res.data.allBusinesses.Businesses.map((item) => {
                return {
                  id: item.id,
                  phonenumber: item.mobileNo,
                  name: item.storeName
                };
              })
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  getdropdown = () => {
    let filterData;
    filterData = { active: 1, role: "PARTICIPANT" };
    fetchMethod(participantQuery, {
      where: filterData
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allUserdata != undefined
          ? this.setState({
              Options: res.data.allUserdata.Userdata.map((item) => {
                return {
                  id: item.id,
                  // phonenumber: item.phonenumber,
                  name:
                    item.firstname +
                    " " +
                    (item.lastname != null ? item.lastname : "")
                };
              })
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  addFunds = () => {
    this.props.history.push("/addFunds");
  };
  handleClose = () => {
    this.setState({
      openModal: false,
      cardId: null,
      userId: undefined,
      amount: 0,
      cardOptions: []
    });
  };

  handleParticipant = (e, v) => {
    this.setState({
      businessId: undefined,
      userId: undefined,
      cardOptions: [],
      cardId: null
    });
    if (v !== null && v !== undefined) {
      this.setState(
        {
          userId: v.id
        },
        () => {
          this.getCardDetails();
        }
      );
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.getLedgerDetails();
      }
    );
  };

  openModalBox = (x) => {
    this.setState({ openModal: true, isRemoveFund: x === "add" ? false : true });
  };

  downloadCSV = () => {
    fetchMethod(superLedgerQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => res.json())
      .then((res) => {
        let totalData = res.data.allSuperLedgers.SuperLedgers;
        console.log("totalData", totalData);
        let totalDataArray = [];
        totalData.forEach((obj, index) => {
          // console.log('jhsdfsdjkfggsjdgfjhjhdfgsjdgf', obj)
          let formatdata = {
            "S No.": index + 1,
            "Participant Name":
              obj.fkuseridsuperledgermaprel !== undefined &&
              obj.fkuseridsuperledgermaprel.Userdata !== ""
                ? obj.fkuseridsuperledgermaprel.Userdata[0].firstname +
                  " " +
                  obj.fkuseridsuperledgermaprel.Userdata[0].lastname
                : "",
            "Amount": "$" + obj.amountAdded || "-",
            "Transaction ID": obj.txnId || "-",
            "Transaction Type": obj.txnType || "-",
            "Transaction By":
              obj.fkcreatebysuperledgermaprel !== undefined &&
              obj.fkcreatebysuperledgermaprel.Userdata !== ""
                ? obj.fkcreatebysuperledgermaprel.Userdata[0].firstname +
                  " " +
                  obj.fkcreatebysuperledgermaprel.Userdata[0].lastname
                : "",
            "Transaction Date": formatDate(obj.createdAt) || "-",
            "PPAN":
              obj.fkcarddetailiimaprel !== undefined &&
              obj.fkcarddetailiimaprel.CardDetails !== ""
                ? obj.fkcarddetailiimaprel.CardDetails[0].cardNumber
                : "",
            "Card Limit": "$" + obj.cardlimit || "-",
            "Item Description":
              obj.fkpaymentmaprel?.PaymentRequests[0]?.extraNotes || "",
            "Store Name":
              obj.fkbusinessidsuperledgermaprel?.Businesses[0]?.storeName ||
              "-",
            "Store City":
              obj.fkbusinessidsuperledgermaprel?.Businesses[0]
                ?.txnLocationCity || "-",
            "Store Country":
              obj.fkbusinessidsuperledgermaprel?.Businesses[0]
                ?.txnLocationCountry || "-",
            "MCC": "N/A",
            "paymentType": obj.paymentType
          };
          totalDataArray.push(formatdata);
        });
        this.setState({
          allCSVData: totalDataArray || []
        });
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ allCSVData: [] });
      });
  };

  getAllSuperLedgerData = () => {
    fetchMethod(superLedgerQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          const newData = res.data.allSuperLedgers.SuperLedgers.map((item) => {
            return {
              status: this.state.selectedRows.includes(item.txnId),
              name: (
                item?.fkcreatebysuperledgermaprel?.Userdata[0]?.firstname +
                " " +
                item?.fkcreatebysuperledgermaprel?.Userdata[0]?.lastname
              ).trim(),
              txnId: item.txnId || "-",
              participantname: (
                item?.fkuseridsuperledgermaprel?.Userdata[0]?.firstname +
                " " +
                item?.fkuseridsuperledgermaprel?.Userdata[0]?.lastname
              ).trim(),
              amountAdded: "$" + item.amountAdded,
              paymentType: item.paymentType,
              txnType: item.txnType,
              cardNumber:
                item?.fkcarddetailiimaprel?.CardDetails[0]?.cardNumber || "-",
              cardLimit: "$" + item.cardlimit,
              createdAt: item.createdAt ? formatDate(item.createdAt) : "-",
              itemDescription:
                item?.fkpaymentmaprel?.PaymentRequests[0]?.extraNotes || "-",
              storeName:
                item?.fkbusinessidsuperledgermaprel?.Businesses[0]?.storeName ||
                "-",
              storeCity:
                item?.fkbusinessidsuperledgermaprel?.Businesses[0]
                  ?.txnLocationCity || "-",
              storeCountry:
                item?.fkbusinessidsuperledgermaprel?.Businesses[0]
                  ?.txnLocationCountry || "-",
              mcc: "N/A",
              walletId: item?.walletId
            };
          });
          this.setState({
            listData_copy: newData || []
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });
  };

  prepareSuperLedgerData = () => {
    fetchMethod(superLedgerQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          const newData = res.data.allSuperLedgers.SuperLedgers.map((item) => {
            return {
              status: this.state.selectedRows.includes(item.txnId),
              name: (
                item?.fkcreatebysuperledgermaprel?.Userdata[0]?.firstname +
                " " +
                item?.fkcreatebysuperledgermaprel?.Userdata[0]?.lastname
              ).trim(),
              txnId: item.txnId || "-",
              participantname: (
                item?.fkuseridsuperledgermaprel?.Userdata[0]?.firstname +
                " " +
                item?.fkuseridsuperledgermaprel?.Userdata[0]?.lastname
              ).trim(),
              amountAdded: "$" + item.amountAdded,
              paymentType: item.paymentType,
              txnType: item.txnType,
              cardNumber:
                item?.fkcarddetailiimaprel?.CardDetails[0]?.cardNumber || "-",
              cardLimit: "$" + item.cardlimit,
              createdAt: item.createdAt ? formatDate(item.createdAt) : "-",
              itemDescription:
                item?.fkpaymentmaprel?.PaymentRequests[0]?.extraNotes || "-",
              storeName:
                item?.fkbusinessidsuperledgermaprel?.Businesses[0]?.storeName ||
                "-",
              storeCity:
                item?.fkbusinessidsuperledgermaprel?.Businesses[0]
                  ?.txnLocationCity || "-",
              storeCountry:
                item?.fkbusinessidsuperledgermaprel?.Businesses[0]
                  ?.txnLocationCountry || "-",
              mcc: "N/A",
              walletId: item?.walletId
            };
          });

          // let allData = res.data.allSuperLedgers.SuperLedgers.map(function(obj, index) {
          //   return {
          //     ['Serial No.']: index + 1,
          //     ['Participant Name'] : obj.participantname,
          //     ['Amount'] : obj.amountAdded,
          //     ['Transaction ID'] : obj.txnId,
          //     ['Transaction Type'] : obj.txnType,
          //     ['Transaction By'] : obj.name,
          //     ['Transaction Date'] : obj.createdAt,
          //     ['PPAN'] : obj.cardNumber,
          //     ['Card Limit'] : obj.cardLimit,
          //     ['Item Description'] : obj.itemDescription,
          //     ['Store Name'] : obj.storeName,
          //     ['Store City'] :  obj.storeCity,
          //     ['Store Country'] : obj.storeCountry,
          //     ['MCC'] : "N/A"
          //   }
          // })
          this.setState({
            count:
              res.data &&
              res.data.allSuperLedgers &&
              res.data.allSuperLedgers !== null
                ? res.data.allSuperLedgers.totalCount
                : "",
            listData: newData || [],
            listData__copy: newData || []
            // allCSVData: res?.data?.allSuperLedgers?.SuperLedgers !== null ? newData : [],
          });
        }
        // console.log(this.state.listData);
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });
  };

  getLedgerDetails = async () => {
    if (
      localStorage.getItem("role") === "GUARDIAN" &&
      !this.state.filter.userId
    ) {
      var filterdata = this.state.filter;
      if(this.props.participantId) {
        filterdata.userId = this.props.participantId

        if(this.props.paymentType) {
          filterdata.paymentType = this.props.paymentType
        }
      }else {
        var obj = { role: "PARTICIPANT", active: 1 };
        obj.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
        const data = await fetchMethod(participantQueryData, {
          where: obj
        });
        const resp = await data.json();
        if (
          resp &&
          resp.data &&
          resp.data.allUserdata &&
          resp.data.allUserdata.Userdata
        ) {
          var ids = [];
          resp.data.allUserdata.Userdata.forEach((i) => {
            ids.push(i.id);
          });
          filterdata.userId = { inq: [ids] };
          this.setState({
            filter: filterdata
          });
        }
          
      }
      this.prepareSuperLedgerData();
      this.downloadCSV();

    } else {
      this.prepareSuperLedgerData();
      this.downloadCSV();
    }
  };

  CallsendNotificationApi = (token, title, body, data) => {
    // console.log(
    //   "GetUserdataNotification token,title,body,data",
    //   token,
    //   title,
    //   body,
    //   data
    // );
    fetchMethod(GetUserdataNotification(token, title, body, data))
      .then((response) => response.json())
      .then((res) => {
        // console.log("GetUserdataNotification on payment confirm", res);
      })
      .catch((e) => {
        console.log("GetUserdataNotification on payment confirm error ", e);
      });
  };
  submitButton = () => {
    var limit;
    fetchMethod(cardData, {
      where: { id: this.state.cardId }
    })
      .then((res) => res.json())
      .then((res) => {
        limit =
          res.data.allCardDetails != undefined &&
          res.data.allCardDetails.CardDetails[0] != undefined
            ? res.data.allCardDetails.CardDetails[0].cardLimit //this.setState({ cardLimit: res.data.allCardDetails.CardDetails[0].cardLimit})
            : 0; //this.setState({loading:true});
        if (
          this.state.userId !== undefined ||
          this.state.businessId !== undefined
        ) {
          if (this.state.amount === 0 || isNaN(parseInt(this.state.amount))) {
            swal({ title: "Please enter numeric amount", icon: "warning" });
          } else {
            if (
              this.state.isRemoveFund == true &&
              parseInt(this.state.amount) > limit
            ) {
              swal({
                title:
                  "Amount entered is greater than the card limit. Current limit is $" +
                  limit,
                icon: "warning"
              });
              return false;
            }

            var userid = this.state.userId;
            const test = {
              txnType: this.state.isRemoveFund == true ? "DEBIT" : "CREDIT",
              userId: this.state.userId,
              // businessId: this.state.businessId,
              cardDetailId: this.state.cardId,
              amountAdded: parseFloat(this.state.amount),
              cardlimit:
                this.state.isRemoveFund == true
                  ? limit - parseFloat(this.state.amount)
                  : limit + parseFloat(this.state.amount),
              createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
              updatedBy: JSON.parse(localStorage.getItem("userInfo")).id,
              flag: 1
            };
            fetchMethod(saveLedger, { obj: test })
              .then((res) => res.json())
              .then((response) => {
                const id = response.data.saveSuperLedger;
                if (id && id !== null) {
                  this.getLedgerDetails();
                  swal({
                    title:
                      this.state.isRemoveFund == true
                        ? "Funds removed successfully."
                        : "Funds added successfully.",
                    icon: "success"
                  });
                  this.handleClose();

                  // if (loginDevice) {
                  //   CallsendNotificationApi(loginDevice, title, body, title);
                  // }
                  if (this.state.isRemoveFund == false) {
                    fetchMethod(participantQuery, {
                      where: { id: userid }
                    })
                      .then((res) => res.json())
                      .then((res) => {
                        if (
                          res.data.allUserdata != undefined &&
                          res.data.allUserdata.Userdata[0].loginDevice != null
                        ) {
                          let title = "Fund Added";
                          let body = ` ${
                            JSON.parse(localStorage.getItem("userInfo"))
                              .fullName
                          } Added $${test.amountAdded} in your Balance.`;
                          this.CallsendNotificationApi(
                            res.data.allUserdata.Userdata[0].loginDevice,
                            title,
                            body,
                            title
                          );
                        }
                      })
                      .catch((e) => console.log(e));
                  }

                  this.setState({
                    cardId: null,
                    cardOptions: [],
                    amount: 0,
                    userId: undefined
                  });
                } else {
                  swal("Please try again", { icon: "error" });
                }
              });
            //limit += test.amountAdded;
            const cardData = {
              id: this.state.cardId,
              cardLimit: test.cardlimit
            };
            fetchMethod(updateCardLimit, { obj: cardData });
          }
        } else {
          swal({ title: "Please select options", icon: "warning" });
        }
      })
      .catch((e) => console.log(e));
  };
  handleAmount = (e) => {
    this.setState({
      amount: 0
    });
    // console.log("################card data", this.state.cardId);
    if (e.target.value.length > 6) {
      swal({ title: "Amount can be only be upto 6 digit.", icon: "warning" });
      return false;
    } else if (
      e.target.value.length === 0 ||
      e.target.value === 0 ||
      e.target.value <= 0
    ) {
      swal({ title: "Please enter valid amount", icon: "warning" });
      return false;
    } else if (this.state.cardId === null) {
      this.setState({
        amount: 0
      });
      swal({ title: "Please select PPAN", icon: "warning" });
    } else {
      this.setState({
        amount: e.target.value
      });
    }
  };

  handleroleselect = (e) => {
    delete this.state.filter.userId;
    delete this.state.filter.businessId;

    let temp = this.state.filter;
    this.setState({ filter: temp });
    this.getLedgerDetails();
    const {
      target: { name, value }
    } = e;

    this.setState({
      role1: e.target.value,
      selecetedRole: e.target.value,
      userData: [],
      id: "",
      businessData: []
    });

    if (e.target.value === "PARTICIPANT" || e.target.value === "SUPPORTER") {
      fetchMethod(participantQueryData, {
        where: { role: e.target.value, active: 1 }
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({
            userData: res.data.allUserdata.Userdata
          });
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
        });
    } else {
      fetchMethod(businessQueryData, {
        where: { active: 1 }
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({
            userData: res.data.allBusinesses.Businesses
          });
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
        });
    }
  };

  handleFilter1 = (e) => {
    // console.log("this.setlecdRole", this.state.selecetedRole)
    this.setState({ id: e.target.value });
    const {
      target: { name, value }
    } = e;

    const { filter } = this.state;

    filter[name] = value;

    this.getLedgerDetails();

    this.setState({
      [name]: value,
      filter
    });
  };

  handleFilterautocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      const { filter } = this.state;
      filter.userId = v.id;
      this.setState({ userxyz: v, filter }, () => {
        console.log(
          "id test///////////////////",
          this.state.id,
          "jjjjjjjjjjjjjjjj",
          v.id
        );
      });

      this.getLedgerDetails();

      // this.setState({
      //   [name]: value,
      //   filter
      // });
    }
  };
  handleTransactionType = (e) => {
    this.setState({ txnType: e.target.value });
    const {
      target: { name, value }
    } = e;

    const { filter } = this.state;

    filter[name] = value;
    this.getLedgerDetails();

    this.setState({
      [name]: value,
      filter
    });
  };

  handleTransactionTypeAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      console.log(v);
      const { filter } = this.state;
      filter.txnType = v.txnType;
      this.setState({ txnxyz: v, filter });
      this.getLedgerDetails();
    }
  };

  handleSearch = (event) => {
    if (event.target.value) {
      console.log(
        "jksjksadjkhsajdkhjk",
        this.state.listData,
        this.state.listData__copy
      );
      this.setState({
        listData: this.state.listData__copy.filter(
          (record) =>
            record.storeName
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            record.participantname
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            record.name
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            record.amountAdded.includes(event.target.value) ||
            record.txnType
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
        )
      });
    } else {
      this.setState({
        listData: this.state.listData__copy
      });
    }
  };

  handleDateSearch = (date) => {
    let d = date.target.value.split("-").reverse().join("/");
    this.setState({
      selectedDate: date,
      listData: this.state.listData__copy.filter((record) =>
        record.createdAt.includes(d)
      )
    });
  };

  handlecardFilter = (e) => {
    console.log("%^&^&^&^^&*", e);
    this.setState({ cardId: e.target.value });
  };

  resetFilters = () => {
    if (localStorage.getItem("role") === "GUARDIAN") {
      document.querySelector("#date").value = "";
      this.setState({
        id: "",
        txnType: "",
        userxyz: null,
        txnxyz: null,
        searchValue: "",
        masterCheckboxSelected: false,
        listData: this.state.listData__copy
      });
      if (this.state.listData != "") {
        this.handleMasterCheckbox(false, true);
        this.getLedgerDetails();
      }
    } else {
      console.log("this ,,,,ste filter", this.state.filter);
      delete this.state.filter.userId;
      delete this.state.filter.txnType;
      this.setState({
        id: "",
        txnType: "",
        userxyz: null,
        txnxyz: null,
        searchValue: "",
        masterCheckboxSelected: false
      });
      console.log(this.state.listData);
      this.handleMasterCheckbox(false, true);
      this.getLedgerDetails();
    }
  };

  handleMasterCheckbox = (status, fromReset = false) => {
    if (this.state.searchValue || this.state.selectedDate) {
      this.onSearchSelectAll(status);
      return;
    }
    const selected = [];
    const list = this.state.allCSVData.map((data) => {
      data.status = status;
      status && selected.push(data["Transaction ID"]);
      return data;
    });
    this.setState({
      selectedRows: selected,
      allCSVData: list,
      masterCheckboxSelected: status,
      listData: fromReset
        ? this.state.listData_copy.map((row) => {
            row.status = status;
            return row;
          })
        : this.state.listData.map((row) => {
            row.status = status;
            return row;
          })
    });
  };

  handleCheckBox = (evt, txnId) => {
    if (evt.target.checked) {
      this.state.selectedRows.push(txnId);
      this.setState({
        bool: !this.state.bool // To just re-render a component, nothing else
      });
    } else {
      this.setState({
        selectedRows: this.state.selectedRows.filter((row) => row !== txnId)
      });
    }
  };

  onSearchSelectAll = (status) => {
    const selected = [];
    const list = this.state.allCSVData.map((data) => {
      if (
        this.state.listData.find((row) => row.txnId === data["Transaction ID"])
      ) {
        data.status = status;
        status && selected.push(data["Transaction ID"]);
      } else {
        data.status = false;
      }
      return data;
    });
    this.setState({
      selectedRows: selected,
      allCSVData: list,
      masterCheckboxSelected: status,
      listData: this.state.listData.map((row) => {
        row.status = status;
        return row;
      })
    });
  };

  rowsForDownload = () => {
    return this.state.listData
      .filter((row) => this.state.selectedRows.includes(row["txnId"]))
      .map((row) => {
        return {
          "Participant Name": row.participantname,
          "Amount": row.amountAdded,
          "Transaction ID": row.txnId,
          "Transaction Type": row.txnType,
          "Transaction By": row.name,
          "Transaction Date": row.createdAt,
          "PPAN": row.cardNumber,
          "Card Limit": row.cardLimit,
          "Item Description": row.itemDescription,
          "Store Name": row.storeName,
          "Store City": row.storeCity,
          "Store Country": row.storeCountry,
          "MCC": "N/A"
        };
      });
  };

  render() {
    const roleOptions = [
      { role: "PARTICIPANT" }
      // { role: "BUSINESS" },
      // { role: "SUPPORTER" }
    ];

    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45
      }
    ];

    const actionButton = [
      {
        Header: (
          <div>
            <Checkbox
              color="primary"
              id="master-checkbox"
              checked={this.state.masterCheckboxSelected}
              onChange={(e) => this.handleMasterCheckbox(e.target.checked)}
            />
          </div>
        ),
        sortable: false,
        Cell: (row) => (
          <div>
            <Checkbox
              color="primary"
              id={row.original.txnId}
              checked={row.original.status}
              onChange={(e) => {
                row.original["status"] = !row?.original?.status;
                this.handleCheckBox(e, row.original.txnId);
              }}
            />
          </div>
        )
      }
    ];
    const columns = nameColumn
      .concat(this.props.tableConfig ? this.props.tableConfig.columns : superLedgerList.columns)
      .concat(localStorage.getItem("role") === "GUARDIAN" && !this.props.hideActionButton ? actionButton : "");
    return (
      <div className="SuperledgerSection">
        {!this.props.hideTitle && <h2> Super Ledger</h2>}
        {!this.props.hideFilters && (
          <div>
            {localStorage.getItem("role") === "GUARDIAN" ? (
              <div>
                <FormControl style={{ width: 550 }}>
                  <TextField
                    className="textFilters"
                    style={{ width: 540 }}
                    onKeyUp={(event) => this.handleSearch(event)}
                    onChange={(evt) =>
                      this.setState({ searchValue: evt.target.value })
                    }
                    value={this.state.searchValue}
                    label="Transaction By, Transaction Type, Transaction For, Store Name, Budget"
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="date"
                    className="textFilters"
                    label="Transaction Date"
                    type="date"
                    format="dd/MM/yyyy"
                    onChange={(evt) => this.handleDateSearch(evt)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </div>
            ) : (
              <div>
                <FormControl>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    value={this.state.userxyz}
                    // defaultValue=""
                    // name={this.state.id}
                    options={this.state.userData}
                    onChange={(e, v) => this.handleFilterautocomplete(e, v)}
                    getOptionLabel={(option) =>
                      option
                        ? option.firstname +
                          (option.lastname ? " " + option.lastname : "")
                        : ""
                    }
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Participants"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    value={this.state.txnxyz}
                    options={this.state.CardType}
                    onChange={(e, v) =>
                      this.handleTransactionTypeAutocomplete(e, v)
                    }
                    getOptionLabel={(option) =>
                      option && option.txnType ? option.txnType : ""
                    }
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Transaction Type"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}
          </div>
        )}

        <div className="actions">
          {!this.props.hideReset && (
            <Button className="resetBtn mr5" onClick={this.resetFilters}>
              Reset
            </Button>
          )}
          {localStorage.getItem("role") === "ADMIN" ? (
            <>
              {/* <Button onClick={() => this.openModalBox('add')} className="addfundBtn">
                Add Funds
              </Button>
              <Button onClick={() => this.openModalBox('remove')} className="addfundBtn">
                Remove Funds
              </Button> */}
            </>
          ) : (
            (// this.state.allCSVData.length ?
            !this.props.hideDownload && <CSVLink
              data={this.state.csvForDownload}
              filename="super-ledger.csv"
              target="_blank"
              onClick={() => {
                let dataToBeDownload = [];
                if (this.state.searchValue || this.state.selectedDate) {
                  dataToBeDownload = this.rowsForDownload();
                } else {
                  console.log(
                    "elseeeeeeeee",
                    this.state.allCSVData,
                    this.state.selectedRows
                  );
                  dataToBeDownload = this.state.allCSVData
                    .filter((row) =>
                      this.state.selectedRows.includes(row["Transaction ID"])
                    )
                    .map((row, index) => {
                      const rw = { ...row };
                      delete rw["sNo"];
                      delete rw["S No."];
                      delete rw["status"];
                      return rw;
                    });
                  console.log("dataToBeDownload", dataToBeDownload);
                }
                if (!dataToBeDownload.length) {
                  return false;
                }
                this.setState({
                  csvForDownload: dataToBeDownload
                });
              }}
            >
              <Button className="resetBtn">Download CSV</Button>
            </CSVLink> /* : null */ /*: null */ /*: null */ /*: null */ /*: null */ /*: null */ /*: null */ /*: null */ /*: null */ /*: null */)
          )}
        </div>
        {console.log("listDatalistData", this.state.listData)}
        {this.state.listData ? (
          <div className="superledgerTable">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={this.props.tableConfig ? this.props.tableConfig : superLedgerList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}

        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          className="chooseBuisness superledgerAddfundModal"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.state.isRemoveFund == true ? "REMOVE FUNDS" : "ADD FUNDS"}
          </DialogTitle>
          <DialogContent>
            <div>
              <FormControl>
                {/* <Autocomplete
                  id="combo-box-demo"
                  value={this.state.storeId}
                  options={roleOptions}
                  onChange={(e, v) => this.handleRole(e, v)}
                  getOptionLabel={option =>
                    option && option.role ? option.role : ""
                  }
                  style={{ width: 300 }}
                  renderInput={params => (
                    <TextField {...params} label="Participant" variant="outlined" />
                  )}
                /> */}

                <Autocomplete
                  id="combo-box-demo"
                  value={this.state.storeId}
                  options={this.state.Options}
                  onChange={(e, v) => this.handleParticipant(e, v)}
                  getOptionLabel={(option) =>
                    option && option.name ? option.name : ""
                  }
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Participant"
                      variant="outlined"
                    />
                  )}
                />
                <FormControl>
                  <InputLabel
                    id="demo-simple"
                    margin="dense"
                    variant="outlined"
                  >
                    PPAN LIST
                  </InputLabel>
                  <Select
                    labelid="demo-simple"
                    label=" CARDS"
                    // value={this.state.storeId}
                    name="cardNumber"
                    // onChange={this.handleBuisnessFilter}
                    onChange={this.handlecardFilter}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {this.state.cardOptions
                      ? this.state.cardOptions.map((item, index) => {
                          return (
                            <MenuItem
                              className="EmployeeType"
                              key={index}
                              value={item.id}
                            >
                              {item.cardNumber}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
              </FormControl>
              {/* <FormControl> */}
              <TextField
                id="standard-basic"
                label="AMOUNT"
                className="amountfield"
                onChange={this.handleAmount}
                keyboardType="numeric"
                // maxLength={4}
                value={this.state.amount ? this.state.amount : ""}
                // helperText="Incorrect entry."
                InputLabelProps={{
                  shrink: true
                }}
              />
              {/* </FormControl> */}
            </div>
          </DialogContent>
          <DialogButton>
            <Button onClick={() => this.handleClose()}>Cancel</Button>
            <Button
              onClick={() => {
                this.submitButton();
              }}
            >
              Save
            </Button>
          </DialogButton>
        </Dialog>
      </div>
    );
  }
}
