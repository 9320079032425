export const paymentGuardianRequestAllQuery = (guardianId) => `
query($where:JSON){
  allPaymentRequests(where:$where){
    totalCount
			PaymentRequests{
      id
      isrelated
        participantId
        supporterId
        businessMemberId
        itemCategoryId
        itemDetail
        requestedAmount
        extraNotes
        cardId
        createdbycardid
        storeId
        externaltransid
        paymentStatus
        createdAt
        createdBy
        paymentStatus
        fkpaymentrequestcreatebymaprel{
          Userdata{
            id
            firstname
            lastname
            role
          }
        }
       fkPaymentRequestParticipantIdrel(where:{ guardianId : ${guardianId}  }){
        Userdata{
          id
          firstname
          lastname
        }
      }
        fkPaymentRequestSupporterIdrel{
        Userdata{
          id
          firstname
          lastname
        }
      }
        fkPaymentRequestBusinessMemberIdrel{
          Userdata{
            id
            firstname
            lastname
          }
        }
        fkPaymentRequestItemCategoryIdrel{
          MasterItemCategories{
            id
            categoryName
          }
        }
        fkPaymentRequestCreatedbyCardIdrel{
          CardDetails{
            id
            cardName
            cardLimit
            cardNumber
            cardstatus
          }
        }
        fkPaymentRequestStoreIdrel{
          Businesses{
            id
            storeName
          }
        }
    }
  }
}`;

export const paymentGuardianRequestQuery = `
query($where:JSON,$last: Int, $first: Int){
  allPaymentRequests(where:$where,last:$last,first:$first){
    totalCount
			PaymentRequests{
       id
      isrelated
      isverified
        participantId
        supporterId
        businessMemberId
        itemCategoryId
        itemDetail
        transactiondate
        requestedAmount
        extraNotes
        cardId
        createdbycardid
        storeId
        externaltransid
        createdAt
        createdBy
        paymentStatus
        isrepeatpay
        fkpaymentrequestcreatebymaprel{
          Userdata{
            id
            firstname
            lastname
            role
          }
        }
       fkPaymentRequestParticipantIdrel{
        Userdata{
          id
          firstname
          lastname
        }
      }
      fkPaymentMap{
        SuperLedgers{
          cardlimit
        }
      }
        fkPaymentRequestSupporterIdrel{
        Userdata{
          id
          firstname
          lastname
        }
      }
        fkPaymentRequestBusinessMemberIdrel{
          Userdata{
            id
            firstname
            lastname
          }
        }
        fkPaymentRequestItemCategoryIdrel{
          MasterItemCategories{
            id
            categoryName
          }
        }
        fkPaymentRequestCreatedbyCardIdrel{
          CardDetails{
            id
            cardName
            cardLimit
            cardNumber
            cardstatus
          }
        }
        fkPaymentRequestStoreIdrel{
          Businesses{
            id
            storeName
          }
        }
        fk_dispute_payment_request_id{
          Disputes{
            id
          }
        }
        authledgerPaymentRequestMap{
          Authledgers{
            paymentrequestid
            id
            changeamount
            spentamount
            merchantname
            requestedamount
          }
        } 
    }
  }
}`;

export const lastPaymentGuardianRequestQuery = (guardianId) => `
query($where:JSON,$last: Int, $first: Int){
  allPaymentRequests(where:$where,last:$last,first:$first){
    totalCount
			PaymentRequests{
      id
      isrelated
        participantId
        supporterId
        businessMemberId
        itemCategoryId
        transactiondate
        itemDetail
        requestedAmount
        extraNotes
        cardId
        createdbycardid
        storeId
        externaltransid
        paymentStatus
        createdAt
        createdBy
        paymentStatus
        fkpaymentrequestcreatebymaprel{
          Userdata{
            id
            firstname
            lastname
            role
          }
        }
       fkPaymentRequestParticipantIdrel(where:{ guardianId : ${guardianId}  }){
        Userdata{
          id
          firstname
          lastname
        }
      }
        fkPaymentRequestSupporterIdrel{
        Userdata{
          id
          firstname
          lastname
        }
      }
        fkPaymentRequestBusinessMemberIdrel{
          Userdata{
            id
            firstname
            lastname
          }
        }
        fkPaymentRequestItemCategoryIdrel{
          MasterItemCategories{
            id
            categoryName
          }
        }
        fkPaymentRequestCreatedbyCardIdrel{
          CardDetails{
            id
            cardName
            cardLimit
            cardNumber
            cardstatus
          }
        }
        fkPaymentRequestStoreIdrel{
          Businesses{
            id
            storeName
          }
        }
    }
  }
}`;

export const paymentQuery = `
query($where:JSON,$last: Int, $first: Int){
  allPaymentRequests(where:$where,last:$last,first:$first){
    totalCount
			PaymentRequests{
      id
      isrelated
      isverified
        participantId
        supporterId
        businessMemberId
        itemCategoryId
        transactiondate
        itemDetail
        requestedAmount
        extraNotes
        cardId
        createdbycardid
        storeId
        externaltransid
        paymentStatus
        createdAt
        createdBy
        paymentStatus
        walletid
        fkpaymentrequestcreatebymaprel{
          Userdata{
            id
            firstname
            lastname
            role
          }
        }
        fkPaymentMap{
          SuperLedgers{
            cardlimit
          }
        }
        authledgerPaymentRequestMap{
          Authledgers{
            paymentrequestid
            id
            changeamount
            spentamount
            merchantname
            requestedamount
          }
        } 
       fkPaymentRequestParticipantIdrel{
        Userdata{
          id
          firstname
          lastname
        }
      }
        fkPaymentRequestSupporterIdrel{
        Userdata{
          id
          firstname
          lastname
        }
      }
        fkPaymentRequestBusinessMemberIdrel{
          Userdata{
            id
            firstname
            lastname
          }
        }
        fkPaymentRequestItemCategoryIdrel{
          MasterItemCategories{
            id
            categoryName
          }
        }
        fkPaymentRequestCreatedbyCardIdrel{
          CardDetails{
            id
            cardName
            cardLimit
            cardNumber
            cardstatus
          }
        }
        fk_dispute_payment_request_id{
          Disputes{
            id
          }
        }
    }
  }
}`;

export const paymentImagesList = `query allRequestItemImages($where:JSON){
  allRequestItemImages(where:$where){
    RequestItemImages{
      id
      itemImageUrl
      paymentRequestId
    }
  }
}`;

export const paymentShoppingList = `query allRequestItemShoppingLists($where:JSON){
  allRequestItemShoppingLists(where:$where){
    RequestItemShoppingLists{
      id
      itemName
      itemPrice
    }
  }
}`;

export const getCardBalanace = (id) => `
  mutation{
    UserdataCheckBalance(userid:${id},isbusiness:null)
  }
`;

export const transactionList = (ppan, last, first) => `
  mutation{
    CardDetailTransactionHistory(ppan:"${ppan}", last:${last}, first:${first})
  }
`;
export const transactionDetails = (supporterId, userIds) => `
mutation{
  CardDetailAllTransactionHistory(ppan:"${supporterId}",userid:[${userIds}])
}
`;

export const paymentDisputes = `query allDisputes($where:JSON){
  allDisputes(where:$where){
    Disputes{
      id
      createdAt
      createdBy
      disputeStatus
      fkDisputeCreatedbyrel{
        Userdata{
          id
          firstname
          lastname
        }
      }
    }
  }
}
`;

export const saveDispute = `mutation saveDispute($obj: DisputeInput!) {
  saveDispute(obj: $obj) {
    id
   paymentRequestId
    createdBy
    createdAt
    disputeStatus
    updatedBy
    updatedAt
  }
}`;

export const saveNotes = `mutation savePaymentRequest ($obj: PaymentRequestInput!){
  savePaymentRequest (obj: $obj){
    id
  }
}`;

export const saveTransactionPhoto = `mutation saveRequestItemImage ($obj: RequestItemImageInput!){
  saveRequestItemImage (obj: $obj){
    id
  }
}`;

export const saveTransactionReceipt = `mutation saveRequestItemReceipt ($obj: RequestItemReceiptInput!){
  saveRequestItemReceipt (obj: $obj){
    id
  }
}`;

export const saveVerification = `mutation savePaymentRequest ($obj: PaymentRequestInput!){
    savePaymentRequest (obj: $obj){
      isverified
      id
    }
  }`;

export const paymentReceipt = `query allRequestItemReceipts($where:JSON){
  allRequestItemReceipts(where:$where){
    RequestItemReceipts{
      id
      receiptUrl
      paymentRequestId
    }
  }
}
`;

export const userdataQuery = `
query($where:JSON, $first: Int,$last: Int){
  allUserdata(where:$where,first:$first, last:$last){
    totalCount
    Userdata{
      id
      firstname
      lastname
      email
      phonenumber
      ndisNumber
      dob
      address
      city
      active
      profileimage
    }
  }
}
`;

export const disputeQuery = `query allDisputes ($where:JSON){
  allDisputes(where:$where){
    Disputes{
      id
      paymentRequestId 
      createdAt
      createdBy
      disputeStatus
    }
  }
}`;

export const UserdataCardtransfer = (id, amt, desc, flag, isbusiness) =>
  `
mutation {
  UserdataCardtransfer(userid:${id},amount:"${amt}",description:"${desc}",flag:0,isbusiness:${isbusiness})
}
`;
export const UserdataCardBlock = (id, isbusiness) =>
  `
mutation {
  UserdataCardBlock(userid:${id},isbusiness:${isbusiness})
}
`;

export const UserdataCheckBalance = (id, isbusiness) =>
  `
mutation {
  UserdataCheckBalance(userid:${id},isbusiness:${isbusiness})
}
`;

export const updateCardStatus = (id) =>
  `
mutation  {
    saveCardDetail(obj: {id: ${id}, cardstatus: "1"}) {
    id,cardstatus
  }
}`;

export const updateSuperledger = `
mutation saveSuperLedger($obj: SuperLedgerInput!) {
saveSuperLedger(obj: $obj) {
id
userId
fkuseridsuperledgermaprel {
Userdata {
loginDevice
plateform
}
}
}
}
`;

export const getCardDetailsQuery = `
query($where:JSON){
  allCardDetails(where:$where){
    CardDetails{
      id
      businessId
      cardLimit
      cardName
      cardNumber
      cvc
      cardstatus
      userId
      accountname
      accountnumber
      bsb
      expiryDate

      isCardLocked
    }
  }
}
`;

export const updateCardLimit = (userId, id, limit) =>
  `
mutation  {
  saveCardDetail(obj:{userId:${userId},id:${id},cardLimit:${limit}}) {
    id,cardLimit,userId
  }
}`;

export const GetUserdataNotification = (token, title, body, data) =>
  `
mutation{
  UserdataNotification(deviceToken:"${token}",title:"${title}",body:"${body}",data:{type:"${data}"})
}
`;

export const getUserBudget = (userId, itemId) => `query{
  allBudgets(where:{participantId:${userId},itemCategoryId:${itemId}}){
    Budgets{
      id
      budgetAvailable
    }
  }
}`;

export const updateUserBudget = `
  mutation saveBudget($obj: BudgetInput!) {
    saveBudget(obj: $obj) {
      budgetAvailable
    }
  }
`;

export const getCardDetails = `
query($where:JSON){
  allCardDetails(where:$where){
    CardDetails{
      id
      cardLimit
      cardName
      cardNumber
      cvc
      accountname
      accountnumber
      bsb
      cardtypeid
      expiryDate
      isCardLocked
      fkcardtypeidrel{
        Cardtypes{
          id
          cardtype
        }
      }

    }
  }
}
`;

export const getSingleTransaction = `
query($where:JSON){
  allAuthledgers(where:$where){
    totalCount
    Authledgers{
      id
      supporterid
      userid
      spentamount
      createdon
      ppan
      authledgerstoremaprel{
        Businesses{
          storeName
          txnLocationCity
        }
      }
      authledgersupportermaprel{
        Userdata{
          firstname
          lastname
        }
      }
      authledgerusermaprel{
        Userdata{
          firstname
          lastname
        }
      }
      authledgeritemcategorymaprel{
        MasterItemCategories{
          categoryName
        }
      }
    }
  }
}
`;

export const getParticipentCardDetails = `
query allCardDetails($filter:JSON, $where: JSON, $walletFilter: JSON){
  allCardDetails(where:$filter){
    CardDetails{
      id
      userId
      cardLimit
      fkuseridcarddetailmaprel{
        Userdata{
          id
          firstname
          lastname
          profileimage
        }
      }
    }
  }
  allAuthledgers(where:$where){
    Authledgers{
      id
      isrepeatpay
      isrepeatpaysetforparticipant
      repeatpayamount
      userid
      active
    }
  }
  allWallets(where:$walletFilter){
    Wallets{
      balance
    }
  }
}
`;

export const getItemCategory = `
query allMasterItemCategories($where:JSON){
    allMasterItemCategories(where:$where){
      totalCount
      MasterItemCategories{
        id
        categoryName
        isActive
        tierId          
      }
    }
  }
`;

export const saveItemCategory = `mutation savePaymentRequest ($obj: PaymentRequestInput!){
  savePaymentRequest (obj: $obj){
    id
  }
}`;

export const saveAuthledgerCategory = `mutation saveAuthledger ($obj:  AuthledgerInput!){
  saveAuthledger (obj: $obj){
    id
  }
}`;
