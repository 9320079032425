import React, { Component } from "react";
import ReactTableComponent from "../../../ReactTable/ReactTable";
import { adminWalletListForSupporter } from "./SupporterTabSetupConfig";
import swal from "sweetalert";
import { fetchMethod } from "../../../FetchMethod";
import { connectedWalletQuery } from "./SupporterTabQuery";
import { DotLoader } from "react-spinners";
import "./supporterTabSetup.css";
import {
  getGuardianParticipant,
  getSupporterConnectedParticipant,
} from "./SupporterTabQuery";
class ConnectedParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      filter: {
        guardianId: JSON.parse(localStorage.getItem("userInfo")).id,
      },
      supporterId: this.props.id,
    };
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  componentWillMount() {
    this.connectedParticipant();
  }

  connectedParticipant = async () => {
    if (localStorage.getItem("role") === "GUARDIAN") {
      try {
        const response = await fetchMethod(getGuardianParticipant, {
          where: this.state.filter,
        }).then((res) => res.json());
        const participants = response?.data?.allUserdata?.Userdata || [];
        let usersData = [];
        for (let i = 0; i < participants.length; i++) {
          let users = await fetchMethod(getSupporterConnectedParticipant, {
            where: {
              participantId: participants[i].id,
              supporterId: this.state.supporterId,
            },
          }).then((_res) => _res.json());
          const connectedParticipant =
            users?.data?.allParticipantConnectedSupporters
              ?.ParticipantConnectedSupporters || [];
          if (connectedParticipant.length) {
            connectedParticipant.map((item) => {
              let participantDetail =
                item?.fkParticipantConnectedSupporterParticipantIdrel
                  ?.Userdata[0];
              if (participantDetail) {
                participantDetail.dob = this.formatDate(participantDetail.dob);
                participantDetail["fullAddress"] = (
                  (participantDetail.address || "") +
                  " " +
                  (participantDetail.city || "")
                ).trim();
                usersData.push(participantDetail);
              }
            });
          }
        }
        this.setState({
          count: usersData.length,
          listData: usersData,
        });
      } catch (err) {
        swal({ title: err.message, icon: "warning" });
        this.setState({ listData: [] });
      }
    } else {
      fetchMethod(connectedWalletQuery, {
        where: { userId: this.state.supporterId, active: 1 },
        last: parseInt(this.state.rows),
        first: parseInt(this.state.pageNo),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.error && res.error.statusCode === 401) {
            swal({ title: res.error.message, icon: "warning" }).then(() => {
              localStorage.clear();
              window.location = "/";
            });
          } else {
            res.data.allWalletConnectedUsers.WalletConnectedUsers.map(
              (item) => {
                if (
                  item.walletWalletrel &&
                  item.walletWalletrel.Wallets.length > 0
                ) {
                  let walletData = item.walletWalletrel.Wallets[0];
                  let userdata =
                    walletData.participantUserdatarel != null &&
                    walletData.participantUserdatarel.Userdata != null &&
                    walletData.participantUserdatarel.Userdata.length > 0
                      ? walletData.participantUserdatarel.Userdata[0]
                      : "";
                  return (
                    (item.id = walletData.id),
                    (item.description = walletData.description),
                    (item.firstname =
                      userdata != null ? userdata.firstname : ""),
                    (item.lastname = userdata != null ? userdata.lastname : ""),
                    (item.email = userdata != null ? userdata.email : ""),
                    (item.phonenumber =
                      userdata != null ? userdata.phonenumber : ""),
                    (item.ndisNumber =
                      userdata != null ? userdata.ndisNumber : "")
                  );
                }
              }
            );
            this.setState({
              count:
                res.data &&
                res.data.allWalletConnectedUsers &&
                res.data.allWalletConnectedUsers !== null &&
                res.data.allWalletConnectedUsers &&
                res.data.allWalletConnectedUsers.WalletConnectedUsers !== null
                  ? res.data.allWalletConnectedUsers.WalletConnectedUsers
                      .totalCount
                  : "",
              listData:
                res.data &&
                res.data.allWalletConnectedUsers &&
                res.data.allWalletConnectedUsers !== null &&
                res.data.allWalletConnectedUsers &&
                res.data.allWalletConnectedUsers.WalletConnectedUsers !== null
                  ? res.data.allWalletConnectedUsers.WalletConnectedUsers
                  : "",
            });
          }
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
          this.setState({ listData: [] });
        });
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size,
      },
      () => {
        this.connectedParticipant();
      }
    );
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45,
      },
    ];
    const columns = nameColumn.concat(adminWalletListForSupporter.columns);
    return (
      <div>
        {this.state.listData ? (
          <div className="connectedParticipant">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={adminWalletListForSupporter}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows,
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}
      </div>
    );
  }
}

export default ConnectedParticipant;
