export const ADMIN = "ADMIN";
export const GUARDIAN = "GUARDIAN";
export const WALLET_LIST_ROUTE = "wallets";
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const YES = "Yes";
export const NO = "No";
export const LINK = "LINK";
export const UNLINK = "UNLINK";
export const KYC_STATUS_PASS = "pass";
