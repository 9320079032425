import React, { Component } from "react";
import ReactTableComponent from "../../ReactTable/ReactTable";
import { fetchMethod } from "../../FetchMethod";
import {
  getCardDetailsQuery,
  getUserBudget,
  lastPaymentGuardianRequestQuery,
  paymentDisputes,
  paymentGuardianRequestQuery,
  paymentImagesList,
  paymentShoppingList,
  updateCardLimit,
  updateCardStatus,
  updateSuperledger,
  updateUserBudget,
  UserdataCardBlock,
  UserdataCardtransfer,
  UserdataCheckBalance,
  userdataQuery
} from "./PaymentRquestQuery";
import swal from "sweetalert";
import { paymentGuardianRequestAllQuery } from "../Dashboard/DashboardQuery";
import { DotLoader } from "react-spinners";
import { adminPaymentRequestList } from "./PaymentRequestsConfig";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Typography
} from "@material-ui/core";
import Carousel from "react-bootstrap/Carousel";
import noImgFound from "../../assets/images/noImgFound.jpg";
import "./paymentRequests.css";
import { withRouter } from "react-router";
import S3Image from "../S3Image";

export class LargestPaymentRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      openModal: false,
      paymentImages: [],
      openDispute: false,
      openShoppingList: false,
      paymentShopping: undefined,
      paymentDispute: undefined,
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      images: [],
      loading: false,
      filter: {
        order: "requestedAmount desc"
      },
      role1: "",
      userData: undefined,
      paymentId: undefined,
      count: 0,
      createdBy: "",
      paymentxyz: null
    };
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy + " " + hours + ":" + minutes);
  };

  componentWillMount() {
    this.paymentRequestData();
  }

  // getPaymentGuardianRequestAllQuery = (guardianId) =>{
  //   fetchMethod(paymentGuardianRequestAllQuery(guardianId), {
  //       where: this.state.filter,
  //   })
  //   .then(res => res.json())
  //   .then(res => {
  //       let paymentData = res.data.allPaymentRequests.PaymentRequests || [];
  //       paymentData = paymentData.filter(item=> item.fkPaymentRequestParticipantIdrel.Userdata.length > 0 )

  //       this.setState({
  //           count:
  //           paymentData !== null
  //           ? paymentData.length
  //           : "",
  //       })
  //   })
  //   .catch(e => {
  //       swal({ title: e.message, icon: "warning" });
  //       this.setState({ count: [] });
  //   });
  // }

  paymentRequestData = async () => {
    // await this.getPaymentGuardianRequestAllQuery(localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : '');
    fetchMethod(
      lastPaymentGuardianRequestQuery(
        localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")).id
          : ""
      ),
      {
        where: this.state.filter
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          let paymentData = res.data.allPaymentRequests.PaymentRequests || [];

          paymentData = paymentData.filter((item) => {
            return item.fkPaymentRequestParticipantIdrel.Userdata.length > 0;
          });

          let fiveLargestPaymentRequest = paymentData.slice(0, 5);
          // console.log(fiveLargestPaymentRequest);
          fiveLargestPaymentRequest.map((item) => {
            return (
              (item.cardNumber =
                item.fkPaymentRequestCreatedbyCardIdrel.CardDetails &&
                item.fkPaymentRequestCreatedbyCardIdrel.CardDetails.length > 0
                  ? item.fkPaymentRequestCreatedbyCardIdrel.CardDetails[0]
                      .cardNumber
                  : ""),
              (item.cardStatus =
                item.fkPaymentRequestCreatedbyCardIdrel.CardDetails &&
                item.fkPaymentRequestCreatedbyCardIdrel.CardDetails.length > 0
                  ? item.fkPaymentRequestCreatedbyCardIdrel.CardDetails[0]
                      .cardstatus === "0" ||
                    item.fkPaymentRequestCreatedbyCardIdrel.CardDetails[0]
                      .cardstatus === 0 ||
                    item.fkPaymentRequestCreatedbyCardIdrel.CardDetails[0]
                      .cardstatus === null
                    ? "UNBLOCK"
                    : "BLOCK"
                  : ""),
              // (item.cardLimit =
              //   item.fkPaymentRequestCreatedbyCardIdrel.CardDetails &&
              //   item.fkPaymentRequestCreatedbyCardIdrel.CardDetails.length > 0
              //     ? item.fkPaymentRequestCreatedbyCardIdrel.CardDetails[0]
              //         .cardLimit
              //     : ""),
              (item.madefor =
                item.createdBy === item.participantId
                  ? "SELF"
                  : item.fkPaymentRequestParticipantIdrel.Userdata &&
                    item.fkPaymentRequestParticipantIdrel.Userdata.length > 0
                  ? item.fkPaymentRequestParticipantIdrel.Userdata[0].firstname.concat(
                      item.fkPaymentRequestParticipantIdrel.Userdata &&
                        item.fkPaymentRequestParticipantIdrel.Userdata.length >
                          0
                        ? " " +
                            (item.fkPaymentRequestParticipantIdrel.Userdata[0]
                              .lastname != null
                              ? item.fkPaymentRequestParticipantIdrel
                                  .Userdata[0].lastname
                              : "")
                        : ""
                    )
                  : ""),
              (item.madeby =
                item.fkpaymentrequestcreatebymaprel.Userdata &&
                item.fkpaymentrequestcreatebymaprel.Userdata.length > 0
                  ? item.fkpaymentrequestcreatebymaprel.Userdata[0].firstname.concat(
                      item.fkpaymentrequestcreatebymaprel.Userdata &&
                        item.fkpaymentrequestcreatebymaprel.Userdata.length > 0
                        ? " " +
                            (item.fkpaymentrequestcreatebymaprel.Userdata[0]
                              .lastname != null
                              ? item.fkpaymentrequestcreatebymaprel.Userdata[0]
                                  .lastname
                              : "")
                        : ""
                    )
                  : ""),
              // (item.buisnessMemberName =
              //   item.fkPaymentRequestBusinessMemberIdrel.Userdata &&
              //   item.fkPaymentRequestBusinessMemberIdrel.Userdata.length > 0
              //     ? item.fkPaymentRequestBusinessMemberIdrel.Userdata[0].firstname.concat(
              //         item.fkPaymentRequestBusinessMemberIdrel.Userdata &&
              //           item.fkPaymentRequestBusinessMemberIdrel.Userdata
              //             .length > 0
              //           ? " " +
              //               (item.fkPaymentRequestBusinessMemberIdrel
              //                 .Userdata[0].lastname !== null
              //                 ? item.fkPaymentRequestBusinessMemberIdrel
              //                     .Userdata[0].lastname
              //                 : "")
              //           : ""
              //       )
              //     : ""),
              (item.categoryName =
                item.fkPaymentRequestItemCategoryIdrel !== null &&
                item.fkPaymentRequestItemCategoryIdrel.MasterItemCategories &&
                item.fkPaymentRequestItemCategoryIdrel.MasterItemCategories
                  .length > 0
                  ? item.fkPaymentRequestItemCategoryIdrel
                      .MasterItemCategories[0].categoryName
                  : ""),
              (item.storeName =
                item.fkPaymentRequestStoreIdrel !== null &&
                item.fkPaymentRequestStoreIdrel.Businesses &&
                item.fkPaymentRequestStoreIdrel.Businesses.length > 0
                  ? item.fkPaymentRequestStoreIdrel.Businesses[0].storeName
                  : ""),
              (item.requestedAmount =
                item.requestedAmount !== undefined &&
                item.requestedAmount !== null
                  ? `$${item.requestedAmount}`
                  : ""),
              (item.createdAt =
                item.createdAt !== undefined && item.createdAt !== null
                  ? this.formatDate(item.createdAt)
                  : ""),
              (item.transactiondate =
                item.transactiondate !== undefined &&
                item.transactiondate !== null
                  ? this.formatDate(item.transactiondate)
                  : "")
            );
          });
          this.setState({
            listData:
              fiveLargestPaymentRequest !== null
                ? fiveLargestPaymentRequest
                : ""
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });
  };

  Blockusercard = (data) => {
    this.unloadApi(data, 1);
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.paymentRequestData();
      }
    );
  };

  handleClose = () => {
    this.setState({ openModal: false, paymentImages: [] });
  };
  handleCloseShopping = () => {
    this.setState({ openShoppingList: false, paymentShopping: undefined });
  };
  handleCloseDispute = () => {
    this.setState({ openDispute: false, paymentDispute: undefined });
  };
  openModalBox = (data) => {
    this.setState({ openModal: true });
    this.getPaymentImages(data.id);
  };
  openShoppingList = (data) => {
    this.setState({ openShoppingList: true });
    this.getShoppingList(data.id);
  };
  openDispute = (data) => {
    this.setState({ openDispute: true });
    this.getDisputes(data.id);
  };

  getShoppingList = (id) => {
    fetchMethod(paymentShoppingList, {
      where: { paymentRequestId: id }
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allRequestItemShoppingLists.RequestItemShoppingLists.length > 0
          ? this.setState({
              loading: false,
              paymentShopping:
                res.data.allRequestItemShoppingLists.RequestItemShoppingLists.map(
                  (item) => {
                    return {
                      id: item.id,
                      name: item.itemName,
                      price: item.itemPrice ? item.itemPrice : 0
                    };
                  }
                )
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  getDisputes = (id) => {
    fetchMethod(paymentDisputes, {
      where: { paymentRequestId: id }
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allDisputes.Disputes.length > 0
          ? this.setState({
              loading: false,
              paymentDispute: res.data.allDisputes.Disputes.map((item) => {
                return {
                  id: item.id,
                  // name: item.itemName,
                  createdBy:
                    item.fkDisputeCreatedbyrel &&
                    item.fkDisputeCreatedbyrel.Userdata[0]
                      ? item.fkDisputeCreatedbyrel.Userdata[0].firstname +
                        (item.fkDisputeCreatedbyrel.Userdata[0].lastname
                          ? " " +
                            item.fkDisputeCreatedbyrel.Userdata[0].lastname
                          : "")
                      : "",
                  disputeStatus: item.disputeStatus ? item.disputeStatus : "",
                  createdAt: (item.createdAt =
                    item.createdAt !== undefined && item.createdAt !== null
                      ? this.formatDate(item.createdAt)
                      : "")
                };
              })
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  getPaymentImages = (id) => {
    fetchMethod(paymentImagesList, {
      where: { paymentRequestId: id }
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.allRequestItemImages.RequestItemImages.length > 0
          ? this.setState({
              paymentImages:
                res.data.allRequestItemImages.RequestItemImages.map((item) => {
                  return {
                    id: item.id,
                    url: item.itemImageUrl
                  };
                })
            })
          : this.setState({ loading: true });
      })
      .catch((e) => console.log(e));
  };

  blockfunction = (createdBy, cardId, businessflag) => {
    // console.log("")
    fetchMethod(UserdataCardBlock(createdBy, businessflag))
      .then((response) => response.json())
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.UserdataCardBlock &&
          res.data.UserdataCardBlock.resultCode &&
          res.data.UserdataCardBlock.resultCode !== "0"
        ) {
        } else {
          fetchMethod(updateCardStatus(cardId))
            .then((response) => response.json())
            .then((res) => {
              this.paymentRequestData();
              swal({ title: "CARD BLOCKED", icon: "success" });
            })
            .catch((e) => {
              swal({ title: e, icon: "warning" });
            });
        }
      })
      .catch((e) => {
        swal({ title: e, icon: "warning" });
      });
  };

  unloadApi = (data, blockflag) => {
    if (data.createdBy) {
      let isbusiness =
        data.fkpaymentrequestcreatebymaprel &&
        data.fkpaymentrequestcreatebymaprel.Userdata[0] &&
        data.fkpaymentrequestcreatebymaprel.Userdata[0].role ===
          "BUSINESSMEMBER"
          ? 1
          : 0;
      let userid = isbusiness === 1 ? data.storeId : data.createdBy;

      fetchMethod(UserdataCheckBalance(userid, isbusiness))
        .then((response) => response.json())
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.UserdataCheckBalance &&
            res.data.UserdataCheckBalance.data &&
            res.data.UserdataCheckBalance.data.balance &&
            res.data.UserdataCheckBalance.data.balance.availableAmount > 0
          ) {
            // this.UserdataCardtransferCall(
            //   userid,
            //   res.data.UserdataCheckBalance.data.balance.availableAmount,
            //   "withdraw",
            //   0,
            //   isbusiness
            // );
            // console.log(
            // "REset....................",
            // data.requestedAmount.substring(1, data.requestedAmount.length)
            // );

            fetchMethod(
              UserdataCardtransfer(
                userid,
                res.data.UserdataCheckBalance.data.balance.availableAmount,
                "withdraw",
                0,
                isbusiness
              )
            )
              .then((response) => response.json())
              .then((response) => {
                if (
                  response &&
                  response.data &&
                  response.data.UserdataCardtransfer &&
                  response.data.UserdataCardtransfer.resultCode &&
                  response.data.UserdataCardtransfer.resultCode === "0"
                ) {
                  if (blockflag == 1) {
                    this.blockfunction(
                      userid,
                      data.createdbycardid,
                      isbusiness
                    );
                  }

                  fetchMethod(
                    getUserBudget(data.participantId, data.itemCategoryId)
                  )
                    .then((response) => response.json())
                    .then((respp) => {
                      let budget =
                        respp.data.allBudgets &&
                        respp.data.allBudgets.Budgets[0]
                          ? respp.data.allBudgets.Budgets[0]
                          : {};
                      let amount = parseFloat(
                        budget.budgetAvailable
                          ? budget.budgetAvailable +
                              res.data.UserdataCheckBalance.data.balance
                                .availableAmount
                          : 0 +
                              res.data.UserdataCheckBalance.data.balance
                                .availableAmount
                      );
                      const Obj = {
                        obj: {
                          budgetAvailable: amount,
                          id: budget.id
                        }
                      };
                      fetchMethod(updateUserBudget, Obj)
                        .then((respUpdate) => respUpdate.json())
                        .then((respUpdate) => {
                          // console.log(
                          //   "res status PaymentConfirmation updateUserBudget",
                          //   res
                          // );
                          // alert("Payment Cancelled Successfully!");
                          // swal({title:})
                        })
                        .catch((e) => {
                          console.log(
                            "updateUserBudget on PaymentConfirmation error ",
                            e
                          );
                        });
                    });
                  fetchMethod(getCardDetailsQuery, {
                    where: { userId: data.participantId }
                  })
                    .then((resp) => resp.json())
                    .then((resp) => {
                      if (
                        resp &&
                        resp.data &&
                        resp.data.allCardDetails &&
                        resp.data.allCardDetails.CardDetails &&
                        resp.data.allCardDetails.CardDetails != ""
                      ) {
                        // let cardlimit = resp.data.allCardDetails.CardDetails[0]
                        //   .cardLimit
                        //   ? resp.data.allCardDetails.CardDetails[0].cardLimit +
                        //     res.data.UserdataCheckBalance.data.balance
                        //       .availableAmount
                        //   : res.data.UserdataCheckBalance.data.balance
                        //       .availableAmount;

                        let DATA = resp.data.allCardDetails.CardDetails;

                        let cardlimit =
                          DATA &&
                          DATA[DATA.length - 1] &&
                          DATA[DATA.length - 1].cardLimit
                            ? DATA[DATA.length - 1].cardLimit
                            : 0;

                        let Bal =
                          res.data.UserdataCheckBalance.data.balance
                            .availableAmount;

                        if (Bal && Bal > 0) {
                          cardlimit = cardlimit ? cardlimit + Bal : 0 + Bal;
                        }

                        let cardId =
                          DATA &&
                          DATA[DATA.length - 1] &&
                          DATA[DATA.length - 1].id
                            ? DATA[DATA.length - 1].id
                            : 0;

                        // let cardId = resp.data.allCardDetails.CardDetails[0].id;
                        const Obj = {
                          obj: {
                            // paymentrequestid: data.id,
                            id:
                              response.data.UserdataCardtransfer &&
                              response.data.UserdataCardtransfer.ledgerid
                                ? response.data.UserdataCardtransfer.ledgerid
                                : "",
                            createdBy: JSON.parse(
                              localStorage.getItem("userInfo")
                            ).id,
                            cardlimit: cardlimit,
                            userId: data.participantId,
                            cardDetailId: data.cardId,
                            amountAdded:
                              res.data.UserdataCheckBalance.data.balance
                                .availableAmount,
                            // txnId:
                            //   res.data.UserdataCardtransfer &&
                            //   res.data.UserdataCardtransfer.transNo
                            //     ? res.data.UserdataCardtransfer.transNo
                            //     : "",
                            txnType: "CREDIT"
                          }
                        };
                        fetchMethod(updateSuperledger, Obj)
                          .then((response) => response.json())
                          .then((res) => {
                            if (
                              res &&
                              res.data &&
                              res.data.saveSuperLedger &&
                              res.data.saveSuperLedger.id
                            ) {
                              fetchMethod(userdataQuery, {
                                where: { id: data.participantId }
                              })
                                .then((res) => res.json())
                                .then((res) => {
                                  if (
                                    res.data.allUserdata != undefined &&
                                    res.data.allUserdata.Userdata[0]
                                      .loginDevice != null
                                  ) {
                                    let title = "Payment";
                                    let body = ` ${
                                      JSON.parse(
                                        localStorage.getItem("userInfo")
                                      ).fullName
                                    } Added $${
                                      res.data.UserdataCheckBalance.data.balance
                                        .availableAmount
                                    } to your card.`;
                                    this.CallsendNotificationApi(
                                      res.data.allUserdata.Userdata[0]
                                        .loginDevice,
                                      title,
                                      body,
                                      title
                                    );
                                  }
                                })
                                .catch((e) => console.log(e));

                              fetchMethod(
                                updateCardLimit(
                                  data.participantId,
                                  cardId,
                                  cardlimit
                                )
                              )
                                .then((res) => res.json())
                                .then((res) => {
                                  if (
                                    res &&
                                    res.data &&
                                    res.data.saveCardDetail &&
                                    res.data.saveCardDetail.id
                                  ) {
                                    if (blockflag != 1) {
                                      swal({
                                        text: "Unload successfully.",
                                        icon: "success"
                                      });
                                    }
                                  } else {
                                    // console.log(
                                    //   "updateCardLimit not done error",
                                    //   res
                                    // );
                                  }
                                })
                                .catch((e) => {
                                  // setLoading(false);
                                  // alert(e);
                                  swal({ title: e, icon: "warning" });
                                });

                              // swal({
                              //   title: "Unload successfully.",
                              //   icon: "success."
                              // });
                            }
                          })
                          .catch((error) => {
                            swal({ title: error.message, icon: "warning" });
                          });
                      }
                    })
                    .catch((error) => {
                      swal({ title: error.message, icon: "warning" });
                    });
                } else {
                  let Err =
                    response.data.UserdataCardtransfer.resultDescription;

                  swal({
                    title: response.data.UserdataCardtransfer.resultDescription,
                    icon: "warning"
                  });
                }
              })
              .catch((e) => {
                console.log("UserdataCardtransfer error ", e);
              });

            // getCardDetailsQuery

            // console.log("updateSuperledger on payment status", res);
            // let Data = {};
            // if (
            //   res &&
            //   res.data &&
            //   res.data.saveSuperLedger &&
            //   res.data.saveSuperLedger.id
            // ) {
            //   Data = res.data.saveSuperLedger;
            //   if (data.participantId && CardID) {
            //     callUpdateCardLimit(data.participantId, CardID, CardL);
            //   }
            // }
            // let loginDevice = "";
            // if (
            //   Data &&
            //   Data.fkuseridsuperledgermaprel &&
            //   Data.fkuseridsuperledgermaprel.Userdata &&
            //   Data.fkuseridsuperledgermaprel.Userdata != "" &&
            //   Data.fkuseridsuperledgermaprel.Userdata[0].loginDevice
            // ) {
            //   loginDevice =
            //     Data.fkuseridsuperledgermaprel.Userdata[0].loginDevice;
            // }
            // if (loginDevice && userRole && userRole !== "PARTICIPANT") {
            //   CallsendNotificationApi(loginDevice, title, body, title);
            // }
            // })
            // .catch(e => {
            //   alert(e);
            //   console.log("updateSuperledger on payment status error ", e);
            // });
          } else if (
            res &&
            res.data &&
            res.data.UserdataCheckBalance &&
            res.data.UserdataCheckBalance.data &&
            res.data.UserdataCheckBalance.data.balance &&
            res.data.UserdataCheckBalance.data.balance.availableAmount <= 0
          ) {
            if (blockflag == 1) {
              this.blockfunction(userid, data.createdbycardid, isbusiness);
            } else {
              swal({ title: "There are no fund to unload.", icon: "warning" });
            }
          } else if (
            res &&
            res.data &&
            res.data.UserdataCheckBalance &&
            res.data.UserdataCheckBalance.resultDescription
          ) {
            swal({
              title: res.data.UserdataCheckBalance.resultDescription,
              icon: "warning"
            });
          }
        })
        .catch((e) => {
          swal({ title: e, icon: "warning" });
        });
    } else {
      return;
    }
    // .then(res => {
    //   // this.
    // });
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45
      }
    ];

    const actionButton = [
      {
        Header: "View Images",
        sortable: false,
        Cell: (row) => (
          <div
            onClick={() => {
              this.openModalBox(row.original);
            }}
          >
            <span className="viewimageLink">
              <u>View</u>
            </span>
          </div>
        )
      },
      {
        Header: "View Shopping ",
        sortable: false,
        Cell: (row) => (
          <div
            onClick={() => {
              this.openShoppingList(row.original);
            }}
          >
            <span className="viewshoppingLink">
              <u>View</u>
            </span>
          </div>
        )
      },
      {
        Header: "View Dispute ",
        sortable: false,
        Cell: (row) => (
          <div
            onClick={() => {
              this.openDispute(row.original);
            }}
          >
            <span className="viewshoppingLink">
              <u>View</u>
            </span>
          </div>
        )
      }
      // {
      //   Header: "UNLOAD CARD",
      //   sortable: false,
      //   Cell: row => (
      //     <div>
      //       {row.original.paymentStatus === "Complete" &&
      //       row.original.isrelated === 1 ? (
      //         <Button
      //           className="resetBtn"
      //           onClick={() => {
      //             this.unloadApi(row.original);
      //           }}
      //         >
      //           UNLOAD
      //         </Button>
      //       ) : (
      //         ""
      //       )}
      //     </div>
      //   )
      // },
      // {
      //   Header: "BLOCK CARD",
      //   sortable: false,
      //   Cell: row => (
      //     <div>
      //       {row.original.cardStatus === "UNBLOCK" &&
      //       row.original.isrelated === 1 &&
      //       row.original.fkpaymentrequestcreatebymaprel &&
      //       row.original.participantId !== row.original.createdBy ? (
      //         <Button
      //           className="resetBtn"
      //           onClick={() => {
      //             this.Blockusercard(row.original);
      //           }}
      //         >
      //           BLOCK
      //         </Button>
      //       ) : (
      //         ""
      //       )}
      //     </div>
      //   )

      //   //   onClick={() => {
      //   //     this.openDispute(row.original);
      //   //   }}
      //   // <span className="viewshoppingLink">
      //   //   <u>View</u>
      //   // </span>

      //   // </div>
      // }
    ];

    const columns = nameColumn
      .concat(adminPaymentRequestList.columns)
      .filter((col) => col.accessor !== "cardBalance")
      .concat(actionButton);
    return (
      <div>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          className="viewImageScrollModal"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Images
          </DialogTitle>
          <DialogContent>
            <Carousel>
              {this.state.paymentImages.length > 0 ? (
                this.state.paymentImages.map((item, index) => (
                  <Carousel.Item>
                    <S3Image
                      className="d-block w-100"
                      src={item.url}
                      alt="First slide"
                    />
                  </Carousel.Item>
                ))
              ) : (
                <div>
                  <img
                    src={noImgFound}
                    alt="noImgFound"
                    className="noImageFound"
                  />
                </div>
              )}
            </Carousel>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openShoppingList}
          onClose={this.handleCloseShopping}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          className="shoppinglistMOdal"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleCloseShopping}
          >
            Shopping List
          </DialogTitle>
          <DialogContent>
            <div>
              <FormControl>
                <ul
                  className={
                    this.state.paymentShopping ? "showtable" : "hidetable"
                  }
                >
                  <li>
                    <b>Product</b>
                  </li>
                  <li>
                    <b>Price</b>
                  </li>
                </ul>
                <span className="shoppingListData">
                  {this.state.paymentShopping
                    ? this.state.paymentShopping.map((item, index) => {
                        return (
                          <FormControl>
                            <MenuItem>
                              <span> {item.name}</span>
                            </MenuItem>{" "}
                            <MenuItem>
                              {" "}
                              <span>${item.price}</span>{" "}
                            </MenuItem>
                          </FormControl>
                        );
                      })
                    : "There is no shopping list found."}
                </span>
                {/* </ol> */}
              </FormControl>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openDispute}
          onClose={this.handleCloseDispute}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          className="shoppinglistMOdal "
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleCloseDispute}
          >
            Dispute
          </DialogTitle>
          <DialogContent>
            <div>
              <FormControl>
                <ul
                  className={
                    this.state.paymentDispute ? "showtable" : "hidetable"
                  }
                >
                  <li>
                    <b>Raised By</b>
                  </li>
                  <li>
                    <b>Created At</b>
                  </li>
                  <li>
                    <b> Dispute Status</b>
                  </li>
                </ul>
                <span className="shoppingListData">
                  {this.state.paymentDispute
                    ? this.state.paymentDispute.map((item, index) => {
                        return (
                          <FormControl>
                            <MenuItem>
                              <span> {item.createdBy}</span>
                            </MenuItem>{" "}
                            <MenuItem>
                              {" "}
                              <span>{item.createdAt}</span>{" "}
                            </MenuItem>
                            <MenuItem>
                              {" "}
                              <span>{item.disputeStatus}</span>{" "}
                            </MenuItem>
                          </FormControl>
                        );
                      })
                    : "There is no Dispute Raised."}
                </span>
                {/* </ol> */}
              </FormControl>
            </div>
          </DialogContent>
        </Dialog>
        <div className="adminPaymentSection">
          <h2>5 Largest Transactions</h2>
          {this.state.listData ? (
            <div className="adminPaymentTable">
              <ReactTableComponent
                listData={this.state.listData}
                listConfig={adminPaymentRequestList}
                columns={columns}
                dataCount={this.state.count}
                updatePagination={this.updatePagination}
                initialPage={this.state.pageNo / this.state.rows}
                forSerialNo={{
                  pageNo: this.state.pageNo,
                  pageSize: this.state.rows
                }}
              />
            </div>
          ) : (
            <div className="spinner">
              <DotLoader size={70} color={"#020f1f"} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(LargestPaymentRequests);
