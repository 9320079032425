import React, { Component } from "react";
import GuardianAutomatedBudget from "./GuardianAutomatedBudget";
import AdminAutomatedBudget from "./AdminAutomatedBudget";
export default class AutomatedBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo")).id) {
      console.log("first");
    }else{
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div>
        {localStorage.getItem("role") === "GUARDIAN" ? (
          <GuardianAutomatedBudget />
        ) : (
          <AdminAutomatedBudget />
        )}
      </div>
    );
  }
}
