import React, { Component } from "react";
import "../assets/theme/_bh-form.scss";
import "./login.scss";
import { Link } from "react-router-dom";
import BackdropLoader from "../services/loader";
import { API_URL } from "../Config";
import swal from "sweetalert";
import Logo from "../assets/images/logo-budget-hub.svg";
import User from "../assets/svg/user.svg";
import Lock from "../assets/svg/lock.svg";
import showPass from "../assets/svg/show_pwd.svg";
import hidePass from "../assets/svg/hide_pwd.svg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      show: false,
      loader: false,
      errors: { email: "", password: "" },
      commonError: ""
    };
  }

  handleChange = (e) => {
    if (e.target.id === "email") {
      this.setState({ email: e.target.value, errors: { email: "" } });
    }
    if (e.target.id === "password") {
      this.setState({ password: e.target.value, errors: { password: "" } });
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  validateForm = (email, password) => {
    const { errors } = this.state;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    errors.email = validEmailRegex.test(email.trim())
      ? ""
      : "Email does not exist!";
    errors.password =
      password.trim().length > 6
        ? ""
        : "Password should be greater than 6 characters";
    this.setState({ errors });
    return this.state.errors.email === "Email is not valid!" ||
      this.state.errors.password ===
        "Password should be greater than 6 characters"
      ? false
      : true;
  };

  onSubmit = (e) => {
    // console.log("i am calling")
    e.preventDefault();
    if (this.state.email === "" || this.state.password === "") {
      const { errors } = this.state;
      if (this.state.email === "") errors.email = "Please enter Email-ID";
      if (this.state.password === "") errors.password = "Please enter Password";
      this.setState({ errors });
      return;
    } else if (this.validateForm(this.state.email, this.state.password)) {
      fetch(
        `${API_URL}/login?email=${this.state.email}&password=${this.state.password}&flag=1`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-type": "application/json"
          }
        }
      )
        .then((res) => res.json())
        .then(async (response) => {
          if (
            response.email &&
            response.email !== null &&
            response.email !== ""
          ) {
            await localStorage.setItem("token", response.token);
            let firstName =
              response.firstname !== undefined && response.firstname !== null
                ? response.firstname
                : "";
            let lastName =
              response.lastname !== undefined && response.lastname !== null
                ? response.lastname
                : "";
            let fullName = firstName + " " + lastName;
            let userInitials =
              firstName.substring(0, 1) + lastName.substring(0, 1);
            let data = {
              email: response.email,
              id: response.id,
              fullName: fullName,
              userInitials: userInitials,
              fName: firstName
            };
            localStorage.setItem("role", response.role);
            localStorage.setItem("rows", 10);
            localStorage.setItem("userInfo", JSON.stringify(data));
            this.props.history.push("dashboard");
            this.setState({
              email: "",
              password: ""
            });
          } else if (response.error.status) {
            swal({
              icon: "warning",
              title: "Unable to reach server"
            });
          } else {
            swal({
              icon: "warning",
              title: response.displayMessage
            });
          }
        })
        .catch((e) => {
          swal({
            icon: "warning",
            title: "Incorrect username or password." //now
            // title: e.toString().substring(11, e.length) //before
          });
        });
    }
  };

  render() {
    return (
      <div className="signInNew">
        <BackdropLoader open={this.state.loader} />
        <div className="flex">
          <div className="topRow">
            <p>
              Don't have an account?{" "}
              <Link to="/signup" className="btn btn-primary sm">
                Sign Up
              </Link>
            </p>
          </div>
          <div className="signInWrap">
            <div className="logo">
              <img src={Logo} alt="Logo | Budget hub" />
            </div>
            <div className="innerBox">
              <h1>Sign In</h1>
              <form noValidate onSubmit={this.onSubmit}>
                <ul>
                  <li>
                    <label>Email address</label>
                    <div
                      className={
                        this.state.errors.email
                          ? "bh-input-group error"
                          : "bh-input-group"
                      }
                    >
                      <div className="bh-input-group-text">
                        <img src={User} alt="user email address" />
                      </div>
                      <input
                        value={this.state.email}
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        className="bh-form-control"
                        onChange={this.handleChange}
                        // helperText={
                        //   this.state.errors.email ? this.state.errors.email : ""
                        // }
                      />
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors.email}
                    </span>
                  </li>
                  <li>
                    <label>Password</label>
                    <div
                      className={
                        this.state.errors.email
                          ? "bh-input-group error"
                          : "bh-input-group"
                      }
                    >
                      <div className="bh-input-group-text">
                        <img src={Lock} alt="user passowrd" />
                      </div>
                      <input
                        className="bh-form-control"
                        variant="outlined"
                        margin="normal"
                        id="password"
                        name="password"
                        placeholder="Password"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handleChange}
                        // helperText={
                        //   this.state.errors.password
                        //     ? this.state.errors.password
                        //     : ""
                        // }
                      />
                      <div
                        className="bh-input-group-text"
                        onClick={this.handleClickShowPassword}
                      >
                        <img
                          src={this.state.showPassword ? showPass : hidePass}
                          alt="user passowrd"
                        />
                      </div>
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.errors.password}
                    </span>
                  </li>
                  <span style={{ color: "red" }}>{this.state.commonError}</span>
                  <li>
                    <button className="btn btn-primary full" type="submit">
                      Sign In
                    </button>
                  </li>
                </ul>
              </form>
              <div className="forgotPass">
                <Link to="/forgotPassword">Forgot Password?</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
