export const walletQuery = `
  query($where:JSON){
    allWallets(where:$where){
      Wallets{
        id
        description
        status
        default
        balance
        participantUserdatarel {
          Userdata{
            loginDevice
          }
        }
      }
    }
  }
`;

export const allSupporterQuery = `
  query{
    allUserdata(where:{role:"SUPPORTER",active:1}){
      Userdata{
        id
        firstname
        lastname
        phonenumber
        role
        email
      }
    }
  }
`;
