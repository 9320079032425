export const allParticipantList = {
    showPagination: true,
    showSerialNo: true,
    columns: [
        {
            Header: "First Name",
            accessor: "firstname",
            sortable: false
        },
        {
            Header: "Last Name",
            accessor: "lastname",
            sortable: false
        },
        {
            Header: "User Type",
            accessor: "role",
            sortable: false
        },
        {
            Header: "Email",
            accessor: "email",
            sortable: false
        },
        {
            Header: "Mobile No.",
            accessor: "phonenumber",
            sortable: false
        },
        {
            Header: "Date of birth",
            accessor: "dob",
            sortable: false
        }
    ]
}