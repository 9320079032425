import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import "./transactionDetailsModal.css";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress, IconButton } from "@material-ui/core";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  paymentShoppingList,
  paymentImagesList,
  paymentReceipt,
  saveDispute,
  saveVerification,
  saveNotes,
  saveTransactionPhoto,
  saveTransactionReceipt,
  saveItemCategory,
  saveAuthledgerCategory
} from "./PaymentRquestQuery";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import Loader from "../../assets/Infinity-1s-200px.svg";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import S3Image from "../S3Image";
import { getImage, uploadImage } from "src/network/service/imageService";

export default function TransactionDetailsModal(props) {
  React.useEffect(() => {
    setNoteItem(props?.data?.extraNotes);
    if (
      props.data?.isrepeatpay == 1 &&
      props.data?.paymentStatus == "Complete"
    ) {
      setSelectedCategory(props?.data?.categoryName);
      setItemCategory(props?.data?.categoryList);
    }
    if ("show" in props) {
      setPaymentImages([]);
      getPaymentImages(props.data.id);
      getReceipt(props.data.id);
      getShoppingList(props.data.id);
      //checked1(props.data.id,props.data.createdBy,props.data.isverified)
    }
  }, []);

  const [state, setState] = React.useState({
    view: props.show
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageLoader, setImageLoader] = React.useState(false);
  const [paymentShopping, setPaymentShopping] = React.useState([]);
  const [paymentImages, setPaymentImages] = React.useState([]);
  const [paymentreciept, setPaymentReciept] = React.useState([]);
  const [noteItem, setNoteItem] = React.useState(null);
  const [photoItem, setPhotoItem] = React.useState(null);
  const [receiptItem, setReceiptItem] = React.useState(null);
  const [itemCategory, setItemCategory] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const getShoppingList = (id) => {
    console.log("getShoppingList", id);
    fetchMethod(paymentShoppingList, {
      where: { paymentRequestId: id }
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          res.data.allRequestItemShoppingLists &&
          res.data.allRequestItemShoppingLists.RequestItemShoppingLists.length >
            0
        ) {
          setPaymentShopping(
            res.data.allRequestItemShoppingLists.RequestItemShoppingLists.map(
              (item) => {
                return {
                  id: item.id,
                  name: item.itemName,
                  price: item.itemPrice ? item.itemPrice : 0
                };
              }
            )
          );
        }
      })
      .catch((e) => console.log(e));
  };

  const getPaymentImages = (id) => {
    setImageLoader(true);
    fetchMethod(paymentImagesList, {
      where: { paymentRequestId: id }
    })
      .then((res) => res.json())
      .then((res) => {
        setImageLoader(false);
        if (
          res.data.allRequestItemImages &&
          res.data.allRequestItemImages.RequestItemImages.length > 0
        ) {
          const result = res.data.allRequestItemImages.RequestItemImages.map(
            (item) => {
              return {
                id: item.id,
                url: item.itemImageUrl
              };
            }
          );
          setPaymentImages((pay) => [...result, ...pay]);
        }
      })
      .catch((e) => {
        console.log(e);
        setImageLoader(false);
      });
  };

  const getReceipt = (id) => {
    fetchMethod(paymentReceipt, {
      where: { paymentRequestId: id }
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          res.data.allRequestItemReceipts &&
          res.data.allRequestItemReceipts.RequestItemReceipts.length > 0
        ) {
          const result =
            res.data.allRequestItemReceipts.RequestItemReceipts.map((item) => {
              return {
                id: item.id,
                url: item.receiptUrl
              };
            });
          if (localStorage?.getItem("role") == "GUARDIAN") {
            setPaymentReciept(result);
          } else {
            setPaymentImages((pay) => [...result, ...pay]);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setPaymentImages([]);
    getPaymentImages(props.data.id);
    getReceipt(props.data.id);
    getShoppingList(props.data.id);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const toggleDraweroff = (anchor, open) => (event) => {
    setSelectedCategory(props?.data?.categoryName);
    setNoteItem(props?.data?.extraNotes);
    if ("hideTransactionDetailsModal" in props) {
      props.hideTransactionDetailsModal();
    }
    setPaymentImages([]);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const checked = (id) => {
    checkVerify(id, 1);
  };

  const unchecked = (id) => {
    checkVerify(id, 0);
  };

  const checkVerify = (id, isverified) => {
    const Obj = {
      obj: {
        id: id,
        isverified: isverified
      }
    };
    fetchMethod(saveVerification, Obj)
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data && res.data.savePaymentRequest) {
          toggleDraweroff("view", false);
          if ("hideTransactionDetailsModal" in props) {
            props.hideTransactionDetailsModal();
          }
          props.isChecked(id, isverified);
          if (isverified == 1) {
            swal({
              title: id ? "Checked successfully" : "Error deleting",
              icon: "success"
            });
          } else {
            swal({
              title: id ? "Unchecked successfully" : "Error deleting",
              icon: "success"
            });
          }
        } else {
          console.log("faileddd");
          if (res.error) {
            swal({ title: res.error.message, icon: "warning" });
          } else {
            console.log("verifiedddddddd");
          }
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      });
  };

  const _saveDispute = (transaction) => {
    const Obj = {
      obj: {
        createdAt: Date.now(),
        paymentRequestId: transaction.id,
        disputeStatus: transaction.paymentStatus ?? "Success",
        createdBy: JSON.parse(localStorage.getItem("userInfo")).id
      }
    };
    fetchMethod(saveDispute, Obj)
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data && res.data.saveDispute) {
          swal({ text: "Dispute raised successfully", icon: "success" });
          toggleDraweroff("view", false);
        } else {
          if (res.error) {
            swal({ title: res.error.message, icon: "warning" });
          } else {
            swal({ title: "Dispute error", icon: "warning" });
          }
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      });
  };
  const onClickDownload = async (imgUrl, type, num) => {
    if (imgUrl) {
      setIsLoading(true);
      try {
        const image = await getImage(imgUrl).then((resp) => fetch(resp.data));
        const imageBlog = await image?.blob();
        const imageURL = URL?.createObjectURL(imageBlog);
        const link = document?.createElement("a");
        link.href = imageURL;
        link.download =
          type === "Payment" ? `product_image_${num}` : `receipt_image_${num}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };
  const _saveNote = (transaction) => {
    if (noteItem != null && noteItem != "" && noteItem != undefined) {
      const Obj = {
        obj: {
          id: transaction.id,
          extraNotes: noteItem
        }
      };
      setIsLoading(true);
      fetchMethod(saveNotes, Obj)
        .then((response) => response.json())
        .then((res) => {
          setIsLoading(false);
          if (res && res.data && res.data.savePaymentRequest) {
            props.data.extraNotes = noteItem;
            swal({ text: "Notes saved successfully", icon: "success" });
            toggleDraweroff("view", false);
          } else {
            if (res.error) {
              swal({ title: res.error.message, icon: "warning" });
            } else {
              swal({ title: "Notes error", icon: "warning" });
            }
          }
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
        });
    }
  };
  const uploadFile = async (file) => {
    const formData = new FormData();
    let fileName = moment() + "_" + file.name;
    formData.append("file", file, fileName);
    return await uploadImage(formData).catch((err) => {
      swal("Please try again", { icon: "error" });
    });
  };
  const addPhoto = async (id, file) => {
    var pattern = /image-*/;
    if (!file[0].type.match(pattern)) {
      swal({ title: "Only images are allowed", icon: "warning" });
    } else if (paymentImages.length == 4) {
      swal({ title: "You can upload maximum 4 photos", icon: "warning" });
    } else {
      let fileUploadRes = await uploadFile(file[0]);
      if (fileUploadRes?.data) {
        const Obj = {
          obj: {
            paymentRequestId: id,
            itemImageUrl: fileUploadRes.data
          }
        };
        setIsLoading(true);
        fetchMethod(saveTransactionPhoto, Obj)
          .then((response) => response.json())
          .then((res) => {
            setIsLoading(false);
            if (res && res.data && res.data.saveRequestItemImage) {
              swal({ text: "Photo uploaded successfully", icon: "success" });
              toggleDraweroff("view", false);
              setPaymentImages([]);
              getPaymentImages(id);
            } else {
              if (res.error) {
                swal({ title: res.error.message, icon: "warning" });
              } else {
                swal({ title: "Upload error", icon: "warning" });
              }
            }
          })
          .catch((e) => {
            swal({ title: e.message, icon: "warning" });
          });
      }
    }
  };
  const addReceipt = async (id, file) => {
    var pattern = /image-*/;
    if (!file[0].type.match(pattern)) {
      swal({ title: "Only images are allowed", icon: "warning" });
    } else if (paymentreciept.length == 4) {
      swal({ title: "You can upload maximum 4 receipts", icon: "warning" });
    } else {
      let fileUploadRes = await uploadFile(file[0]);
      if (fileUploadRes?.data?.result?.files?.file[0]?.name) {
        const Obj = {
          obj: {
            paymentRequestId: id,
            receiptUrl: fileUploadRes.data.result.files.file[0].name
          }
        };
        setIsLoading(true);
        fetchMethod(saveTransactionReceipt, Obj)
          .then((response) => response.json())
          .then((res) => {
            setIsLoading(false);

            if (res && res.data && res.data.saveRequestItemReceipt) {
              swal({ text: "Receipt uploaded successfully", icon: "success" });
              toggleDraweroff("view", false);
              setPaymentReciept([]);
              getReceipt(id);
            } else {
              if (res.error) {
                swal({ title: res.error.message, icon: "warning" });
              } else {
                swal({ title: "Upload error", icon: "warning" });
              }
            }
          })
          .catch((e) => {
            swal({ title: e.message, icon: "warning" });
          });
      }
    }
  };
  const saveCategory = (id, selectedCategory) => {
    if (selectedCategory > 0) {
      const Obj = {
        obj: {
          id: id,
          itemCategoryId: selectedCategory
        }
      };
      setIsLoading(true);
      fetchMethod(saveItemCategory, Obj)
        .then((response) => response.json())
        .then((res) => {
          setIsLoading(false);
          if (res && res.data && res.data.savePaymentRequest) {
            swal({ text: "Item category saved successfully", icon: "success" });
            toggleDraweroff("view", false);
          } else {
            if (res.error) {
              swal({ title: res.error.message, icon: "warning" });
            } else {
              swal({ title: "Update error", icon: "warning" });
            }
          }
        })
        .catch((e) => {
          setIsLoading(false);
          swal({ title: e.message, icon: "warning" });
        });
    }
  };

  const saveCategoryInAuthledger = (id, selectedCategory) => {
    if (selectedCategory > 0) {
      const Obj = {
        obj: {
          id: id,
          itemcatid: selectedCategory
        }
      };
      setIsLoading(true);
      fetchMethod(saveAuthledgerCategory, Obj)
        .then((response) => response.json())
        .then((res) => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          swal({ title: e.message, icon: "warning" });
        });
    }
  };

  const handleNotesLength = () => {
    swal({ title: "Maximum 255 characters are allowed", icon: "warning" });
  };
  return (
    <div>
      {"show" in props ? (
        ""
      ) : (
        <IconButton onClick={toggleDrawer("view", true)}>
          <VisibilityIcon />
        </IconButton>
      )}
      <Drawer
        anchor={"right"}
        open={state["view"]}
        onClose={toggleDraweroff("view", false)}
      >
        <Box sx={{ width: 600 }} role="presentation">
          <div className="Transaction-titel">
            <h2>
              Transaction Details{" "}
              <IconButton className="col-xs-1 ">
                <CloseIcon onClick={toggleDraweroff("view", false)} />
              </IconButton>
            </h2>
          </div>
          <hr />
          <div className="container">
            <div className="row">
              <div className="tag col-xs-5">
                <p className="headP">Transactions Id</p>
                <h6>{props.data.id}</h6>
              </div>
              <div className="tag col-xs-5">
                <p className="headP">{`Date & time`}</p>
                <h6>{props.data.transactiondateValue}</h6>
              </div>
            </div>
            <div className="col-xs-5">
              <hr />
            </div>
            <div className="row">
              <div className="tag col-xs-5">
                <p className="headP">Made by</p>
                <h6>{props.data.madeby}</h6>
              </div>
              <div className="tag col-xs-5">
                <p className="headP">Made for</p>
                <h6>{props.data.madefor}</h6>
              </div>
            </div>
            <div className="col-xs-5">
              <hr />
            </div>
            <div className="row">
              <div className="tag col-xs-5">
                <p className="headP">Store selected</p>
                <h6>{props.data.storeName}</h6>
              </div>
              <div className="tag col-xs-5">
                <p className="headP">Merchant description</p>
                <h6>{props.data.merchantname}</h6>
              </div>
            </div>
            <div className="col-xs-5">
              <hr />
            </div>
            <div className="row">
              {props.data.isrepeatpay == 1 &&
              props.data.paymentStatus == "Complete" ? (
                <div className="tag col-xs-5">
                  <Autocomplete
                    id="combo-box-demo"
                    freeSolo={false}
                    options={itemCategory}
                    getOptionLabel={(option) =>
                      option.name ? option.name : option
                    }
                    sx={{ width: 300 }}
                    onChange={(data, e) => {
                      props.data.categoryName = e
                        ? e.name
                        : props.data.categoryName;
                      setSelectedCategory(e ? e.name : null);
                      saveCategory(props.data.id, e ? e.id : null);
                      saveCategoryInAuthledger(
                        props.data.authid,
                        e ? e.id : null
                      );
                    }}
                    value={selectedCategory || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select category"
                        fullWidth
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="tag col-xs-5">
                  <p className="headP">Category</p>
                  <h6>{props.data.categoryName}</h6>
                </div>
              )}
              <div className="tag col-xs-5">
                <p className="headP">Allocated amount</p>
                <h6>{props.data.requestedAmount}</h6>
              </div>
            </div>
            <div className="col-xs-5">
              <hr />
            </div>
            <div className="row">
              <div className="tag col-xs-5">
                <p className="headP">Spent amount</p>
                <h6>{props.data.spentamount}</h6>
              </div>
              <div className="tag col-xs-5">
                <p className="headP">Change amount</p>
                <h6>{props.data.changeamount}</h6>
              </div>
            </div>
            <div className="col-xs-5">
              <hr />
            </div>
            <div style={{ paddingTop: "15px" }}>
              <TextField
                className="textFilters"
                label="Notes"
                variant="standard"
                value={noteItem}
                onChange={(e) => {
                  e.target.value.length <= 255
                    ? setNoteItem(e.target.value)
                    : handleNotesLength();
                }}
              />
            </div>
            <div style={{ paddingTop: "10px" }}>
              <button
                className="btn btn-primary sm raise-query"
                onClick={() => _saveNote(props.data)}
              >
                Save
              </button>
            </div>
            {paymentImages.length > 0 ? (
              <div className="col-xs-5">
                <hr />
              </div>
            ) : (
              ""
            )}
            {paymentImages.length > 0 && (
              <div className={"tag col-xs-5"}>
                <p>Product Image</p>
              </div>
            )}
            {imageLoader ? (
              <div className="Transaction-Images">
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              </div>
            ) : null}

            <div
              className={paymentImages.length > 0 ? `Transaction-Images` : ""}
            >
              {paymentImages.length > 0 ? (
                <>
                  {paymentImages.map((item, index) => {
                    if (!!item?.url) {
                      return (
                        <div className="images" key={index}>
                          <S3Image
                            className="d-block w-100 imagess"
                            src={item.url}
                            alt="First slide"
                          />
                          <IconButton
                            className="iconnn"
                            onClick={() =>
                              onClickDownload(item.url, "Payment", index)
                            }
                          >
                            <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>
                          </IconButton>
                        </div>
                      );
                    }
                  })}
                </>
              ) : (
                ""
              )}
            </div>
            {paymentreciept.length > 0 && (
              <div className={"tag col-xs-5"}>
                <p>Receipt</p>
              </div>
            )}
            <div
              className={paymentreciept.length > 0 ? `Transaction-Images` : ""}
            >
              {paymentreciept.length > 0 && (
                <>
                  {paymentreciept.map((item, index) => {
                    if (!!item?.url) {
                      return (
                        <div className="images">
                          <S3Image
                            className="d-block w-100 imagess"
                            src={item.url}
                            alt="First slide"
                          />
                          <IconButton
                            className="iconnn"
                            onClick={() =>
                              onClickDownload(item.url, "Reciept", index)
                            }
                          >
                            <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>
                          </IconButton>
                        </div>
                      );
                    }
                  })}
                </>
              )}
            </div>
            <div className="col-xs-5">
              <hr />
            </div>
            <div className="row">
              <div className="col-xs-5">
                <div className="transaction-btn-wrap">
                  <Button
                    variant="contained"
                    component="label"
                    className="btn btn-primary sm raise-query"
                  >
                    Add photo
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        setPhotoItem(e.target.files);
                        addPhoto(props.data.id, e.target.files);
                      }}
                    />
                  </Button>
                </div>
              </div>
              <div className="tag col-xs-5">
                <div className="transaction-btn-wrap">
                  <Button
                    variant="contained"
                    component="label"
                    className="btn btn-primary sm raise-query"
                  >
                    Add receipt
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        setReceiptItem(e.target.files);
                        addReceipt(props.data.id, e.target.files);
                      }}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-5">
                <div className="transaction-btn-wrap">
                  {props.data.isverified == "Yes" ? (
                    <button
                      className="btn btn-primary sm raise-query"
                      onClick={() => unchecked(props.data.id)}
                    >
                      Unchecked
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary sm raise-query"
                      onClick={() => checked(props.data.id)}
                    >
                      Checked
                    </button>
                  )}
                </div>
              </div>
              <div className="tag col-xs-5">
                <div className="transaction-btn-wrap">
                  <button
                    className="btn btn-primary sm raise-query"
                    // onClick={toggleDraweroff("view", false)}
                    onClick={() => _saveDispute(props.data)}
                  >
                    Raise query
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>

      {isLoading && (
        <div className="spinner">
          <img src={Loader}></img>
        </div>
      )}
    </div>
  );
}
