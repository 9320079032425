import React, { useEffect } from "react";
import ConnectedEntitiesTab from "./ConnectedEntitiesTab";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletDetailsAction } from "./walletSlice";
import WalletTable from "./WalletTable";
import { DotLoader } from "react-spinners";

function AdminWalletDetail() {
  const { walletId } = useParams();
  const dispatch = useDispatch();
  const {
    walletDetail: { wallet },
    loading
  } = useSelector((state) => state.wallet);

  useEffect(() => {
    dispatch(fetchWalletDetailsAction(walletId));
  }, [dispatch, walletId]);
  return (
    <div className="container">
      {loading ? (
        <div className="spinner">
          <DotLoader size={70} color={"#020f1f"} />
        </div>
      ) : (
        <div className="participantDocumentVerifivation">
          <WalletTable wallet={wallet} />
          <ConnectedEntitiesTab id={wallet?.id} />
        </div>
      )}
    </div>
  );
}

export default AdminWalletDetail;
