import React, { Component } from "react";
import ReactTableComponent from "../../../ReactTable/ReactTable";
import { adminPaymentRequestList } from "./ParticipantTabSetupConfig";
import { fetchMethod } from "../../../FetchMethod";
import swal from "sweetalert";
import { connectedPaymentRequestQuery } from "./ParticipantTabQuery";

import { paymentQuery } from "../../PaymentRequests/PaymentRquestQuery";
import { DotLoader } from "react-spinners";
import "./participantTabSetup.css";
class PaymentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      filter: {
        participantId: this.props.id,
        order: "updatedAt desc",
      },
    };
  }

  componentWillMount() {
    this.connectedPaymentRequest();
  }

  connectedPaymentRequest = () => {
    fetchMethod(paymentQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          if (
            res &&
            res.data &&
            res.data.allPaymentRequests &&
            res.data.allPaymentRequests.PaymentRequests
          ) {
            res.data.allPaymentRequests.PaymentRequests.map((item) => {
              return (
                (item.participantName =
                  item.createdBy === item.participantId
                    ? "SELF"
                    : item.fkPaymentRequestParticipantIdrel.Userdata &&
                      item.fkPaymentRequestParticipantIdrel.Userdata.length > 0
                    ? item.fkPaymentRequestParticipantIdrel.Userdata[0].firstname.concat(
                        item.fkPaymentRequestParticipantIdrel.Userdata &&
                          item.fkPaymentRequestParticipantIdrel.Userdata
                            .length > 0
                          ? " " +
                              (item.fkPaymentRequestParticipantIdrel.Userdata[0]
                                .lastname != null
                                ? item.fkPaymentRequestParticipantIdrel
                                    .Userdata[0].lastname
                                : "")
                          : ""
                      )
                    : ""),
                (item.madeby =
                  item.fkPaymentRequestSupporterIdrel.Userdata &&
                  item.fkPaymentRequestSupporterIdrel.Userdata.length > 0
                    ? item.fkPaymentRequestSupporterIdrel.Userdata[0].firstname.concat(
                        " " +
                          (item.fkPaymentRequestSupporterIdrel.Userdata[0]
                            .lastname != null
                            ? item.fkPaymentRequestSupporterIdrel.Userdata[0]
                                .lastname
                            : "")
                      )
                    : ""),
                // (item.madeby =
                //   item.fkpaymentrequestcreatebymaprel.Userdata &&
                //   item.fkpaymentrequestcreatebymaprel.Userdata.length > 0
                //     ? item.fkpaymentrequestcreatebymaprel.Userdata[0].firstname.concat(
                //         item.fkpaymentrequestcreatebymaprel.Userdata &&
                //           item.fkpaymentrequestcreatebymaprel.Userdata.length >
                //             0
                //           ? " " +
                //               (item.fkpaymentrequestcreatebymaprel.Userdata[0]
                //                 .lastname != null
                //                 ? item.fkpaymentrequestcreatebymaprel
                //                     .Userdata[0].lastname
                //                 : "")
                //           : ""
                //       )
                //     : ""),
                (item.categoryName =
                  item.fkPaymentRequestItemCategoryIdrel !== null &&
                  item.fkPaymentRequestItemCategoryIdrel.MasterItemCategories &&
                  item.fkPaymentRequestItemCategoryIdrel.MasterItemCategories
                    .length > 0
                    ? this.capitalizeFirstLetter(
                        item.fkPaymentRequestItemCategoryIdrel
                          .MasterItemCategories[0].categoryName
                      )
                    : ""),
                // (item.requestedAmount = item.requestedAmount
                //   ? "$" + item.requestedAmount
                //   : ""),
                (item.requestedAmount = item.requestedAmount
                  ? "$" + item.requestedAmount
                  : ""),
                (item.purchaseAmount =
                  item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
                  item?.authledgerPaymentRequestMap?.Authledgers[0]
                    .spentamount !== null
                    ? "$" +
                      item?.authledgerPaymentRequestMap.Authledgers[0]
                        .spentamount
                    : ""),
                (item.changeamount =
                  item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
                  item?.authledgerPaymentRequestMap?.Authledgers[0]
                    .changeamount !== null
                    ? "$" +
                      item?.authledgerPaymentRequestMap.Authledgers[0]
                        .changeamount
                    : item?.paymentStatus === "Complete"
                    ? "$0"
                    : ""),
                (item.spentamount =
                  item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
                  item?.authledgerPaymentRequestMap?.Authledgers[0]
                    .spentamount !== null
                    ? "$" +
                      item?.authledgerPaymentRequestMap.Authledgers[0]
                        .spentamount
                    : ""),
                (item.cardlimit =
                  item?.fkPaymentMap?.SuperLedgers.length > 0
                    ? "$" +
                      (item?.fkPaymentMap.SuperLedgers?.length
                        ? item?.fkPaymentMap.SuperLedgers[
                            item?.fkPaymentMap.SuperLedgers?.length - 1
                          ].cardlimit
                        : 0)
                    : ""),
                (item.isverified = item?.isverified == 0 ? "No" : "Yes"),
                (item.merchantname = item
                  ? item.authledgerPaymentRequestMap
                    ? item.authledgerPaymentRequestMap.Authledgers.length > 0
                      ? item.authledgerPaymentRequestMap.Authledgers[0][
                          "merchantname"
                        ]
                      : ""
                    : ""
                  : ""),
                (item.authid = item
                  ? item.authledgerPaymentRequestMap
                    ? item.authledgerPaymentRequestMap.Authledgers.length > 0
                      ? item.authledgerPaymentRequestMap.Authledgers[0]["id"]
                      : ""
                    : ""
                  : ""),
                item.transactiondate !== undefined &&
                item.transactiondate !== null
                  ? this.formatDate(item.transactiondate)
                  : ""
              );
            });
            this.setState({
              count:
                res.data &&
                res.data.allPaymentRequests &&
                res.data.allPaymentRequests !== null
                  ? res.data.allPaymentRequests.totalCount
                  : "",
              listData:
                res.data &&
                res.data.allPaymentRequests &&
                res.data.allPaymentRequests !== null
                  ? res.data.allPaymentRequests.PaymentRequests
                  : "",
            });
          }
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [] });
      });
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: parseInt(pageNumber),
        rows: parseInt(size),
      },
      () => {
        this.connectedPaymentRequest();
      }
    );
  };

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45,
      },
      // {
      //   Header: "Date",
      //   accessor: "createdAt",
      //   Cell: (row) => {
      //     return (
      //       <div className="dot">{this.formatDate(row.original.createdAt)}</div>
      //     );
      //   },
      //   sortable: true,
      // },
    ];
    const columns = nameColumn.concat(adminPaymentRequestList.columns);
    return (
      <div>
        {this.state.listData ? (
          <div className="paymentRequest">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={adminPaymentRequestList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows,
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}
      </div>
    );
  }
}

export default PaymentRequest;
