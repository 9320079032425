import React, { useState } from "react";
import ReactTableComponent from "src/ReactTable/ReactTable";
import "src/Components/Participant/ParticipantTabSetup/participantTabSetup.css";
import { useDispatch, useSelector } from "react-redux";
import { deepCopy } from "src/utils/utils";
import { supporterList } from "./ConnectedSupportersConfig";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { useEffect } from "react";
import {
  fetchAllSupportersAction,
  fetchWalletDetailsAction
} from "./walletSlice";
import { connectSupporter } from "src/network/service/walletService";
import swal from "sweetalert";

import { deleteSupporter } from "src/network/service/walletService";
import ConfirmationPrompt from "src/Components/UI/ConfirmationPrompt";

function WalletConnectedSupporters() {
  const { wallet, supporters, supporterOptions } = useSelector(
    (state) => state.wallet.walletDetail
  );

  const [openModal, setOpenModal] = useState(false);
  const [selectedSupporterId, setSelectedSupporterId] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllSupportersAction());
  }, [dispatch]);

  const handleDeleteWallet = (data) => {
    ConfirmationPrompt({
      visible: true,
      title: "Are you sure! \nDo you want to delete this Supporter?",
      onConfirm: () => {
        deleteSupporter(wallet.id, data.original.id).then(() => {
          dispatch(fetchWalletDetailsAction(wallet.id));
        });
      }
    });
  };

  const actionButton = [
    {
      Header: "",
      sortable: false,
      Cell: (row) => (
        <div>
          <Button onClick={(e) => handleDeleteWallet(row)}>Remove</Button>
        </div>
      )
    }
  ];

  const columns = nameColumn.concat(supporterList.columns).concat(actionButton);

  const handleSupporterSelection = (e, value) => {
    if (value !== null) {
      setSelectedSupporterId(value?.id);
    } else {
      setSelectedSupporterId(null);
    }
  };

  const handleConnectSupporter = () => {
    const supporterId = selectedSupporterId;
    setSelectedSupporterId(null);
    connectSupporter(wallet?.id, supporterId)
      .then((resp) => {
        swal({
          icon: "success",
          title: "Supporter is successfully connected with the wallet"
        });
        setOpenModal(false);
        dispatch(fetchWalletDetailsAction(wallet.id));
      })
      .catch((error) => {
        swal({
          title: "Something went wrong",
          icon: "error"
        });
      });
  };

  return (
    <>
      <div className="connectedSupporterTable">
        <ReactTableComponent
          listData={
            supporters !== null && supporters !== undefined
              ? deepCopy(supporters)
              : []
          }
          listConfig={supporterList}
          columns={columns}
          dataCount={supporters?.length}
          forSerialNo={{
            pageNo: 1,
            pageSize: 1
          }}
        />
      </div>
      <Button
        className="addsupportBtn"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        CONNECT SUPPORTER{" "}
      </Button>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="add-supporter-modal"
        ariadescribedby="modal for connecting supporter with wallet"
        className="chooseBuisness selectSupportModal"
      >
        <DialogTitle id="customized-dialog-title" onClose={() => {}}>
          Select Supporter
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            options={supporterOptions}
            getOptionLabel={(option) =>
              option ? option.firstname + " " + option.lastname : ""
            }
            style={{ width: 300 }}
            onChange={handleSupporterSelection}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supporter"
                variant="outlined"
                style={{ paddingRight: 0 }}
              />
            )}
          />
        </DialogContent>
        <DialogButton>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button
            onClick={handleConnectSupporter}
            disabled={
              selectedSupporterId === null || selectedSupporterId === undefined
            }
          >
            Save
          </Button>
        </DialogButton>
      </Dialog>
    </>
  );
}

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const nameColumn = [
  {
    Header: "S No.",
    Cell: (row) => {
      return <div className="dot">{row.original.sNo}</div>;
    },
    width: 45
  }
];

export default WalletConnectedSupporters;
