import React, { Component } from "react";
import FormComponent from "../../Form/FormComponent";
import { addBuisnessConfig } from "./BuisnessConfig";
import { fetchMethod } from "../../FetchMethod";
import {
    saveBusiness,
    updateBankDetail,
    allcardtypes,
    getCardDetailsQuery,
    businessTypesListing,
    addBusiness,
    categoryQueryData,
    businessTypeQuery,
    businessType
} from "./BuisnessQuery";
import swal from "sweetalert";
import "./addBuisness.css";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
export default class GuardianAddbusinessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardtypeOptions: undefined,
            storeTypeOptions: undefined,
            participantId: "participantId" in this.props ? this.props.participantId : undefined,
            drawerOpen: this.props.drawerOpen
        };
    }

    componentWillMount() {
        this.prepareAddBusinessConfig();
        this.getBusinessTypes();
    }

    prepareAddBusinessConfig = () => {
        addBuisnessConfig.fields = addBuisnessConfig.fields.filter(field => {
            if ((!field.name.includes("storeType") && !field.name.includes("deleteButton") && !field.name.includes('Button')) || field.name.includes('addStoreType1')) {
                return true;
            } else {
                delete addBuisnessConfig.validations[field.name];
                return false;
            }
        });
    }

    getBusinessTypes = () => {
        if (localStorage.getItem('role') === 'GUARDIAN') {
            fetchMethod(businessType, {
                where: { isActive: 1 }
            })
                .then(res => res.json())
                .then(res => {
                    if (res?.data?.allMasterItemCategories?.MasterItemCategories.length) {
                        this.setState({
                            storeTypeOptions: res.data.allMasterItemCategories.MasterItemCategories.map(business => {
                                return {
                                    id: business.id,
                                    name: business.categoryName.charAt(0).toUpperCase() + business.categoryName.slice(1)
                                }
                            })
                        })
                    }
                })
                .catch(() => {
                    swal("Something went wrong", {
                        icon: 'warning'
                    })
                })
        }
    }

    closeForm = () => {
        this.props.handleDrawerClose()
        if("openBusinessModel" in this.props)
        this.props.openBusinessModel()
    };

    preSubmitChanges = e => {
        let businessObj = {
            active: 1,
            storeName: e.storeName,
            storeTypeId: e.storeTypeId,
            abnNumber: e.abnNumber,
            websiteUrl: e.websiteUrl,
            email: e.email,
            txnLocationAddress: e.txnLocationAddress,
            txnLocationCity: e.txnLocationCity,
            txnLocationState: e.txnLocationState,
            txnLocationCountry: e.txnLocationCountry,
            contactLocationAdress: e.contactLocationAdress,
            contactLocationCity: e.contactLocationCity,
            contactLocationState: e.contactLocationState,
            contactLocationCountry: e.contactLocationCountry,
            storeManagerName: e.storeManagerName,
            adminId: JSON.parse(localStorage.getItem("userInfo")).id,
            createdBy: `${JSON.parse(localStorage.getItem("userInfo")).id}`,
            mobileNo: parseFloat(e.mobileNo),
            storeTelephoneNo: parseFloat(e.storeTelephoneNo),
            contactLocationZipCode: parseFloat(e.contactLocationZipCode),
            txnLocationZipCode: parseFloat(e.txnLocationZipCode)
        };
        fetchMethod(addBusiness, { data: businessObj })
            .then(res => res.json())
            .then(response => {
                if (response?.data?.BusinessAddNewBusiness?.status === 200) {
                    this.props.handleDrawerClose()
                    swal({ text: "Business added successfully", icon: "success" });
                    if("openBusinessModel" in this.props)this.props.openBusinessModel()
                }
                else {
                    swal("Please try again", { icon: "error" });
                }
            })
            .catch((err) => {
                console.log(err,"=================>")
                swal("Something went wrong", {
                    icon: 'warning'
                })
            });
        return false;
    };

    render() {
        return (
            <>
                {this.state.storeTypeOptions ? (
                    <div className="addBuisnessSection">
                        <Drawer
                            anchor={"right"}
                            open={this.props.drawerOpen}
                            onClose={() => this.props.handleDrawerClose()}
                        >
                            <Box
                                sx={{ width: 600 }}
                                role="presentation"
                                className="Guardianbusinessmodal"
                            >
                                <h3>Add Business</h3>
                                <div className="addGuardianbusinessform">
                                <FormComponent
                                    formConfig={addBuisnessConfig}
                                    preSubmitChanges={this.preSubmitChanges}
                                    buttonTitleCSS="buisnessSaveButton"
                                    modalCloseCallback={() => { }}
                                    closeButton={this.closeForm}
                                    params={{
                                        storeTypeOptions: this.state.storeTypeOptions
                                    }}
                                />
                                </div>
                            </Box>
                        </Drawer>
                    </div>)
                    : ("")}
            </>
        )
    }
}
