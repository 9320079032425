import React, { Component } from "react";
import { fetchMethod } from "../../FetchMethod";
import {
  getParticipantBarGraphDataQuery,
  getPieGraphDataQuery,
  getPieGraphDataQueryNew,
  paymentGuardianRequestDataQuery
} from "./DashboardQuery";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";
import { CustomAlert } from "../CustomAlert/CustomAlert";
import Warning from "../../assets/svg/warning.svg";
import {
  userdataQuery,
  getParticipentCardDetails,
  getItemCategory
} from "../PaymentRequests/PaymentRquestQuery";
import defaultUserImages from "../../assets/images/defaultUser.png";
import defaultProductImages from "../../assets/images/noImgFound.jpg";
import { DotLoader } from "react-spinners";
import { Bar, Doughnut } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { getMonthsData, getTopThreeYear } from "./CommonMethod";
import TransactionDetailsModal from "../PaymentRequests/transactionDetailsModal";
import { CollectionsOutlined } from "@material-ui/icons";
import S3Image from "../S3Image";

const existingBudgetColor = "rgba(255, 99, 132, 0.5)";
const spendBudgetColor = "rgba(53, 162, 235, 0.5)";
const backgroundColorArr = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)"
];
const borderColorArr = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)"
];

class GuardianDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: 1,
      loader: false,
      listData: undefined,
      participantxyz: null,
      pageNo: 8,
      rows: 8,
      participantOptions: [],
      participantCardDetailsList: [],
      filter: {},
      currentParicipantId: null,
      showError: false,
      bar_graph: {
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            },
            title: {
              display: false
              // text: "Chart.js Bar Chart",
            }
          }
        },
        data: {}
      },
      pie_graph: {
        data: {}
      },
      monthsList: [],
      selectedMonth: new Date().getMonth() + 1,
      yearsList: [],
      selectedYear: new Date().getFullYear(),
      selectedFilter: "YEARLY", //'MONTHLY',
      isBarGraphData: false,
      isPieChartData: false,
      selectedParticipant: 0,
      transactionCount: 0,
      transactionDetailsModalShow: false,
      dataFortransactionDetailsModal: undefined,
      itemCategory: []
    };
  }
  async componentWillMount() {
    this.getFilterData();

    // await this.getPieChartData();
    await this.getCategories();
    await this.getAllPaymentData();
    await this.participants();
    await this.participantCardLimit();
  }

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  getCategories = async () => {
    let obj = {
      isActive: 1,
      forrepeatpay: 1
    };
    this.setState({ loading: true });
    await fetchMethod(getItemCategory, { where: obj })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ loading: false });
        let result =
          res.data?.allMasterItemCategories?.MasterItemCategories?.map((i) => {
            return {
              id: i.id !== null ? i.id : "",
              name:
                i.categoryName !== null
                  ? this.capitalizeFirstLetter(i.categoryName)
                  : ""
            };
          });
        this.state.itemCategory = result;
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        this.state.itemCategory = [];
        swal({ title: error.message, icon: "warning" });
      });
  };

  getFilterData = () => {
    const year = getTopThreeYear();
    const month = getMonthsData();

    this.setState({
      ...this.state,
      monthsList: month.list,
      yearsList: year.list
    });
  };

  getPieChartData = async (participantId) => {
    try {
      const response = await fetchMethod(getPieGraphDataQueryNew, {
        participantId: participantId, //199
        duration: this.state.selectedFilter //"MONTHLY"
        // durationValue:
        //   this.state.selectedFilter === "YEARLY"
        //     ? this.state.selectedYear
        //     : this.state.selectedMonth, //9,
      }).then((res) => res.json());
      if (
        response &&
        response.data &&
        response.data.BudgetSpendingBySupportWorkerNew
      ) {
        const supporterSpendData =
          response.data.BudgetSpendingBySupportWorkerNew;
        // console.log(
        //   "supporterSpendData",
        //   supporterSpendData,
        //   supporterSpendData.sum2,
        //   supporterSpendData.sum1
        // );
        if (supporterSpendData.status == 200) {
          const pie_graphT = { ...this.state.pie_graph };
          pie_graphT["data"] = {
            labels: supporterSpendData.sum2,
            datasets: [
              {
                data: supporterSpendData.sum1,
                backgroundColor: [...backgroundColorArr],
                borderColor: [...borderColorArr],
                borderWidth: 1
              }
            ]
          };
          this.setState({
            ...this.state,
            pie_graph: pie_graphT,
            isPieChartData: true
          });
        } else {
          this.setState({
            ...this.state,
            isPieChartData: false
          });
        }
      } else {
        this.setState({
          ...this.state,
          isPieChartData: true
        });
      }
    } catch (error) {}
  };

  getBarGraphData = async (participantId) => {
    try {
      const response = await fetchMethod(getParticipantBarGraphDataQuery, {
        participantId: participantId, //211
        duration: this.state.selectedFilter //"MONTHLY",
        // durationValue:
        //   this.state.selectedFilter === "YEARLY"
        //     ? this.state.selectedYear
        //     : this.state.selectedMonth, //9,
      }).then((res) => res.json());
      if (
        response &&
        response.data &&
        response.data.BudgetExpenses.status === 200
      ) {
        const data = response.data.BudgetExpenses;
        if (data) {
          const labelsObj = data.data ? data.data.reverse() : [];
          const bar_graphT = { ...this.state.bar_graph };
          if (this.state.selectedFilter == "MONTHLY") {
            bar_graphT["data"] = {
              labels: labelsObj.map((item) => item.month),
              datasets: [
                {
                  label: "Existing Card Limit",
                  data: labelsObj.map((item) => {
                    // const tempdata = data.data.find(
                    //   (item1) => item1.participantId === item.participantId
                    // );

                    if (item) {
                      return item.cardLimit;
                    } else {
                      return 0;
                    }
                  }),
                  backgroundColor: existingBudgetColor
                },
                {
                  label: "Spent Amount",
                  data: labelsObj.map((item) => {
                    // const tempdata = data.data.find(
                    //   (item1) => item1.participantId === item.participantId
                    // );

                    if (item) {
                      return item.spentAmount;
                    } else {
                      return 0;
                    }
                  }),
                  backgroundColor: spendBudgetColor
                }
              ]
            };
          } else {
            bar_graphT["data"] = {
              labels: labelsObj.map((item) => item.yr),
              datasets: [
                {
                  label: "Existing Card Limit",
                  data: labelsObj.map((item) => {
                    // const tempdata = data.data.find(
                    //   (item1) => item1.participantId === item.participantId
                    // );console.log("tempdata existing budget", data);
                    if (item) {
                      return item.cardLimit ? item.cardLimit.toFixed(2) : 0;
                    } else {
                      return 0;
                    }
                  }),
                  backgroundColor: existingBudgetColor
                },
                {
                  label: "Spent Amount",
                  data: labelsObj.map((item) => {
                    // const tempdata = data.data.find(
                    //   (item1) => item1.participantId === item.participantId
                    // );

                    if (item) {
                      return item.spentAmount ? item.spentAmount.toFixed(2) : 0;
                    } else {
                      return 0;
                    }
                  }),
                  backgroundColor: spendBudgetColor
                }
              ]
            };
          }
          this.setState({
            ...this.state,
            bar_graph: bar_graphT,
            isBarGraphData: true
          });
        } else {
          this.setState({ ...this.state, isBarGraphData: false });
        }
      } else {
        this.setState({ ...this.state, isBarGraphData: false });
      }
    } catch (error) {}
  };

  selectChange = (e) => {
    const { name, value } = e.target;
    let stateT = { ...this.state };
    if (name === "year") {
      stateT["selectedYear"] = +value;
      // this.setState({ ...this.state, selectedYear: +value });
    }
    if (name === "month") {
      stateT["selectedMonth"] = +value;
      // this.setState({ ...this.state, selectedMonth: +value });
    }
    if (name === "participants") {
      stateT["selectedParticipant"] = +value;
      // this.setState({ ...this.state, selectedParticipant: +value });
    }
    this.setState({ ...stateT }, () => {
      // if (name !== "participants") {
      //   this.getPieChartData();
      // }

      this.getBarGraphData(this.state.selectedParticipant);
      this.getPieChartData(this.state.selectedParticipant);
    });
  };

  handleRadioChange = (e) => {
    this.setState(
      { ...this.state, selectedFilter: e.currentTarget.value },
      () => {
        this.getPieChartData(this.state.selectedParticipant);
        this.getBarGraphData(this.state.selectedParticipant);
      }
    );
  };

  participants = async () => {
    if (
      localStorage.getItem("userInfo") &&
      JSON.parse(localStorage.getItem("userInfo")).id
    ) {
      await fetchMethod(userdataQuery, {
        where: {
          guardianId: JSON.parse(localStorage.getItem("userInfo")).id,
          active: 1,
          order: "id desc",
          role: "PARTICIPANT"
        }
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.data?.allUserdata?.Userdata) {
            const id = res?.data?.allUserdata?.Userdata[0]
              ? res?.data?.allUserdata?.Userdata[0].id
              : 0;
            this.getBarGraphData(id);
            this.getPieChartData(id);
          }
          res.data.allUserdata != undefined
            ? this.setState({
                participantOptions: res.data.allUserdata.Userdata.map(
                  (item) => {
                    return {
                      id: item.id,
                      name:
                        item.firstname +
                        " " +
                        (item.lastname != null ? item.lastname : "")
                    };
                  }
                ),
                selectedParticipant: res.data.allUserdata.Userdata[0]
                  ? res.data.allUserdata.Userdata[0].id
                  : 0
              })
            : this.setState({ loading: true });
        })
        .catch((e) => console.log(e));
    } else {
      this.props.history.push("/");
    }
  };
  participantCardLimit = async () => {
    let participantIdArray = [];
    let array =
      this.state.participantOptions.length != 0
        ? this.state.participantOptions.filter((item) =>
            participantIdArray.push(item.id)
          )
        : [];
    let listArray = [];
    for (let participantidValue of participantIdArray) {
      await fetchMethod(getParticipentCardDetails, {
        filter: {
          userId: participantidValue,
          order: "id desc"
        },
        where: {
          userId: participantidValue,
          active: 1,
          supporterId: null,
          status: "PENDING"
        },
        walletFilter: {
          status: 1,
          participantId: participantidValue
        }
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data.allCardDetails.CardDetails !== "") {
            res.data.allCardDetails.CardDetails.forEach((item) => {
              const walletBal = res.data?.allWallets?.Wallets?.reduce(
                (total, { balance }) => total + balance,
                0
              );
              let userDetails =
                item.fkuseridcarddetailmaprel.Userdata !== ""
                  ? item.fkuseridcarddetailmaprel.Userdata
                  : "";
              listArray.push({
                id: participantidValue,
                cardLimit: item.cardLimit,
                name:
                  userDetails !== ""
                    ? userDetails[0].firstname + " " + userDetails[0].lastname
                    : "",
                profileImage:
                  userDetails !== "" ? userDetails[0].profileimage : "",
                repeatpayamount:
                  res.data?.allAuthledgers?.Authledgers[0]?.repeatpayamount,
                walletBal
              });
            });
          } else {
            this.state.participantOptions.map((item) => {
              if (item.id === participantidValue) {
                listArray.push({
                  id: item.id,
                  cardLimit: "",
                  name: item.name,
                  profileImage:
                    item.profileimage != null && item.profileimage !== ""
                      ? item.profileimage
                      : "",
                  repeatpayamount:
                    res.data?.allAuthledgers?.Authledgers[0]?.repeatpayamount
                });
              }
            });
          }
        });
    }
    if (listArray.length > 0) {
      if (listArray.length > 5) {
        listArray.length = 5;
      }
    }
    this.setState({
      participantCardDetailsList: listArray,
      loader: false
    });
  };

  formatDate = (date) => {
    return moment(date).format("lll");
  };
  getAllPaymentData = async () => {
    this.setState({
      loader: true
    });
    try {
      const response = await fetchMethod(userdataQuery, {
        where: { guardianId: JSON.parse(localStorage.getItem("userInfo")).id }
        // first: this.state.pageNo,
        // last: this.state.rows
      }).then((res) => res.json());

      const participants = response?.data?.allUserdata?.Userdata || [];
      var ids = [];
      participants.map((i) => {
        ids.push(i.id);
      });
      let payments;
      let usersData = [];
      let count = 0;
      var paymentGuardianRequestQueryWhere = {
        participantId: { inq: [ids] },
        order: "createdAt desc"
      };
      payments = await fetchMethod(paymentGuardianRequestDataQuery, {
        where: {
          participantId: { inq: [ids] },
          order: "createdAt desc"
          // paymentStatus:
          //   this.state.paymentStatus == 1 ? "Complete" : "Pending",
        },
        first: parseInt(this.state.pageNo),
        last: parseInt(this.state.rows)
      }).then((res) => res.json());
      const connectedSupporterPayments =
        payments?.data?.allPaymentRequests?.PaymentRequests || [];
      count += payments?.data?.allPaymentRequests?.totalCount || 0;
      if (connectedSupporterPayments.length) {
        connectedSupporterPayments.map(async (item) => {
          let id = item?.id;
          let category = item?.fkPaymentRequestItemCategoryIdrel
            ?.MasterItemCategories[0]
            ? item?.fkPaymentRequestItemCategoryIdrel?.MasterItemCategories[0][
                "categoryName"
              ]
            : "";
          let categoryName =
            category.charAt(0).toUpperCase() + category.slice(1);
          let paymentMadeby =
            item?.fkpaymentrequestcreatebymaprel?.Userdata[0] || [];
          let paymentMadeFor =
            item?.fkPaymentRequestParticipantIdrel?.Userdata[0] || [];
          let madeby = paymentMadeby.firstname + " " + paymentMadeby.lastname;
          let ForThe =
            paymentMadeby.role == "SUPPORTER"
              ? "on behalf of "
              : paymentMadeby.role == "PARTICIPANT"
              ? "by"
              : "";
          let paymentByProfileImages = paymentMadeby.profileimage;
          let madefor =
            item.createdBy === item.participantId
              ? "SELF"
              : paymentMadeFor.firstname + " " + paymentMadeFor.lastname;
          let madeFor =
            paymentMadeFor.firstname + " " + paymentMadeFor.lastname;
          let madeforId = item.participantId;
          let itemDetail = item?.itemDetail;
          let paymentStatus = item?.paymentStatus;
          let extraNotes = item?.extraNotes;
          let isverified = item?.isverified == 0 ? "No" : "Yes";
          let requestedAmount =
            item?.requestedAmount !== null ? "$" + item?.requestedAmount : "";
          let createdAt =
            item?.createdAt !== undefined && item?.createdAt !== null
              ? this.formatDate(item.createdAt)
              : "";
          let storeName =
            item?.fkPaymentRequestStoreIdrel?.Businesses.length > 0
              ? item?.fkPaymentRequestStoreIdrel?.Businesses[0].storeName
              : "";
          let productImage =
            item?.fk_request_item_image_payment_request_id?.RequestItemImages
              .length > 0
              ? item?.fk_request_item_image_payment_request_id
                  ?.RequestItemImages[0].itemImageUrl
              : "";
          let cardLimit =
            item?.fkPaymentCardIdRelrel?.CardDetails.length > 0
              ? item?.fkPaymentCardIdRelrel?.CardDetails[0].cardLimit
              : "";
          let changeamount =
            item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
            item?.authledgerPaymentRequestMap?.Authledgers[0].changeamount !==
              null
              ? "$" +
                item?.authledgerPaymentRequestMap.Authledgers[0].changeamount
              : item?.paymentStatus === "Complete"
              ? "$0"
              : "";
          let spentamount =
            item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
            item?.authledgerPaymentRequestMap?.Authledgers[0].spentamount !==
              null
              ? "$" +
                item?.authledgerPaymentRequestMap.Authledgers[0].spentamount
              : "";
          let merchantname = item
            ? item.authledgerPaymentRequestMap
              ? item.authledgerPaymentRequestMap.Authledgers.length > 0
                ? item.authledgerPaymentRequestMap.Authledgers[0][
                    "merchantname"
                  ]
                : ""
              : ""
            : "";
          let categoryList = this.state.itemCategory;
          usersData.push({
            id,
            isrepeatpay: item.isrepeatpay,
            categoryName,
            madeby,
            ForThe,
            paymentByProfileImages,
            madefor,
            madeFor,
            itemDetail,
            paymentStatus,
            extraNotes,
            requestedAmount,
            createdAt,
            storeName,
            madeforId,
            productImage,
            cardLimit,
            changeamount,
            spentamount,
            merchantname,
            isverified,
            categoryList
          });
        });
      }
      this.setState({
        listData: usersData !== null ? usersData : [],
        transactionCount: count,
        loader: false
      });
    } catch (err) {
      this.setState({
        showError: true
      });
    }
  };
  handleChange = async (e) => {
    await this.setState({
      paymentStatus: e.target.value
    });
    if (this.state.participantxyz != null) {
      this.participantsPaymentData();
    } else {
      this.getAllPaymentData();
    }
  };
  participantsPaymentData = async () => {
    try {
      let payments;
      let usersData = [];
      let count = 0;
      let currentDateValue = new Date(
        new Date().setDate(new Date().getDate() + 1)
      )
        .toISOString()
        .slice(0, 10);
      let lastSeventhDateValue = new Date(
        new Date().setDate(new Date().getDate() - 7)
      )
        .toISOString()
        .slice(0, 10);
      payments = await fetchMethod(paymentGuardianRequestDataQuery, {
        where: {
          participantId: this.state.currentParicipantId,
          order: "createdAt desc",
          paymentStatus: this.state.paymentStatus == 1 ? "Complete" : "Pending",
          createdAt: {
            between: [
              new Date(lastSeventhDateValue),
              new Date(currentDateValue)
            ]
          }
        },
        first: parseInt(this.state.pageNo),
        last: parseInt(this.state.rows)
      }).then((res) => res.json());
      const connectedSupporterPayments =
        payments.data?.allPaymentRequests?.PaymentRequests || [];
      count += payments?.data?.allPaymentRequests?.totalCount || 0;
      if (connectedSupporterPayments.length) {
        connectedSupporterPayments.map(async (item) => {
          let id = item?.id;
          let category =
            item?.fkPaymentRequestItemCategoryIdrel?.MasterItemCategories[0][
              "categoryName"
            ];
          let categoryName =
            category.charAt(0).toUpperCase() + category.slice(1);
          let paymentMadeby =
            item?.fkpaymentrequestcreatebymaprel?.Userdata[0] || [];
          let paymentMadeFor =
            item?.fkPaymentRequestParticipantIdrel?.Userdata[0] || [];
          let madeby =
            paymentMadeby?.firstname == undefined &&
            paymentMadeby?.lastname == undefined
              ? " "
              : paymentMadeby.firstname + " " + paymentMadeby.lastname;
          let ForThe =
            paymentMadeby.role == "SUPPORTER"
              ? "on behalf of "
              : paymentMadeby.role == "PARTICIPANT"
              ? "by"
              : "";
          let paymentByProfileImages = paymentMadeby.profileimage;
          let madefor =
            item.createdBy === item.participantId
              ? "SELF"
              : paymentMadeFor.firstname + " " + paymentMadeFor.lastname;
          let madeFor =
            paymentMadeFor.firstname + " " + paymentMadeFor.lastname;
          let madeforId = item.participantId;
          let itemDetail = item?.itemDetail;
          let paymentStatus = item?.paymentStatus;
          let extraNotes = item?.extraNotes;
          let isverified = item?.isverified == 0 ? "No" : "Yes";
          let createdAt =
            item?.createdAt !== undefined && item?.createdAt !== null
              ? this.formatDate(item.createdAt)
              : "";
          let storeName =
            item?.fkPaymentRequestStoreIdrel?.Businesses.length > 0
              ? item?.fkPaymentRequestStoreIdrel?.Businesses[0].storeName
              : "";
          let productImage =
            item?.fk_request_item_image_payment_request_id?.RequestItemImages
              .length > 0
              ? item?.fk_request_item_image_payment_request_id
                  ?.RequestItemImages[0].itemImageUrl
              : "";
          let cardLimit =
            item?.fkPaymentCardIdRelrel?.CardDetails.length > 0
              ? item?.fkPaymentCardIdRelrel?.CardDetails[0].cardLimit
              : "";
          let requestedAmount =
            item?.requestedAmount !== null ? "$" + item?.requestedAmount : "";
          let changeamount =
            item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
            item?.authledgerPaymentRequestMap?.Authledgers[0].changeamount !==
              null
              ? "$" +
                item?.authledgerPaymentRequestMap.Authledgers[0].changeamount
              : "";
          let spentamount =
            item?.authledgerPaymentRequestMap?.Authledgers.length > 0 &&
            item?.authledgerPaymentRequestMap?.Authledgers[0].spentamount !==
              null
              ? "$" +
                item?.authledgerPaymentRequestMap.Authledgers[0].spentamount
              : "";
          let categoryList = this.state.itemCategory;
          usersData.push({
            id,
            isrepeatpay: item.isrepeatpay,
            categoryName,
            madeby,
            ForThe,
            paymentByProfileImages,
            madefor,
            madeFor,
            itemDetail,
            paymentStatus,
            extraNotes,
            requestedAmount,
            createdAt,
            storeName,
            madeforId,
            productImage,
            cardLimit,
            spentamount,
            changeamount,
            isverified,
            categoryList
          });
        });
      }
      this.setState({
        listData: usersData !== null ? usersData : [],
        transactionCount: count,
        loader: false
      });
    } catch (err) {
      swal({ title: err.message, icon: "warning" });
      this.setState({ listData: [] });
    }
  };
  handleParticipantfilter = (e, v) => {
    if (v !== null && v !== undefined) {
      const { filter } = this.state;
      filter.participantId = v.id;
      this.setState(
        {
          filter,
          currentParicipantId: v.id,
          participantxyz: v,
          ["participantId"]: v.id
        },
        () => {
          this.participantsPaymentData();
        }
      );
    }
  };
  handleReset = async (e) => {
    e.preventDefault();
    await this.setState({
      currentParicipantId: null,
      paymentStatus: 1,
      participantxyz: null,
      participantOptions: []
    });
    this.getAllPaymentData();
    this.participants();
  };
  handelGetTransactionDetails = (paymentId) => {
    localStorage.setItem("paymentId", paymentId);
    this.props.history.push("/transactions");
  };
  showMoreClick = () => {
    this.props.history.push("/participants");
    window.location.reload();
  };
  addParticipantClick = () => {
    localStorage.setItem("addParticipantModal", true);
    this.props.history.push({
      pathname: "/participants"
    });
    window.location.reload();
  };

  showMoreCount = () => {
    this.setState(
      {
        pageNo: this.state.pageNo + 8,
        rows: this.state.rows + 8,
        loader: true
      },
      () => {
        if (this.state.participantxyz != null) {
          this.participantsPaymentData();
        } else {
          this.getAllPaymentData();
        }
      }
    );
  };

  showLessCount = () => {
    this.setState(
      {
        pageNo: 8,
        rows: 8,
        loader: true
      },
      () => {
        if (this.state.participantxyz != null) {
          this.participantsPaymentData();
        } else {
          this.getAllPaymentData();
        }
      }
    );
  };

  onFirstNameClick = (data) => {
    localStorage.setItem("participantInfoData", data.id);
    this.props.history.push({
      pathname: "/newParticipantInfo",
      state: { details: data.id }
    });
  };

  showTransactionDetailsModal = (data) => {
    this.setState({
      transactionDetailsModalShow: true,
      dataFortransactionDetailsModal: data
    });
  };
  hideTransactionDetailsModal = () => {
    this.setState({
      transactionDetailsModalShow: false,
      dataFortransactionDetailsModal: undefined
    });
  };

  isChecked = (id, isverified) => {
    const verifiedValue = this.state.listData.map((item) => {
      if (item.id == id) {
        return { ...item, isverified: isverified === 1 ? "Yes" : "No" };
      }
      return item;
    });
    this.setState({ listData: verifiedValue });
  };

  render() {
    return (
      <>
        <div className="container">
          <CustomAlert
            show={this.state.showError}
            icon={Warning}
            message="Failed to fetch the data, please check your internet connection!"
          ></CustomAlert>
          <div className="dashboard">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <h1>Dashboard</h1>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-xs-12 col-md-8">
                <h1>Dashboard</h1>
               
                <p>
                  Check out the transactions that are made with your wallets
                  here
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <hr />
                <h2>Choose wallet</h2>
                <div className="card">
                  <div className="row">
                    <div className="col-sm-9">
                      <div className="barFilters">
                        <div className="row justify-space-between">
                          <div className="col-md-3">
                            <select
                              className="bh-form-select sm"
                              name="participants"
                              value={this.state.selectedParticipant}
                              onChange={this.selectChange}
                            >
                              <option>All participants</option>
                              {this.state.participantOptions != "" ? (
                                this.state.participantOptions.map(
                                  (participant, index) => (
                                    <option key={index} value={participant.id}>
                                      {participant.name}
                                    </option>
                                  )
                                )
                              ) : (
                                <option>All participants</option>
                              )}
                            </select>
                          </div>
                          <div className="col-md-6">
                            <div className="flex">
                              <div className="bh-radio-group">
                                <input
                                  className="bh-btn-check"
                                  type="radio"
                                  name="period"
                                  id="year"
                                  value={"YEARLY"}
                                  checked={
                                    this.state.selectedFilter === "YEARLY"
                                  }
                                  onChange={this.handleRadioChange}
                                />
                                <label
                                  className="btn bh-round-corner sm"
                                  htmlFor="year"
                                >
                                  Year
                                </label>
                                <input
                                  className="bh-btn-check"
                                  type="radio"
                                  name="period"
                                  id="month"
                                  value={"MONTHLY"}
                                  checked={
                                    this.state.selectedFilter === "MONTHLY"
                                  }
                                  onChange={this.handleRadioChange}
                                />
                                <label
                                  className="btn bh-round-corner sm"
                                  htmlFor="month"
                                >
                                  Month
                                </label>
                              </div>                          
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="barChart">
                        {this.state.isBarGraphData ? (
                          <Bar
                            options={this.state.bar_graph.options}
                            data={this.state.bar_graph.data}
                          />
                        ) : (
                          <span>No data found</span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="inner">
                        <div className="title">Spending by Support workers</div>
                        <div className="pieChart">
                          {this.state.isPieChartData ? (
                            <Doughnut
                              data={this.state.pie_graph.data}
                              options={{
                                legend: {
                                  display: false,
                                },
                              }}
                            />
                          ) : (
                            <span>No data found</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row participants">
              <div className="col-xs-12">
                <div className="flex forTitle">
                  <h2>Participants</h2>
                </div>
              </div>
              {/* <div className="col-lg-2 col-sm-4 col-xs-6">
                <Link
                  to={void 0}
                  className="add"
                  onClick={(e) => this.addParticipantClick(e)}
                >
                  <span>Add participant</span>
                </Link>
              </div> */}
              {this.state.participantCardDetailsList &&
                this.state.participantCardDetailsList.map(
                  (activityDetails, index) => (
                    <div className="col-lg-3 col-sm-4 col-xs-6" key={index}>
                      <Link
                        to="#"
                        onClick={() => this.onFirstNameClick(activityDetails)}
                      >
                        <div className="pic">
                          {activityDetails.profileImage !== null &&
                          activityDetails.profileImage !== "" ? (
                            <div className="userPic">
                              <S3Image
                                src={activityDetails.profileImage}
                                alt={activityDetails.name}
                              />
                            </div>
                          ) : (
                            <div className="initial">
                              {activityDetails.name.charAt(0)}
                            </div>
                          )}
                        </div>
                        <p>
                          {activityDetails?.id ? (
                            <strong>{"Id: " + activityDetails.id}</strong>
                          ) : (
                            ""
                          )}
                          <strong>{activityDetails.name}</strong>
                          {activityDetails.repeatpayamount !== undefined && (
                            <span>
                              Card Bal: ${activityDetails.repeatpayamount}
                            </span>
                          )}
                          {activityDetails.walletBal !== undefined && (
                            <span>
                              Wallet Bal: ${activityDetails.walletBal}
                            </span>
                          )}
                        </p>
                      </Link>
                    </div>
                  )
                )}
              {this?.state?.participantCardDetailsList?.length > 0 ? (
                <button
                  className="btn btn-link"
                  onClick={(e) => this.showMoreClick(e)}
                >
                  Show all
                </button>
              ) : (
                ""
              )}
            </div>

            <div className="recentTransactions row">
              <div className="col-xs-12">
                <h3>Recent Transactions</h3>
              </div>
              {this.state.dataFortransactionDetailsModal ? (
                <TransactionDetailsModal
                  hideTransactionDetailsModal={this.hideTransactionDetailsModal}
                  data={this.state.dataFortransactionDetailsModal}
                  show={this.state.transactionDetailsModalShow}
                  isChecked={(id, isverified) => {
                    this.isChecked(id, isverified);
                  }}
                />
              ) : (
                ""
              )}
              {this.state.listData !== undefined ? (
                this.state.listData.map((activityDetails, i) =>
                  activityDetails.paymentStatus == "Complete" ? (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 col-xs-12 item"
                      key={i}
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.showTransactionDetailsModal(activityDetails)
                        }
                      >
                        <div
                          className="supporterPic"
                          role="img"
                          aria-label="support worker picture"
                        >
                          {activityDetails.paymentByProfileImages !== null &&
                          activityDetails.paymentByProfileImages !==
                            undefined &&
                          activityDetails.paymentByProfileImages !== "" ? (
                            <S3Image
                              src={activityDetails.paymentByProfileImages}
                              alt={activityDetails.madeby}
                            />
                          ) : (
                            <div className="init">
                              {activityDetails.madeby.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div
                          className="storePic"
                          role="img"
                          aria-label="store picture"
                        >
                          {!activityDetails.productImage ||
                          activityDetails.productImage === "" ? (
                            <img
                              src={defaultProductImages}
                              alt={activityDetails.storeName}
                            />
                          ) : (
                            <S3Image
                              src={activityDetails.productImage}
                              alt={activityDetails.storeName}
                            />
                          )}
                        </div>
                        <div className="title">
                          <h4 aria-label="Support worker name">
                            {activityDetails.madeby}
                          </h4>
                          <span aria-label="transaction amount">
                            {activityDetails.spentamount}
                          </span>
                        </div>
                        <div className="description">
                          <p arai-label="transaction description">
                            {activityDetails.spentamount +
                              " Paid " +
                              activityDetails.ForThe +
                              " " +
                              activityDetails.madeFor +
                              " for " +
                              (activityDetails.isrepeatpay === 1
                                ? "Repeat Pay"
                                : activityDetails.categoryName)}
                          </p>
                        </div>
                        <time>{activityDetails.createdAt}</time>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )
                )
              ) : this.state.loader === true ? (
                <DotLoader size={70} color={"#020f1f"} />
              ) : (
                <h5>
                  <center>No recent transactions found</center>
                </h5>
              )}
              {this.state.listData && this.state.transactionCount > 8 ? (
                this.state.transactionCount !== this.state.listData.length ? (
                  <button
                    className="btn btn-link"
                    onClick={() => this.showMoreCount()}
                  >
                    Load more
                  </button>
                ) : (
                  <button
                    className="btn btn-link"
                    onClick={() => this.showLessCount()}
                  >
                    Load less
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(GuardianDashboard);
