import { fetchMethod } from "src/FetchMethod";
import {
  fetchWalletDetails,
  fetchWallets
} from "src/network/service/walletService";
import { ACTIVE, INACTIVE, NO, YES } from "src/utils/constants";
import { allSupporterQuery } from "./WalletQuery";
import swal from "sweetalert";

const { createSlice } = require("@reduxjs/toolkit");

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    loading: false,
    walletData: [],
    total: 0,
    walletDetail: {
      wallet: null,
      supporters: [],
      budgets: [],
      supporterOptions: []
    }
  },
  reducers: {
    startWalletsFetch: (state, action) => {
      state.loading = true;
    },
    successWalletsFetch: (state, action) => {
      state.loading = false;
      state.walletData = action.payload?.wallets;
      state.total = action.payload?.total;
    },
    failWalletsFetch: (state, action) => {
      state.loading = false;
      state.walletData = [];
      state.walletDetail = {
        wallet: null,
        supporters: [],
        budgets: []
      };
    },
    successWalletDetailsFetch: (state, action) => {
      state.loading = false;
      state.walletDetail = action.payload;
    },
    successSupportersFetch: (state, action) => {
      state.walletDetail.supporterOptions = action.payload;
    }
  }
});

const {
  startWalletsFetch,
  successWalletsFetch,
  failWalletsFetch,
  successWalletDetailsFetch,
  successSupportersFetch
} = walletSlice.actions;

export function fetchWalletsAction(data) {
  return async (dispatch) => {
    dispatch(startWalletsFetch());
    try {
      const response = await fetchWallets(data);

      const list = response?.list.map((item) => ({
        ...item,
        participant: item.participant?.fullName,
        owner: item.owner?.fullName,
        status: item.status ? ACTIVE : INACTIVE,
        canwithdraw: item.canwithdraw ? YES : NO
      }));

      dispatch(
        successWalletsFetch({
          wallets: list,
          total: response?.total
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(failWalletsFetch(error));
    }
  };
}

export function fetchWalletDetailsAction(id) {
  return async (dispatch) => {
    dispatch(startWalletsFetch());
    try {
      const response = await fetchWalletDetails(id);
      dispatch(successWalletDetailsFetch(response));
    } catch (error) {
      console.error(error);
      dispatch(failWalletsFetch(error));
    }
  };
}

export function fetchAllSupportersAction() {
  return async (dispatch) => {
    try {
      const response = await fetchMethod(allSupporterQuery).then((resp) =>
        resp.json()
      );
      const supportersOptions = response.data.allUserdata.Userdata;
      dispatch(successSupportersFetch(supportersOptions));
    } catch (error) {
      swal({ text: "unable to find the supporters", icon: "warning" });
    }
  };
}
export default walletSlice.reducer;
