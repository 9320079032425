import React, { Component } from 'react'
import { DotLoader } from 'react-spinners';
import swal from 'sweetalert';
import { fetchMethod } from '../../FetchMethod';
import FormComponent from '../../Form/FormComponent';
import { editRuleConfig } from './RulesConfig';
import { cronUpdate, editCronQuery, getItemCategories } from './RulesQuery';
import { participantQueryData } from '../SuperLedger/LedgerQuery';

export class EditRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: undefined,
            search: "",
            pageNo: parseInt(localStorage.getItem("rows")),
            rows: parseInt(localStorage.getItem("rows")),
            filter: {
                active: 1,
                role: "PARTICIPANT",
                order: "id desc"
            },
            count: 0,
            participantOptions: undefined,
            itemDataOptions: undefined,
            allParticipantList: undefined,
            allCategoryList: undefined,
            timePeriod: ["Daily", "Weekly", "Monthly"],
            id:
                this.props.location.state && this.props.location.state.details
                    ? this.props.location.state.details
                    : "",
            editableData: undefined
        };
    }

    async componentWillMount() {
        await this.getCroneRuleById(this.state.id);
    }

    getCroneRuleById = (id) => {
        if (!this.state.id) {
            this.props.history.push('/rules');
            return;
        }
        fetchMethod(editCronQuery(id))
            .then(res => res.json())
            .then(res => {
                if (res.data.allCronjobs?.Cronjobs?.length) {
                    let data = res.data.allCronjobs.Cronjobs[0];
                    // console.log('data', data)
                    let obj = {
                        id: data.id,
                        budgetAvailable: data.amount,
                        ParticipantId: data.fk_userdata_participantid.Userdata[0].id,
                        participantId: data.fk_userdata_participantid.Userdata[0].firstname + data.fk_userdata_participantid.Userdata[0].lastname,
                        itemCategoryId: data.fk_masteritemcategory_categoryid.MasterItemCategories[0].categoryName,
                        timePeriod: data.event

                    }
                    this.setState({ editableData: obj })
                    this.getAllCategory()
                }
            })
    }


    getAllCategory = () => {
        // console.log("editableData", this.state.editableData.ParticipantId)
        let obj = {
            participantId: this.state.editableData.ParticipantId,
            active: 1
        }
        fetchMethod(getItemCategories, { where: obj })
            .then(res => res.json())
            .then(res => {
                const filterData = res.data.allBudgets.Budgets.filter(item => { if (item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].isActive != 0) { return item } })
                const filterCatData = filterData !== null ?
                    filterData.map(item => {
                        return {
                            id: item.fkBudgetItemCategoryIdrel != undefined && item.fkBudgetItemCategoryIdrel.MasterItemCategories != "" ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].id : '',
                            name: item.fkBudgetItemCategoryIdrel != undefined && item.fkBudgetItemCategoryIdrel.MasterItemCategories != "" ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName : ''
                        }
                    }) : ""
                this.setState({
                    allCategoryList: filterCatData
                })
            })
            .catch(err => {
                swal({ title: err.message, icon: "warning" });
            })
    };

    closeForm = () => {
        this.props.history.push("/rules");
    };

    preSubmitChanges = (e) => {
        fetchMethod(cronUpdate, {
            where: { id: e.id },
            data: {
                amount: e.budgetAvailable,
                event: e.timePeriod.toLowerCase(),
                updatedby: JSON.parse(localStorage.getItem('userInfo')).id
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.data.CronjobUpdateAll.count === 1) {
                    swal({ title: "Cron updated successfully", icon: "success" })
                    this.props.history.push("/rules");
                }
            })
            .catch(err => {
                swal({ title: err.message, icon: "warning" })
            })
    }

    render() {
        return (
            <div className="addbudgetSection">
                <h2>Edit Rule</h2>
                {this.props.location.state !== undefined && this.state.editableData ? (
                    <FormComponent
                        formConfig={editRuleConfig}
                        editableData={this.state.editableData}
                        preSubmitChanges={this.preSubmitChanges}
                        buttonTitleCSS="adminParticipant"
                        modalCloseCallback={() => { }}
                        closeButton={this.closeForm}
                        params={{
                            categoryOptions: this.state.allCategoryList,
                            timePeriodOptions: this.state.timePeriod
                        }}
                    />
                ) : (
                    <div className="spinner">
                        <DotLoader size={70} color={"#020f1f"} />
                    </div>
                )}
            </div>
        )
    }
}

export default EditRule
