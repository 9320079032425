export const participantQueryData = `
query($where:JSON){
  allUserdata(where:$where){
    Userdata{
      id
      firstname
      lastname
      loginDevice
    }
  }
}
`;

export const getCardLimitCron = `
query allCronjobs($where:JSON,$last: Int, $first: Int){
    allCronjobs(where:$where,last:$last,first:$first) {
        totalCount
        Cronjobs {
            id
            amount
            event
            active
            categoryid
            typeid
            fk_userdata_participantid{
                Userdata{
                  firstname
                  lastname
                  fkUserIdCardDetailMap{
                    CardDetails{
                      cardLimit
                    }
                  }
        }
              }
        }
    }
}
`

export const saveBudgetdata = `
  mutation saveCronjob($obj: CronjobInput!) {
    saveCronjob(obj: $obj) {
      id
    }
  }
`;

export const getAllCategories = `
query {
  allMasterItemCategories{
    MasterItemCategories{
      id
      categoryName
    }
  }
}`


export const getAlltypes = `
query {
  allTiertypes(where : {active:1}){
    Tiertypes{
      id
      tierType
    }
  }
}`

export const getAllCategoriesByTypeId = `
mutation
  TiertypeCategory($tierId: Int){
    TiertypeCategory(tierId:$tierId)
}`;

export const getCardLimitById = `
query allCardDetails($where: JSON){
  allCardDetails(where:$where){
    CardDetails{
      id
      cardLimit
    }
  }
}
`;

export const deleteAutomationById = `
mutation
CronjobDeleteById($id:JSON){
  CronjobDeleteById(id:$id)
}`

// export const saveCardLimitCron = `
// mutation CardcronjobSaveRule($userid:Int,$event:String,$amount:Int,$createdBy:Int){
//     CardcronjobSaveRule(userid:$userid,,event:$event,amount:$amount,createdBy:$createdBy)
// }
// `