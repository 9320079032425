import React, { Component } from "react";
import './custom_alert.scss';
import Cross from '../../assets/svg/cross.svg';

export class CustomAlert extends Component {
constructor(props) {
  super(props);
  this.state = {
    show: false,
    showError: false,
  };
}
  componentWillMount() {
  this.closeAlertHandler = () => {
    alert("Set show == false to hide this");
  }
}
render() {
  return (
    <div
      className={`alert alert__has_error ${this.props.show ? "show" : ""}`}
      aria-live="assertive"
      role="alert"
    >
      <span>
        <span className="icon" role="img">
          <img src={this.props.icon} alt="walrning icon" />
        </span>
        {this.props.message}
        <button
          className="close"
          aria-label="close alert"
          onClick={this.closeAlertHandler}
        >
          <img src={Cross} alt="close icon" />
        </button>
      </span>
    </div>
  );
  }
}
export default CustomAlert;
