export const getAllItemCategories = `
query allMasterItemCategories($where:JSON){
    allMasterItemCategories(where:$where){
        MasterItemCategories{
            id
            isActive
            categoryName
        }
    }
}
`

// export const getItemCategories = `
// mutation CronjobShowConnectedItemCategories($participantid:Int){
//   CronjobShowConnectedItemCategories(participantid:$participantid)
//   }
// `
export const getItemCategories = `
query allBudgets($where:JSON){
    allBudgets(where:$where){
    Budgets{
      id
      fkBudgetItemCategoryIdrel{
        MasterItemCategories{
          id
          categoryName
          isActive
        }
      }
    }
  }
}
 `

export const saveCron = `
mutation CronjobSaveRule($participantid:Int,$categoryid:Int,$event:String,$amount:Int,$createdBy:Int){
    CronjobSaveRule(participantid:$participantid,categoryid:$categoryid,event:$event,amount:$amount,createdBy:$createdBy)
}
`

export const cronUpdate = `
mutation CronjobUpdateAll($where:JSON, $data:JSON){
    CronjobUpdateAll(where:$where, data:$data)
}
`
export const deleteCron = `
mutation CronjobDeleteById($id:JSON){
    CronjobDeleteById(id:$id)
}
`

export const getCron = `
query allCronjobs($where:JSON,$last: Int, $first: Int){
    allCronjobs(where:$where,last:$last,first:$first) {
        totalCount
        Cronjobs {
            id
            amount
            event
            fk_masteritemcategory_categoryid {
              MasterItemCategories {
                categoryName
                isActive
              }
            }
            fk_userdata_participantid {
              Userdata {
                firstname
                lastname
              }
            }
        }
    }
}
`

export const editCronQuery = id => `
query{
    allCronjobs (where:{id:${id}}){
        Cronjobs {
            id
            amount
            createdon
            event
            fk_masteritemcategory_categoryid {
              MasterItemCategories {
                id
                categoryName
              }
            }
            fk_userdata_participantid {
              Userdata {
                id
                firstname
                lastname
              }
            }
        }
    }
}
`
export const participantQueryData = `
query($where:JSON){
  allUserdata(where:$where){
    Userdata{
      id
      firstname
      lastname
      loginDevice
    }
  }
}
`;