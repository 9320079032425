import React, { Component } from "react";
import swal from "sweetalert";
import { withRouter } from "react-router-dom";
import { fetchMethod } from "../FetchMethod";
import Logo from "../assets/images/logo-budget-hub.svg";
import ShowPwd from "../assets/svg/show_pwd.svg";
import HidePwd from "../assets/svg/hide_pwd.svg";
import "./login.scss";
import {
  UserdataPasswordforgot,
  UserdataOtp,
} from "./ForgotQuery";
import { Link } from "react-router-dom";
class ForgotPasswordEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: 0,
      showPassword: false,
      showcPassword: false,
      email: "",
      password: "",
      cPassword: "",
      errors: { email: "", password: "" }
    };
  }

  componentWillMount() {
    if (this.props && this.props.location && this.props.location.email) {
      this.setState({ email: this.props.location.email });
    }
  }
  // onSignIn = () => {
  //   this.props.history.push("/");
  // };
  nextEmail() {
    const { otp, password, cPassword, email } = this.state;
    if (
      email.length != 0 &&
      otp != 0 &&
      password.length != 0 &&
      cPassword.length != 0 &&
      password === cPassword
    ) {
      if (this.validateForm(email, password)) {
        fetchMethod(UserdataPasswordforgot(email, otp, password))
          .then(res => res.json())
          .then(res => {
            if(res.data.UserdataPasswordforgot.status === 403) {
              swal({
                title: "Invalid Temporary Password.",
                icon: "warning"
              });
            }
            if (res && res.data && res.data.UserdataPasswordforgot.status === 200) {
              this.setState({
                email: "",
                otp: 0,
                password: ""
              });
              swal({
                icon: "success",
                title: "Password reset successfully"
              });
              this.props.history.push("/");
            }
          });
      } else {
          swal({
            title:
              this.state.errors.email != ""
                ? this.state.errors.email
                : this.state.errors.password,
            icon: "error"
          });
        }
    } 
    else if(otp == '') {
      swal({
        title: 'Please enter a valid temporary password',
        icon: 'warning'
      })
    }
    else if(password == '') {
      swal({
        title: 'Please enter a valid new password',
        icon: 'warning'
      })
    }
    else if(cPassword == '') {
      swal({
        title: 'Please enter a valid confirm new password',
        icon: 'warning'
      })
    }
    else if(password !== cPassword) {
      swal({
        title: 'Password & confirm password must be same.',
        icon: 'warning'
      })
    }
  }
  validateForm = (email, password) => {
    const { errors } = this.state;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    errors.email = validEmailRegex.test(email.trim())
      ? ""
      : "Email is not valid!";
    errors.password =
      password.trim().length > 6
        ? ""
        : "Password should be greater than 6 characters";
    this.setState({ errors });
    return this.state.errors.email === "Email is not valid!" ||
      this.state.errors.password ===
        "Password should be greater than 6 characters"
      ? false
      : true;
  };
  handleOtp = e => {
    this.setState({ otp: e.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleClickShowcPassword = () => {
    this.setState({ showcPassword: !this.state.showcPassword });
  };
  handlePassword = e => {
    this.setState({ password: e.target.value });
  };
  handleChange = e => {
    this.setState({
      cPassword: e.target.value
    });
  };
  resend() {
    const { email } = this.state;
    let Data = JSON.stringify({ email: email });
    // fetchMethod(UserdataResendotp(email))
    //   .then(res => res.json())
    //   .then(res => {
    //     if (res && res.data && res.data.UserdataResendotp) {
    //       swal({
    //         title: "Otp sent",
    //         icon: "success"
    //       });
    //       // this.props.history.push("/");
    //     } else {
    //       swal({
    //         title: "Error Occured",
    //         icon: "error"
    //       });
    //     }
    //   });

    fetchMethod(UserdataOtp, Data)
      .then(res => res.json())
      .then(res => {
        if (res && res.data && res.data.UserdataOtp) {
          swal({
            title: "Temporary Password has been sent on your registered Email Id.",
            icon: "success"
          });
          // this.setState({ email: "" });
        } else {
          swal({
            title: "Error Occured",
            icon: "error"
          });
        }
      })
      .catch(e => {
        console.log("error_________________", e);
      });
  }
  render() {
    return (
      <div className="signInNew">
        <div className="flex">
          <div className="signInWrap">
            <div className="logo">
              <img src={Logo} alt="Logo | Budget hub" />
            </div>
            <div className="innerBox">
              <h1>Reset Password</h1>
              {/* <form > */}
              <ul>
                <li>
                  <label>Temporary password</label>
                  <input
                    value={this.state.Otp}
                    className="bh-form-control"
                    id="Otp"
                    name="Otp"
                    placeholder="Enter temporary password"
                    onChange={this.handleOtp}
                  />
                </li>
                <li>
                  <label>New password</label>
                  <div className="bh-input-group">
                    <input
                      value={this.state.Password}
                      className="bh-form-control"
                      id="Password"
                      name="Password"
                      placeholder="New Password"
                      type={this.state.showPassword ? "text" : "password"}
                      onChange={this.handlePassword}
                    />
                    <div
                      className="bh-input-group-text"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <img src={HidePwd} alt="user email address" />
                      ) : (
                        <img src={ShowPwd} alt="user email address" />
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <label>New password</label>
                  <div className="bh-input-group">
                    <input
                      value={this.state.cPassword}
                      className="bh-form-control"
                      id="Confirm password"
                      name="Confirm password"
                      placeholder="Confirm New Password"
                      type={this.state.showcPassword ? "text" : "password"}
                      onChange={this.handleChange}
                    />
                    <div
                      className="bh-input-group-text"
                      onClick={this.handleClickShowcPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showcPassword ? (
                        <img src={HidePwd} alt="user email address" />
                      ) : (
                        <img src={ShowPwd} alt="user email address" />
                      )}
                    </div>
                  </div>
                </li>
                <li>
                  <button
                    color="primary"
                    type="submit"
                    className="btn btn-primary full"
                    onClick={() => this.nextEmail()}
                  >
                    Reset Password
                  </button>
                </li>
              </ul>
              <div className="forgotPass">
                <Link to="/forgotPassword" onClick={() => this.resend()}>
                  Resend Temporary Password
                </Link>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ForgotPasswordEmail);
