import React from "react";
import ReactTableComponent from "src/ReactTable/ReactTable";
import { adminWalletsConfig } from "./walletsConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "src/Components/PaymentRequests/paymentRequests.css";
import { useDispatch, useSelector } from "react-redux";
import { WALLET_LIST_ROUTE } from "src/utils/constants";
import usePagination from "src/hooks/usePagination";
import { useState, useEffect } from "react";
import { fetchWalletsAction } from "./walletSlice";
import { deepCopy } from "src/utils/utils";

import SearchIcon from "../../assets/search.svg";
import { TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const activeStatusOptions = [
  { status: "Active" },
  { status: "Inactive" }
  // Add other filter options as needed
];

function AdminWallet() {
  const { total, loading, walletData } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const history = useHistory();
  const { page, pageSize } = usePagination();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    status: null
  });

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      dispatch(
        fetchWalletsAction({
          page,
          limit: pageSize,
          // status: filter.status,
          status: filter.status === null ? null : filter.status ? true : false,

          searchText: search
        })
      );
    }, 200);

    return () => clearTimeout(debounceTimer);
  }, [page, pageSize, filter.status, search, dispatch]);

  const updatePagination = (totalEntries, size) => {
    const pageNo = totalEntries / size - 1;
    history.push(`/${WALLET_LIST_ROUTE}?page=${pageNo}&pageSize=${size}`);
  };

  const columns = nameColumn.concat(adminWalletsConfig.columns);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleStatusFilterAutocomplete = (event, value) => {
    let statusValue = null;

    if (value) {
      if (value.status === "Active") {
        statusValue = true;
      } else if (value.status === "Inactive") {
        statusValue = false;
      }
    }

    setFilter({
      ...filter,
      status: statusValue
    });
  };

  return (
    <div className="adminPaymentSection">
      <h2>Wallets</h2>
      <div
        className="formFlex"
        style={{ display: "flex", width: "100%", padding: "10px 0px" }}
      >
        <div className="searchTextField">
          <TextField
            type="text"
            placeholder="Search by name"
            value={search}
            onChange={handleSearchChange}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: (
                <img
                  src={SearchIcon}
                  alt=""
                  style={{ width: "15px", cursor: "pointer" }}
                />
              )
            }}
            style={{ width: "auto" }}
          />
        </div>
        <div>
          <FormControl>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={activeStatusOptions}
              getOptionLabel={(option) =>
                option && option.status ? option.status : ""
              }
              onChange={handleStatusFilterAutocomplete}
              renderInput={(params) => (
                <TextField {...params} label="Status" variant="outlined" />
              )}
            />
          </FormControl>
        </div>
      </div>

      <div className="adminPaymentTable">
        <ReactTableComponent
          listData={deepCopy(walletData)}
          listConfig={adminWalletsConfig}
          columns={columns}
          dataCount={total}
          updatePagination={updatePagination}
          initialPage={page + 1}
          forSerialNo={{
            pageNo: (page + 1) * pageSize,
            pageSize: pageSize
          }}
          loading={loading}
        />
      </div>
    </div>
  );
}

const nameColumn = [
  {
    Header: "S No.",
    Cell: (row) => {
      return <div>{row.original.sNo}</div>;
    },
    width: 50
  }
];
export default AdminWallet;
