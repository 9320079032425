const isProd = () =>
  window.location.hostname === "gimmeit.net.au" ||
  window.location.hostname === "www.gimmeit.net.au" ||
  window.location.hostname === "admin.gimmeit.net.au";
//prod url
//export const API_URL = "https://gimmeit.net.au:8443";
//staging port
export const API_URL = isProd()
  ? "https://gimmeit.net.au:8443"
  : "https://spendable-old-stage.gimmeit.net.au";
// export const API_URL = "http://0.0.0.0:8443";
// prod url
//export const NOVATI_API_URL = "https://spendableledger.com:8443"
//staging  url
export const NOVATI_API_URL = isProd()
  ? "https://gimmeit.net.au:8443"
  : "https://spendable-old-stage.gimmeit.net.au";

export const SIMS_URL = isProd()
  ? "https://sims.gimmeit.net.au"
  : "https://sims-stage.gimmeit.net.au";

export const URLDATA = {
  url: API_URL + "/graphql",
  queryFile: "",
  novatiurl: NOVATI_API_URL + "/graphql"
};
export const Image_GETURL = API_URL + "/api/containers/images/download/";
export const Image_UPLOADURL = API_URL + "/api/containers/images/upload/";

// export const SIMS_URL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:8080"
//     : "https://sims-stage.gimmeit.net.au";
