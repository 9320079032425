import React, { Component } from "react";
import ReactTableComponent from "../../../ReactTable/ReactTable";
import { guardianSupporterList } from "./ParticipantTabSetupConfig";
import { fetchMethod } from "../../../FetchMethod";
import swal from "sweetalert";
import {
  connectedSupporterQuery,
  UserdataCardtransfer,
  UserdataCheckBalance,
  UserdataCardBlock,
  getCardDetailsQuery,
  updateCardStatus,
  updateCardLimit,
  updateSuperledger,
  destroysupporterconnection,
  participantQuery,
  GetUserdataNotification
} from "./ParticipantTabQuery";
import { DotLoader } from "react-spinners";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import "./participantTabSetup.css";
import {
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  Typography,
  Input,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import Delete from "../../../assets/svg/delete.svg";
import Edit from "../../../assets/svg/edit.svg";
import {
  getParticipantConnectedSupporters,
  linkSupporterToAllWallets
} from "src/network/service/walletService";
import SupporterConnectedWallets from "./SupporterConnectedWallets";
import { GUARDIAN } from "src/utils/constants";
import { supporterDevice } from "src/Components/Supporter/SupporterQuery";
import { isValidEmail } from "src/utils/utils";
import SupporterConnectedBudgets from "./SupporterConnectedBudgets";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
class ConnectedSupporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      search: "",
      pageNo: parseInt(localStorage.getItem(0)),
      rows: parseInt(localStorage.getItem(10)),
      count: 0,
      filter: {
        participantId: this.props.id,
        order: "id desc"
      },
      totalData: undefined,
      cardData: [],
      flagged: undefined,
      selectedSupporterId: undefined,
      openDialog: false,
      openAddSupporter: false,
      supporterEmail: "",
      loading: false,
      openModal: false,
      openConnecteBudgetModal: false
    };
  }

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  componentWillMount() {
    this.connectedSupporter();
  }

  connectedSupporter = (flag) => {
    if (localStorage.getItem("role") === "ADMIN") {
      fetchMethod(connectedSupporterQuery, {
        where: this.state.filter,
        last: parseInt(this.state.rows),
        first: parseInt(this.state.pageNo)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.error && res.error.statusCode === 401) {
            swal({ title: res.error.message, icon: "warning" }).then(() => {
              localStorage.clear();
              window.location = "/";
            });
          } else {
            this.setState({
              totalData: res.data.id1.ParticipantConnectedSupporters
            });
            res.data.allParticipantConnectedSupporters.ParticipantConnectedSupporters.map(
              (item) => {
                return (
                  (item.firstname =
                    item.fkParticipantConnectedSupporterIdrel &&
                    item.fkParticipantConnectedSupporterIdrel.Userdata.length >
                      0
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .firstname
                      : ""),
                  (item.lastname =
                    item.fkParticipantConnectedSupporterIdrel &&
                    item.fkParticipantConnectedSupporterIdrel.Userdata.length >
                      0
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .lastname
                      : ""),
                  (item.email =
                    item.fkParticipantConnectedSupporterIdrel &&
                    item.fkParticipantConnectedSupporterIdrel.Userdata.length >
                      0
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .email
                      : ""),
                  (item.flagged = item.isSupporterFlagged === 0 ? "NO" : "YES"),
                  //SUPPORTER ACTIVATED OR DEACTIVATED
                  (item.status =
                    item.fkParticipantConnectedSupporterIdrel &&
                    item.fkParticipantConnectedSupporterIdrel.Userdata.length >
                      0
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .active === 0
                        ? "DEACTIVATED"
                        : "ACTIVATED"
                      : ""),
                  (item.address =
                    item.fkParticipantConnectedSupporterIdrel &&
                    item.fkParticipantConnectedSupporterIdrel.Userdata.length >
                      0
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .address !== null &&
                        item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .address !== ""
                        ? item.fkParticipantConnectedSupporterIdrel.Userdata[0].address?.concat(
                            item.fkParticipantConnectedSupporterIdrel
                              .Userdata[0].city &&
                              item.fkParticipantConnectedSupporterIdrel
                                .Userdata[0].city !== null
                              ? " " +
                                  item.fkParticipantConnectedSupporterIdrel
                                    .Userdata[0].city
                              : ""
                          )
                        : ""
                      : ""),
                  (item.phonenumber =
                    item.fkParticipantConnectedSupporterIdrel &&
                    item.fkParticipantConnectedSupporterIdrel.Userdata.length >
                      0
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .phonenumber !== null
                        ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                            .phonenumber
                        : ""
                      : ""),
                  (item.dob =
                    item.fkParticipantConnectedSupporterIdrel &&
                    item.fkParticipantConnectedSupporterIdrel.Userdata.length >
                      0
                      ? item.fkParticipantConnectedSupporterIdrel.Userdata[0]
                          .dob !== null
                        ? this.formatDate(
                            item.fkParticipantConnectedSupporterIdrel
                              .Userdata[0].dob
                          )
                        : ""
                      : ""),
                  (item.cardStatus = "UNBLOCK")
                );
              }
            );

            this.setState(
              {
                count:
                  res.data &&
                  res.data.allParticipantConnectedSupporters &&
                  res.data.allParticipantConnectedSupporters !== null
                    ? res.data.allParticipantConnectedSupporters.totalCount
                    : "",
                listData:
                  res.data &&
                  res.data.allParticipantConnectedSupporters !== undefined &&
                  res.data.allParticipantConnectedSupporters !== null
                    ? res.data.allParticipantConnectedSupporters
                        .ParticipantConnectedSupporters ?? []
                    : []
              },
              () => {
                this.props.disablerefetchConnect();
                this.props.listModalData(this.state.totalData, flag);
              }
            );
          }
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
          this.setState({ listData: [] });
        });
    }

    if (localStorage.getItem("role") === "GUARDIAN") {
      getParticipantConnectedSupporters(this.props.id)
        .then((res) => {
          this.setState({
            count: res?.total ?? 0,
            listData: res?.list ?? []
          });
        })

        .catch((e) => {
          this.setState({ listData: [] });
        });
    }
  };

  UserdataCardtransferCall = (id, amt, desc) => {
    fetchMethod(UserdataCardtransfer(id, amt, desc))
      .then((response) => response.json())
      .then((res) => {})
      .catch((e) => {
        swal({ title: e, icon: "warning" });
      });
  };

  unloadApi = (data) => {
    if (data.createdBy) {
      fetchMethod(UserdataCheckBalance(data.createdBy))
        .then((response) => response.json())
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.UserdataCheckBalance &&
            res.data.UserdataCheckBalance.data &&
            res.data.UserdataCheckBalance.data.balance &&
            res.data.UserdataCheckBalance.data.balance.availableAmount > 0
          ) {
            this.UserdataCardtransferCall(
              data.createdBy,
              res.data.UserdataCheckBalance.data.balance.availableAmount,
              "withdraw"
            );
          } else if (
            res &&
            res.data &&
            res.data.UserdataCheckBalance &&
            res.data.UserdataCheckBalance.data &&
            res.data.UserdataCheckBalance.data.balance &&
            res.data.UserdataCheckBalance.data.balance.availableAmount <= 0
          ) {
            swal({ title: "There are no fund to unload.", icon: "warning" });
          } else {
            swal({ title: "Error in unloading.", icon: "warning" });
          }
        })
        .catch((e) => {
          swal({ title: e, icon: "warning" });
        });
    } else {
      return;
    }
    // .then(res => {
    //   // this.
    // });
  };
  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber / size - 1,
        rows: size
      },
      () => {
        this.connectedSupporter();
      }
    );
  };
  openModalBox = (data) => {
    this.setState({
      openModal: true,
      selectedSupporterId: data.id
    });
  };
  openConnecteBudgetModalBox = (data) => {
    this.setState({
      openConnecteBudgetModal: true,
      selectedSupporterId: data.id
    });
  };
  handleClose = () => {
    this.setState({
      openModal: false,
      selectedSupporterId: undefined,
      openConnecteBudgetModal: false
    });
  };
  handleAddSupporterClose = () => {
    this.setState({
      openAddSupporter: false
    });
  };
  openAddSupporterModal = () => {
    this.setState({
      openAddSupporter: true
    });
  };

  blockfunction = (supporterId, cardId) => {
    fetchMethod(UserdataCardBlock(supporterId, 0))
      .then((response) => response.json())
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.UserdataCardBlock &&
          res.data.UserdataCardBlock.resultCode &&
          res.data.UserdataCardBlock.resultCode !== "0"
        ) {
        } else {
          fetchMethod(updateCardStatus(cardId))
            .then((response) => response.json())
            .then((res) => {
              swal({ title: "CARD BLOCKED", icon: "success" });
            })
            .catch((e) => {
              swal({ title: e, icon: "warning" });
            });
        }
      })
      .catch((e) => {
        swal({ title: e, icon: "warning" });
      });
  };
  CallsendNotificationApi = (token, title, body, data) => {
    fetchMethod(GetUserdataNotification(token, title, body, data))
      .then((response) => response.json())
      .then((res) => {})
      .catch((e) => {
        swal({ title: e, icon: "warning" });
      });
  };

  BlockUserCard = (supporterId, cardId) => {
    if (supporterId) {
      fetchMethod(UserdataCheckBalance(supporterId, 0))
        .then((response) => response.json())
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.UserdataCheckBalance &&
            res.data.UserdataCheckBalance.data &&
            res.data.UserdataCheckBalance.data.balance &&
            res.data.UserdataCheckBalance.data.balance.availableAmount > 0
          ) {
            fetchMethod(
              UserdataCardtransfer(
                supporterId,
                `${res.data.UserdataCheckBalance.data.balance.availableAmount}`,
                "test UserdataCardtransfer",
                0
              )
            )
              .then((response) => response.json())
              .then((response) => {
                if (
                  response &&
                  response.data &&
                  response.data.UserdataCardtransfer &&
                  response.data.UserdataCardtransfer.resultCode &&
                  response.data.UserdataCardtransfer.resultCode === "0"
                ) {
                  this.blockfunction(supporterId, cardId);
                  fetchMethod(getCardDetailsQuery, {
                    where: { userId: this.props.id }
                  })
                    .then((resp) => resp.json())
                    .then((resp) => {
                      if (
                        resp &&
                        resp.data &&
                        resp.data.allCardDetails &&
                        resp.data.allCardDetails.CardDetails
                      ) {
                        let DATA = resp.data.allCardDetails.CardDetails;

                        let cardlimit =
                          DATA &&
                          DATA[DATA.length - 1] &&
                          DATA[DATA.length - 1].cardLimit
                            ? DATA[DATA.length - 1].cardLimit
                            : 0;

                        let Bal =
                          res.data.UserdataCheckBalance.data.balance
                            .availableAmount;

                        if (Bal && Bal > 0) {
                          cardlimit = cardlimit ? cardlimit + Bal : 0 + Bal;
                        }

                        let cardId =
                          DATA &&
                          DATA[DATA.length - 1] &&
                          DATA[DATA.length - 1].id
                            ? DATA[DATA.length - 1].id
                            : 0;
                        const Obj = {
                          obj: {
                            id:
                              response.data.UserdataCardtransfer &&
                              response.data.UserdataCardtransfer.ledgerid
                                ? response.data.UserdataCardtransfer.ledgerid
                                : "",
                            createdBy: JSON.parse(
                              localStorage.getItem("userInfo")
                            ).id,
                            cardlimit: cardlimit,
                            userId: this.props.id, //participant id
                            cardDetailId: cardId,
                            amountAdded:
                              res.data.UserdataCheckBalance.data.balance
                                .availableAmount,
                            // txnId:
                            //   res.data.UserdataCardtransfer &&
                            //   res.data.UserdataCardtransfer.transNo
                            //     ? res.data.UserdataCardtransfer.transNo
                            //     : "",
                            txnType: "CREDIT"
                          }
                        };
                        fetchMethod(updateSuperledger, Obj)
                          .then((response) => response.json())
                          .then((res) => {
                            if (
                              res &&
                              res.data &&
                              res.data.saveSuperLedger &&
                              res.data.saveSuperLedger.id
                            ) {
                              fetchMethod(participantQuery, {
                                where: { id: this.props.id }
                              })
                                .then((res) => res.json())
                                .then((res) => {
                                  if (
                                    res.data.allUserdata != undefined &&
                                    res.data.allUserdata.Userdata[0]
                                      .loginDevice != null
                                  ) {
                                    let title = "Payment";
                                    let body = ` ${
                                      JSON.parse(
                                        localStorage.getItem("userInfo")
                                      ).fullName
                                    } Added $${
                                      res.data.UserdataCheckBalance.data.balance
                                        .availableAmount
                                    } in your card.`;
                                    this.CallsendNotificationApi(
                                      res.data.allUserdata.Userdata[0]
                                        .loginDevice,
                                      title,
                                      body,
                                      title
                                    );
                                  }
                                })
                                .catch((e) => console.log(e));

                              fetchMethod(
                                updateCardLimit(
                                  this.props.id,
                                  cardId,
                                  cardlimit
                                )
                              )
                                .then((res) => res.json())
                                .then((res) => {
                                  // setLoading(false);
                                  if (
                                    res &&
                                    res.data &&
                                    res.data.saveCardDetail &&
                                    res.data.saveCardDetail.id
                                  ) {
                                    // swal({
                                    //   text: "Unload successfully.",
                                    //   icon: "success"
                                    // });
                                  } else {
                                    // console.log(
                                    //   "updateCardLimit not done error",
                                    //   res
                                    // );
                                  }
                                })
                                .catch((e) => {
                                  swal({ title: e, icon: "warning" });
                                });

                              // swal({ title: "Unload successfully.", icon: "success." });
                            }
                          })
                          .catch((error) => {
                            swal({ title: error.message, icon: "warning" });
                          });
                      }
                    });
                  // swal({
                  //   title: "Not able to unload so can not block the card.",
                  //   icon: "warning."
                  // });
                } else {
                  swal({
                    title: response.data.UserdataCardtransfer.resultDescription,
                    icon: "warning"
                  });
                  // addSuperLedger(B);
                }
              })
              .catch((e) => {
                swal({ title: e, icon: "warning" });
              });
          } else if (
            res &&
            res.data &&
            res.data.UserdataCheckBalance &&
            res.data.UserdataCheckBalance.data &&
            res.data.UserdataCheckBalance.data.balance &&
            res.data.UserdataCheckBalance.data.balance.availableAmount <= 0
          ) {
            this.blockfunction(supporterId, cardId);
          } else {
            swal({
              title: res.data.UserdataCheckBalance.resultDescription,
              icon: "warning"
            });
          }
        })
        .catch((e) => {
          swal({ title: e, icon: "warning" });
        });
    }
  };
  removeconnect = (data) => {
    fetchMethod(destroysupporterconnection(data.connectionId))
      .then((response) => response.json())
      .then((res) => {
        swal({ title: "Removed Successfully.", icon: "success" });
        this.connectedSupporter(1);
      });
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleSupporterText = async (e) => {
    let value = e?.target?.value ?? e;
    if (!!value) {
      if (isValidEmail(value)) {
        const status = await fetchMethod(supporterDevice, {
          where: {
            role: "SUPPORTER",
            active: 1,
            and: [
              {
                or: [
                  {
                    email: {
                      like: `%${value}%`,
                      options: "i"
                    }
                  }
                ]
              }
            ]
          }
        })
          .then((res) => res.json())
          .then(async (res) => {
            if (res?.data?.allUserdata?.Userdata?.length > 0) {
              this.setState({
                supporterId: res?.data?.allUserdata?.Userdata?.[0]?.id ?? 0
              });
              return {
                status: true,
                data: res?.data?.allUserdata?.Userdata?.[0]?.id
              };
            } else {
              this.setState({
                supporterOptions: [],
                supporterId: null
              });
              swal({ title: " Supporter email not found.", icon: "error" });
              return { status: false, data: null };
            }
          });
        return { status: status?.status, data: status?.data };
      } else {
        swal({
          title: "Please enter valid supporter email id.",
          icon: "error"
        });
        return { status: false, data: null };
      }
    } else {
      return { status: false, data: null };
    }
  };

  submitButtonPS = async () => {
    if (!!this.state.supporterEmail) {
      const status = await this.handleSupporterText(this.state.supporterEmail);
      if (status?.status) {
        const test = {
          supporterId: status?.data ?? 0,
          participantId: this.props.id,
          isverified: this.state.isVerified ? 1 : 0,
          createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
          updatedBy: JSON.parse(localStorage.getItem("userInfo")).id
        };
        linkSupporterToAllWallets(test.participantId, test.supporterId)
          .then(() => {
            swal({
              title: "Support Connected.",
              icon: "success"
            });
            this.handleAddSupporterClose();
            this.connectedSupporter();
            // this.setState({ supporterEmail: null });
          })
          .catch((error) => swal("Please try again", { icon: "error" }));
      } else {
        console.log("return false");
      }
    } else {
      swal({ title: "Please enter supporter email id", icon: "warning" });
    }
  };

  handleSearch = (event, values) => {
    const { filter } = this.state;
    if (values) {
      filter["supporterId"] = values.supporterId;
      this.setState({ listData: [] });
      this.setState({
        search: values.firstname,
        filter,
        pageNo: this.state.rows
      });
    } else {
      delete filter["supporterId"];
      this.setState({ listData: [] });
      this.setState({
        search: "",
        filter,
        pageNo: this.state.rows
      });
    }
    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.connectedSupporter(1);
      },
      200
    );
  };
  nameColumn = [
    {
      Header: "Customer id",
      accessor: "id",
      sortable: true,
      Cell: (row) => {
        return <div className="dot">{row.original.id}</div>;
      }
    },
    {
      Header: "Supporter Name",
      accessor: "firstname",
      sortable: true,
      Cell: (row) => {
        return <div className="dot">{row.original.fullName}</div>;
      }
    }
  ];
  actionButton = [
    {
      sortable: false,
      Cell: (row) => (
        <div
          onClick={() => this.openModalBox(row.original)}
          className="link bold blue"
        >
          View Wallets
        </div>
      )
    },
    {
      sortable: false,
      Cell: (row) => (
        <div
          onClick={() => this.openConnecteBudgetModalBox(row.original)}
          className="link bold blue"
        >
          View Budgets
        </div>
      )
    }
  ];
  columns = this.nameColumn
    .concat(guardianSupporterList.columns)
    .concat(this.actionButton);

  render() {
    return (
      <div>
        <Dialog
          open={this.state.openAddSupporter}
          onClose={this.handleAddSupporterClose}
          aria-labelledby="simple-modal-title"
          ariadescribedby="simple-modal-description"
          // className="chooseBuisness"
          className="chooseBuisness selectSupportModal"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleAddSupporterClose}
          >
            Select Supporter
          </DialogTitle>
          {/* <DialogContent> */}
          <div className="modal-body-box">
            <TextField
              disabled
              value={
                this.props.userData
                  ? `${this.props.userData.firstname} ${this.props.userData.lastname}`
                  : ""
              }
              label="Participant"
              variant="outlined"
            />
            <TextField
              value={this.state.supporterEmail ?? ""}
              onChange={(e) =>
                this.setState({ supporterEmail: e?.target?.value })
              }
              variant="outlined"
              label="Supporter Email"
            />

            {/* </DialogContent> */}
            <DialogContent>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.isVerified}
                    onChange={(e) => this.handleIsVerified(e)}
                  />
                }
                label="Is verified"
              />
            </DialogContent>
            <DialogButton>
              <Button onClick={() => this.handleAddSupporterClose()}>
                Cancel
              </Button>
              <Button
                onClick={() =>
                  localStorage?.getItem("role") === GUARDIAN
                    ? this.submitButtonPS()
                    : () => {}
                }
              >
                Save
              </Button>
            </DialogButton>
          </div>
        </Dialog>
        <SupporterConnectedBudgets
          open={this.state.openConnecteBudgetModal}
          supporterId={this.state.selectedSupporterId}
          handleClose={this.handleClose}
          handleSubmit={() => {}}
          participantId={this.props.id}
        />
        <SupporterConnectedWallets
          open={this.state.openModal}
          supporterId={this.state.selectedSupporterId}
          handleClose={this.handleClose}
          handleSubmit={this.connectedSupporter}
          participantId={this.props.id}
        />
        {this.props.hasRefetch ? this.connectedSupporter() : ""}
        {!this.state.loading ? (
          <>
            <div className="tab-data-box">
              <div className="tab-filter-box">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <Button
                      style={{ textDecoration: "underline" }}
                      onClick={this.openAddSupporterModal}
                    >
                      Connect New Support worker
                    </Button>
                  </div>
                </div>
              </div>
              <div className="connectedSupporterTable">
                <ReactTableComponent
                  listData={this.state.listData}
                  listConfig={guardianSupporterList}
                  columns={this.columns}
                  dataCount={this.state.count}
                  updatePagination={this.updatePagination}
                  initialPage={this.state.pageNo / this.state.rows}
                  onRowClick={() => {}}
                  forSerialNo={{
                    pageNo: this.state.pageNo,
                    pageSize: this.state.rows
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}
      </div>
    );
  }
}

export default ConnectedSupporter;
