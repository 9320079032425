import React, { Component } from 'react'

export default class AdminAutomatedBudget extends Component {
    render() {
        return (
            <div>
                AdminAutomatedBudget
            </div>
        )
    }
}
