import {
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField
} from "@material-ui/core";
import React, { useEffect } from "react";
import swal from "sweetalert";
import { fetchMethod } from "../../FetchMethod";
import {
  getRepeatPayDetails,
  updateRepeatPay,
  getWalletsQuery
} from "./ParticipantQuery";
import Loader from "../../assets/Infinity-1s-200px.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCallback } from "react";

export default function SettingsTab(props) {
  // const [cardLimit, setCardLimit] = React.useState(0);
  const [repeatPay, setRepeatPay] = React.useState(false);
  const [repeatPayForParticipant, setRepeatPayForParticipant] =
    React.useState(false);
  const [repeatPayAmount, setRepeatPayAmount] = React.useState("");
  const [useWhole, setUseWhole] = React.useState(false);
  const [actualRepeatPay, setActualRepeatPay] = React.useState("");
  // const [actualCardLimit, setActualCardLimit] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [walletInfoLoading, setWalletInfoLoading] = React.useState(false);
  const [wallet, setWallet] = React.useState(undefined);
  const [participantWallets, setParticipantWallets] = React.useState([]);

  React.useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = () => {
    fetchMethod(getWalletsQuery, {
      where: {
        participantId: props.id,
        status: 1,
        default: 1
      }
    })
      .then((resp) => resp.json())
      .then(async (resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allWallets &&
          resp.data.allWallets.Wallets
        ) {
          await setParticipantWallets(resp.data.allWallets.Wallets);
          if (wallet !== undefined && wallet !== null) {
            const selectedWallet = resp.data.allWallets.Wallets?.find(
              (item) => item.id === wallet.id
            );
            setWallet(selectedWallet);
          }
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  const getWalletInfo = useCallback(() => {
    setWalletInfoLoading(true);
    fetchMethod(getRepeatPayDetails, {
      where: {
        active: 1,
        isrepeatpay: 1,
        isrepeatpaysetforparticipant: 1,
        walletid: wallet.id,
        status: "PENDING"
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data) {
          const finalData = res.data?.allAuthledgers?.Authledgers[0]
            ? res.data?.allAuthledgers?.Authledgers[0]
            : null;
          if (finalData) {
            setRepeatPay(!!finalData?.isrepeatpaysetforparticipant);
            setRepeatPayForParticipant(
              !!finalData?.isrepeatpaysetforparticipant
            );
            const isTotalUse =
              finalData.isrepeatpaysetforparticipant && wallet?.balance === 0
                ? true
                : false;
            setUseWhole(isTotalUse);
            setRepeatPayAmount(finalData.repeatpayamount || "");
            if (!isTotalUse) {
              setRepeatPayAmount(finalData.repeatpayamount || "");
            }
            setActualRepeatPay(finalData.repeatpayamount);
          } else {
            setRepeatPay(false);
            setUseWhole(false);
            setRepeatPayAmount("");
            setActualRepeatPay("");
          }
        }
      })
      .finally(() => setWalletInfoLoading(false));
  }, [wallet]);

  useEffect(() => {
    if (wallet !== undefined && wallet !== null) {
      getWalletInfo();
    }
  }, [wallet, getWalletInfo]);

  const toggleChange = (toggle) => {
    setUseWhole(toggle);
  };

  const validateAmount = (amount) => {
    if (amount) {
      if (isNaN(amount)) {
        return "Please enter a valid number.";
      }
      if (+amount <= 0) {
        return "Amount should be greater than 0.";
      }
      if (+amount > wallet?.balance) {
        return "Amount should less than wallet balance.";
      }
      return "";
    }
    return "Please enter amount.";
  };

  const handleSaveRepeatPay = (e) => {
    const amount = useWhole ? wallet?.balance : repeatPayAmount;
    const errMsg = validateAmount(`${amount}`);
    if (!errMsg) {
      handleSaveRepeatPayAPI(props.id, +amount, 1);
    } else {
      swal({ title: errMsg, icon: "warning" });
    }
  };

  const handleRepeatPayToggle = (toggle) => {
    setRepeatPay(toggle);
    if (!toggle) {
      if (repeatPayForParticipant) {
        setRepeatPayForParticipant(toggle);
        handleSaveRepeatPayAPI(props.id, 0, 0);
      }
    }
  };

  const handleSaveRepeatPayAPI = (userId, amount, isRepeatpay) => {
    setIsLoading(true);
    fetchMethod(updateRepeatPay, {
      userId,
      amount: `${amount}`,
      isRepeatpay,
      createdBy: JSON.parse(localStorage.getItem("userInfo"))?.id || 0,
      useWholeBalance: useWhole ? 1 : 0,
      walletId: wallet.id
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);
        if (res && res.data && res.data.BudgetSetRepeatpayLimit) {
          swal({
            title: res.data.BudgetSetRepeatpayLimit?.msg,
            icon: "success"
          });
        } else {
          if (res.errors && res.errors[0]) {
            swal({ title: res.errors[0].message, icon: "error" });
          }
        }
        fetchInitialData();
      })
      .catch((e) => {
        setIsLoading(false);
        swal({ title: e.message, icon: "error" });
      });
  };

  const handleRepeatAmountChange = (e) => {
    const amount = e.target.value ? e.target.value : "";
    setRepeatPayAmount(amount);
  };

  const handleWalletSelect = (e, object) => {
    setWallet(object);
  };

  return (
    <div className="container participantSection budgetSection">
      <div className="heading">
        <h2>Settings</h2>
      </div>
      <div className="row mb-3">
        <div className="col-md-2" style={{ textAlign: "left" }}>
          <p style={{ fontWeight: "600" }}>Select Wallet</p>
        </div>
        <div className="col-md-4" style={{ textAlign: "left" }}>
          <Autocomplete
            size="small"
            label="Wallet"
            onChange={handleWalletSelect}
            value={wallet}
            options={participantWallets}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Wallet"
                variant="outlined"
                placeholder="Type to search"
              />
            )}
          />
        </div>

        {wallet?.balance !== undefined && (
          <div className="col-md-3" style={{ textAlign: "left" }}>
            <p>${wallet?.balance ? wallet?.balance : 0} Available</p>
          </div>
        )}
      </div>
      {walletInfoLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
      {wallet !== undefined && wallet !== null && !walletInfoLoading && (
        <div className="tab-data-box">
          <div className="tab-filter-box">
            <div className="row" style={{ borderBottom: "1px solid #D3DAE6" }}>
              <div className="col-md-3" style={{ textAlign: "left" }}>
                <p style={{ fontWeight: "600" }}>
                  Turn on Repeat Pay?
                  {/* {props.userdata?.firstname + " " + props.userdata?.lastname} */}
                </p>
              </div>
              <div className="col-md-3" style={{ textAlign: "left" }}>
                <FormControlLabel
                  control={
                    <Switch
                      name="alwaysavailablebudget"
                      checked={repeatPay || false}
                      onChange={(e) => {
                        handleRepeatPayToggle(!repeatPay);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="Repeat Pay"
                />
              </div>

              <div className="col-md-3" style={{ textAlign: "left" }}>
                {/* {repeatPay ? <p>{repeatAmount}</p> : null} */}
              </div>
            </div>
          </div>
          {repeatPay ? (
            <div className="tab-filter-box">
              <div className="row">
                <div className="col-md-2" style={{ textAlign: "left" }}>
                  <p style={{ fontWeight: "600" }}>Use Whole Balance</p>
                </div>
                <div className="col-md-2" style={{ textAlign: "left" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useWhole || false}
                        onChange={(e) => {
                          toggleChange(!useWhole);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                  />
                </div>
                <div className="col-md-7" style={{ textAlign: "left" }}>
                  <div className="row">
                    <div className="col-md-5">
                      <p style={{ fontWeight: "600" }}>
                        How much should be available?
                      </p>
                    </div>
                    <div className="col-md-3">
                      <div>
                        <input
                          id="filled-basic"
                          style={{ width: "100%" }}
                          type="number"
                          variant="filled"
                          disabled={useWhole}
                          value={repeatPayAmount}
                          onChange={handleRepeatAmountChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <Button onClick={handleSaveRepeatPay} variant="outlined">
                        Set Limit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      {isLoading && (
        <div className="spinner">
          <img src={Loader} alt="loader" />
        </div>
      )}
    </div>
  );
}
