import {
  Alert,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { usePrevious } from "../../../hooks/usePrevious";

const NEGATIVE_AMOUNT_ERROR = "amount can't be negative";
const GREATER_AMOUNT_ERROR = "amount can't be greater than available fund";

function FundAllocationTableInput({ disabled, handleInputChange, addedFund }) {
  const [inputValue, setInputValue] = useState();
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const previousInput = usePrevious(inputValue);

  const handleChange = (e) => {
    const targetValue = parseInt(e.target.value);
    let previousInputValue = parseInt(previousInput);
    if (isNaN(previousInput)) previousInputValue = 0;

    if (targetValue < 0) {
      setError(NEGATIVE_AMOUNT_ERROR);
    } else if (addedFund + previousInputValue - targetValue < 0) {
      setError(GREATER_AMOUNT_ERROR);
      setTimeout(() => {
        setError(null);
      }, 1000);
      setInputValue(previousInput);
      return;
    } else {
      setError(null);
      handleInputChange(targetValue);
    }
    setInputValue(targetValue);
  };

  useEffect(() => {
    if (error != null) {
      setSnackbarOpen(true);
    } else {
      setSnackbarOpen(false);
    }
  }, [error]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        key={"top center"}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
      <FormControl>
        <InputLabel htmlFor="outlined-adornment-amount" error={error != null}>
          Amount
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          label="Amount"
          size="small"
          type="number"
          error={error != null}
          startAdornment={
            <InputAdornment error={error != null} position="start">
              $
            </InputAdornment>
          }
          value={inputValue}
          onChange={handleChange}
          disabled={disabled && !inputValue}
        />
      </FormControl>
    </>
  );
}

export default FundAllocationTableInput;
