import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../../Budget/addBudget.css";
import Edit from "../../../assets/svg/edit.svg";
import Delete from "../../../assets/svg/delete.svg";
import { fetchMethod } from "../../../FetchMethod";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal from "sweetalert";
import { TextField } from "@material-ui/core";
import { IconButton, FormControl, Button } from "@material-ui/core";

import ReactTableComponent from "../../../ReactTable/ReactTable";
import { participantBudgetList } from "../../Budget/BudgetConfig";
import {
  budgetQuery,
  saveBudget,
  participantQueryData,
  getAllTier,
  categoryQueryData,
  categoryQueryDataForAdmin
} from "../../Budget/BudgetQuery";
import { DotLoader } from "react-spinners";
import Loader from "../../../assets/Infinity-1s-200px.svg";
import AddItemCategoryModal from "../../ItemCategory/AddItemCategoryModal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
class ParticipantBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      temp: undefined,
      AllData: undefined,
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      filter: {
        participantId: this.props.id,
        order: "id desc",
        active: 1
      },
      count: 0,
      role: "PARTICIPANT",
      id: "",
      userData: [],
      businessData: [],
      selecetedRole: "PARTICIPANT",
      Role: [
        {
          role: "PARTICIPANT",
          id: "PARTICIPANT"
        }
        // {
        //   role: 'SUPPORTER',
        //   id: 'SUPPORTER',
        // },
        // {
        //   role: 'BUSINESS',
        //   id: 'BUSINESS',
        // },
      ],
      allTierList: [],
      selectedTier: "",
      allParticipantList: [],
      selectedParticipant: "",
      allCategoryList: [],
      selectedCategory: "",
      budgetxyz: null,
      tierxyz: null,
      categoryxyz: null
      // show: false
    };
  }

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size,
        listData: undefined
      },
      () => {
        this.getBudgetData();
      }
    );
  };
  componentWillMount() {
    this.getBudgetData();
    this.getAllParticipant();
    this.getAllTier();
  }

  getAllParticipant = () => {
    var obj = { role: "PARTICIPANT", active: 1 };
    if (localStorage.getItem("role") === "GUARDIAN") {
      obj.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
    }
    fetchMethod(participantQueryData, {
      where: obj
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allUserdata &&
          resp.data.allUserdata.Userdata
        ) {
          this.setState({
            allParticipantList: resp.data.allUserdata.Userdata
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  getAllTier = () => {
    fetchMethod(getAllTier)
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allTiertypes &&
          resp.data.allTiertypes.Tiertypes
        ) {
          this.setState({
            allTierList: resp.data.allTiertypes.Tiertypes
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  getBudgetData = () => {
    if (
      localStorage.getItem("role") === "GUARDIAN" &&
      !this.state.filter.participantId
    ) {
      var filterdata = this.state.filter;
      var obj = { role: "PARTICIPANT", active: 1 };
      obj.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;

      fetchMethod(participantQueryData, {
        where: obj
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.allUserdata &&
            resp.data.allUserdata.Userdata
          ) {
            var ids = [];
            resp.data.allUserdata.Userdata.map((i) => {
              ids.push(i.id);
            });
            filterdata.participantId = { inq: [ids] };
            this.setState({
              filter: filterdata
            });
          }
        })
        .then(() => {
          fetchMethod(budgetQuery, {
            where: this.state.filter,
            last: parseInt(this.state.rows),
            first: parseInt(this.state.pageNo)
          })
            .then((res) => res.json())
            .then((res) => {
              console.log(" active state value", res);
              if (res && res.error && res.error.statusCode === 401) {
                swal({ title: res.error.message, icon: "warning" }).then(() => {
                  localStorage.clear();
                  window.location = "/";
                });
              } else {
                res.data.allBudgets.Budgets.map((item) => {
                  return (
                    (item["budgetAvailable"] =
                      item.budgetAvailable !== null &&
                      item.budgetAvailable !== ""
                        ? "$" + item.budgetAvailable
                        : 0),
                    (item["storeName"] =
                      item?.budget_store_mapping_budgetMap?.BudgetStoreMappings
                        ?.length > 0
                        ? item?.budget_store_mapping_budgetMap?.BudgetStoreMappings?.map(
                            (data, index) => {
                              if (
                                data?.budgetStoreMappingStoremaprel?.Businesses
                                  ?.length > 0
                              ) {
                                if (
                                  item?.budget_store_mapping_budgetMap
                                    ?.BudgetStoreMappings?.length ===
                                  index + 1
                                ) {
                                  return (
                                    data?.budgetStoreMappingStoremaprel
                                      ?.Businesses[0]?.storeName || ""
                                  );
                                } else {
                                  return data?.budgetStoreMappingStoremaprel?.Businesses[0]?.storeName?.concat(
                                    ", "
                                  );
                                }
                              }
                            }
                          )
                        : ""),
                    (item["supporterName"] =
                      item?.budget_supporter_mapping_budgetMap
                        ?.BudgetSupporterMappings?.length > 0
                        ? item?.budget_supporter_mapping_budgetMap?.BudgetSupporterMappings?.map(
                            (data, index) => {
                              if (
                                data?.budgetSupporterMappingSupportermaprel
                                  ?.Userdata?.length > 0
                              ) {
                                if (
                                  item?.budget_supporter_mapping_budgetMap
                                    ?.BudgetSupporterMappings?.length ===
                                  index + 1
                                ) {
                                  return (
                                    data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname?.concat(
                                      data
                                        ?.budgetSupporterMappingSupportermaprel
                                        ?.Userdata[0]?.lastname
                                        ? " " +
                                            data
                                              ?.budgetSupporterMappingSupportermaprel
                                              ?.Userdata[0]?.lastname
                                        : ""
                                    ) || ""
                                  );
                                } else {
                                  return (
                                    data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname
                                      ?.concat(
                                        data
                                          ?.budgetSupporterMappingSupportermaprel
                                          ?.Userdata[0]?.lastname
                                          ? " " +
                                              data
                                                ?.budgetSupporterMappingSupportermaprel
                                                ?.Userdata[0]?.lastname
                                          : ""
                                      )
                                      ?.concat(", ") || ""
                                  );
                                }
                              }
                            }
                          )
                        : ""),
                    (item["userName"] =
                      item &&
                      item.fkBudgetParticipantIdrel &&
                      item.fkBudgetParticipantIdrel.Userdata &&
                      item.fkBudgetParticipantIdrel.Userdata[0]
                        ? item.fkBudgetParticipantIdrel.Userdata[0].firstname +
                          " " +
                          (item.fkBudgetParticipantIdrel.Userdata[0]
                            .lastname !== null
                            ? item.fkBudgetParticipantIdrel.Userdata[0].lastname
                            : "")
                        : item &&
                          item.fkSupporterIdBudgetMaprel &&
                          item.fkSupporterIdBudgetMaprel.Userdata &&
                          item.fkSupporterIdBudgetMaprel.Userdata[0]
                        ? item.fkSupporterIdBudgetMaprel.Userdata[0].firstname +
                          " " +
                          (item.fkSupporterIdBudgetMaprel.Userdata[0]
                            .lastname !== null
                            ? item.fkSupporterIdBudgetMaprel.Userdata[0]
                                .lastname
                            : "")
                        : item &&
                          item.fkBusinessIdBudgetMaprel &&
                          item.fkBusinessIdBudgetMaprel.Businesses &&
                          item.fkBusinessIdBudgetMaprel.Businesses[0] &&
                          item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                        ? item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                        : ""),
                    (item["userType"] =
                      item &&
                      item.fkBudgetParticipantIdrel &&
                      item.fkBudgetParticipantIdrel.Userdata &&
                      item.fkBudgetParticipantIdrel.Userdata[0] &&
                      item.fkBudgetParticipantIdrel.Userdata[0].role
                        ? item.fkBudgetParticipantIdrel.Userdata[0].role
                        : item &&
                          item.fkSupporterIdBudgetMaprel &&
                          item.fkSupporterIdBudgetMaprel.Userdata &&
                          item.fkSupporterIdBudgetMaprel.Userdata[0] &&
                          item.fkSupporterIdBudgetMaprel.Userdata[0].role
                        ? item.fkSupporterIdBudgetMaprel.Userdata[0].role
                        : "BUSINESS"),
                    (item["itemCategory"] =
                      item !== null &&
                      item.fkBudgetItemCategoryIdrel != null &&
                      item.fkBudgetItemCategoryIdrel &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .categoryName
                        ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName
                            .charAt(0)
                            .toUpperCase() +
                          item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName.slice(
                            1
                          )
                        : ""),
                    (item["status"] =
                      item !== null &&
                      item.fkBudgetItemCategoryIdrel != null &&
                      item.fkBudgetItemCategoryIdrel &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .isActive
                        ? "ACTIVE"
                        : "INACTIVE"),
                    (item["createdAt"] =
                      item != null && item.createdAt
                        ? this.formatDate(item.createdAt)
                        : ""),
                    (item["tier"] =
                      item !== null &&
                      item.fkBudgetItemCategoryIdrel != null &&
                      item.fkBudgetItemCategoryIdrel &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .fktiertypemaprel &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .fktiertypemaprel.Tiertypes &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .fktiertypemaprel.Tiertypes[0] &&
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .fktiertypemaprel.Tiertypes[0].tierType
                        ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                            .fktiertypemaprel.Tiertypes[0].tierType
                        : ""),
                    (item["wallet"] = item?.fkBudgetWalletIdrel?.Wallets[0])
                    // " - " +
                    // item?.fkBudgetWalletIdrel?.Wallets[0]?.description
                  );
                });
                this.setState({
                  count:
                    res.data &&
                    res.data.allBudgets &&
                    res.data.allBudgets !== null
                      ? res.data.allBudgets.totalCount
                      : "",
                  listData:
                    res.data &&
                    res.data.allBudgets &&
                    res.data.allBudgets !== null
                      ? res.data.allBudgets.Budgets
                      : "",
                  temp:
                    res.data &&
                    res.data.allBudgets &&
                    res.data.allBudgets !== null
                      ? res.data.allBudgets.Budgets
                      : ""
                });
              }
            })
            .catch((e) => {
              swal({ title: e.message, icon: "warning" });
              this.setState({ listData: [] });
            });
        })
        .catch((error) => {
          swal({ title: error.message, icon: "warning" });
        });
    } else {
      fetchMethod(budgetQuery, {
        where: this.state.filter,
        last: parseInt(this.state.rows),
        first: parseInt(this.state.pageNo)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.error && res.error.statusCode === 401) {
            swal({ title: res.error.message, icon: "warning" }).then(() => {
              localStorage.clear();
              window.location = "/";
            });
          } else {
            res.data.allBudgets.Budgets.map((item) => {
              return (
                (item["budgetAvailable"] =
                  item.budgetAvailable !== null && item.budgetAvailable !== ""
                    ? "$" + item.budgetAvailable
                    : 0),
                (item["wallet"] = item?.fkBudgetWalletIdrel?.Wallets[0]),
                (item["storeName"] =
                  item?.budget_store_mapping_budgetMap?.BudgetStoreMappings
                    ?.length > 0
                    ? item?.budget_store_mapping_budgetMap?.BudgetStoreMappings?.map(
                        (data, index) => {
                          if (
                            data?.budgetStoreMappingStoremaprel?.Businesses
                              ?.length > 0
                          ) {
                            if (
                              item?.budget_store_mapping_budgetMap
                                ?.BudgetStoreMappings?.length ===
                              index + 1
                            ) {
                              return (
                                data?.budgetStoreMappingStoremaprel
                                  ?.Businesses[0]?.storeName || ""
                              );
                            } else {
                              return data?.budgetStoreMappingStoremaprel?.Businesses[0]?.storeName?.concat(
                                ", "
                              );
                            }
                          }
                        }
                      )
                    : ""),
                (item["supporterName"] =
                  item?.budget_supporter_mapping_budgetMap
                    ?.BudgetSupporterMappings?.length > 0
                    ? item?.budget_supporter_mapping_budgetMap?.BudgetSupporterMappings?.map(
                        (data, index) => {
                          if (
                            data?.budgetSupporterMappingSupportermaprel
                              ?.Userdata?.length > 0
                          ) {
                            if (
                              item?.budget_supporter_mapping_budgetMap
                                ?.BudgetSupporterMappings?.length ===
                              index + 1
                            ) {
                              return (
                                data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname?.concat(
                                  data?.budgetSupporterMappingSupportermaprel
                                    ?.Userdata[0]?.lastname
                                    ? " " +
                                        data
                                          ?.budgetSupporterMappingSupportermaprel
                                          ?.Userdata[0]?.lastname
                                    : ""
                                ) || ""
                              );
                            } else {
                              return (
                                data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname
                                  ?.concat(
                                    data?.budgetSupporterMappingSupportermaprel
                                      ?.Userdata[0]?.lastname
                                      ? " " +
                                          data
                                            ?.budgetSupporterMappingSupportermaprel
                                            ?.Userdata[0]?.lastname
                                      : ""
                                  )
                                  ?.concat(", ") || ""
                              );
                            }
                          }
                        }
                      )
                    : ""),
                (item["userName"] =
                  item &&
                  item.fkBudgetParticipantIdrel &&
                  item.fkBudgetParticipantIdrel.Userdata &&
                  item.fkBudgetParticipantIdrel.Userdata[0]
                    ? item.fkBudgetParticipantIdrel.Userdata[0].firstname +
                      " " +
                      (item.fkBudgetParticipantIdrel.Userdata[0].lastname !==
                      null
                        ? item.fkBudgetParticipantIdrel.Userdata[0].lastname
                        : "")
                    : item &&
                      item.fkSupporterIdBudgetMaprel &&
                      item.fkSupporterIdBudgetMaprel.Userdata &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0]
                    ? item.fkSupporterIdBudgetMaprel.Userdata[0].firstname +
                      " " +
                      (item.fkSupporterIdBudgetMaprel.Userdata[0].lastname !==
                      null
                        ? item.fkSupporterIdBudgetMaprel.Userdata[0].lastname
                        : "")
                    : item &&
                      item.fkBusinessIdBudgetMaprel &&
                      item.fkBusinessIdBudgetMaprel.Businesses &&
                      item.fkBusinessIdBudgetMaprel.Businesses[0] &&
                      item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                    ? item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                    : ""),
                (item["userType"] =
                  item &&
                  item.fkBudgetParticipantIdrel &&
                  item.fkBudgetParticipantIdrel.Userdata &&
                  item.fkBudgetParticipantIdrel.Userdata[0] &&
                  item.fkBudgetParticipantIdrel.Userdata[0].role
                    ? item.fkBudgetParticipantIdrel.Userdata[0].role
                    : item &&
                      item.fkSupporterIdBudgetMaprel &&
                      item.fkSupporterIdBudgetMaprel.Userdata &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0] &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0].role
                    ? item.fkSupporterIdBudgetMaprel.Userdata[0].role
                    : "BUSINESS"),
                (item["itemCategory"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .categoryName
                    ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName
                        .charAt(0)
                        .toUpperCase() +
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName.slice(
                        1
                      )
                    : ""),
                (item["status"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .isActive
                    ? "ACTIVE"
                    : "INACTIVE"),
                (item["createdAt"] =
                  item != null && item.updatedAt
                    ? this.formatDate(item.updatedAt)
                    : ""),
                (item["tier"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes[0].tierType
                    ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .fktiertypemaprel.Tiertypes[0].tierType
                    : "")
              );
            });
            this.setState({
              count:
                res.data && res.data.allBudgets && res.data.allBudgets !== null
                  ? res.data.allBudgets.totalCount
                  : "",
              listData:
                res.data && res.data.allBudgets && res.data.allBudgets !== null
                  ? res.data.allBudgets.Budgets
                  : "",
              temp:
                res?.data?.allBudgets?.Budgets?.length > 0
                  ? res.data.allBudgets.Budgets
                  : ""
            });
          }
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
          this.setState({ listData: [] });
        });
    }
  };

  addBudget = () => {
    this.props.history.push({
      pathname: `/addParticipantBudget/${this.props.userdata?.id}`,
      state: { Userdata: this.props.userdata }
    });
  };

  handleEditModal = (data) => {
    this.props.history.push({
      pathname: `/editParticipantBudget/${data.id}`
    });
  };

  handleDelete = (row) => {
    if (row.active === 1 || row.active === "1") {
      swal({
        title: "Are you sure you really want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          const test = {
            id: row.id,
            active: 0
          };

          fetchMethod(saveBudget, { obj: test })
            .then((res) => res.json())
            .then((res) => {
              const id = res.data.saveBudget.id;
              swal({
                title: id ? "Deleted successfully" : "Error deleting",
                icon: "success"
              });
              if (id) {
                let pageNo = this.state.pageNo;
                let previousData = this.state.listData.length;
                this.setState({ listData: undefined });

                const { filter } = this.state;

                let search = this.state.search;

                if (this.state.search != "" && previousData === 1) {
                  pageNo = this.state.rows;
                  delete filter["and"];
                  search = "";
                } else if (
                  this.state.search == "" &&
                  previousData === 1 &&
                  pageNo != this.state.rows
                ) {
                  pageNo = this.state.pageNo - this.state.rows;
                } else {
                  pageNo = this.state.pageNo;
                }

                this.setState({ pageNo, filter, search }, () =>
                  this.getBudgetData()
                );
              }
            })
            .catch((e) => {
              swal({ title: e.message, icon: "warning" });
              this.setState({ listData: [] });
            });
        }
      });
    }
    if (row.active === 0 || row.active === "0") {
      swal({
        title: "This record is already inactive?",
        icon: "warning",
        dangerMode: true
      });
    }
  };
  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleParticipantAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      delete this.state.filter.participantId;
      const { filter } = this.state;
      this.setState({ listData: undefined });
      filter.participantId = v.id;
      this.setState(
        { selectedParticipant: v.id, filter, budgetxyz: v },
        () => {}
      );
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    } else {
      delete this.state.filter.participantId;
      this.setState({ listData: undefined, budgetxyz: null });
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    }
  };

  handleTierFilterAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      this.setState({
        selectedTier: v.id,
        tierxyz: v
      });
      if (localStorage.getItem("role") === "GUARDIAN") {
        fetchMethod(categoryQueryData, {
          createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
          isActive: 1,
          tierId: v.id
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (
              resp &&
              resp.data &&
              resp.data.MasterItemCategoryShowItemCategories &&
              resp.data.MasterItemCategoryShowItemCategories.itemCategoryData
            ) {
              this.setState({
                allCategoryList:
                  resp.data.MasterItemCategoryShowItemCategories
                    .itemCategoryData
              });
            }
          })
          .catch((error) => {
            swal({ title: error.message, icon: "warning" });
          });
        const { filter } = this.state;
        this.setState({ listData: undefined });
        filter.tierid = v.id;
        this.debounceTimer = this.debounce(
          this.debounceTimer,
          () => {
            this.getBudgetData();
          },
          200
        );
      } else {
        fetchMethod(categoryQueryDataForAdmin, {
          where: { tierId: v.id, isActive: 1 }
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (
              resp &&
              resp.data &&
              resp.data.allMasterItemCategories &&
              resp.data.allMasterItemCategories.MasterItemCategories
            ) {
              let finalData =
                resp.data.allMasterItemCategories.MasterItemCategories.map(
                  (item) => {
                    return {
                      id: item.id,
                      category_name:
                        item.categoryName.charAt(0).toUpperCase() +
                        item.categoryName.slice(1)
                    };
                  }
                );
              this.setState({
                allCategoryList: finalData
              });
            }
          })
          .catch((error) => {
            swal({ title: error.message, icon: "warning" });
          });
        const { filter } = this.state;
        this.setState({ listData: undefined });
        filter.tierid = v.id;
        this.debounceTimer = this.debounce(
          this.debounceTimer,
          () => {
            this.getBudgetData();
          },
          200
        );
      }
    } else {
      this.setState({
        allCategoryList: []
      });
      const { filter } = this.state;
      delete filter.tierid;
      this.setState({ listData: undefined, tierxyz: null });
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    }
  };

  handleCategoryFilterAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      const { filter } = this.state;
      this.setState({ listData: undefined });
      this.setState({ selectedCategory: v.id, filter, categoryxyz: v });
      filter.item_category_id = v.id;
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    } else {
      const { filter } = this.state;
      this.setState({ listData: undefined });
      this.setState({ selectedCategory: null, filter, categoryxyz: null });
      delete filter.item_category_id;
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    }
  };

  resetFilters = () => {
    delete this.state.filter.participantId;
    delete this.state.filter.item_category_id;
    delete this.state.filter.tierid;
    this.setState({
      selectedParticipant: "",
      selectedTier: "",
      selectedCategory: "",
      budgetxyz: null,
      tierxyz: null,
      categoryxyz: null,
      listData: undefined,
      allCategoryList: []
    });
    this.getBudgetData();
  };
  // handleShow = () => this.setState({ show: true });
  // addItemCategory = () => {
  //   this.handleShow()
  // };
  // handleClose = () => {
  //   console.log("iiiiiiiiiiiiiii")
  //   this.setState({
  //     show: false,
  //   });
  //   this.getBudgetData();
  // }
  render() {
    const nameColumn = [
      {
        Header: "Budget Id",
        Cell: (row) => {
          return <div className="dot">{row.original.id}</div>;
        },
        width: 60
      }
    ];

    const actionButton = [
      {
        Header: "Status",
        accessor: "active",
        Cell: (row) => {
          return <p>{row.original.status}</p>;
        }
      },
      {
        Header: "",
        sortable: false,
        Cell: (row) => (
          <div>
            <IconButton
              aria-label="edit"
              onClick={() => this.handleEditModal(row.original)}
              disabled={row.original.status === "INACTIVE"}
            >
              <img src={Edit} alt="Edit" />
            </IconButton>
          </div>
        ),
        width: 70
      },
      {
        Header: "",
        sortable: false,
        Cell: (row) => (
          <div>
            {
              <IconButton
                aria-label="delete"
                onClick={() => this.handleDelete(row.original)}
              >
                <img src={Delete} alt="Delete" />
              </IconButton>
            }
          </div>
        ),
        width: 70
      }
    ];

    const columns = nameColumn
      .concat(participantBudgetList.columns)
      .concat(actionButton);
    return (
      <div className="container participantSection budgetSection">
        <div className="heading">
          <h2>Budgets</h2>
          <div className="addbdgetBtn add">
            <Button onClick={this.addBudget}>Add Budget</Button>
          </div>
        </div>
        <div className="tab-data-box">
          <div className="tab-filter-box">
            <div className="row">
              <div className="col-md-8">
                {/* <Autocomplete
                  size="small"
                  value={this.state.budgetxyz}
                  options={this.state.allParticipantList}
                  onChange={(e, v) => this.handleParticipantAutocomplete(e, v)}
                  getOptionLabel={(option) =>
                    option
                      ? option.firstname +
                        (option.lastname ? " " + option.lastname : "")
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Participants"
                      variant="outlined"
                    />
                  )}
                /> */}

                {/* <FormControl>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              value={this.state.tierxyz}
              options={this.state.allTierList}
              onChange={(e, v) => this.handleTierFilterAutocomplete(e, v)}
              getOptionLabel={option =>
                option && option.tierType ? option.tierType : ""
              }
              // style={{ width: 300 }}
              renderInput={params => (
                <TextField {...params} label="Type" variant="outlined" />
              )}
            />
          </FormControl> */}
                {/* <FormControl>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              value={this.state.categoryxyz}
              options={this.state.allCategoryList}
              onChange={(e, v) => this.handleCategoryFilterAutocomplete(e, v)}
              getOptionLabel={option =>
                option && option.category_name ? option.category_name : ""
              }
              // style={{ width: 300 }}
              renderInput={params => (
                <TextField {...params} label="Category" variant="outlined" />
              )}
            />
          </FormControl> */}
                {/* <div className="addbdgetBtn">
            <Button onClick={this.resetFilters}>Reset</Button>
          </div> */}
              </div>
            </div>
          </div>
        </div>
        {this.state.listData ? (
          <div className="adminBudgetTable">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={participantBudgetList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            <img src={Loader}></img>
          </div>
        )}
        {/* <AddItemCategoryModal
          show={this.state.show}
          handleClose={this.handleClose}
          editItemDetails={undefined}
        ></AddItemCategoryModal> */}
      </div>
    );
  }
}

export default withRouter(ParticipantBudget);
