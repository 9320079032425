import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  withStyles,
  Checkbox
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import ReactTableComponent from "src/ReactTable/ReactTable";
import "./participantTabSetup.css";
import { useState } from "react";
import { participantBudgetList } from "src/Components/Budget/BudgetConfig";
import { useRef } from "react";
import { useMemo } from "react";
import Loader from "src/assets/Infinity-1s-200px.svg";
import {
  getParticipantConnectedBudgets,
  getSupporterConnectedBudgets,
  linkSupporterToBudgets,
  unlinkSupporterToBudgets
} from "src/network/service/budgetService";
import swal from "sweetalert";

const DialogButton = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

function SupporterConnectedBudgets({
  open,
  supporterId,
  handleClose,
  participantId,
  handleSubmit
}) {
  const [addedBudgetIds, setAddedBudgetIds] = useState([]);
  const [removedBudgetIds, setRemovedBudgetIds] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  const [loadingSupporterBudgets, setLoadingSupporterBudgets] = useState(false);
  const [loadingParticipantBudgets, setLoadingParticipantBudgets] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const connectedBudgetIds = useRef([]);

  const onCheckBoxClick = (value, data) => {
    if (value) {
      if (!addedBudgetIds.includes(data.id)) {
        setAddedBudgetIds((prev) => [...prev, data.id]);
      }
      setRemovedBudgetIds((prev) => prev.filter((id) => id !== data.id));
    } else {
      if (!removedBudgetIds.includes(data.id)) {
        setRemovedBudgetIds((prev) => [...prev, data.id]);
      }
      setAddedBudgetIds((prev) => prev.filter((id) => id !== data.id));
    }
  };

  const handleSupporterSave = () => {
    setLoading(true);
    Promise.all([
      addedBudgetIds.length > 0
        ? linkSupporterToBudgets(participantId, supporterId, addedBudgetIds)
        : Promise.resolve(),
      removedBudgetIds.length > 0
        ? unlinkSupporterToBudgets(participantId, supporterId, removedBudgetIds)
        : Promise.resolve()
    ])
      .catch(() => {
        swal({ icon: "error", title: "Something went wrong try again" });
      })
      .finally(() => {
        setLoading(false);
        handleSubmit();
        handleClose();
      });
  };

  useEffect(() => {
    // fetch supporters's budgets
    if (supporterId !== undefined) {
      setLoadingSupporterBudgets(true);
      getSupporterConnectedBudgets(supporterId, participantId)
        .then((res) => {
          const allConnectedBudgets =
            res?.data?.allBudgetSupporterMappings?.BudgetSupporterMappings;
          if (allConnectedBudgets && allConnectedBudgets?.length > 0) {
            const idList = [];
            allConnectedBudgets.forEach((budgets) => {
              const participantsBudget =
                budgets?.budgetSupporterMappingBudgetmaprel?.Budgets;
              if (participantsBudget?.length > 0) {
                participantsBudget.forEach((item) => idList.push(item.id));
              }
            });
            connectedBudgetIds.current = idList;
          }
        })
        .catch(() => {
          connectedBudgetIds.current = [];
        })
        .finally(() => {
          setLoadingSupporterBudgets(false);
        });
    }
  }, [participantId, supporterId]);

  useEffect(() => {
    // fetch participant's budgets
    if (participantId !== undefined) {
      setLoadingParticipantBudgets(true);
      getParticipantConnectedBudgets(participantId)
        .then((res) => {
          let participantBudgets = res?.data?.allBudgets?.Budgets;
          participantBudgets = participantBudgets.map((item) => ({
            ...item,
            budgetAvailable:
              item.budgetAvailable !== null && item.budgetAvailable !== ""
                ? "$" + item.budgetAvailable
                : 0,
            wallet: item?.fkBudgetWalletIdrel?.Wallets[0],
            itemCategory: item?.fkBudgetItemCategoryIdrel
              ?.MasterItemCategories[0]?.categoryName
              ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName
                  .charAt(0)
                  .toUpperCase() +
                item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName.slice(
                  1
                )
              : "",
            tier:
              item?.fkBudgetItemCategoryIdrel?.MasterItemCategories[0]
                ?.fktiertypemaprel?.Tiertypes[0]?.tierType ?? ""
          }));
          setBudgetData(participantBudgets);
        })
        .catch(() => {
          setBudgetData([]);
        })
        .finally(() => {
          setLoadingParticipantBudgets(false);
        });
    }
  }, [participantId]);

  const actionButton = useMemo(
    () => [
      {
        Header: "Connect",
        Cell: (row) => {
          return (
            <TableCheckBox
              row={row}
              connectedBudgetIds={connectedBudgetIds.current}
              onCheckBoxClick={onCheckBoxClick}
            />
          );
        }
      }
    ],
    [connectedBudgetIds.current]
  );
  const columns = nameColumn
    .concat(participantBudgetList.columns)
    .concat(actionButton);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="supporterConnectedWallets"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        className="chooseBuisness"
      >
        Edit Supporter's Budgets
      </DialogTitle>
      {loading ? (
        <div className="spinner">
          <img src={Loader} alt="loader" />
        </div>
      ) : (
        <DialogContent>
          <div className="modal-body-box">
            <div className="adminPaymentTable">
              <ReactTableComponent
                listData={budgetData}
                listConfig={{ ...participantBudgetList, showPagination: false }}
                columns={columns}
                dataCount={0}
                forSerialNo={{
                  pageNo: 0,
                  pageSize: 0
                }}
                loading={loadingParticipantBudgets || loadingSupporterBudgets}
              />
            </div>
            <DialogButton>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSupporterSave}>Save</Button>
            </DialogButton>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}

const nameColumn = [
  {
    Header: "Budget Id",
    Cell: (row) => {
      return <div>{row.original.id}</div>;
    },
    width: 90
  }
];

const TableCheckBox = ({ row, connectedBudgetIds, onCheckBoxClick }) => {
  return (
    <Checkbox
      color="primary"
      defaultChecked={connectedBudgetIds?.includes(row.original.id)}
      onChange={(_event, value) => {
        onCheckBoxClick(value, row.original);
      }}
    />
  );
};
export default SupporterConnectedBudgets;
