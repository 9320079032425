import { ACTIVE, INACTIVE } from "src/utils/constants";
import { WALLET_LIST_ROUTE } from "src/utils/constants";
import { Link } from "react-router-dom";

export const adminSupporterList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "First Name",
      accessor: "firstname",
      sortable: false,
    },
    {
      Header: "Last Name",
      accessor: "lastname",
      sortable: false,
    },
    {
      Header: "Email",
      accessor: "email",
      sortable: false,
    },
    {
      Header: "Flagged",
      accessor: "flagged",
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: false,
    },
    {
      Header: "Mobile No.",
      accessor: "phonenumber",
      sortable: false,
    },
    {
      Header: "Date Of Birth",
      accessor: "dob",
      sortable: false,
    },
    {
      Header: "Address",
      accessor: "address",
      sortable: false,
    },
    // {
    //   Header: "Card Status",
    //   accessor: "cardStatus",
    //   sortable: false
    // }
  ],
};

export const guardianSupporterList = {
  showPagination: false,
  showSerialNo: true,
  columns: [
    // {
    //   Header: "Last Name",
    //   accessor: "lastname",
    //   sortable: true
    // },
    {
      Header: "Email Address",
      accessor: "email",
      sortable: false,
    },
    // {
    //   Header: "Flagged",
    //   accessor: "flagged",
    //   sortable: false
    // },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      sortable: true,
    },
    {
      Header: "Status",
      sortable: false,
      Cell: (row) => {
        return <div>{row.original.active ? ACTIVE : INACTIVE}</div>;
      },
    },

    // {
    //   Header: "Date Of Birth",
    //   accessor: "dob",
    //   sortable: false
    // },
    // {
    //   Header: "Address",
    //   accessor: "address",
    //   sortable: false
    // }
  ],
};
export const adminBuisnessList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Store Name",
      accessor: "storeName",
      sortable: false,
    },
    {
      Header: "ABN Number",
      accessor: "abnNumber",
      sortable: false,
    },
    {
      Header: "Contact Number",
      accessor: "mobileNo",
      sortable: false,
    },
    {
      Header: "Address",
      accessor: "address",
      sortable: false,
    },
    {
      Header: "Email",
      accessor: "email",
      sortable: false,
    },
    {
      Header: "Flagged",
      accessor: "flagged",
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: false,
    },
  ],
};

export const guardianBuisnessList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Accounts Id",
      accessor: "id",
      sortable: true,
    },
    {
      Header: "Store Name",
      accessor: "storeName",
      sortable: true,
    },
    // {
    //   Header: "ABN Number",
    //   accessor: "abnNumber",
    //   sortable: true
    // },
    {
      Header: "Phone Number",
      accessor: "mobileNo",
      sortable: true,
    },
    {
      Header: "Store Location",
      accessor: "address",
      sortable: true,
    },
    {
      Header: "Email Address",
      accessor: "email",
      sortable: true,
    },
    // {
    //   Header: "Flagged",
    //   accessor: "flagged",
    //   sortable: false
    // },
    {
      Header: "Status",
      accessor: "status",
      sortable: false,
    },
  ],
};
export const adminPaymentRequestList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Date/Time of Transaction",
      accessor: "transactiondate",
      sortable: true,
    },
    {
      Header: "Merchant",
      accessor: "merchantname",
      sortable: true,
    },
    {
      Header: "Starting Balance",
      accessor: "requestedAmount",
      sortable: true,
    },
    {
      Header: "Purchase Amount",
      accessor: "spentamount",
      sortable: true,
    },
    // {
    //   Header: "Card Limit",
    //   accessor: "cardlimit",
    //   sortable: true,
    // },
    {
      Header: "Change Amount",
      accessor: "changeamount",
      sortable: true,
    },
    {
      Header: "Wallet name",
      accessor: "description",
      sortable: true,
      Cell: (row) => (
        <Link to={`/${WALLET_LIST_ROUTE}/${row.original.id}`}>
          {/* <p>{row.original.wallet}</p> */}
          <p>..WallName..</p>
        </Link>
      ),
    },
    {
      Header: "Participant Name",
      accessor: "participantName",
      sortable: true,
    },
    {
      Header: "Purchase Made By",
      accessor: "madeby",
      sortable: true,
    },
    {
      Header: "Category",
      accessor: "categoryName",
      sortable: true,
    },
    // {
    //   Header: "Payment Status",
    //   accessor: "paymentStatus",
    //   sortable: false,
    // },
    {
      Header: "Verified",
      accessor: "isverified",
      sortable: true,
    },
    // {
    //   Header: "Allocated Amount",
    //   accessor: "requestedAmount",
    //   sortable: true,
    // },

    // {
    //   Header: "AuthId",
    //   accessor: "authid",
    //   sortable: true,
    // },
  ],
};
