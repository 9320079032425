import axios from "axios";
import { SIMS_URL } from "src/Config";

const api = axios.create({
  baseURL: SIMS_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem("token");
  config.headers["Access_token"] = token;
  return config;
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    return Promise.reject(error?.response?.data);
  }
);

export default api;
