import { Link } from "react-router-dom";
import { WALLET_LIST_ROUTE } from "src/utils/constants";

export const adminWalletsConfig = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Desciption",
      accessor: "description",
      sortable: false,
      Cell: (row) => {
        return (
          <Link
            to={`${WALLET_LIST_ROUTE}/${row.original.id}`}
            style={{
              color: "#000",
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: 600
            }}
          >
            {row.original.description}
          </Link>
        );
      }
    },
    {
      Header: "Owner",
      accessor: "owner",
      sortable: false
    },
    {
      Header: "Participant",
      accessor: "participant",
      sortable: false
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: false
    },
    {
      Header: "Balance",
      accessor: "balance",
      sortable: false
    },
    {
      Header: "Can Withdraw",
      accessor: "canwithdraw",
      sortable: false
    }
  ]
};
