import React, { useState } from "react";
import {
  NavigateBefore,
  NavigateNext,
  FirstPage,
  LastPage
} from "@material-ui/icons";
import "./reactTable.css";
import Pagination from "@mui/material/Pagination";
import "./pagination.scss";

const CardPagination = ({
  dataPerPage,
  totalData,
  paginate,
  initialPage,
  dataRange
}) => {
  const rows = dataPerPage;
  const paginationRangeSet = (val) => {
    var arr = [];
    for (let i = 1; i <= val; i++) {
      arr.push(i);
    }
    return arr;
  };

  const [defaultRows, setRows] = useState(rows);
  const [pageNumber, setPageNumber] = useState(initialPage);
  const pageNumbers = [];
  const NumberOfPages = totalData / defaultRows;
  const [paginationRange, setRange] = useState(
    paginationRangeSet(Math.ceil(totalData / rows))
  );
  const [pageDirect, setPageDirect] = useState(initialPage);

  for (let i = 1; i <= Math.ceil(NumberOfPages); i++) {
    pageNumbers.push(i);
  }

  const handlePageNumber = (number) => {
    setPageNumber(number);
    paginate(number * rows, defaultRows);
  };

  const handlePageChange = (event, value) => {
    handlePageNumber(value);
  };

  const handleDirectPage = (e) => {
    setPageDirect(e.target.value);
  };

  const handleChanegeRow = (e) => {
    paginate(1 * parseInt(e.target.value), parseInt(e.target.value));
    setRows(e.target.value);
  };

  return (
    <div className="pagination">
      <div className="items">
        <label>Items per page</label>
        <select
          onChange={handleChanegeRow}
          value={defaultRows}
          className="bh-form-select xs"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </div>
      <nav className="paginationNav">
        <ul>
          <li className="page-item">
            <button
              aria-label="Previous"
              onClick={() => (pageNumber !== 1 ? handlePageNumber(1) : "")}
            >
              <FirstPage />
            </button>
          </li>
          <Pagination
            count={Math.ceil(totalData / rows)}
            size="small"
            siblingCount={0}
            boundaryCount={2}
            page={pageNumber}
            onChange={handlePageChange}
          />
          <li className="page-item">
            <button
              aria-label="Next"
              onClick={() =>
                pageNumber < NumberOfPages
                  ? handlePageNumber(pageNumber + 1)
                  : ""
              }
            >
              <LastPage />
            </button>
          </li>
        </ul>
      </nav>

      <div className="goto">
        <label>Go to page</label>
        <select
          onChange={handleDirectPage}
          value={pageDirect}
          className="bh-form-select xs"
        >
          {paginationRange.map((ele) => {
            return (
              <option value={ele} key={ele}>
                {ele}
              </option>
            );
          })}
        </select>

        <button
          className="btn btn-primary xs"
          onClick={() => handlePageNumber(pageDirect)}
        >
          Go
        </button>
      </div>
    </div>
  );
};

export default CardPagination;
