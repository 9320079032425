import React, { Component } from "react";
import AdminWallet from "./AdminWallet";
import { ADMIN } from "src/utils/constants";
import GuardianWallet from "./GuardianWallet";

export class Wallet extends Component {
  render() {
    return (
      <div>
        {localStorage.getItem("role") === ADMIN ? (
          <AdminWallet />
        ) : (
          <GuardianWallet />
        )}
      </div>
    );
  }
}

export default Wallet;
