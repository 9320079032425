import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  NavigateBefore,
  NavigateNext,
  FirstPage,
  LastPage
} from "@material-ui/icons";
import "./reactTable.css";
import Pagination from "@mui/material/Pagination";
import { Button } from "@material-ui/core";

const Paginations = ({
  dataPerPage,
  totalData,
  paginate,
  initialPage,
  dataRange,
  showPerPageItem = true
}) => {
  const paginationRangeSet = (val) => {
    var arr = [];
    for (let i = 1; i <= val; i++) {
      arr.push(i);
    }
    return arr;
  };

  const rows = parseInt(localStorage.getItem("rows"));
  const [defaultRows, setRows] = useState(rows);
  const [pageNumber, setPageNumber] = useState(initialPage);
  const [pageDirect, setPageDirect] = useState(initialPage);

  const [paginationRange, setRange] = useState(
    paginationRangeSet(Math.ceil(totalData / rows))
  );

  const handlePageChange = (event, value) => {
    handlePageNumber(value);
  };

  const pageNumbers = [];
  const NumberOfPages = totalData / defaultRows;

  for (let i = 1; i <= Math.ceil(NumberOfPages); i++) {
    pageNumbers.push(i);
  }

  const handlePageRows = (e) => {
    setPageNumber(1);
    localStorage.setItem("rows", e.target.value);

    setRows(e.target.value);

    paginate(1 * e.target.value, e.target.value);
  };

  const handlePageNumber = (number) => {
    setPageNumber(number);

    paginate(number * rows, defaultRows);
  };

  const handleDirectPage = (e) => {
    setPageDirect(e.target.value);
  };

  return (
    <div className="pagination">
      {showPerPageItem && (
        <div className="items">
          <label>Items per page</label>
          <select
            onChange={handlePageRows}
            value={defaultRows}
            className="bh-form-select xs"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </div>
      )}

      {/* <p>
        <span>
          {dataRange.first} - {dataRange.last}
        </span>
        of <span>{totalData}</span>
      </p> */}

      <nav className="paginationNav">
        <ul className="pagination">
          <li className="page-item">
            <a
              className="page-link"
              aria-label="Previous"
              onClick={() => (pageNumber !== 1 ? handlePageNumber(1) : "")}
            >
              <FirstPage />
            </a>
          </li>
          <Pagination
            count={Math.ceil(totalData / rows)}
            size="small"
            siblingCount={0}
            boundaryCount={2}
            page={pageNumber}
            onChange={handlePageChange}
          />
          <li className="page-item">
            <a
              className="page-link"
              aria-label="Next"
              onClick={() =>
                pageNumber < NumberOfPages
                  ? handlePageNumber(Math.ceil(totalData / rows))
                  : ""
              }
            >
              <LastPage />
            </a>
          </li>
        </ul>
      </nav>

      <div className="goto">
        <label>Go to page</label>
        <select
          onChange={handleDirectPage}
          value={pageDirect}
          className="bh-form-select xs"
        >
          {paginationRange.map((ele) => {
            return (
              <option value={ele} key={ele}>
                {ele}
              </option>
            );
          })}
        </select>

        <button
          className="btn btn-primary xs"
          onClick={() => handlePageNumber(pageDirect)}
        >
          Go
        </button>
      </div>
    </div>
  );
};

export default Paginations;
