import gql from "graphql-tag";
export const participantQuery = `
query($where:JSON,$last: Int, $first: Int){
  allUserdata(where:$where,last:$last,first:$first){
    totalCount
    Userdata{
      id
      firstname
      lastname
      email
      phonenumber
      ndisNumber
      dob
      address
      city
      active
      kycstatus
      street
      zipcode
      role
      profileimage
      fkUserIdCardDetailMap{
        CardDetails{
          cardLimit
        }
      }
    }
  }
}
`;

export const authLedgerQuery = `
query($where:JSON,$last: Int, $first: Int){
  allAuthledgers(where:$where, last:$last,first:$first){
    Authledgers{
      id
      isrepeatpay
      isrepeatpaysetforparticipant
      repeatpayamount
      userid
      active
      requestedamount
    }
  }
}
`;

export const getParticipantData = (id, includeCardDetail) => `
query{
  allUserdata(where:{id:${id}}){
    Userdata{
      id
      firstname
      lastname
      email
      phonenumber
      dob
      address
      city
      state
			zipcode
      country
      profileimage
      ndisNumber
      kycstatus
      active
			verificationDoc1Url
      verificationDoc2Url
      verificationDoc3Url
      verificationDoc1Name
      verificationDoc2Name
      verificationDoc3Name
      fkUserIdCardDetailMap{
        CardDetails{
          cardLimit
          cardNumber
          id
        }
      }
      authledgerUserMap(where:{canwithdraw:1}){
        Authledgers{
          id
          userid
          canwithdraw
          withdrawfrom
        }
      }
      userguardianidmaprel {
        Userdata{
          id
          firstname
          lastname
        }
      }
      alwaysavailablebudget
      repeatpayamount
    }
  }
  ${
    includeCardDetail
      ? `allCardDetails(where:{userId:${id}}){
      CardDetails{
        cardNumber
      }
    }`
      : ""
  }
}
`;
export const getRepeatPayAmount = `
query($where:JSON){
  allAuthledgers(where:$where){
    Authledgers{
      id
      repeatpayamount
    }
  }
}
`;

export const editParticipantData = (id) => `
query{
  allUserdata(where:{id:${id},active:1,order:"id desc"}){
    Userdata{
      id
      firstname
      lastname
      email
      phonenumber
      active
      dob
      address
      city
      state
      country
      zipcode
      ndisNumber
      guardianId
      role
      profileimage
      kycstatus
      userguardianidmaprel{
        Userdata{
          id
          firstname
          lastname
        }
      }
      }
  }
}
`;

export const saveUserdata = gql`
  mutation saveUserdata($obj: UserdataInput!) {
    saveUserdata(obj: $obj) {
      id
    }
  }
`;

export const addEditFunds = gql`
  mutation AddFunds(
    $walletId: Int!
    $amount: String
    $description: String
    $flag: Int!
    $createdby: Int!
    $userid: Int!
  ) {
    UserdataAddEditFunds(
      userid: $userid
      amount: $amount
      description: $description
      flag: $flag
      createdby: $createdby
      walletId: $walletId
    )
  }
`;

export const guardianDropdownData = `
query($where:JSON){
  allUserdata(where:$where){
    Userdata{
      id
      firstname
      lastname
    }
  }
}
`;

export const getTransactions = `
query($where:JSON){
  allSuperLedgers(where:$where){
    SuperLedgers{
      cardlimit
      txnType
      amountAdded
      createdAt
    }
  }
}
`;

export const getCardDetailsQuery = `
query($where:JSON){
  allCardDetails(where:$where){
    CardDetails{
      id
      cardLimit
      cardName
      cardNumber
      cvc
      accountname
      accountnumber
      bsb
      cardtypeid
      expiryDate
      isCardLocked
      cardstatus
      fkcardtypeidrel{
        Cardtypes{
          id
          cardtype
        }
      }

    }
  }
}
`;

export const getWalletsQuery = `
query($where:JSON){
  allWallets(where:$where){
    Wallets{
      id
      ownerId
      participantId
      description
      balance
      default
      canwithdraw
      participantUserdatarel{
        Userdata{
          id
          firstname
          lastname
        }
      }
      ownerUsedatarel{
        Userdata{
            id
            firstname
            lastname
        }
      } 
    }
  }
}
`;

export const getWalletsAndAuthledger = gql`
  query AllWallets($where: JSON) {
    allWallets(where: $where) {
      Wallets {
        id
        description
        balance
        participantUserdatarel(where: { active: 1 }) {
          Userdata {
            id
            lastname
            firstname
            fkUserIdCardDetailMap {
              CardDetails {
                cardNumber
                id
              }
            }
          }
        }
        authledgerWalletMap(where: { isrepeatpay: 1, status: "PENDING" }) {
          Authledgers {
            id
            isrepeatpay
            requestedamount
          }
        }
      }
    }
  }
`;

export const saveWallet = gql`
  mutation saveWallet($obj: WalletInput!) {
    saveWallet(obj: $obj) {
      id
    }
  }
`;

export const saveUpdateWallet = gql`
  mutation WalletCreateUpdata(
    $id: Int
    $description: String!
    $defaultWallet: Int
    $participantId: Int!
    $ownerId: Int
    $requestedBy: Int!
  ) {
    WalletCreateOrUpdateWallet(
      id: $id
      description: $description
      defaultWallet: $defaultWallet
      participantId: $participantId
      ownerId: $ownerId
      requestedBy: $requestedBy
    )
  }
`;

export const updateBankDetail = `
 mutation saveCardDetail($obj: CardDetailInput!) {
  saveCardDetail(obj: $obj) {
    id
    userId
    cardLimit
    cardName
    createdAt
    cardNumber
    cvc
    accountname
    accountnumber
    bsb
    cardstatus
  }
}`;

export const allcardtypes = `
{
  allCardtypes {
    Cardtypes {
      id
      cardtype
    }
  }
}
`;
export const generatecard = `
mutation CardDetailNewCardGenerateByAdmin($obj:JSON,$cardTypeId: Int){
  CardDetailNewCardGenerateByAdmin(obj:$obj,cardTypeId:$cardTypeId)
}
`;

export const updateRepeatPay = `
mutation BudgetSetRepeatpayLimit($userId:Int, $amount:String, $isRepeatpay:Int, $createdBy:Int, $useWholeBalance:Int, $walletId:Int){
  BudgetSetRepeatpayLimit(userId:$userId, amount:$amount, isRepeatpay:$isRepeatpay, createdBy:$createdBy, useWholeBalance:$useWholeBalance, walletId:$walletId)
}
`;

export const getRepeatPayDetails = `
query($where:JSON){
  allAuthledgers(where:$where){
    Authledgers{
      id
      isrepeatpay
      isrepeatpaysetforparticipant
      repeatpayamount
      userid
      active
      walletid
    }
  }
}
`;

export const createCard = `
mutation UserdataNewCard($obj:JSON,$cardTypeId: Int){
  UserdataNewCard(obj:$obj,cardTypeId:$cardTypeId)
}
`;

export const UserdataSetPin = (email, password) => `
mutation  {
  UserdataSetPin(email:"${email}", password:"${password}")
}
`;

export const saveAuthledger = `mutation saveAuthledger ($obj:  AuthledgerInput!){
  saveAuthledger (obj: $obj){
    id
  }
}`;

export const updateCardStatus = `
mutation UserdataUpdateCardState($userId:Int,$cardState: String){
  UserdataUpdateCardState(userId:$userId,cardState:$cardState)
}
`;

export const getBudgetDetails = `
query($where:JSON){
  allBudgets(where:$where){
    Budgets{
      id
      participantId
      canwithdraw
      itemCategoryId
      active
    }
  }
}
`;
