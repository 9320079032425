export const supporterList = {
  showPagination: false,
  showSerialNo: true,
  columns: [
    {
      Header: "First Name",
      accessor: "firstName",
      sortable: false
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      sortable: false
    },
    {
      Header: "Email",
      accessor: "email",
      sortable: false
    },
    {
      Header: "Status",
      accessor: "active",
      sortable: false,
      Cell: (row) => {
        return row.original.active ? <div>Active</div> : <div>Inactive</div>;
      }
    },
    {
      Header: "Mobile No.",
      accessor: "phoneNumber",
      sortable: false
    },
    {
      Header: "Date Of Birth",
      accessor: "dob",
      sortable: false
    },
    {
      Header: "Address",
      accessor: "address",
      sortable: false
    }
  ]
};

export const budgetList = {
  showPagination: false,
  showSerialNo: true,
  columns: [
    {
      Header: "Tier",
      accessor: "tier",
      sortable: false,
      Cell: (row) => {
        return <div>{row.original.tier?.tierType}</div>;
      }
    },
    {
      Header: "Category",
      sortable: false,
      Cell: (row) => {
        return <div>{row.original.itemCategory?.categoryName}</div>;
      }
    },
    {
      Header: "Status",
      accessor: "active",
      sortable: false,
      Cell: (row) => {
        return row.original.active ? <div>Active</div> : <div>Inactive</div>;
      }
    },
    {
      Header: "Budget Allocated",
      accessor: "budgetAvailable",
      sortable: false,
      Cell: (row) => {
        return (
          <div>
            {row.original.budgetAvailable != null
              ? `$${row.original.budgetAvailable}`
              : ""}
          </div>
        );
      }
    },
    {
      Header: "Charged Amount",
      sortable: false,
      Cell: (row) => {
        return (
          <div>
            {row.original.chargedAmount != null
              ? `$${row.original.chargedAmount}`
              : ""}
          </div>
        );
      }
    }
  ]
};
