import api from "../api";

export const getImage = (url) => api.get(`/file?fileName=${url}`);

export const uploadImage = (formData) =>
  api.post("/file/upload", formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
