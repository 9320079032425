import React, { Component } from "react";
import SearchIcon from "../../assets/search.svg";
import { TextField, Button, FormControl } from "@material-ui/core";
import ReactTableComponent from "../../ReactTable/ReactTable";
import { adminParticipantList } from "./ParticipantConfig";
import { withRouter } from "react-router-dom";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import {
  participantQuery,
  saveUserdata,
  generatecard,
} from "./ParticipantQuery";
import { DotLoader } from "react-spinners";
import "./participant.css";
import ActionDropDown from "./ActionDropDown/ActionDropDown";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { activeStatusOptions } from "./ParticipantConfig";

class AdminParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      filter: {
        role: "PARTICIPANT",
        order: "id desc",
        active: 1,
      },
      count: 0,
      loading: false,
    };
  }

  componentWillMount() {
    this.getParticipantData();
  }

  formatDate = (date) => {
    date = new Date(date);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  getParticipantData = () => {
    this.setState({ loading: true });
    fetchMethod(participantQuery, {
      where: this.state.filter,
      last: parseInt(this.state.rows),
      first: parseInt(this.state.pageNo),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.error && res.error.statusCode === 401) {
          swal({ title: res.error.message, icon: "warning" }).then(() => {
            localStorage.clear();
            window.location = "/";
          });
        } else {
          res.data.allUserdata.Userdata.forEach((item) => {
            item["fullAddress"] =
              item.address && item.address !== null && item.address !== ""
                ? item.address.concat(
                    item.city && item.city !== null ? " " + item.city : ""
                  )
                : "";
            item.dob = this.formatDate(item.dob);
            item.active = item.active === 1 ? "Active" : "Inactive";
            item.kycstatus = item.kycstatus === null ? "pending" : "pass";
          });
          this.setState({
            count:
              res.data && res.data.allUserdata && res.data.allUserdata !== null
                ? res.data.allUserdata.totalCount
                : "",
            listData:
              res.data && res.data.allUserdata && res.data.allUserdata !== null
                ? res.data.allUserdata.Userdata
                : "",
            loading: false,
          });
        }
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
        this.setState({ listData: [], loading: false });
      });
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size,
      },
      () => {
        this.getParticipantData();
      }
    );
  };

  handleDelete = (row) => {
    if (row.active === "Active") {
      swal({
        title: "Are you sure you really want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const test = {
            id: row.id,
            active: 0,
          };

          fetchMethod(saveUserdata, { obj: test })
            .then((res) => res.json())
            .then((res) => {
              const id = res.data.saveUserdata.id;
              swal({
                title: id ? "Deleted successfully" : "Error deleting",
                icon: "success",
              });
              if (id) {
                let pageNo = this.state.pageNo;
                let previousData = this.state.listData.length;
                this.setState({ listData: undefined });

                const { filter } = this.state;

                let search = this.state.search;

                if (this.state.search !== "" && previousData === 1) {
                  pageNo = this.state.rows;
                  delete filter["and"];
                  search = "";
                } else if (
                  this.state.search == "" &&
                  previousData === 1 &&
                  pageNo != this.state.rows
                ) {
                  pageNo = this.state.pageNo - this.state.rows;
                } else {
                  pageNo = this.state.pageNo;
                }

                this.setState({ pageNo, filter, search }, () =>
                  this.getParticipantData()
                );
              }
            })
            .catch((e) => {
              swal({ title: e.message, icon: "warning" });
              this.setState({ listData: [] });
            });
        }
      });
    }
  };

  handleEditFunds = (row) => {
    this.props.history.push({ pathname: `/editFunds/${row.id}` });
  };

  createCard = (row) => {
    const etNo = Math.floor(Math.random() * 899999 + 100000);
    let cardObj = {
      obj: {
        externalTransNo: `EXT${etNo}`,
        cardType: "SPENDABLE_PLASTIC_CARD",
        passphrase: "unset",
        customer: {
          id: row.id,
          firstName: '"' + row.firstname + '"',
          lastName: '"' + row.lastname + '"',
          street: '"' + row.street + '"',
          city: '"' + row.city + '"',
          countryCode: '"' + row.firstname + '"',
          postCode: '"' + row.zipcode + '"',
          mobileNumber: '"' + row.phonenumber + '"',
          email: '"' + row.email + '"',
        },
      },
      cardTypeId: 8,
    };
    fetchMethod(generatecard, {
      obj: cardObj.obj,
      cardTypeId: cardObj.cardTypeId,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res !== "") {
          if (res.data.CardDetailNewCardGenerateByAdmin != "") {
            swal({
              title: res.data.CardDetailNewCardGenerateByAdmin.message,
              icon: "error",
            });
          }
        }
      });
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleSearchChange = (event) => {
    const { filter } = this.state;
    filter["and"] = [
      {
        or: [
          { firstname: { like: `%${event.target.value}%` } },
          { lastname: { like: `%${event.target.value}%` } },
        ],
      },
    ];

    this.setState({ listData: undefined });
    this.setState({
      search: event.target.value,
      filter,
      pageNo: this.state.rows,
    });
    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.getParticipantData();
      },
      200
    );
  };

  addParticipant = () => {
    this.props.history.push("/addParticipants");
  };

  handleEditModal = (data) => {
    this.props.history.push({
      pathname: "/editParticipants",
      state: { details: data.id },
    });
  };

  onFirstNameClick = (data) => {
    this.props.history.push({
      pathname: `/participantInfo/${data.id}`,
    });
  };

  handleStatusFilterAutocomplete = async (e, v) => {
    console.log(v);
    if (v === null) {
      await this.setState({
        filter: {
          role: "PARTICIPANT",
          order: "id desc",
        },
      });
    } else if (v.status === "Active") {
      await this.setState({
        filter: {
          ...this.state.filter,
          active: 1,
        },
      });
    } else if (v.status === "Inactive") {
      await this.setState({
        filter: {
          ...this.state.filter,
          active: 0,
        },
      });
    }
    this.getParticipantData();
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45,
      },
      {
        Header: "Name",
        Cell: (row) => {
          return (
            <div
              className="dot"
              onClick={() => this.onFirstNameClick(row.original)}
            >
              {row.original.firstname} {row.original.lastname}
            </div>
          );
        },
      },
    ];

    const actionButton = [
      {
        Header: "Actions",
        sortable: false,
        Cell: (row) => (
          <ActionDropDown
            handleEdit={() => this.handleEditModal(row.original)}
            handleDelete={() => this.handleDelete(row.original)}
            handleCreateCard={() => this.createCard(row.original)}
            handleEditFunds={() => this.handleEditFunds(row.original)}
            data={row.original}
          />
        ),
      },
    ];

    const columns = nameColumn
      .concat(adminParticipantList.columns)
      .concat(actionButton);
    return (
      <div className="participantSection">
        <h2>Participant</h2>
        <div className="formFlex">
          <div className="searchTextField">
            <TextField
              type="text"
              placeholder="By First, Last Name"
              value={this.state.search}
              onChange={this.handleSearchChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <img
                    src={SearchIcon}
                    alt=""
                    style={{ width: "15px", cursor: "pointer" }}
                  />
                ),
              }}
            />
            <FormControl>
              <Autocomplete
                id="combo-box-demo"
                size="small"
                options={activeStatusOptions}
                defaultValue={activeStatusOptions[0]}
                onChange={(e, v) => this.handleStatusFilterAutocomplete(e, v)}
                getOptionLabel={(option) =>
                  option && option.status ? option.status : ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="Status" variant="outlined" />
                )}
              />
            </FormControl>
          </div>
          <div>
            <Button onClick={this.addParticipant}>Add Participant</Button>
          </div>
        </div>

        {this.state.listData ? (
          <div className="adminParticipantTable">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={adminParticipantList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows,
              }}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AdminParticipant);
