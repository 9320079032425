import React, { Component } from "react";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "./login.scss";
import swal from "sweetalert";
import { fetchMethod } from "../FetchMethod";
import { UserdataResendAccessCode } from "./ForgotQuery";
import Logo from "../assets/images/logo-budget-hub.svg";
import User from "../assets/svg/user.svg";

class ResendAccessCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }
  componentWillMount() {
    this.setState({ email: "" });
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  validateForm = (email) => {
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return validEmailRegex.test(email.trim());
  }

  nextEmail(e) {
    let { email } = this.state;
    if (email.length === 0) {
      swal({ 
        icon: 'warning',
        title: "Please enter a valid email address." ,
      });
      return;
    }
    let Data = JSON.stringify({ email: email });
    if(!this.validateForm(email)) {
      swal({ 
        icon: 'warning',
        title: "Invalid email.",
        className:"check"
      });
      return;
    }
    fetchMethod(UserdataResendAccessCode, Data)
      .then(res => res.json())
      .then(res => {
        // console.log(res);
        if(res && res.data && res.data.UserdataResendAccessCode && res.data.UserdataResendAccessCode.status === 403) {
          swal(res.data.UserdataResendAccessCode.msg, {
            icon: 'warning'
          })
        }
        if(res && res.data && res.data.UserdataResendAccessCode && res.data.UserdataResendAccessCode.status === 409) {
          swal(res.data.UserdataResendAccessCode.msg, {
            icon: 'warning'
          })
        }
        if (res && res.data && res.data.UserdataResendAccessCode && res.data.UserdataResendAccessCode.status === 200) {
          swal(res.data.UserdataResendAccessCode.message, {
              icon: 'success'
          })
          this.props.history.push({
            pathname: "/signup",
            email
          });
          this.setState({ email: "" });
        } 
      })
      .catch(e => {
        console.log("error_________________", e);
      });
  }

  render() {
    return (
      <div className="signInNew">
        <div className="flex">
          <div className="signInWrap">
            <div className="logo">
              <img src={Logo} alt="Logo | Budget hub" />
            </div>
            <div className="innerBox">
              <h1>Resend Access Code</h1>
              {/* <form onSubmit={this.onSubmit}> */}
              <ul>
                <li>
                  <label>Email address</label>
                  <div className="bh-input-group">
                    <div className="bh-input-group-text">
                      <img src={User} alt="user email address" />
                    </div>
                    <input
                      value={this.state.email}
                      className="bh-form-control"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      onChange={(val) => this.handleChange(val)}
                    />
                  </div>
                </li>
                <li className="noMb">
                  <button
                    className="btn btn-primary full"
                    type="submit"
                    onClick={() => {
                      this.nextEmail();
                    }}
                  >
                    Send Access Code
                  </button>
                </li>
              </ul>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResendAccessCode);
