import React, { Component } from "react";
import ReactTableComponent from "../../../ReactTable/ReactTable";
import { adminPaymentRequestList } from "./SupporterTabSetupConfig";
import { fetchMethod, fetchMethodForNovati } from "../../../FetchMethod";
import swal from "sweetalert";
import { connectedPaymentRequestQuery, superLedgerTransactions } from "./SupporterTabQuery";
import { participantQueryData } from "../../Rules/RulesQuery"
import { paymentQuery, userdataQuery, getCardDetails, transactionList, transactionDetails } from "../../PaymentRequests/PaymentRquestQuery";
import { DotLoader } from "react-spinners";
import "./supporterTabSetup.css";
class PaymentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      filter: {
        guardianId: JSON.parse(localStorage.getItem('userInfo')).id
        // order: "id desc"
      },
      supporterId: this.props.id,
      cardDetails: undefined,
      loading: false,
      participentId: undefined
    };
  }

  componentWillMount() {
    this.connectedPaymentRequest();
  }


  getCardDetailsData = async () => {
    await fetchMethod(getCardDetails, {
      where: { userId: this.state.supporterId }
    })
      .then(async resp => await resp.json())
      .then(async resp => {
        if (
          resp &&
          resp.data &&
          resp.data.allCardDetails &&
          resp.data.allCardDetails.CardDetails
        ) {
          this.setState({
            cardDetails: resp.data.allCardDetails.CardDetails
          });
        }
      })
      .catch(error => {
        swal({ title: error.message, icon: "warning" });
      });
  };
  allParticipent = async () => {
    var participantObj = { role: "PARTICIPANT", active: 1 };
    participantObj.guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
    await fetchMethod(participantQueryData, {
      where: participantObj
    })
      .then(resp => resp.json())
      .then(resp => {
        if (
          resp &&
          resp.data &&
          resp.data.allUserdata &&
          resp.data.allUserdata.Userdata
        ) {
          var ids = [];
          resp.data.allUserdata.Userdata.map(i => {
            ids.push(i.id);
          });
          this.setState({
            participentId: ids
          });
        }
      })

  }

  connectedPaymentRequest = async () => {
    try {
      const cardData = await this.getCardDetailsData()
      let supporterId = this.state.supporterId
      if (this.state.cardDetails != "") {
        if (localStorage.getItem('role') === 'GUARDIAN') {
          const allParticipentArray = await this.allParticipent()
          await fetchMethodForNovati(transactionDetails(this.state.cardDetails[0].cardNumber, this.state.participentId, this.state.rows, this.state.pageNo))
            .then(res => res.json())
            .then(res => {
              res?.data?.CardDetailAllTransactionHistory?.data ?
                this.setState({
                  count: res.data.CardDetailAllTransactionHistory.totalCount,
                  listData: res?.data?.CardDetailAllTransactionHistory?.data.map(item => {
                    return {
                      paymentDate: item.createdon.toString().split("T")[0],
                      paymentType: item.novattiresponse != undefined ? JSON.parse(item.purchaseobject).transactionType : "",
                      merchantName: item.merchantname ? item.merchantname : "",
                      Amount: item.spentamount === null ? '-' : '$' + item.spentamount,
                      amountAud: item.novattiresponse != undefined ? '$' + JSON.parse(item.novattiresponse).amount : "",
                      Reference: item.novattiresponse != undefined ? JSON.parse(item.novattiresponse).transactionNumber : "",
                    }
                  })
                })
                : this.setState({ listData: [] });
            })
        } else {
          await fetchMethodForNovati(transactionList(this.state.cardDetails[0].cardNumber, this.state.rows, this.state.pageNo))
            .then(res => res.json())
            .then(res => {
              res?.data?.CardDetailTransactionHistory?.data ?
                this.setState({
                  count: res.data.CardDetailTransactionHistory.totalCount,
                  listData: res?.data?.CardDetailTransactionHistory?.data.map(item => {
                    return {
                      paymentDate: item.createdon.toString().split("T")[0],
                      paymentType: item.novattiresponse != undefined ? JSON.parse(item.purchaseobject).transactionType : "",
                      merchantName: item.merchantname ? item.merchantname : "",
                      Amount: item.spentamount === null ? '-' : '$' + item.spentamount,
                      amountAud: item.novattiresponse != undefined ? '$' + JSON.parse(item.novattiresponse).amount : "",
                      Reference: item.novattiresponse != undefined ? JSON.parse(item.novattiresponse).transactionNumber : "",
                    }
                  })
                })
                : this.setState({ listData: [] });
            })
        }
      } else {
        this.setState({ listData: [] });
      }
    } catch (err) {
      swal({ title: err.message, icon: "warning" });
      this.setState({ listData: [] });
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.connectedPaymentRequest();
      }
    );
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: row => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45
      }
    ];
    const columns = nameColumn.concat(adminPaymentRequestList.columns);
    return (
      <div>
        {this.state.listData ? (
          <div className="paymentRequestSupporter">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={adminPaymentRequestList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => { }}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            <DotLoader size={70} color={"#020f1f"} />
          </div>
        )}
      </div>
    );
  }
}

export default PaymentRequest;
