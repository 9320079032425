import React from "react";
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import Login from "../../Login/Login";
import ForgotPassword from "../../Login/ForgotPassword";
import ForgotPasswordEmail from "../../Login/ForgotPasswordEmail";
import Dashboard from "../Dashboard/Dashboard";
import SideBar from "./SideBar/Sidebar";
import Logout from "../Logout/Logout";
import Participant from "../Participant/Participant";
import AddParticipant from "../Participant/AddParticipant";
import EditParticipant from "../Participant/EditParticipant";
import ParticipantInfo from "../Participant/ParticipantInfo";
import Buisness from "../Buisness/Buisness";
import PaymentRequests from "../PaymentRequests/PaymentRequests";
import AddBuisness from "../Buisness/AddBuisness";
import EditBuisness from "../Buisness/EditBuisness";
import BuisnessInfo from "../Buisness/BuisnessInfo";
import SuperLedger from "../SuperLedger/SuperLedger";
import Rules from "../Rules/Rules";
import Admin from "../Admin/Admin";
import Guardian from "../Guardian/Guardian";
import Supporter from "../Supporter/Supporter";
import AddGuardian from "../Guardian/AddGuardian";
import EditGuardian from "../Guardian/EditGuardian";
import GuardianInfo from "../Guardian/GuardianInfo";
import AddSupporter from "../Supporter/AddSupporter";
import EditSupporter from "../Supporter/EditSupporter";
import SupporterInfo from "../Supporter/SupporterInfo";
import BuisnessMember from "../Buisness/BuisnessMember";
import BuisnessMemberInfo from "../Buisness/BuisnessMemberInfo";
import AddBuisnessMember from "../Buisness/AddBuisnessMember";
import EditBuisnessMember from "../Buisness/EditBuisnessMember";
import GuardianMember from "../Guardian/GuardianMember";
import AddGuardianParticipant from "../Guardian/AddGuardianParticipant";
import EditGuardianParticipant from "../Guardian/EditGuardianParticipant";
import itemCategory from "../ItemCategory/ItemCategory";
import AddItemCategory from "../ItemCategory/AddItemCategory";
import EditItemCategory from "../ItemCategory/EditItemCategory";
import Profile from "../Profile/Profile";
import ItemCategory from "../ItemCategory/ItemCategory";
import CardDetails from "../Participant/CardDetails/CardDetails";
import AddFunds from "../SuperLedger/AddFunds";
import Budget from "../Budget/Budget";
import AddBudget from "../Budget/AddBudget";
import EditGuardianBudget from "../Budget/EditGuardianBudget";
import GuardianLedger from "../SuperLedger/GuardianLedger";
import EditBudget from "../Budget/EditBudget";
import AddTier from "../ItemCategory/AddTier";
import Signup from "../../../src/Login/SignUp";
import ResendAccessCode from "../../Login/ResendAccessCode";
import LargestPaymentRequests from "../PaymentRequests/LargestPaymentRequests";
import LastFivePaymentRequests from "../PaymentRequests/LastFivePaymentRequests";
import BusinessAddedByGuardian from "../Buisness/BusinessAddedByGuardian";
import AddRule from "../Rules/AddRule";
import EditRule from "../Rules/EditRule";
import AutomateFunds from "../AutomateFunds/AutomateFunds";
import AddAutomateFunds from "../AutomateFunds/AddAutomateFunds";
import EditAutomatedFunds from "../AutomateFunds/EditAutomatedFunds";
import Notification from "../Notification/Notification";
import AutomatedBudget from "../Budget/automatedBudget";
import AdminParticipanInfo from "../Participant/AdminParticipantInfo";
import AddParticipantBudget from "../Participant/ParticipantTabSetup/AddParticipantBudget";
import EditParticipantBudget from "../Budget/EditParticipantBudget";
import EditFunds from "../Participant/EditFunds";
import Settlements from "../Settlements/Settlements";
import Wallet from "../Wallet/Wallet";
import { WALLET_LIST_ROUTE } from "src/utils/constants";
import WalletDetail from "../Wallet/WalletDetail";
import MainBudget from "../Budget/MainBudget";
import NovattiSftp from "../NovattiSftp/NovattiSftp";

function Routes() {
  return (
    <HashRouter>
      <Switch>
        <Route
          exact={true}
          path="/login"
          render={(e, props) => <Login {...e} data={props} />}
        />
        <Route
          exact={true}
          path="/"
          render={(e, props) =>
            localStorage.getItem("token") ? (
              <Redirect to="dashboard"></Redirect>
            ) : (
              <Login {...e} data={props} />
            )
          }
        />
        <Route
          exact={true}
          path="/dashboard"
          render={(e, props) => (
            <SideBar>
              <Dashboard {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/participants"
          render={(e, props) => (
            <SideBar>
              <Participant {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path={`/${WALLET_LIST_ROUTE}`}
          render={(e, props) => (
            <SideBar>
              <Wallet {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path={`/${WALLET_LIST_ROUTE}/:walletId`}
          render={(e, props) => (
            <SideBar>
              <WalletDetail {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/guardians"
          render={(e, props) => (
            <SideBar>
              <Guardian {...e} data={props} />
            </SideBar>
          )}
        />

        <Route
          exact={true}
          path="/budget"
          render={(e, props) => (
            <SideBar>
              <MainBudget {...e} data={props} />
            </SideBar>
          )}
        />

        <Route
          exact={true}
          path="/addBudget"
          render={(e, props) => (
            <SideBar>
              <AddBudget {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addParticipantBudget/:participantId"
          render={(e, props) => (
            <SideBar>
              <AddBudget {...e} data={props} />
            </SideBar>
          )}
        />

        <Route
          exact={true}
          path="/editBudget"
          render={(e, props) => (
            <SideBar>
              <EditBudget {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editParticipantBudget/:id"
          render={(e, props) => (
            <SideBar>
              <EditGuardianBudget {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editBudget/:id"
          render={(e, props) => (
            <SideBar>
              <EditGuardianBudget {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addGuardian"
          render={(e, props) => (
            <SideBar>
              <AddGuardian {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editGuardian"
          render={(e, props) => (
            <SideBar>
              <EditGuardian {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/guardianInfo"
          render={(e, props) => (
            <SideBar>
              <GuardianInfo {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/guardianMember/:guardianId"
          render={(e, props) => (
            <SideBar>
              <GuardianMember {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/guardianMember"
          render={(e, props) => <Redirect to="guardian" />}
        />
        <Route
          exact={true}
          path="/addGuardianParticipant"
          render={(e, props) => (
            <SideBar>
              <AddGuardianParticipant {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editGuardianParticipant"
          render={(e, props) => (
            <SideBar>
              <EditGuardianParticipant {...e} data={props} />
            </SideBar>
          )}
        />
        {/* <Route
          exact={true}
          path="/item_category"
          render={(e, props) => (
            <SideBar>
              <BudgetItemCategory {...e} data={props} />
            </SideBar>
          )}
        /> */}
        <Route
          exact={true}
          path="/supporter"
          render={(e, props) => (
            <SideBar>
              <Supporter {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addSupporter"
          render={(e, props) => (
            <SideBar>
              <AddSupporter {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editSupporter"
          render={(e, props) => (
            <SideBar>
              <EditSupporter {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/supporterInfo"
          render={(e, props) => (
            <SideBar>
              <SupporterInfo {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/admin"
          render={(e, props) => (
            <SideBar>
              <Admin {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addParticipants"
          render={(e, props) => (
            <SideBar>
              <AddParticipant {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editFunds/:participantId"
          render={(e, props) => (
            <SideBar>
              <EditFunds {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/profile"
          render={(e, props) => (
            <SideBar>
              <Profile {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editParticipants"
          render={(e, props) => (
            <SideBar>
              <EditParticipant {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/newParticipantInfo"
          render={(e, props) => (
            <SideBar>
              <ParticipantInfo {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/participantInfo/:participantId"
          render={(e, props) => (
            <SideBar>
              <AdminParticipanInfo {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/buisness"
          render={(e, props) => (
            <SideBar>
              <Buisness {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addBuisness"
          render={(e, props) => (
            <SideBar>
              <AddBuisness {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addedBusiness"
          render={(e, props) => (
            <SideBar>
              <BusinessAddedByGuardian {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editBuisness"
          render={(e, props) => (
            <SideBar>
              <EditBuisness {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/buisnessInfo"
          render={(e, props) => (
            <SideBar>
              <BuisnessInfo {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/transactions"
          render={(e, props) => (
            <SideBar>
              <PaymentRequests {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/settlements"
          render={(e, props) => (
            <SideBar>
              <Settlements {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/novattisftp"
          render={(e, props) => (
            <SideBar>
              <NovattiSftp {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/automated-budgets"
          render={(e, props) => (
            <SideBar>
              <AutomatedBudget {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/superLedger"
          render={(e, props) => (
            <SideBar>
              <SuperLedger {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/guardianLedger"
          render={(e, props) => (
            <SideBar>
              <GuardianLedger {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addFunds"
          render={(e, props) => (
            <SideBar>
              <AddFunds {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editRule"
          render={(e, props) => (
            <SideBar>
              <EditRule {...e} data={props} />
            </SideBar>
          )}
        />

        <Route
          exact={true}
          path="/editAutomatedFunds"
          render={(e, props) => (
            <SideBar>
              <EditAutomatedFunds {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/rules"
          render={(e, props) => (
            <SideBar>
              <Rules {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/automateFunds"
          render={(e, props) => (
            <SideBar>
              <AutomateFunds {...e} data={props} />
            </SideBar>
          )}
        />

        <Route
          exact={true}
          path="/addrule"
          render={(e, props) => (
            <SideBar>
              <AddRule {...e} data={props} />
            </SideBar>
          )}
        />

        <Route
          exact={true}
          path="/addAutomateFunds"
          render={(e, props) => (
            <SideBar>
              <AddAutomateFunds {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/buisnessMember"
          render={(e, props) => (
            <SideBar>
              <BuisnessMember {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/buisnessMemberInfo"
          render={(e, props) => (
            <SideBar>
              <BuisnessMemberInfo {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addBuisnessMember"
          render={(e, props) => (
            <SideBar>
              <AddBuisnessMember {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editBuisnessMember"
          render={(e, props) => (
            <SideBar>
              <EditBuisnessMember {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/itemCategory"
          render={(e, props) => (
            <SideBar>
              {/* <ItemCategory/> */}
              <ItemCategory {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addItemCategory"
          render={(e, props) => (
            <SideBar>
              <AddItemCategory {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/editItemCategory"
          render={(e, props) => (
            <SideBar>
              <EditItemCategory {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/addTier"
          render={(e, props) => (
            <SideBar>
              <AddTier {...e} data={props} />
            </SideBar>
          )}
        />
        <Route
          exact={true}
          path="/cardDetails"
          render={(e, props) => (
            <SideBar>
              <CardDetails {...e} data={props} />
            </SideBar>
          )}
        />
        <Route exact={true} path="/logout" component={Logout} />

        <Route
          exact={true}
          path="/forgotpassword"
          render={(e, props) => <ForgotPassword {...e} data={props} />}
        />
        <Route
          exact={true}
          path="/ForgotPasswordEmail"
          render={(e, props) => <ForgotPasswordEmail {...e} data={props} />}
        />
        <Route
          exact={true}
          path="/signup"
          render={(e, props) => <Signup {...e} data={props} />}
        />
        <Route
          exact={true}
          path="/resendAccessCode"
          render={(e, props) => <ResendAccessCode {...e} data={props} />}
        ></Route>
        <Route
          exact={true}
          path="/largestPaymentRequest"
          render={(e, props) => (
            <SideBar>
              <LargestPaymentRequests {...e} data={props} />
            </SideBar>
          )}
        ></Route>
        <Route
          exact={true}
          path="/lastFivePaymentRequest"
          render={(e, props) => (
            <SideBar>
              <LastFivePaymentRequests {...e} data={props} />
            </SideBar>
          )}
        ></Route>
        <Route
          exact={true}
          path="/notification"
          render={(e, props) => (
            <SideBar>
              <Notification {...e} data={props} />
            </SideBar>
          )}
        ></Route>
      </Switch>
    </HashRouter>
  );
}

export default Routes;
