import React, { Component } from "react";
import "./admin.css";
export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <h3>Hello Admin</h3>
      </div>
    );
  }
}
