import { Button, IconButton } from "@material-ui/core";
import React, { Component } from "react";
import { DotLoader } from "react-spinners";
import { fetchMethod } from "../../FetchMethod";
import ReactTableComponent from "../../ReactTable/ReactTable";
import "../Rules/Rules.css";
import {
  getCardLimitCron,
  saveCardLimitdata,
  deleteCardCron
} from "./AutomateFundsQuery.js";
import { deleteCron, getCron, participantQueryData } from "../Rules/RulesQuery";
import { cardLimitList } from "./automateFundsConfig.js";
import Edit from "../../assets/edit.svg";
import Delete from "../../assets/svg/delete.svg";
import swal from "sweetalert";

export class AutomateFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      count: 0,
      filter: {
        order: "createdon desc"
      },
      loader: false
    };
  }

  componentWillMount() {
    this.getCronCardLimitRules();
  }

  addRule = () => {
    this.props.history.push("/addAutomateFunds");
  };

  getCronCardLimitRules = () => {
    this.setState({ loader: true });
    let obj = {};
    if (localStorage.getItem("role") === "GUARDIAN") {
      var filterdata = this.state.filter;
      var participantObj = { role: "PARTICIPANT", active: 1 };
      participantObj.guardianId = JSON.parse(
        localStorage.getItem("userInfo")
      ).id;

      fetchMethod(participantQueryData, {
        where: participantObj
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.allUserdata &&
            resp.data.allUserdata.Userdata
          ) {
            var ids = [];
            resp.data.allUserdata.Userdata.map((i) => {
              ids.push(i.id);
            });
            filterdata.userid = { inq: [ids] };
            this.setState({
              filter: filterdata
            });
          }
        })
        .then(() => {
          fetchMethod(getCardLimitCron, {
            where: this.state.filter,
            last: parseInt(this.state.rows),
            first: parseInt(this.state.pageNo)
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.data.allCardcronjobs.Cardcronjobs.length) {
                res.data.allCardcronjobs.Cardcronjobs.map((item) => {
                  return (
                    (item["userName"] =
                      item.cardcronjobusermaprel &&
                      item.cardcronjobusermaprel.Userdata.length
                        ? item.cardcronjobusermaprel.Userdata[0].firstname +
                          " " +
                          item.cardcronjobusermaprel.Userdata[0].lastname
                        : ""),
                    (item["budgetAvailable"] = "$" + item.amount),
                    (item["scheduler"] =
                      item.event.charAt(0).toUpperCase() + item.event.slice(1)),
                    (item["status"] = item.active ? item.active : "")
                  );
                });
                this.setState({
                  count: res.data.allCardcronjobs.totalCount,
                  listData: res.data.allCardcronjobs.Cardcronjobs,
                  loader: false
                });
              } else {
                this.setState({
                  loader: false
                });
              }
            });
        });
    } else {
      obj = {
        where: {
          order: "createdon desc"
        },
        last: parseInt(this.state.rows),
        first: parseInt(this.state.pageNo)
      };
      fetchMethod(getCardLimitCron, obj)
        .then((res) => res.json())
        .then((res) => {
          if (res.data.allCardcronjobs.Cardcronjobs.length) {
            res.data.allCardcronjobs.Cardcronjobs.map((item) => {
              return (
                (item["userName"] =
                  item.cardcronjobusermaprel &&
                  item.cardcronjobusermaprel.Userdata.length
                    ? item.cardcronjobusermaprel.Userdata[0].firstname +
                      " " +
                      item.cardcronjobusermaprel.Userdata[0].lastname
                    : ""),
                (item["budgetAvailable"] = "$" + item.amount),
                (item["scheduler"] = item.event
                  ? item.event.charAt(0).toUpperCase() + item.event.slice(1)
                  : " "),
                (item["status"] = item.active ? item.active : "")
              );
            });
            this.setState({
              count: res.data.allCardcronjobs.totalCount,
              listData: res.data.allCardcronjobs.Cardcronjobs,
              loader: false
            });
          } else {
            this.setState({
              loader: false
            });
          }
        });
    }
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size
      },
      () => {
        this.getCronCardLimitRules();
      }
    );
  };

  handleEditModal = (data) => {
    this.props.history.push({
      pathname: "/editAutomatedFunds",
      state: { details: data.id }
    });
  };

  handleDelete = (data) => {
    swal({
      title: "Are you sure you really want to delete this record?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          fetchMethod(deleteCardCron, { id: data.id })
            .then((res) => res.json())
            .then((res) => {
              if (res.data.CardcronjobDeleteById.count === 1) {
                swal({
                  title: "Automate funds deleted successfully.",
                  icon: "success"
                });
                this.getCronCardLimitRules();
              }
            });
        }
      })
      .catch((err) => {
        swal({ title: err.message, icon: "warning" });
      });
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 45
      }
    ];

    const actionButton = [
      {
        Header: "",
        sortable: false,
        Cell: (row) => (
          <div>
            {row.original.active === 1 ? (
              <IconButton
                aria-label="edit"
                onClick={() => this.handleEditModal(row.original)}
              >
                <img src={Edit} alt="Edit" />
              </IconButton>
            ) : (
              <IconButton aria-label="edit" disabled>
                <img src={Edit} alt="Edit" />
              </IconButton>
            )}
            {row.original.active === 1 ? (
              <IconButton
                aria-label="delete"
                onClick={() => this.handleDelete(row.original)}
              >
                <img src={Delete} alt="Delete" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="delete"
                disabled
                color="primary"
                cursor="not-allowed"
              >
                <img src={Delete} alt="Delete" />
              </IconButton>
            )}
          </div>
        )
      }
    ];

    const columns = nameColumn
      .concat(cardLimitList.columns)
      .concat(actionButton);
    return (
      <div className="rulesSection">
        <h2>Automate Funds</h2>
        <div className="addbdgetBtn add">
          <Button onClick={this.addRule}>Add Automate Funds</Button>
        </div>
        {this.state.listData ? (
          <div className="rulesTable">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={cardLimitList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            {this.state.loader === true ? (
              <DotLoader size={70} color={"#020f1f"} />
            ) : (
              <h4>
                <center>No Records Found.</center>
              </h4>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AutomateFunds;
