export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const throttle = (func, delay) => {
  let isThrottled = false;
  let lastArgs = null;
  let lastThis = null;

  function executeThrottled() {
    if (isThrottled) {
      lastArgs = arguments;
      lastThis = this;
      return;
    }

    func.apply(this, arguments);
    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (lastArgs) {
        executeThrottled.apply(lastThis, lastArgs);
        lastArgs = null;
        lastThis = null;
      }
    }, delay);
  }

  return executeThrottled;
};

export const getUserToken = async () => {
  const token = await localStorage.getItem("token");
  return token;
};

export const isValidEmail = (value) => {
  //this below code is done becaoue the regex only take alphabet in small letter
  const newValue = !!value ? value?.toLowerCase() : "";
  const RegTest = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)$/
  ).test(newValue);
  return RegTest;
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("userInfo"));
};
