import React, { Component } from "react";
import ReactTableComponent from "../../ReactTable/ReactTable";
import { guardianAutomatedBudgetList } from "./automatedBudgetsConfig";
import { IconButton, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@mui/material/Switch";
import Search from "../../assets/svg/search.svg";
import { fetchMethod } from "../../FetchMethod";
import {
  participantQueryData,
  getCardLimitCron,
  saveBudgetdata,
  getAllCategories,
  getAlltypes,
  getAllCategoriesByTypeId,
  deleteAutomationById,
} from "./automatedBudgetQuery";
import swal from "sweetalert";
import AddAutomationModal from "./addAutomationModal";
import Delete from "../../assets/svg/delete.svg";
import Loader from "../../assets/Infinity-1s-200px.svg";
export default class GuardianAutomatedBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      search: "",
      type: null,
      category: null,
      allCategories: undefined,
      allCategorie: undefined,
      allUserdata: undefined,
      allTypes: undefined,
      allType: undefined,
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      filter: {
        role: "PARTICIPANT",
        order: "id desc",
        guardianId: JSON.parse(localStorage.getItem("userInfo")).id,
        active: 1,
      },
      count: 0,
      showError: false,
      show: false,
      tableAmount: {},
      tableOccurance: {},
      tableAutomate: {},
    };
  }

  getAutomatedBudgetData = () => {
    this.setState({ loader: true });
    let obj = {};
    var filterdata = this.state.filter;
    fetchMethod(participantQueryData, {
      where: filterdata,
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allUserdata &&
          resp.data.allUserdata.Userdata
        ) {
          var ids = [];
          this.setState({ allUserdata: resp.data.allUserdata.Userdata });
          resp.data.allUserdata.Userdata.map((i) => {
            ids.push(i.id);
          });
          filterdata.participantid = { inq: [ids] };
          if (this.state.category) {
            filterdata["categoryid"] = this.state.category
              ? this.state.category.id
              : null;
          } else delete filterdata["categoryid"];
          if (this.state.type) {
            filterdata["typeid"] = this.state.type ? this.state.type.id : null;
          } else {
            delete filterdata["typeid"];
          }

          this.setState({
            filter: filterdata,
          });
        }
      })
      .then(() => {
        fetchMethod(getCardLimitCron, {
          where: this.state.filter,
          last: parseInt(this.state.rows),
          first: parseInt(this.state.pageNo),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.data.allCronjobs?.Cronjobs?.length) {
              res.data.allCronjobs.Cronjobs.map((item) => {
                return (
                  (item["userName"] =
                    item.fk_userdata_participantid &&
                    item.fk_userdata_participantid.Userdata.length
                      ? item.fk_userdata_participantid.Userdata[0].firstname +
                        " " +
                        item.fk_userdata_participantid.Userdata[0].lastname
                      : ""),
                  (item["cardLimit"] =
                    item?.fk_userdata_participantid?.Userdata?.length > 0
                      ? item?.fk_userdata_participantid?.Userdata?.[0]
                          ?.fkUserIdCardDetailMap?.CardDetails?.length > 0
                        ? item?.fk_userdata_participantid?.Userdata?.[0]
                            ?.fkUserIdCardDetailMap?.CardDetails?.[0]?.cardLimit
                        : 0
                      : 0),
                  (item["budgetAvailable"] = "$" + item.amount),
                  (item["scheduler"] =
                    item.event.charAt(0).toUpperCase() + item.event.slice(1)),
                  (item["status"] = item.active ? item.active : "")
                );
              });
              this.setState({
                count: res.data.allCronjobs.totalCount,
                listData: res.data.allCronjobs.Cronjobs,
                loader: false,
              });
            } else {
              this.setState({
                loader: false,
                listData: [],
                count: 0,
              });
            }
          });
      });
  };

  getAllCategoriesData = () => {
    fetchMethod(getAllCategories)
      .then((res) => res.json())
      .then((res) => {
        if (res.data.allMasterItemCategories.MasterItemCategories) {
          this.setState({
            allCategories: res.data.allMasterItemCategories.MasterItemCategories
              ? res.data.allMasterItemCategories.MasterItemCategories.map(
                  (item) => ({
                    ...item,
                    categoryName: this.capitalizeFirstLetter(item.categoryName),
                  })
                )
              : [],
          });
          var output =
            res.data.allMasterItemCategories.MasterItemCategories.reduce(
              (acc, curr) => {
                if (acc[curr.id]) {
                  acc[curr.id] = curr.categoryName;
                } else {
                  acc[curr.id] = curr.categoryName;
                }
                return acc;
              },
              {}
            );
          this.setState({ allCategorie: output });
        }
      })
      .catch((err) => {
        swal({ title: err.message, icon: "warning" });
      });
  };

  getAllTypesData = () => {
    fetchMethod(getAlltypes)
      .then((res) => res.json())
      .then((res) => {
        if (res.data.allTiertypes.Tiertypes) {
          this.setState({ allTypes: res.data.allTiertypes.Tiertypes });
          var output = res.data.allTiertypes.Tiertypes.reduce((acc, curr) => {
            if (acc[curr.id]) {
              acc[curr.id] = curr.tierType;
            } else {
              acc[curr.id] = curr.tierType;
            }
            return acc;
          }, {});
          this.setState({ allType: output });
        }
      })
      .catch((err) => {
        swal({ title: err.message, icon: "warning" });
      });
  };

  componentWillMount() {
    this.getAutomatedBudgetData();
    this.getAllCategoriesData();
    this.getAllTypesData();
  }

  addAutomation = () => {
    this.handleShow();
  };

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        listData: undefined,
        pageNo: pageNumber,
        rows: size,
      },
      () => {
        this.getAutomatedBudgetData();
      }
    );
  };

  handleClose = () => {
    this.setState({
      show: false,
      modalBudget: null,
      modalDate: "",
      modalOcuurance: null,
      modalCategory: null,
      modalParticipant: "",
    });
  };
  handleShow = () => this.setState({ show: true });

  updateAtomation = (variable) => {
    fetchMethod(saveBudgetdata, variable)
      .then((res) => res.json())
      .then((res) => {
        if (res.data.saveCronjob.id) {
          swal({
            title: "Automated Budget updated successfully",
            icon: "success",
          });
        }
      })
      .then((resp) => {
        this.getAutomatedBudgetData();
      })
      .catch((err) => {
        swal({ title: err.message, icon: "warning" });
      });
  };

  handleAmountChange = (event, row) => {
    if (event.target.value) {
      if (event.target.value > 0) {
        if (
          parseFloat(event.target.value) < parseFloat(row.original.cardLimit)
        ) {
          const id = row.original.id;
          const targetValue = event.target.value;
          let variable = {
            obj: {
              id: row.original.id,
              amount: parseFloat(event.target.value),
              updatedby: JSON.parse(localStorage.getItem("userInfo")).id,
            },
          };
          this.setState({
            tableAmount: {
              ...this.state.tableAmount,
              [id]: targetValue,
            },
          });
          this.updateAtomation(variable);
        } else {
          this.setState({
            tableAmount: {
              ...this.state.tableAmount,
              [row.original.id]: event.target.value,
            },
          });
          let msg =
            row.original.cardLimit == 0
              ? "Insufficient Card Balance"
              : "Fund should be less than " + row.original.cardLimit;
          swal({ title: msg, icon: "warning" });
        }
      } else {
        swal({ title: "Amount should be greater than 0.", icon: "warning" });
      }
    }
  };

  handleOccuranceChange = (event, row) => {
    if (event.target.value) {
      const id = row.original.id;
      const targetValue = event.target.value;
      let variable = {
        obj: {
          id: row.original.id,
          event: event.target.value.toLowerCase(),
          updatedby: JSON.parse(localStorage.getItem("userInfo")).id,
        },
      };
      this.setState({
        tableOccurance: {
          ...this.state.tableOccurance,
          [id]: targetValue,
        },
      });
      this.updateAtomation(variable);
    }
  };

  handleAutomateChange = (event, row) => {
    const id = row.original.id;
    const targetValue = event.target.checked;
    let variable = {
      obj: {
        id: row.original.id,
        active: Number(targetValue),
        updatedby: JSON.parse(localStorage.getItem("userInfo")).id,
      },
    };
    this.setState({
      tableAutomate: {
        ...this.state.tableAutomate,
        [id]: targetValue,
      },
    });
    this.updateAtomation(variable);
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleSearchChange = (event) => {
    const { filter } = this.state;
    filter["and"] = [
      {
        or: [
          { firstname: { like: `%${event.target.value}%` } },
          { lastname: { like: `%${event.target.value}%` } },
        ],
      },
    ];
    this.setState({ listData: undefined });
    this.setState({
      search: event.target.value,
      filter,
      pageNo: this.state.rows,
    });
    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.getAutomatedBudgetData();
      },
      200
    );
  };

  GetAllCategoriesByType = () => {
    if (this.state.type) {
      fetchMethod(getAllCategoriesByTypeId, { tierId: this.state.type.id })
        .then((res) => res.json())
        .then((res) => {
          if (res.data.TiertypeCategory.data) {
            this.setState({
              allCategories: res.data.TiertypeCategory.data
                ? res.data.TiertypeCategory.data.map((item) => ({
                    ...item,
                    category_name: this.capitalizeFirstLetter(
                      item.category_name
                    ),
                  }))
                : [],
            });
          }
        })
        .catch((err) => {
          swal({ title: err.message, icon: "warning" });
        });
    } else {
      this.getAllCategoriesData();
    }
  };

  capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  handleTypeChange = (event, values) => {
    this.setState({ listData: undefined });
    this.setState({
      type: values,
      category: null,
    });

    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.getAutomatedBudgetData();
        this.GetAllCategoriesByType();
      },
      200
    );
  };

  handleCategoryChange = (event, values) => {
    this.setState({ listData: undefined });
    this.setState({
      category: values,
    });

    this.debounceTimer = this.debounce(
      this.debounceTimer,
      () => {
        this.getAutomatedBudgetData();
      },
      200
    );
  };

  handleaddAutomation = (
    budget,
    date,
    ocuurance,
    category,
    participant,
    type,
    resetForms = null
  ) => {
    const filterdata = {
      // role: "PARTICIPANT",
      participantid: participant.id,
      categoryid: category.id,
      // typeid: type.id,
    };
    fetchMethod(getCardLimitCron, {
      where: filterdata,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.allCronjobs?.Cronjobs.length > 0) {
          swal("Budget Automated already exists for this category", {
            icon: "error",
          });
          return false;
        } else {
          if (budget && date && ocuurance && category && participant) {
            // this.handleClose();
            if (resetForms) {
              resetForms();
            } else {
              this.handleClose();
            }
            let variable = {
              obj: {
                participantid: participant.id,
                amount: parseFloat(budget),
                categoryid: category.id,
                typeid: type.id,
                startfrom: date,
                event: ocuurance.toLowerCase(),
                updatedby: JSON.parse(localStorage.getItem("userInfo")).id,
              },
            };
            fetchMethod(saveBudgetdata, variable)
              .then((res) => res.json())
              .then((res) => {
                if (res.data.saveCronjob.id) {
                  swal({
                    title: "Automated Budget added successfully.",
                    icon: "success",
                  });
                }
              })
              .then((resp) => {
                this.getAutomatedBudgetData();
              })
              .catch((err) => {
                swal({ title: err.message, icon: "warning" });
              });
          }
        }
      });
  };

  deleteAtomationById = (id) => {
    swal({
      title: "Are you sure you really want to delete this record?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetchMethod(deleteAutomationById, { id: id })
          .then((res) => res.json())
          .then((res) => {
            if (res.data.CronjobDeleteById) {
              swal({
                title: "Automated Budget deleted successfully",
                icon: "success",
              });
            }
          })
          .then((resp) => {
            this.getAutomatedBudgetData();
          })
          .catch((err) => {
            swal({ title: err.message, icon: "warning" });
          });
      }
    });
  };

  render() {
    const nameColumn = [
      {
        Header: "S No.",
        Cell: (row) => {
          return <div className="dot">{row.original.sNo}</div>;
        },
        width: 60,
      },
    ];

    const actionButton = [
      {
        Header: "Type",
        accessor: "typeid",
        sortable: true,
        Cell: (row) => (
          <div>
            {row.value
              ? this.state.allType
                ? this.state.allType[row.value]
                : row.value
              : ""}
          </div>
        ),
      },
      {
        Header: "Category",
        accessor: "categoryid",
        sortable: true,
        Cell: (row) => (
          <div>
            {console.log("*********", row)}
            {row.value
              ? this.state.allCategorie
                ? this.state?.allCategorie[row.value]
                    ?.split(" ")
                    ?.map((cateogry) =>
                      cateogry
                        ? cateogry?.charAt(0)?.toUpperCase() +
                          cateogry?.slice(1)
                        : ""
                    )
                    ?.join(" ")
                : `${row?.value}`
                    ?.split(" ")
                    ?.map((cateogry) =>
                      cateogry
                        ? cateogry?.charAt(0)?.toUpperCase() +
                          cateogry?.slice(1)
                        : ""
                    )
                    ?.join(" ")
              : "Null"}
          </div>
        ),
      },
      {
        Header: "Occurance",
        accessor: "event",
        sortable: true,
        Cell: (row) => (
          <div>
            <select
              className="bh-form-select sm"
              value={
                row.original.id in this.state.tableOccurance
                  ? this.state.tableOccurance[row.original.id]
                  : row.value
              }
              onChange={(event) => this.handleOccuranceChange(event, row)}
            >
              <option value={""}>Select</option>
              <option value={"daily"}>Daily</option>
              <option value={"weekly"}>Weekly</option>
              <option value={"monthly"}>Monthly</option>
              <option value={"specific date"}>Specific Date</option>
            </select>
          </div>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        sortable: true,
        Cell: (row) => (
          <div className="bh-form-group">
            <div className="bh-input-group sm">
              <div
                className="bh-input-group-text no-bg no-rt-border"
                role="img"
              >
                <p>$</p>
              </div>
              <input
                type="Number"
                value={
                  row.original.id in this.state.tableAmount
                    ? this.state.tableAmount[row.original.id]
                    : row.value
                }
                onChange={(event) => this.handleAmountChange(event, row)}
                min="1"
                placeholder="Enter Amount"
                className="bh-form-control sm no-lt-border"
              />
            </div>
          </div>
        ),
      },
      {
        Header: "Automate",
        accessor: "active",
        sortable: false,
        Cell: (row) => (
          <div>
            <span>Off</span>
            <Switch
              aria-label="switch"
              checked={
                row.original.id in this.state.tableAutomate
                  ? this.state.tableAutomate[row.original.id]
                  : row.value
              }
              onChange={(event) => this.handleAutomateChange(event, row)}
            ></Switch>
            <span>On</span>
          </div>
        ),
      },
      {
        Headers: "",
        width: 60,
        Cell: (row) => (
          <div>
            <IconButton
              aria-label="delete"
              onClick={() => this.deleteAtomationById(row.original.id)}
            >
              <img src={Delete} alt="Delete" />
            </IconButton>
          </div>
        ),
      },
    ];
    const columns = nameColumn
      .concat(guardianAutomatedBudgetList.columns)
      .concat(actionButton);
    return (
      <>
        <AddAutomationModal
          show={this.state.show}
          handleClose={this.handleClose}
          handleaddAutomation={this.handleaddAutomation}
          allUserdata={this.state.allUserdata}
          allCategories={this.state.allCategories}
          allTypes={this.state.allTypes}
        ></AddAutomationModal>
        <div className="container participantSection budgetSection">
          <div className="row">
            <div className="col-md-12">
              <div className="heading">
                <h2>Automated Budget</h2>
                <div className="addbdgetBtn add">
                  <button
                    className="btn btn-primary sm"
                    onClick={this.addAutomation}
                  >
                    Add Automation
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className=" bh-form-group row ">
                <p className="mb-1 pl-5">Select a participant</p>
                <div className="bh-input-group col-md-12">
                  <div
                    className="bh-input-group-text no-bg no-rt-border"
                    role="img"
                  >
                    <img src={Search} alt="search icon" />
                  </div>
                  <input
                    type="search"
                    placeholder="Type to search"
                    value={this.state.search}
                    onChange={this.handleSearchChange}
                    className="bh-form-control md no-lt-border"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bh-form-group">
                <p className="mb-1">Type</p>
                <Autocomplete
                  size="small"
                  onChange={this.handleTypeChange}
                  value={this.state.type}
                  options={this.state.allTypes}
                  getOptionLabel={(option) => option.tierType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Type to search"
                      // margin="normal"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="bh-form-group">
                <p className="mb-1">Category</p>
                <Autocomplete
                  size="small"
                  onChange={this.handleCategoryChange}
                  value={this.state.category}
                  options={this.state.allCategories}
                  getOptionLabel={(option) =>
                    option.category_name
                      ? option.category_name
                      : option.categoryName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Type to search"
                      // margin="normal"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {this.state.listData ? (
                <div className="listing">
                  <ReactTableComponent
                    listData={this.state.listData}
                    listConfig={guardianAutomatedBudgetList}
                    columns={columns}
                    dataCount={this.state.count}
                    updatePagination={this.updatePagination}
                    initialPage={this.state.pageNo / this.state.rows}
                    onRowClick={() => {}}
                    forSerialNo={{
                      pageNo: this.state.pageNo,
                      pageSize: this.state.rows,
                    }}
                  />
                </div>
              ) : (
                <div className="spinner">
                  {/* <DotLoader size={50} color={"#fff"} /> */}
                  <img src={Loader}></img>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
