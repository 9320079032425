import React, { Component } from "react";
import { Button } from "@material-ui/core";
import EditIcon from "../../assets/edit.svg";
import profile from "../../assets/photo.svg";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import { getSupporterData, getCardDetailsQuery } from "./SupporterQuery";
import SupporterTab from "./SupporterTab";
import S3Image from "../S3Image";

export default class SupporterInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supporterData: undefined,
      cardDetails: [],
      filter: {
        guardianId: JSON.parse(localStorage.getItem("userInfo")).id
      }
    };
  }

  componentWillMount() {
    if (this.props.location.state !== undefined) {
      this.supporterInfo(this.props.location.state.details);
      // this.supporterInfo();
      this.getCardDetails();
    }
  }

  getCardDetails = () => {
    fetchMethod(getCardDetailsQuery, {
      where: { userId: this.props.location.state.details }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allCardDetails &&
          resp.data.allCardDetails.CardDetails
        ) {
          this.setState({
            cardDetails: resp.data.allCardDetails.CardDetails
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  supporterInfo = (id) => {
    fetchMethod(getSupporterData(id))
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          supporterData: res.data.allUserdata.Userdata[0]
        });
      })
      .catch((e) => {
        swal({ title: e.message, icon: "warning" });
      });
  };

  editButton = (data) => {
    this.props.history.push({
      pathname: "/editSupporter",
      state: { details: data.id }
    });
  };

  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  render() {
    return (
      <div>
        {this.props.location.state !== undefined ? (
          this.state.supporterData !== undefined ? (
            <div className="supporterTabHeader">
              <div className="supporterTabHeaderProfileInfo">
                <div className="supporterTabHeaderProfileDetails">
                  <div className="supporterName">
                    <div className="Image">
                      {this.state.supporterData.profileimage !== null &&
                      this.state.supporterData.profileimage !== undefined ? (
                        <S3Image
                          src={this.state.supporterData.profileimage}
                          alt="supporter profile"
                        />
                      ) : (
                        <img src={profile} alt="supporter profile" />
                      )}
                    </div>
                    <h5>
                      {this.state.supporterData.firstname
                        ? this.state.supporterData.firstname
                        : "" + " " + this.state.supporterData.lastname
                        ? this.state.supporterData.lastname
                        : ""}
                    </h5>
                    <div className="editLink">
                      {console.log(
                        "this.state.supporterData",
                        this.state.supporterData
                      )}
                      {localStorage.getItem("role") === "ADMIN" &&
                      this.state.supporterData.active == 1 ? (
                        <Button
                          startIcon={<img src={EditIcon} alt="" />}
                          onClick={() =>
                            this.editButton(this.state.supporterData)
                          }
                        >
                          Edit
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {localStorage.getItem("role") === "GUARDIAN" ? (
                    <div style={{ marginTop: "-20px", paddingLeft: "70px" }}>
                      <span>
                        {this.state.supporterData.phonenumber !== null
                          ? this.state.supporterData.phonenumber
                          : ""}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="address" style={{ display: "block" }}>
                        {this.state.supporterData.address !== null
                          ? this.state.supporterData.address
                              // .concat(
                              //   this.state.supporterData.city !== null
                              //     ? ', ' + this.state.supporterData.city
                              //     : ''
                              // )
                              .concat(
                                this.state.supporterData.city !== null
                                  ? " " + this.state.supporterData.city
                                  : ""
                              )
                              .concat(
                                this.state.supporterData.state !== null
                                  ? " " + this.state.supporterData.state
                                  : ""
                              )
                              .concat(
                                this.state.supporterData.country !== null
                                  ? " " + this.state.supporterData.country
                                  : ""
                              )
                              .concat(
                                this.state.supporterData.zipcode !== null
                                  ? " " + this.state.supporterData.zipcode
                                  : ""
                              )
                          : ""}
                      </span>
                      <ul className="supporterInformationDetails">
                        <li className="participantDetail">
                          <span>Date Of Birth</span>
                          <span>
                            {this.state.supporterData.dob !== null
                              ? this.formatDate(this.state.supporterData.dob)
                              : ""}
                          </span>
                        </li>
                        <li className="participantMobile">
                          <span>Mobile</span>
                          <span>
                            {this.state.supporterData.phonenumber !== null
                              ? this.state.supporterData.phonenumber
                              : ""}
                          </span>
                        </li>
                        <li className="participantEmail">
                          <span>Email Address</span>
                          <span>
                            {this.state.supporterData.email !== null
                              ? this.state.supporterData.email
                              : ""}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {localStorage.getItem("role") === "ADMIN" ? (
                <div>
                  <div className="participantDocumentVerifivation">
                    <h6> Bank/Card Details</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>S No.</th>
                          {/* <th>Account Name</th>
                          <th>Account Number</th> */}
                          <th>BSB</th>
                          <th>PPAN</th>
                          <th>Card Type</th>
                          {/* <th>Card Limit</th> */}
                          {/* <th>Expiry Date</th>
                          <th>Locked</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cardDetails
                          ? this.state.cardDetails.map((item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                {/* <td>{item.accountname}</td>
                                <td>{item.accountnumber}</td> */}
                                <td>{item.bsb}</td>
                                <td>{item.cardNumber}</td>
                                <td>
                                  {item.fkcardtypeidrel.Cardtypes[0]
                                    ? item.fkcardtypeidrel.Cardtypes[0].cardtype
                                    : ""}
                                </td>
                                {/* <td>
                                  {item.cardLimit ? "$" + item.cardLimit : ""}
                                </td> */}

                                {/* <td>{item.expiryDate}</td>
                                <td>{item.isCardLocked === 1 ? "Yes" : "No"}</td> */}
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
              <SupporterTab
                id={this.state.supporterData.id}
                cardDetail={this.state.cardDetails}
              />
            </div>
          ) : (
            ""
          )
        ) : (
          this.props.history.push("/supporter")
        )}
      </div>
    );
  }
}
