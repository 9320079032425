export const AllNovattiData = `query($where: JSON, $last: Int, $first: Int){
    allNovattiSftps(where:$where,last:$last,first:$first){
      totalCount
      NovattiSftps{
        id
        filename
        createdon
      }
    }
  }`;
