import swal from "sweetalert";
import { fetchMethod } from "../../FetchMethod";
import { getItemCategories } from "./RulesQuery";

export const rulesList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Participant",
      accessor: "userName",
      sortable: false
    },
    {
      Header: "Category",
      accessor: "itemCategory",
      sortable: false
    },
    {
      Header: "Budget Allocated",
      accessor: "budgetAvailable",
      sortable: false
    },
    {
      Header: "Schedule",
      accessor: "scheduler",
      sortable: false
    }
  ]
}

export const addRuleConfig = {
  className: "addAdminParticipantForm",
  fields: [
    {
      label: "Participant",
      type: "searchSelect",
      name: "participantId",
      data: "participantOptions",
      value: ""
    },
    {
      label: "Category",
      type: "searchSelect",
      name: "itemCategoryId",
      data: "categoryOptions",
      value: ""
    },
    {
      label: "Schedule",
      type: "searchSelect",
      name: "timePeriod",
      data: "timePeriodOptions",
      value: ""
    },
    {
      label: "Budget Allocated",
      type: "input",
      name: "budgetAvailable",
      value: ""
    }
  ],
  dependency: [
    {
      parent: "participantId",
      child: "itemCategoryId",
      childOptions: "categoryOptions",
      listingFunction: value => {
        if (value === null) {
          return [];
        }
        let obj = {
          participantId: value.id,
          active: 1
        }
        return fetchMethod(getItemCategories, { where: obj })
          .then(res => res.json())
          .then(res => {
            const filterData = res.data.allBudgets.Budgets.filter(item => { if (item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].isActive != 0) { return item } })
            return filterData !== null ?
              filterData.map(item => {
                return {
                  id: item.fkBudgetItemCategoryIdrel != undefined && item.fkBudgetItemCategoryIdrel.MasterItemCategories != "" ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].id : '',
                  name: item.fkBudgetItemCategoryIdrel != undefined && item.fkBudgetItemCategoryIdrel.MasterItemCategories != "" ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName : ''
                }
              }) : ""
          })
          .catch(err => {
            swal({ title: err.message, icon: "warning" });
          })
      }
    }
  ],
  validations: {
    participantId: e => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter participant"
        };
      }
      return {
        valid: true
      };
    },
    itemCategoryId: e => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter category"
        };
      }
      return {
        valid: true
      };
    },
    timePeriod: e => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter schedule."
        };
      }
      return {
        valid: true
      };
    },
    budgetAvailable: e => {
      let digit = new RegExp("^[0-9]{10}$");
      if (e == "" || e <= 0) {
        return {
          valid: false,
          errMsg: "Please enter valid Budget amount."
        };
      }
      if (isNaN(e) !== false) {
        return {
          valid: false,
          errMsg: "Budget Allocated should be contain only numeric value."
        };
      }
      if (e.length > 3) {
        return {
          valid: false,
          errMsg: "Budget Allocated should be contain only 3 digit."
        };
      }
      return {
        valid: true
      };
    }
  }
}

export const editRuleConfig = {
  className: "addAdminParticipantForm",
  fields: [
    {
      label: "Participant",
      type: "input",
      name: "participantId",
      disabled: true
    },
    {
      label: "Participant",
      type: "hidden",
      name: "ParticipantId",
      disabled: true
    },
    {
      label: "Category",
      type: "input",
      name: "itemCategoryId",
      data: "categoryOptions",
      value: "",
      disabled: true
    },
    {
      label: "Schedule",
      type: "searchSelect",
      name: "timePeriod",
      data: "timePeriodOptions",
      value: ""
    },
    {
      label: "Budget Allocated",
      type: "input",
      name: "budgetAvailable",
      value: ""
    },
  ],
  validations: {
    budgetAvailable: e => {
      let digit = new RegExp("^[0-9]{10}$");
      if (e == "" || e <= 0) {
        return {
          valid: false,
          errMsg: "Please enter valid Budget amount."
        };
      }
      if (isNaN(e) !== false) {
        return {
          valid: false,
          errMsg: "Budget Allocated should be contain only numeric value."
        };
      }
      if (e.length > 3) {
        return {
          valid: false,
          errMsg: "Budget Allocated should be contain only 3 digit."
        };
      }
      return {
        valid: true
      };
    },
    timePeriod: e => {
      if (e === "") {
        return {
          valid: false,
          errMsg: "Please enter schedule."
        };
      }
      return {
        valid: true
      };
    },
  }
}
