import React from "react";
import { Link } from "react-router-dom";
import SuperLedger from "src/Components/SuperLedger/SuperLedger";

function DepositTab({ participantId }) {
  return (
    <SuperLedger
      hideTitle
      hideFilters
      hideReset
      hideDownload
      hideActionButton
      participantId={participantId}
      paymentType="DEPOSIT"
      tableConfig={tableConfig}
    />
  );
}

export default DepositTab;

const tableConfig = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Date Of Entry",
      sortable: false,
      Cell: (row) => <div>{row.original.createdAt?.split(" ")[0]}</div>,
      width: 200
    },
    {
      Header: "Payment Type",
      accessor: "paymentType",
      sortable: false
    },
    {
      Header: "Amount",
      accessor: "amountAdded",
      sortable: false
    },
    {
      Header: "Wallet",
      accessor: "walletId",
      sortable: false,
      Cell: (row) => (
        <Link to={`wallets/${row.original.walletId}`}>
          {row.original.walletId}
        </Link>
      )
    },
    {
      Header: "Transaction By",
      accessor: "name",
      sortable: false,
      width: 200
    },
    {
      Header: "Transaction ID",
      accessor: "txnId",
      sortable: false
    }
  ]
};
