import moment from "moment";
export const guardianAutomatedBudgetList = {
  showPagination: true,
  showSerialNo: true,
  columns: [
    {
      Header: "Participant",
      accessor: "userName",
      sortable: true
    },
    // {
    //   Header: "Type",
    //   accessor: "typeid",
    //   sortable: false
    // },
    // {
    //   Header: "Category",
    //   accessor: "categoryid",
    //   sortable: false
    // }
  ]
};
