import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./budget.css";
import Edit from "../../assets/svg/edit.svg";
import Delete from "../../assets/svg/delete.svg";
import { fetchMethod } from "../../FetchMethod";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal from "sweetalert";
import { TextField } from "@material-ui/core";
import { IconButton, Button } from "@material-ui/core";

import ReactTableComponent from "../../ReactTable/ReactTable";
import { budgetList } from "./BudgetConfig";
import {
  budgetQuery,
  saveBudget,
  getAllTier,
  categoryQueryData,
  categoryQueryDataForAdmin
} from "./BudgetQuery";
import Loader from "../../assets/Infinity-1s-200px.svg";
import { getWalletsQuery } from "../Participant/ParticipantQuery";
import { userdataQuery } from "../PaymentRequests/PaymentRquestQuery";

class GuardianBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: undefined,
      temp: undefined,
      AllData: undefined,
      search: "",
      pageNo: parseInt(localStorage.getItem("rows")),
      rows: parseInt(localStorage.getItem("rows")),
      filter: {
        walletId: this.props?.id,
        order: "id desc",
        active: 1
      },
      count: 0,
      role: "PARTICIPANT",
      id: "",
      userData: [],
      businessData: [],
      selecetedRole: "PARTICIPANT",
      Role: [
        {
          role: "PARTICIPANT",
          id: "PARTICIPANT"
        }
        // {
        //   role: 'SUPPORTER',
        //   id: 'SUPPORTER',
        // },
        // {
        //   role: 'BUSINESS',
        //   id: 'BUSINESS',
        // },
      ],
      allTierList: [],
      selectedTier: "",
      allWalletsList: [],
      selectedWallet: "",
      allCategoryList: [],
      selectedCategory: "",
      budgetxyz: null,
      tierxyz: null,
      categoryxyz: null
      // show: false
    };
  }

  updatePagination = (pageNumber, size) => {
    this.setState(
      {
        pageNo: pageNumber,
        rows: size,
        listData: undefined
      },
      () => {
        this.getBudgetData();
      }
    );
  };
  componentWillMount() {
    this.getAllWallets().then(() => {
      this.getBudgetData();
    });
    this.getAllTier();
  }

  getAllWallets = async () => {
    var obj = { active: 1 };
    if (localStorage.getItem("role") === "GUARDIAN") {
      const guardianId = JSON.parse(localStorage.getItem("userInfo")).id;
      const participantResp = await fetchMethod(userdataQuery, {
        where: {
          guardianId
        }
      });
      const participantData = await participantResp.json();
      const participantIds = [];
      participantData?.data?.allUserdata?.Userdata?.forEach((participant) => {
        participantIds.push(participant?.id);
      });
      obj.participantId = { inq: participantIds };
    }
    try {
      const data = await fetchMethod(getWalletsQuery, {
        where: obj
      });
      const resp = await data.json();
      if (
        resp &&
        resp.data &&
        resp.data.allWallets &&
        resp.data.allWallets.Wallets
      ) {
        await this.setState({
          allWalletsList: resp.data.allWallets.Wallets
        });
      }
    } catch (error) {
      swal({ title: error.message, icon: "warning" });
    }
  };

  getAllTier = () => {
    fetchMethod(getAllTier)
      .then((resp) => resp.json())
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.allTiertypes &&
          resp.data.allTiertypes.Tiertypes
        ) {
          this.setState({
            allTierList: resp.data.allTiertypes.Tiertypes
          });
        }
      })
      .catch((error) => {
        swal({ title: error.message, icon: "warning" });
      });
  };

  getBudgetData = () => {
    if (
      localStorage.getItem("role") === "GUARDIAN" &&
      !this.state.filter.walletId
    ) {
      var filterdata = this.state.filter;
      var ids = [];
      this.state.allWalletsList.forEach((i) => {
        ids.push(i.id);
      });
      filterdata.walletId = { inq: [ids] };

      fetchMethod(budgetQuery, {
        where: filterdata,
        last: parseInt(this.state.rows),
        first: parseInt(this.state.pageNo)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.error && res.error.statusCode === 401) {
            swal({ title: res.error.message, icon: "warning" }).then(() => {
              localStorage.clear();
              window.location = "/";
            });
          } else {
            res.data.allBudgets.Budgets.map((item) => {
              return (
                (item["budgetAvailable"] =
                  item.budgetAvailable !== null && item.budgetAvailable !== ""
                    ? "$" + item.budgetAvailable
                    : 0),
                (item["storeName"] =
                  item?.budget_store_mapping_budgetMap?.BudgetStoreMappings
                    ?.length > 0
                    ? item?.budget_store_mapping_budgetMap?.BudgetStoreMappings?.map(
                        (data, index) => {
                          if (
                            data?.budgetStoreMappingStoremaprel?.Businesses
                              ?.length > 0
                          ) {
                            if (
                              item?.budget_store_mapping_budgetMap
                                ?.BudgetStoreMappings?.length ===
                              index + 1
                            ) {
                              return (
                                data?.budgetStoreMappingStoremaprel
                                  ?.Businesses[0]?.storeName || ""
                              );
                            } else {
                              return data?.budgetStoreMappingStoremaprel?.Businesses[0]?.storeName?.concat(
                                ", "
                              );
                            }
                          }
                        }
                      )
                    : ""),
                (item["supporterName"] =
                  item?.budget_supporter_mapping_budgetMap
                    ?.BudgetSupporterMappings?.length > 0
                    ? item?.budget_supporter_mapping_budgetMap?.BudgetSupporterMappings?.map(
                        (data, index) => {
                          if (
                            data?.budgetSupporterMappingSupportermaprel
                              ?.Userdata?.length > 0
                          ) {
                            if (
                              item?.budget_supporter_mapping_budgetMap
                                ?.BudgetSupporterMappings?.length ===
                              index + 1
                            ) {
                              return (
                                data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname?.concat(
                                  data?.budgetSupporterMappingSupportermaprel
                                    ?.Userdata[0]?.lastname
                                    ? " " +
                                        data
                                          ?.budgetSupporterMappingSupportermaprel
                                          ?.Userdata[0]?.lastname
                                    : ""
                                ) || ""
                              );
                            } else {
                              return (
                                data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname
                                  ?.concat(
                                    data?.budgetSupporterMappingSupportermaprel
                                      ?.Userdata[0]?.lastname
                                      ? " " +
                                          data
                                            ?.budgetSupporterMappingSupportermaprel
                                            ?.Userdata[0]?.lastname
                                      : ""
                                  )
                                  ?.concat(", ") || ""
                              );
                            }
                          }
                        }
                      )
                    : ""),
                (item["userName"] =
                  item &&
                  item.fkBudgetParticipantIdrel &&
                  item.fkBudgetParticipantIdrel.Userdata &&
                  item.fkBudgetParticipantIdrel.Userdata[0]
                    ? item.fkBudgetParticipantIdrel.Userdata[0].firstname +
                      " " +
                      (item.fkBudgetParticipantIdrel.Userdata[0].lastname !==
                      null
                        ? item.fkBudgetParticipantIdrel.Userdata[0].lastname
                        : "")
                    : item &&
                      item.fkSupporterIdBudgetMaprel &&
                      item.fkSupporterIdBudgetMaprel.Userdata &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0]
                    ? item.fkSupporterIdBudgetMaprel.Userdata[0].firstname +
                      " " +
                      (item.fkSupporterIdBudgetMaprel.Userdata[0].lastname !==
                      null
                        ? item.fkSupporterIdBudgetMaprel.Userdata[0].lastname
                        : "")
                    : item &&
                      item.fkBusinessIdBudgetMaprel &&
                      item.fkBusinessIdBudgetMaprel.Businesses &&
                      item.fkBusinessIdBudgetMaprel.Businesses[0] &&
                      item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                    ? item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                    : ""),
                (item["userType"] =
                  item &&
                  item.fkBudgetParticipantIdrel &&
                  item.fkBudgetParticipantIdrel.Userdata &&
                  item.fkBudgetParticipantIdrel.Userdata[0] &&
                  item.fkBudgetParticipantIdrel.Userdata[0].role
                    ? item.fkBudgetParticipantIdrel.Userdata[0].role
                    : item &&
                      item.fkSupporterIdBudgetMaprel &&
                      item.fkSupporterIdBudgetMaprel.Userdata &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0] &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0].role
                    ? item.fkSupporterIdBudgetMaprel.Userdata[0].role
                    : "BUSINESS"),
                (item["itemCategory"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .categoryName
                    ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName
                        .charAt(0)
                        .toUpperCase() +
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName.slice(
                        1
                      )
                    : ""),
                (item["status"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .isActive
                    ? "ACTIVE"
                    : "INACTIVE"),
                (item["createdAt"] =
                  item != null && item.createdAt
                    ? this.formatDate(item.createdAt)
                    : ""),
                (item["tier"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes[0].tierType
                    ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .fktiertypemaprel.Tiertypes[0].tierType
                    : "")
              );
            });

            this.setState({
              count:
                res.data && res.data.allBudgets && res.data.allBudgets !== null
                  ? res.data.allBudgets.totalCount
                  : "",
              listData:
                res.data && res.data.allBudgets && res.data.allBudgets !== null
                  ? res.data.allBudgets.Budgets
                  : "",
              temp:
                res.data && res.data.allBudgets && res.data.allBudgets !== null
                  ? res.data.allBudgets.Budgets
                  : ""
            });
          }
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
          this.setState({ listData: [] });
        });
    } else {
      fetchMethod(budgetQuery, {
        where: this.state.filter,
        last: parseInt(this.state.rows),
        first: parseInt(this.state.pageNo)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.error && res.error.statusCode === 401) {
            swal({ title: res.error.message, icon: "warning" }).then(() => {
              localStorage.clear();
              window.location = "/";
            });
          } else {
            res.data.allBudgets.Budgets.map((item) => {
              return (
                (item["budgetAvailable"] =
                  item.budgetAvailable !== null && item.budgetAvailable !== ""
                    ? "$" + item.budgetAvailable
                    : 0),
                (item["storeName"] =
                  item?.budget_store_mapping_budgetMap?.BudgetStoreMappings
                    ?.length > 0
                    ? item?.budget_store_mapping_budgetMap?.BudgetStoreMappings?.map(
                        (data, index) => {
                          if (
                            data?.budgetStoreMappingStoremaprel?.Businesses
                              ?.length > 0
                          ) {
                            if (
                              item?.budget_store_mapping_budgetMap
                                ?.BudgetStoreMappings?.length ===
                              index + 1
                            ) {
                              return (
                                data?.budgetStoreMappingStoremaprel
                                  ?.Businesses[0]?.storeName || ""
                              );
                            } else {
                              return data?.budgetStoreMappingStoremaprel?.Businesses[0]?.storeName?.concat(
                                ", "
                              );
                            }
                          }
                        }
                      )
                    : ""),
                (item["supporterName"] =
                  item?.budget_supporter_mapping_budgetMap
                    ?.BudgetSupporterMappings?.length > 0
                    ? item?.budget_supporter_mapping_budgetMap?.BudgetSupporterMappings?.map(
                        (data, index) => {
                          if (
                            data?.budgetSupporterMappingSupportermaprel
                              ?.Userdata?.length > 0
                          ) {
                            if (
                              item?.budget_supporter_mapping_budgetMap
                                ?.BudgetSupporterMappings?.length ===
                              index + 1
                            ) {
                              return (
                                data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname?.concat(
                                  data?.budgetSupporterMappingSupportermaprel
                                    ?.Userdata[0]?.lastname
                                    ? " " +
                                        data
                                          ?.budgetSupporterMappingSupportermaprel
                                          ?.Userdata[0]?.lastname
                                    : ""
                                ) || ""
                              );
                            } else {
                              return (
                                data?.budgetSupporterMappingSupportermaprel?.Userdata[0]?.firstname
                                  ?.concat(
                                    data?.budgetSupporterMappingSupportermaprel
                                      ?.Userdata[0]?.lastname
                                      ? " " +
                                          data
                                            ?.budgetSupporterMappingSupportermaprel
                                            ?.Userdata[0]?.lastname
                                      : ""
                                  )
                                  ?.concat(", ") || ""
                              );
                            }
                          }
                        }
                      )
                    : ""),
                (item["userName"] =
                  item &&
                  item.fkBudgetParticipantIdrel &&
                  item.fkBudgetParticipantIdrel.Userdata &&
                  item.fkBudgetParticipantIdrel.Userdata[0]
                    ? item.fkBudgetParticipantIdrel.Userdata[0].firstname +
                      " " +
                      (item.fkBudgetParticipantIdrel.Userdata[0].lastname !==
                      null
                        ? item.fkBudgetParticipantIdrel.Userdata[0].lastname
                        : "")
                    : item &&
                      item.fkSupporterIdBudgetMaprel &&
                      item.fkSupporterIdBudgetMaprel.Userdata &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0]
                    ? item.fkSupporterIdBudgetMaprel.Userdata[0].firstname +
                      " " +
                      (item.fkSupporterIdBudgetMaprel.Userdata[0].lastname !==
                      null
                        ? item.fkSupporterIdBudgetMaprel.Userdata[0].lastname
                        : "")
                    : item &&
                      item.fkBusinessIdBudgetMaprel &&
                      item.fkBusinessIdBudgetMaprel.Businesses &&
                      item.fkBusinessIdBudgetMaprel.Businesses[0] &&
                      item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                    ? item.fkBusinessIdBudgetMaprel.Businesses[0].storeName
                    : ""),
                (item["userType"] =
                  item &&
                  item.fkBudgetParticipantIdrel &&
                  item.fkBudgetParticipantIdrel.Userdata &&
                  item.fkBudgetParticipantIdrel.Userdata[0] &&
                  item.fkBudgetParticipantIdrel.Userdata[0].role
                    ? item.fkBudgetParticipantIdrel.Userdata[0].role
                    : item &&
                      item.fkSupporterIdBudgetMaprel &&
                      item.fkSupporterIdBudgetMaprel.Userdata &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0] &&
                      item.fkSupporterIdBudgetMaprel.Userdata[0].role
                    ? item.fkSupporterIdBudgetMaprel.Userdata[0].role
                    : "BUSINESS"),
                (item["itemCategory"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .categoryName
                    ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName
                        .charAt(0)
                        .toUpperCase() +
                      item.fkBudgetItemCategoryIdrel.MasterItemCategories[0].categoryName.slice(
                        1
                      )
                    : ""),
                (item["status"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .isActive
                    ? "ACTIVE"
                    : "INACTIVE"),
                (item["createdAt"] =
                  item != null && item.updatedAt
                    ? this.formatDate(item.updatedAt)
                    : ""),
                (item["tier"] =
                  item !== null &&
                  item.fkBudgetItemCategoryIdrel != null &&
                  item.fkBudgetItemCategoryIdrel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes[0] &&
                  item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                    .fktiertypemaprel.Tiertypes[0].tierType
                    ? item.fkBudgetItemCategoryIdrel.MasterItemCategories[0]
                        .fktiertypemaprel.Tiertypes[0].tierType
                    : "")
              );
            });
            console.log("Else Budget Data ", res.data.allBudgets.Budgets);
            this.setState({
              count:
                res.data && res.data.allBudgets && res.data.allBudgets !== null
                  ? res.data.allBudgets.totalCount
                  : "",
              listData:
                res.data && res.data.allBudgets && res.data.allBudgets !== null
                  ? res.data.allBudgets.Budgets
                  : "",
              temp:
                res?.data?.allBudgets?.Budgets?.length > 0
                  ? res.data.allBudgets.Budgets
                  : ""
            });
          }
        })
        .catch((e) => {
          swal({ title: e.message, icon: "warning" });
          this.setState({ listData: [] });
        });
    }
  };

  addBudget = () => {
    this.props.history.push("/addBudget");
  };

  handleEditModal = (data) => {
    this.props.history.push({
      pathname: `/editBudget/${data.id}`,
      state: { id: data.id }
    });
    // this.props.history.push(`/editBudget/${data.id}`);
  };

  handleDelete = (row) => {
    if (row.active === 1 || row.active === "1") {
      swal({
        title: "Are you sure you really want to delete this record?",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willDelete) => {
        if (willDelete) {
          const test = {
            id: row.id,
            active: 0
          };

          fetchMethod(saveBudget, { obj: test })
            .then((res) => res.json())
            .then((res) => {
              const id = res.data.saveBudget.id;
              swal({
                title: id ? "Deleted successfully" : "Error deleting",
                icon: "success"
              });
              if (id) {
                let pageNo = this.state.pageNo;
                let previousData = this.state.listData.length;
                this.setState({ listData: undefined });

                const { filter } = this.state;

                let search = this.state.search;

                if (this.state.search != "" && previousData === 1) {
                  pageNo = this.state.rows;
                  delete filter["and"];
                  search = "";
                } else if (
                  this.state.search == "" &&
                  previousData === 1 &&
                  pageNo != this.state.rows
                ) {
                  pageNo = this.state.pageNo - this.state.rows;
                } else {
                  pageNo = this.state.pageNo;
                }

                this.setState({ pageNo, filter, search }, () =>
                  this.getBudgetData()
                );
              }
            })
            .catch((e) => {
              swal({ title: e.message, icon: "warning" });
              this.setState({ listData: [] });
            });
        }
      });
    }
    if (row.active === 0 || row.active === "0") {
      swal({
        title: "This record is already inactive?",
        icon: "warning",
        dangerMode: true
      });
    }
  };
  formatDate = (date) => {
    var date = new Date(date);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return (date = dd + "/" + mm + "/" + yyyy);
  };

  debounce = (debounceTimer, func, delay) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delay);
    return debounceTimer;
  };
  debounceTimer = null;

  handleParticipantAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      delete this.state.filter.walletId;
      const { filter } = this.state;
      this.setState({ listData: undefined });
      filter.walletId = v.id;
      this.setState({ selectedWallet: v.id, filter, budgetxyz: v }, () => {});
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    } else {
      delete this.state.filter.walletId;
      this.setState({ listData: undefined, budgetxyz: null });
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    }
  };

  handleTierFilterAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      this.setState({
        selectedTier: v.id,
        tierxyz: v
      });
      if (localStorage.getItem("role") === "GUARDIAN") {
        fetchMethod(categoryQueryData, {
          createdBy: JSON.parse(localStorage.getItem("userInfo")).id,
          isActive: 1,
          tierId: v.id
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (
              resp &&
              resp.data &&
              resp.data.MasterItemCategoryShowItemCategories &&
              resp.data.MasterItemCategoryShowItemCategories.itemCategoryData
            ) {
              this.setState({
                allCategoryList:
                  resp.data.MasterItemCategoryShowItemCategories
                    .itemCategoryData
              });
            }
          })
          .catch((error) => {
            swal({ title: error.message, icon: "warning" });
          });
        const { filter } = this.state;
        this.setState({ listData: undefined });
        filter.tierid = v.id;
        this.debounceTimer = this.debounce(
          this.debounceTimer,
          () => {
            this.getBudgetData();
          },
          200
        );
      } else {
        fetchMethod(categoryQueryDataForAdmin, {
          where: { tierId: v.id, isActive: 1 }
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (
              resp &&
              resp.data &&
              resp.data.allMasterItemCategories &&
              resp.data.allMasterItemCategories.MasterItemCategories
            ) {
              let finalData =
                resp.data.allMasterItemCategories.MasterItemCategories.map(
                  (item) => {
                    return {
                      id: item.id,
                      category_name:
                        item.categoryName.charAt(0).toUpperCase() +
                        item.categoryName.slice(1)
                    };
                  }
                );
              this.setState({
                allCategoryList: finalData
              });
            }
          })
          .catch((error) => {
            swal({ title: error.message, icon: "warning" });
          });
        const { filter } = this.state;
        this.setState({ listData: undefined });
        filter.tierid = v.id;
        this.debounceTimer = this.debounce(
          this.debounceTimer,
          () => {
            this.getBudgetData();
          },
          200
        );
      }
    } else {
      this.setState({
        allCategoryList: []
      });
      const { filter } = this.state;
      delete filter.tierid;
      this.setState({ listData: undefined, tierxyz: null });
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    }
  };

  handleCategoryFilterAutocomplete = (e, v) => {
    if (v !== null && v !== undefined) {
      const { filter } = this.state;
      this.setState({ listData: undefined });
      this.setState({ selectedCategory: v.id, filter, categoryxyz: v });
      filter.item_category_id = v.id;
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    } else {
      const { filter } = this.state;
      this.setState({ listData: undefined });
      this.setState({ selectedCategory: null, filter, categoryxyz: null });
      delete filter.item_category_id;
      this.debounceTimer = this.debounce(
        this.debounceTimer,
        () => {
          this.getBudgetData();
        },
        200
      );
    }
  };

  resetFilters = () => {
    delete this.state.filter.walletId;
    delete this.state.filter.item_category_id;
    delete this.state.filter.tierid;
    this.setState({
      selectedParticipant: "",
      selectedTier: "",
      selectedCategory: "",
      budgetxyz: null,
      tierxyz: null,
      categoryxyz: null,
      listData: undefined,
      allCategoryList: []
    });
    this.getBudgetData();
  };
  // handleShow = () => this.setState({ show: true });
  // addItemCategory = () => {
  //   this.handleShow()
  // };
  // handleClose = () => {
  //   console.log("iiiiiiiiiiiiiii")
  //   this.setState({
  //     show: false,
  //   });
  //   this.getBudgetData();
  // }
  render() {
    const nameColumn = [
      {
        Header: "Budget Id",
        Cell: (row) => {
          return <div className="dot">{row.original.id}</div>;
        },
        width: 60
      }
    ];

    const actionButton = [
      {
        Header: "Status",
        accessor: "active",
        Cell: (row) => {
          return <p>{row.original.status}</p>;
        }
      },
      {
        Header: "",
        sortable: false,
        Cell: (row) => (
          <div>
            <IconButton
              aria-label="edit"
              onClick={() => this.handleEditModal(row.original)}
              disabled={row.original.status === "INACTIVE"}
            >
              <img src={Edit} alt="Edit" />
            </IconButton>
          </div>
        ),
        width: 70
      },
      {
        Header: "",
        sortable: false,
        Cell: (row) => (
          <div>
            {
              <IconButton
                aria-label="delete"
                onClick={() => this.handleDelete(row.original)}
              >
                <img src={Delete} alt="Delete" />
              </IconButton>
            }
          </div>
        ),
        width: 70
      }
    ];

    const columns = nameColumn.concat(budgetList.columns).concat(actionButton);
    return (
      <div className="container participantSection budgetSection">
        <div className="heading">
          <h2>Budgets</h2>
          <div className="addbdgetBtn add">
            <Button onClick={this.addBudget}>Add Budget</Button>
          </div>
        </div>
        <div className="tab-data-box">
          <div className="tab-filter-box">
            <div className="row">
              <div className="col-md-8">
                <Autocomplete
                  size="small"
                  value={this.state.budgetxyz}
                  options={this.state.allWalletsList}
                  onChange={(e, v) => this.handleParticipantAutocomplete(e, v)}
                  getOptionLabel={(option) =>
                    option ? `${option?.id} - ${option?.description}` : ""
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Wallets" variant="outlined" />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.listData ? (
          <div className="adminBudgetTable">
            <ReactTableComponent
              listData={this.state.listData}
              listConfig={budgetList}
              columns={columns}
              dataCount={this.state.count}
              updatePagination={this.updatePagination}
              initialPage={this.state.pageNo / this.state.rows}
              onRowClick={() => {}}
              forSerialNo={{
                pageNo: this.state.pageNo,
                pageSize: this.state.rows
              }}
            />
          </div>
        ) : (
          <div className="spinner">
            {/* <DotLoader size={50} color={"#fff"} /> */}
            <img src={Loader}></img>
          </div>
        )}
        {/* <AddItemCategoryModal
          show={this.state.show}
          handleClose={this.handleClose}
          editItemDetails={undefined}
        ></AddItemCategoryModal> */}
      </div>
    );
  }
}

export default withRouter(GuardianBudget);
