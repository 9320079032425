import { Button, FormControl, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { Component } from 'react'
import { DotLoader } from 'react-spinners';
import swal from 'sweetalert';
import { fetchMethod } from '../../FetchMethod';
import FormComponent from '../../Form/FormComponent';
import { participantQueryData } from '../SuperLedger/LedgerQuery';
import "../Rules/Rules.css"
import { addAutomateFundsConfig } from './automateFundsConfig'
import { getAllItemCategories, saveCron } from '../Rules/RulesQuery';
import { saveCardLimitCron } from './AutomateFundsQuery'
import { getCardLimitById } from '../Budget/BudgetQuery'

export default class AddAutomateFunds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            budgetxyz: null,
            categoryxyz: null,
            allParticipantList: undefined,
            selectedParticipant: "",
            timePeriod: ["Daily", "Weekly", "Monthly"]
        };
    }

    componentWillMount() {
        this.getAllParticipant();
    }

    getAllParticipant = () => {
        var obj = { role: "PARTICIPANT", active: 1 };
        if (localStorage.getItem("role") === "GUARDIAN") {
            obj['guardianId'] = JSON.parse(localStorage.getItem("userInfo")).id;
        }
        fetchMethod(participantQueryData, {
            where: obj
        })
            .then(resp => resp.json())
            .then(res => {
                this.setState({
                    allParticipantList: res.data.allUserdata.Userdata.map(item => {
                        return {
                            id: item.id,
                            name: item.firstname + " " + (item.lastname != null ? item.lastname : "")
                        };
                    })
                });
            })
            .catch(error => {
                swal({ title: error.message, icon: "warning" });
            });
    };

    getCardLimit = async (id) => {
        await fetchMethod(getCardLimitById, { where: { userId: parseFloat(id) } }).then(res => res.json())
            .then(res => {
                if (res?.data?.allCardDetails?.CardDetails?.length > 0) {
                    console.log("res?.data?.allCardDetails?.CardDetails?.[0]?.cardLimit", res?.data?.allCardDetails?.CardDetails?.[0]?.cardLimit)
                    this.setState({ cardLimit: res?.data?.allCardDetails?.CardDetails?.[0]?.cardLimit })
                }
                else {
                    this.setState({ cardLimit: 0 })
                }
            })
            .catch(err => {
                this.setState({ cardLimit: 0 })
                swal({ title: err.message, icon: "warning" })
            })
    }


    preSubmitChanges = async (e) => {
        const cardLimit = await this.getCardLimit(e.participantId.id)
        console.log("cardLimitcardLimit", cardLimit, this.state.cardLimit)
        if (parseFloat(this.state.cardLimit) >= parseFloat(e.budgetAvailable)) {
            fetchMethod(saveCardLimitCron, {
                userid: e.participantId.id,
                event: e.timePeriod.toLowerCase(),
                amount: parseInt(e.budgetAvailable),
                createdBy: JSON.parse(localStorage.getItem('userInfo')).id
            })
                .then(res => res.json())
                .then(res => {
                    if (res.data.CardcronjobSaveRule.status === 200) {
                        swal({
                            text: res.data.CardcronjobSaveRule.msg,
                            icon: "success"
                        });
                        this.props.history.push("/automateFunds");
                    } else {
                        swal({
                            text: res.data.CardcronjobSaveRule.msg,
                            icon: "warning"
                        })
                    }
                })
                .catch(err => {
                    swal({ text: err.message, icon: "warning" })
                })
        } else {
            swal("Fund allocated should be less than card limit", { icon: "error" });
        }
    }

    closeForm = () => {
        this.props.history.push("/automateFunds");
    };

    render() {
        return (
            <div className="addbudgetSection">
                <h2>Add Automate Funds</h2>
                {this.state.allParticipantList !== undefined &&
                    this.state.allParticipantList &&
                    this.state.allParticipantList !== "" ? (
                    <FormComponent
                        formConfig={addAutomateFundsConfig}
                        preSubmitChanges={this.preSubmitChanges}
                        buttonTitleCSS="adminParticipant"
                        modalCloseCallback={() => { }}
                        closeButton={this.closeForm}
                        params={{
                            participantOptions: this.state.allParticipantList,
                            timePeriodOptions: this.state.timePeriod
                        }}
                    />
                ) : (
                    <div className="spinner">
                        <DotLoader size={70} color={"#020f1f"} />
                    </div>
                )}
            </div>
        )
    }
}
