import React, { Component } from "react";
import FormComponent from "../../Form/FormComponent";
import { addBuisnessConfig, editBuisnessConfig } from "./BuisnessConfig";
import { fetchMethod } from "../../FetchMethod";
import swal from "sweetalert";
import {
  allBusinessesInfo,
  saveBusiness,
  getCardDetailsQuery,
  updateBankDetail,
  allcardtypes,
  businessTypesListing,
  businessQuery,
  businessTypeQuery,
  editBusiness,
  activeInactiveStoreStatus,
  categoryQueryData,
  businessType
} from "./BuisnessQuery";
import "./addBuisness.css";
import moment from "moment";
export default class EditBuisness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardid: undefined,
      editableData: undefined,
      cardnumber: undefined,
      storeTypeOptions: [],
      activeStatusOfStores: ["ACTIVE", "INACTIVE"]
    };
  }

  componentWillMount() {
    editBuisnessConfig.fields = editBuisnessConfig.fields.filter(editableField => {
      if (!editableField.name.includes('storeType') && !editableField.name.includes('deleteButton') && !editableField.name.includes('Button') && !editableField.name.includes('addStoreType1')) {
        return true;
      } else {
        delete editBuisnessConfig.validations[editableField.name];
        return false;
      }
    })
    this.getBusinessTypes();
    // this.getBusinessActiveStatus(this.props.location.state.details);
    fetchMethod(allcardtypes)
      .then(res => res.json())
      .then(res => {
        return res !== undefined && res.data.allCardtypes !== undefined
          ? this.setState({
            cardtypeOptions: res.data.allCardtypes.Cardtypes.map(item => {
              return {
                id: item.id,
                name: item.cardtype
              };
            })
          })
          : "";
      })
      .catch(e => {
        swal({ title: e.message, icon: "warning" });
      });
    if (this.props.location.state !== undefined) {
      this.editBuisnessData(this.props.location.state.details);
    }
  }

  // getBusinessActiveStatus = (id) => {
  //   fetchMethod(activeInactiveStoreStatus(id))
  //     .then(res => res.json())
  //     .then(res => {
  //       if(res?.data?.allBusinesses?.Businesses.length) {
  //         this.setState({
  //           activeStatusOfStores: res?.data?.allBusinesses?.Businesses[0].active
  //         })
  //       }
  //     })
  // }

  // getBusinessTypes = () => {
  //   fetchMethod(businessTypesListing, {
  //     where: {
  //       isActive: 1
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(res => {
  //       if (res?.data?.allMasterItemCategories?.MasterItemCategories.length) {
  //         this.setState({
  //           storeTypeOptions: res.data.allMasterItemCategories.MasterItemCategories.map(business => {
  //             return {
  //               id: business.id,
  //               name: business.categoryName.charAt(0).toUpperCase() + business.categoryName.slice(1)
  //             }
  //           })
  //         })
  //       }
  //       console.log("types", this.state.storeTypeOptions)
  //     })
  // }
  getBusinessTypes = () => {
    if (localStorage.getItem('role') === 'GUARDIAN') {
      fetchMethod(businessType, { where: { isActive: 1 } })
        .then(res => res.json())
        .then(res => {
          // console.log(res);
          if (res?.data?.allMasterItemCategories?.MasterItemCategories.length) {
            this.setState({
              storeTypeOptions: res.data.allMasterItemCategories.MasterItemCategories.map(business => {
                return {
                  id: business.id,
                  name: business.categoryName.charAt(0).toUpperCase() + business.categoryName.slice(1)
                }
              })
            })
          }
        })
        .catch(() => {
          swal("Something went wrong", {
            icon: 'warning'
          })
        })
    } else {
      fetchMethod(businessType, {
        where: { isActive: 1 }
      })
        .then(res => res.json())
        .then(res => {
          if (res?.data?.allMasterItemCategories?.MasterItemCategories.length) {
            this.setState({
              storeTypeOptions: res.data.allMasterItemCategories.MasterItemCategories.map(business => {
                return {
                  id: business.id,
                  name: business.categoryName.charAt(0).toUpperCase() + business.categoryName.slice(1)
                }
              })
            })
          }
        })
        .catch(() => {
          swal("Something went wrong", {
            icon: 'warning'
          })
        })
    }
  }

  editBuisnessData = id => {
    fetchMethod(allBusinessesInfo(id))
      .then(res => res.json())
      .then(async (res) => {
        const response = await fetchMethod(businessTypeQuery, { where: { storeId: id } }).then(res => res.json())
        const storeType = response?.data?.allStoreConnectedStoreTypes?.StoreConnectedStoreTypes.map(store => {
          return {
            id: store?.fkStoreConnectedStoreTypeStoreTypeIdrel?.MasterItemCategories[0]?.id,
            name: store?.fkStoreConnectedStoreTypeStoreTypeIdrel?.MasterItemCategories[0]?.categoryName
          }
        });
        fetchMethod(getCardDetailsQuery, {
          where: { businessId: id }
        })
          .then(resp => resp.json())
          .then(resp => {
            const cardDetails = resp?.data?.allCardDetails?.CardDetails[0] || {};
            const businesses = res?.data?.allBusinesses?.Businesses[0] || {};
            // console.log(businesses);
            businesses['BSB'] = cardDetails?.bsb || '';
            businesses['cardNumber'] = cardDetails?.cardNumber || '';
            businesses['accNo'] = cardDetails?.accountnumber || '';
            businesses['expirydate'] = cardDetails?.expiryDate || '';
            businesses['cvv'] = cardDetails?.cvc || '';
            businesses['holdername'] = cardDetails?.accountname || '';
            businesses['active'] = businesses.active === 0 ? 'INACTIVE' : 'ACTIVE';
            businesses['cardtypeid'] = cardDetails?.fkcardtypeidrel?.Cardtypes[0] ? {
              id: cardDetails?.fkcardtypeidrel?.Cardtypes[0]?.id || '',
              name: cardDetails?.fkcardtypeidrel?.Cardtypes[0]?.cardtype || ''
            } : null;
            businesses['storeTypeId'] = storeType;
            if (storeType?.length) {
              storeType.map((store, index) => {
                businesses["storeType" + index] = store;
                if (index === 0) {
                  editBuisnessConfig.fields.splice(editBuisnessConfig.fields.length, 0, {
                    label: "Store Type",
                    type: "searchSelect",
                    name: "storeType" + index,
                    value: store,
                    data: "storeTypeOptions"
                  });
                } else {
                  editBuisnessConfig.fields.splice(editBuisnessConfig.fields.length, 0, {
                    label: "Store Type",
                    type: "searchSelect",
                    name: "storeType" + index,
                    value: store,
                    data: "storeTypeOptions"
                  }, {
                    type: "deleteStoreTypeButton",
                    name: "deleteButton",
                    value: ""
                  });
                }
                if (index === storeType.length - 1) {
                  editBuisnessConfig.fields.push({
                    label: "Add Store Type",
                    type: "addStoreTypeButton",
                    name: "Button",
                    value: ""
                  });
                }
                if (!editBuisnessConfig.validations["storeType" + index]) {
                  editBuisnessConfig.validations["storeType" + index] = e => {
                    return e ? { valid: true } : { valid: false, errMsg: "Please choose store type" };
                  }
                }
              });

            } else {
              editBuisnessConfig.fields.push({
                label: "Store Type",
                type: "searchSelect",
                name: "addStoreType1",
                data: "storeTypeOptions"
              })
              if (!editBuisnessConfig.validations["addStoreType1"]) {
                editBuisnessConfig.validations["addStoreType1"] = e => {
                  return e ? { valid: true } : { valid: false, errMsg: "Please choose store type" };
                }
              }
            }
            this.setState({
              cardid: businesses.id,
              editableData: businesses,
              cardnumber: businesses.cardNumber
            });
            // console.log('final array', storeType, editBuisnessConfig);
          });
        // resp.data.allCardDetails.CardDetails.length > 0
        // this.setState({ editableData: res.data.allBusinesses.Businesses[0] });
      })
      .catch(e => {
        swal({ title: e.message, icon: "warning" });
        this.props.history.push("/buisness");
      });
  };

  closeForm = () => {
    this.props.history.push("/buisness");
  };

  preSubmitChanges = e => {
    let businessObj = {
      id: this.props.location.state.details,
      active: e.active === 'ACTIVE' ? 1 : 0,
      storeName: e.storeName,
      storeTypeId: e.storeTypeId,
      abnNumber: e.abnNumber,
      websiteUrl: e.websiteUrl,
      email: e.email,
      txnLocationAddress: e.txnLocationAddress,
      txnLocationCity: e.txnLocationCity,
      txnLocationState: e.txnLocationState,
      txnLocationCountry: e.txnLocationCountry,
      contactLocationAdress: e.contactLocationAdress,
      contactLocationCity: e.contactLocationCity,
      contactLocationState: e.contactLocationState,
      contactLocationCountry: e.contactLocationCountry,
      storeManagerName: e.storeManagerName,
      adminId: JSON.parse(localStorage.getItem("userInfo")).id,
      // createdBy: `${ JSON.parse(localStorage.getItem("userInfo")).id }`,
      updatedBy: `${JSON.parse(localStorage.getItem("userInfo")).id}`,
      mobileNo: parseFloat(e.mobileNo),
      storeTelephoneNo: parseFloat(e.storeTelephoneNo),
      contactLocationZipCode: parseFloat(e.contactLocationZipCode),
      txnLocationZipCode: parseFloat(e.txnLocationZipCode)
    };

    fetchMethod(editBusiness, { data: businessObj })
      .then(res => res.json())
      .then(response => {
        // console.log(response);
        if (response?.data?.BusinessEdit?.status === 200) {
          swal({ text: "Business updated successfully", icon: "success" });
          // this.props.history.push("/buisness");
        } else {
          swal("Please try again", { icon: "error" });
          // this.props.history.push("/buisness");
        }
        localStorage.getItem('path') === 'addByGuardian' ? this.props.history.push("/addedBusiness") : this.props.history.push("/buisness");
        localStorage.removeItem('path');
      })
      .catch(() => {
        swal("Something went wrong", {
          icon: 'warning'
        })
      })
    return false;
  };

  render() {
    return (
      <div>
        {this.props.location.state !== undefined ? (
          this.state.editableData !== undefined &&
            this.state.cardtypeOptions != undefined && this.state.storeTypeOptions ? (
            <div className="addBuisnessSection">
              <h3>Edit Business</h3>
              <FormComponent
                editableData={this.state.editableData}
                formConfig={editBuisnessConfig}
                preSubmitChanges={this.preSubmitChanges}
                buttonTitleCSS="buisnessSaveButton"
                modalCloseCallback={() => { }}
                closeButton={this.closeForm}
                params={{
                  cardtypeOptions: this.state.cardtypeOptions,
                  storeTypeOptions: this.state.storeTypeOptions,
                  activeStatusOfStores: this.state.activeStatusOfStores
                }}
              />
            </div>
          ) : (
            ""
          )
        ) : (
          this.props.history.push("/buisness")
        )}
      </div>
    );
  }
}
