import React from "react";
import { useSelector } from "react-redux";
import { budgetList } from "./ConnectedSupportersConfig";
import ReactTableComponent from "src/ReactTable/ReactTable";
import { deepCopy } from "src/utils/utils";

import { deleteBudget } from "src/network/service/walletService";
import ConfirmationPrompt from "src/Components/UI/ConfirmationPrompt";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { fetchWalletDetailsAction } from "./walletSlice";

function WalletConnectedBudgets() {
  const { wallet, budgets } = useSelector((state) => state.wallet.walletDetail);
  const dispatch = useDispatch();

  const handleDeleteWallet = (data) => {
    ConfirmationPrompt({
      visible: true,
      title: "Are you sure! \nDo you want to delete this Budget?",
      onConfirm: () => {
        deleteBudget(data.original.id).then(() => {
          dispatch(fetchWalletDetailsAction(wallet.id));
        });
      }
    });
  };

  const actionButton = [
    {
      Header: "",
      sortable: false,
      Cell: (row) => (
        <div>
          <Button onClick={(e) => handleDeleteWallet(row)}>Remove</Button>
        </div>
      )
    }
  ];
  const columns = nameColumn.concat(budgetList.columns).concat(actionButton);

  return (
    <div className="connectedSupporterTable">
      <ReactTableComponent
        listData={budgets != null ? deepCopy(budgets) : []}
        listConfig={budgetList}
        columns={columns}
        dataCount={budgets?.length}
        forSerialNo={{
          pageNo: 1,
          pageSize: 1
        }}
      />
    </div>
  );
}

const nameColumn = [
  {
    Header: "S No.",
    Cell: (row) => {
      return <div className="dot">{row.original.sNo}</div>;
    },
    width: 45
  }
];

export default WalletConnectedBudgets;
